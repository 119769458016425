import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from "react-redux"
import CustomizerReducer from "../reducer/CustomizerSlice"
import CommonDataSlice from "../reducer/CommonDataSlice"
import UserDataSlice from "../reducer/UserDataSlice"

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    commonData: CommonDataSlice,
    userData: UserDataSlice,
  },
})

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  commonData: CommonDataSlice,
  userData: UserDataSlice,
})

export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const { dispatch } = store
export const useDispatch = () => useAppDispatch<AppDispatch>()
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector

export default store
