import { styled, useTheme } from "@mui/material"
import MenuIcon from "src/assets/icon/MenuIcon"
import NavItem from "./NavItem"
import { IconChevronDown, IconChevronUp } from "@tabler/icons"
import { ListIcon, ListItemBtn, ListText, SubMenuCollapse } from "src/assets/styles/sideMenu"
import { useEffect, useLocation, usePermissionHook, useState, useTranslation } from "src/hooks"
import { PERMISSION_MODULE } from "src/interface"
import { PAGE_URL } from "src/routes"
import { getPathName } from "src/utils"

type NavGroupProps = {
  [x: string]: any
  navlabel?: boolean
  subheader?: string
  title?: string
  icon?: any
  href?: any
}

interface NavCollapseProps {
  menu: NavGroupProps
  level: number
  pathWithoutLastPart: any
  pathDirect: any
  hideMenu: any
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

// FC Component For Dropdown Menu
const NavCollapse = ({ menu, level, pathWithoutLastPart, pathDirect, hideMenu, onClick }: NavCollapseProps) => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { getModuleGeneralAccess } = usePermissionHook()

  const handleClick = () => {
    setOpen(!open)
  }

  const {
    loanApprovalDetail,
    loanManagementDetails,
    loanManagementTransactionDetail,
    customerManagementDetails,
    organizationManagementDetails,
    approvalWorkflow,
  } = PAGE_URL;
  const prevLocation = [
    getPathName(loanApprovalDetail, 2),
    getPathName(loanManagementDetails, 2),
    getPathName(customerManagementDetails, 2),
    getPathName(organizationManagementDetails, 2),
    getPathName(loanManagementTransactionDetail, 2),
    getPathName(approvalWorkflow, 1),
  ].includes(getPathName(location.pathname, 2))
    ? `/${getPathName(location.pathname, 1)}/${getPathName(
      location.pathname,
      2
    )}`
    : location.pathname;

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false)
    menu?.children?.forEach((item: any) => {
      if (item?.href === prevLocation) {
        setOpen(true)
      }
    })
  }, [pathname, prevLocation, menu.children])

  const ListItemStyled = styled(ListItemBtn)(() => ({
    ...(open &&
      level < 2 && {
      ".MuiListItemIcon-root": {
        svg: {
          fill: theme.palette.primary.main,
          path: {
            fill: theme.palette.primary.main,
          },
          circle: {
            fill: theme.palette.primary.main,
          },
        },
      },
      "&:hover": {
        backgroundColor:
          pathname.includes(menu.href) || open ? theme.palette.grey["200"] : theme.palette.secondary.main,
        color: theme.palette.grey["600"],
        ".MuiListItemIcon-root": {
          svg: {
            fill: theme.palette.grey["600"],
            path: {
              fill: theme.palette.grey["600"],
            },
            circle: {
              fill: theme.palette.grey["600"],
            },
          },
        },
      },
    }),
    color:
      open && level < 2
        ? theme.palette.primary.main
        : `inherit` && level > 1 && open
          ? theme.palette.primary.main
          : theme.palette.text.secondary,
  }))

  // If Menu has Children
  const submenus = menu.children?.map((item: any) => {
    const access = getModuleGeneralAccess(item.module as PERMISSION_MODULE)

    const navCollapseProps = {
      menu: item,
      level: level + 1,
      pathWithoutLastPart: pathWithoutLastPart,
      pathDirect: pathDirect,
      hideMenu: hideMenu,
      onClick: onClick,
    }

    const navItemProps = {
      item: item,
      level: level + 1,
      pathDirect: pathDirect,
      hideMenu: hideMenu,
      onClick: onClick,
    }

    if (item.children) {
      return <NavCollapse key={item?.id} {...navCollapseProps} />
    } else {
      return item.module ? (
        access ? (
          <NavItem key={item.id} {...navItemProps} />
        ) : null
      ) : (
        <NavItem key={item.id} {...navItemProps} />
      )
    }
  })

  return (
    <>
      <ListItemStyled onClick={handleClick} key={menu?.id}>
        <ListIcon className={level > 1 ? "child_icon" : "parent_icon"}>
          <MenuIcon icon={menu.icon} />
        </ListIcon>
        <ListText>{hideMenu ? "" : <>{t(`${menu.title}`)}</>}</ListText>
        {!open ? <IconChevronDown size="1rem" /> : <IconChevronUp size="1rem" />}
      </ListItemStyled>
      <SubMenuCollapse in={open} timeout="auto" unmountOnExit>
        {submenus}
      </SubMenuCollapse>
    </>
  )
}

export default NavCollapse
