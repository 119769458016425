import { IconButton, Box, AppBar, useMediaQuery, Toolbar, styled } from "@mui/material"
import { dispatch, toggleSidebar, toggleMobileSidebar } from "src/redux"
import { IconMenu2 } from "@tabler/icons"
import Notifications from "./Notification"
import Profile from "./Profile"
import Search from "./Search"

import { useSelector } from "src/hooks"

const HeaderIconSection = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginRight: "25px",
  button: {
    marginRight: "15px",
    "&:last-child": {
      marginRight: "0px",
    },
  },
}))

const Header = () => {
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"))

  // drawer
  const customizer = useSelector(state => state.customizer)

  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    justifyContent: "center",
    backdropFilter: "blur(4px)",
    [theme.breakpoints.up("lg")]: {
      minHeight: customizer.TopbarHeight,
    },
  }))
  const ToolbarStyled = styled(Toolbar)(() => ({
    width: "100%",
    paddingInline: "30px !important",
  }))

  return (
    <AppBarStyled position="sticky" color="default" id="header">
      <ToolbarStyled>
        {!lgUp && (
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={lgUp ? () => dispatch(toggleSidebar()) : () => dispatch(toggleMobileSidebar())}
          >
            <IconMenu2 size="20" />
          </IconButton>
        )}

        <Search />

        <Box flexGrow={1} />

        <HeaderIconSection>
          <Notifications />
        </HeaderIconSection>
        <Profile />
      </ToolbarStyled>
    </AppBarStyled>
  )
}

export default Header
