import { styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { CenterFlex } from "src/assets/styles/custom";
import { IKey, IStatusCount, IListCount } from "src/interface";
import { PAGE_URL } from "src/routes";
import { statusIndicatorColor } from "src/theme";

interface IListCountProps {
  options: IListCount[];
  countValues: IStatusCount;
}

const TabsRow = styled("div")(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey["300"]}`,
  display: "flex",
  flexWrap: "wrap"
}));

const TabButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px 20px",
  fontSize: "0.8125rem",
  fontWeight: "500",
  border: "none",
  background: "unset",
  cursor: "pointer",
  ".count": {
    ...CenterFlex(),
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background: theme.palette.grey[200],
    color: theme.palette.text.primary,
    fontWeight: "600",
    fontSize: "0.75rem",
    marginLeft: "12px",
  },
}));

export default function TabFilter(props: IListCountProps) {
  const { options, countValues } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (label: string) => {
    if (location.pathname === "/organization-management/organizations") {
      if (label === "Deactivated") {
        navigate(`${PAGE_URL.organizationManagement}?tab=${label}`)
      } else {
        navigate(`${PAGE_URL.organizationManagement}`)
      }
    } else if(location.pathname === "/loan-management/loan-transactions"){
      if(label === "Bulk payment"){
        navigate(`${PAGE_URL.loanManagementTransaction}?tab=${label}`)
      } else {
        navigate(`${PAGE_URL.loanManagementTransaction}`)
      }
    }
  }

  return (
    <>
      <TabsRow>
        {options.map((item: IListCount, i: number) => {
          return (
            <TabButton key={i} onClick={() => handleNavigate(item.label)}>
              <span className="label">{item.label}</span>
              <span
                className="count"
                style={{
                  background: `rgb(${statusIndicatorColor[item.value as IKey]
                    }/0.15)`,
                  color: `${item.value === "all"
                    ? "initial"
                    : `rgb(${statusIndicatorColor[item.value as IKey]})`
                    }`,
                }}
              >
                {countValues[item.value as IKey]}
              </span>
            </TabButton>
          );
        })}
      </TabsRow>
    </>
  );
}
