import { styled } from "@mui/material"
import { useCallback, useEffect, useState } from "react"

const SideDialogContentStyle = styled("div")(({ theme }) => ({
  padding: "15px 25px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.grey["500"],
    borderRadius: "10px",
  },
}))

export default function SideDialogContent({ children }: { children: JSX.Element }) {
  const [height, setHeight] = useState<number>(0)
  const getMeasurement = useCallback(() => {
    const sideDialogHeader = document.querySelector("#sideDialogHeader")?.clientHeight as number
    const sideDialogFooter = document.querySelector("#sideDialogFooter")?.clientHeight as number

    const heightDeduction = sideDialogHeader + sideDialogFooter
    setHeight(heightDeduction)
  }, [])

  useEffect(() => {
    getMeasurement()
    window.addEventListener("resize", getMeasurement)

    return () => window.removeEventListener("resize", getMeasurement)
  }, [getMeasurement])

  return <SideDialogContentStyle style={{ height: `calc(100vh - ${height}px)` }}>{children}</SideDialogContentStyle>
}
