import { Toaster } from "react-hot-toast"

export default function ResponseAlert() {
  return (
    <>
      <Toaster
        toastOptions={{
          position: "bottom-right",
          style: { minWidth: "280px", fontWeight: "500", fontSize: "1rem", zIndex: 1 },
        }}
        reverseOrder={false}
      />
    </>
  )
}
