import { CloseOutlined } from "@mui/icons-material";
import { DialogProps } from "@mui/material/Dialog";
import { DialogStyle, DialogWrapper } from "src/assets/styles/custom";

type Dialog = DialogProps & {
  blurbackdrop?: "true" | "false";
  enableclosebtn?: "true" | "false";
  onClose?: any;
};

export default function CMDialog(props: Dialog) {
  const { blurbackdrop, enableclosebtn, onClose, ...dialogProps } = props;

  const handleDialogClose = (event: object, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return; // Do nothing for these reasons
    }
    if (onClose) {
      onClose(event, reason); // Call the passed onClose function if provided
    }
  };

  return (
    <DialogStyle
      onClose={handleDialogClose}
      className={blurbackdrop ? "blur" : ""}
      {...dialogProps}
      transitionDuration={500}
    >
      <DialogWrapper>
        {enableclosebtn && (
          <button className="close">
            <CloseOutlined />
          </button>
        )}
        {props.children}
      </DialogWrapper>
    </DialogStyle>
  );
}
