import { RestartAltOutlined, TuneOutlined } from "@mui/icons-material"
import { IDropdownEvent, IDropdownOptions, IKey, IQueryUpdate } from "src/interface"
import { useEffect, useQueryParamsHook, useSelector, useState } from "src/hooks"
import { selectOptionFormater } from "src/utils"
import { orgManagmentFilter, userManagmentFilters } from "src/resources"

//Components
import { MultiSelectDropdown, SelectDropdown } from "src/components"
import { SideDialog, SideDialogSideHeader, SideDialogContent, SideDialogSideFooter } from "src/templates"

//Styles
import { ButtonRow, CMButton, IconButton, CMDialogTitle } from "src/assets/styles/custom"
import { FormGroup, FormLabel } from "src/assets/styles/form"
import { FilterDayOptions, customerManagmentFilters, customerRequestFilter, loanManageFilter } from "src/resources/options"

interface IDataFilters {
  type: "users" | "organisation" | "roles" | "customers" | "emoloyees" | "repayment" | "loanRequest" | "loan"
  hideStatus?: boolean
  customFilters?:
  | {
    label: string
    list: IDropdownOptions[]
    paramName: string
    placeHolder: string
  }[]
  | null
}

interface IFormDataFilter {
  role: string[]
  status: string[]
  dateWise: string
  organization: string[]
  users: string[]
  category: string[]
  action: string[]
  browsers: string[]
}

export default function DataFilters(props: IDataFilters) {
  const { type, hideStatus, customFilters } = props
  const allRoles = useSelector(state => state.commonData.allRoles)
  const filter = useSelector(state => state.commonData.filter)
  const { updateQueryParam } = useQueryParamsHook()

  const [state, setState] = useState<{ values: IFormDataFilter } & { visible: boolean }>({
    visible: false,
    values: {
      role: [],
      status: [],
      dateWise: "",
      organization: [],
      users: [],
      category: [],
      action: [],
      browsers: [],
    },
  })
  const { visible, values } = state
  const { role, status, dateWise, organization } = values

  const changeHandler = (event: IDropdownEvent) => {
    const { name, value } = event
    setState(state => ({
      ...state,
      values: {
        ...state.values,
        [name]: value,
      },
    }))
  }

  // Handler for form submission
  const submitHandler = () => {
    if (role.length > 0 && status.length === 0 && dateWise === "" && organization.length === 0) {
      updateQueryParam([{ name: "role", value: role.join(",") }], ["status", "dateWise", "organization"]);
    } else if (status.length > 0 && role.length === 0 && dateWise === "" && organization.length === 0) {
      updateQueryParam([{ name: "status", value: status.join(",") }], ["role", "dateWise", "organization"]);
    } else if (dateWise !== "" && status.length === 0 && role.length === 0 && organization.length === 0) {
      updateQueryParam([{ name: "dateWise", value: dateWise }], ["role", "status", "organization"]);
    } else if (role.length > 0 && status.length > 0 && dateWise === "" && organization.length === 0) {
      updateQueryParam(
        [
          { name: "role", value: role.join(",") },
          { name: "status", value: status.join(",") },
        ],
        ["dateWise", "organization"]
      );
    } else if (dateWise !== "" && status.length > 0 && role.length === 0 && organization.length === 0) {
      updateQueryParam(
        [
          { name: "dateWise", value: dateWise },
          { name: "status", value: status.join(",") },
        ],
        ["role", "organization"]
      );
    } else if (organization.length > 0 && status.length > 0 && role.length === 0 && dateWise === "") {
      // New condition to validate both organization and status
      updateQueryParam(
        [
          { name: "organization", value: organization.join(",") },
          { name: "status", value: status.join(",") },
        ],
        ["role", "dateWise"]
      );
    } else if (customFilters) {
      const queryParams: IQueryUpdate[] = []
      customFilters.forEach(data => {
        if ((values[data.paramName as IKey] as string[]).length > 0) {
          queryParams.push({
            name: data.paramName,
            value: (values[data.paramName as IKey] as string[]).join(","),
          })
        }
      })

      updateQueryParam(queryParams, queryParams.length > 0 ? [] : customFilters.map(data => data.paramName))
    } else {
      updateQueryParam([], ["role", "status", "dateWise"])
    }
    setState(state => ({ ...state, visible: false }))
  }

  const enableFilterIndicator = filter?.role || filter?.status || filter?.dateWise || filter?.organization || filter?.users || filter?.category || filter?.action || filter?.browsers

  const closeHandler = () => {
    setState(state => ({ ...state, visible: false }))
    setTimeout(() => {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          role: [],
          status: [],
          dateWise: "",
          organization: [],
          users: [],
          category: [],
          action: [],
          browsers: [],
          ...filter,
        },
      }))
    }, 300)
  }

  const AllStatusOptions = {
    users: userManagmentFilters,
    organisation: orgManagmentFilter,
    roles: userManagmentFilters,
    customers: customerManagmentFilters,
    emoloyees: userManagmentFilters,
    repayment: userManagmentFilters,
    loanRequest: customerRequestFilter,
    loan: loanManageFilter
  }

  useEffect(() => {
    if (filter) {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          role: filter?.role ?? [],
          status: filter?.status ?? [],
          dateWise: filter?.dateWise ?? "",
          organization: filter?.organization ?? [],
          users: filter?.users ?? [],
          category: filter?.category ?? [],
          action: filter?.action ?? [],
          browsers: filter?.browsers ?? [],
        },
      }))
    }
  }, [filter])

  return (
    <>
      <IconButton onClick={() => setState(state => ({ ...state, visible: true }))}>
        <svg width="569" height="554" viewBox="0 0 569 554" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M131.875 1.60912C99.0469 8.81082 72.6718 34.5312 64.8154 67.0792L63.2254 73.7197H31.6127H0V91.4902V109.261H32.6415H65.283L65.8442 112.066C66.873 117.398 72.2041 128.995 76.9741 136.197C88.5716 153.967 106.997 167.248 128.321 173.047C134.868 174.824 138.422 175.198 150.581 175.198C166.668 175.198 172.373 174.076 185.374 168.651C207.914 159.111 226.994 138.161 234.383 114.592L236.066 109.261H402.36H568.654V91.4902V73.7197H403.109H237.563L237.095 71.1009C235.692 64.2733 230.642 50.5246 227.555 45.0064C216.332 24.8042 197.158 9.74611 174.431 3.01205C162.927 -0.354986 143.66 -1.00969 131.875 1.60912ZM161.15 36.7759C179.856 40.7041 195.007 55.1075 200.713 74.2809C202.677 80.9214 202.864 92.7996 201.18 99.9078C197.72 114.031 186.964 127.499 174.057 134.046C153.013 144.614 130.66 140.967 114.011 124.319C105.874 116.088 101.946 109.074 99.5145 98.3178C93.4351 71.1009 111.86 42.8553 139.638 36.7759C147.308 35.0924 153.387 35.0924 161.15 36.7759Z"
            fill="#151515"
          />
          <path
            d="M417.044 192.034C384.59 198.206 358.963 220.747 348.768 252.359L346.243 260.309L173.122 260.59L0 260.777V278.548V296.318H173.028C310.702 296.318 346.15 296.599 346.43 297.44C352.042 317.081 357.373 326.995 368.409 338.593C381.597 352.529 397.59 361.507 415.267 365.155C425.93 367.306 446.412 366.558 456.42 363.659C486.817 354.96 510.479 330.643 518.149 300.246L519.178 296.318H543.869H568.654V278.548V260.777H543.963H519.271L517.214 253.949C507.861 223.179 483.917 200.638 452.492 192.875C443.981 190.818 425.742 190.35 417.044 192.034ZM444.916 228.136C455.952 230.474 468.017 238.704 475.126 248.712C486.349 264.799 487.752 286.123 478.586 303.146C462.125 334.104 420.785 340.276 396.094 315.491C383 302.397 377.856 284.44 382.065 266.576C387.676 242.726 412.087 224.675 435.844 226.826C438.649 227.107 442.765 227.668 444.916 228.136Z"
            fill="#151515"
          />
          <path
            d="M242.707 378.249C206.324 381.523 176.489 405.092 165.452 439.417L162.927 447.367L81.4635 447.647L0 447.834V466.073V484.311L81.4635 484.498L162.927 484.778L164.985 491.793C173.496 520.693 197.252 543.514 227.181 551.371C238.592 554.457 258.42 554.831 268.801 552.306C295.364 545.665 315.94 529.391 327.818 505.355C330.624 499.649 333.43 492.728 334.178 489.922L335.394 484.778L452.024 484.498L568.654 484.311V466.073V447.834L452.024 447.647L335.487 447.367L333.804 441.381C325.199 410.984 298.731 386.386 267.586 380.12C260.664 378.717 248.412 377.781 242.707 378.249ZM260.197 415.193C278.622 419.028 294.802 434.647 299.759 453.446C301.536 460.087 301.536 472.152 299.759 478.979C295.083 496.843 280.118 511.902 262.254 516.578C255.146 518.449 242.052 518.355 235.599 516.484C217.735 511.247 203.331 496.563 198.748 478.979C196.878 471.965 196.971 460.367 198.842 453.072C203.425 435.302 218.389 420.431 236.628 415.661C242.894 413.977 253.463 413.79 260.197 415.193Z"
            fill="#151515"
          />
        </svg>
        {enableFilterIndicator && <span className="indication"></span>}
      </IconButton>
      <SideDialog size="medium" open={visible} closeHandler={closeHandler} blurbackdrop="true">
        <>
          <SideDialogSideHeader>
            <>
              <CMDialogTitle>
                <TuneOutlined /> Filter
              </CMDialogTitle>
              {enableFilterIndicator && (
                <IconButton
                  onClick={() => {
                    // Clear the form state
                    setState((state) => ({
                      ...state,
                      values: {
                        ...state.values,
                        role: [],
                        status: [],
                        dateWise: "",
                        organization: [],
                        users: [],
                        category: [],
                        action: [],
                        browsers: [],
                      },
                    }));

                    // Clear the query parameters
                    updateQueryParam([], ["role", "status", "dateWise", "organization", "users", "category", "action", "browsers"]);
                  }}
                >
                  <RestartAltOutlined />
                </IconButton>
              )}
            </>
          </SideDialogSideHeader>
          <SideDialogContent>
            <>
              {["users"].includes(type) && (
                <FormGroup>
                  <FormLabel>Roles</FormLabel>
                  <MultiSelectDropdown
                    name="role"
                    options={selectOptionFormater(allRoles, ["Role_Name", "_id"])}
                    selected={role}
                    onChange={changeHandler}
                    placeholder="Select the users Roles"
                  />
                </FormGroup>
              )}
              {!hideStatus && (
                <FormGroup>
                  <FormLabel>Status</FormLabel>
                  <MultiSelectDropdown
                    name="status"
                    options={AllStatusOptions[type]}
                    selected={status}
                    onChange={changeHandler}
                    placeholder="Select the user status"
                  />
                </FormGroup>
              )}
              {customFilters &&
                customFilters.map((filters, index) => {
                  return (
                    <FormGroup key={index}>
                      <FormLabel>{filters.label}</FormLabel>
                      <MultiSelectDropdown
                        name={filters.paramName}
                        options={filters.list}
                        selected={values[filters.paramName as IKey]}
                        onChange={changeHandler}
                        placeholder={filters.placeHolder}
                      />
                    </FormGroup>
                  )
                })}
              {["loanRequest"].includes(type) && (
                <FormGroup>
                  <FormLabel>Filter</FormLabel>
                  <SelectDropdown
                    name="dateWise"
                    options={FilterDayOptions}
                    selected={dateWise}
                    onChange={changeHandler}
                    placeholder="Select the Day Filter"
                  />
                </FormGroup>
              )}
            </>
          </SideDialogContent>
          <SideDialogSideFooter>
            <ButtonRow>
              <CMButton variant="contained" color="secondary" onClick={closeHandler}>
                Cancel
              </CMButton>
              <CMButton variant="contained" color="primary" onClick={submitHandler}>
                Submit
              </CMButton>
            </ButtonRow>
          </SideDialogSideFooter>
        </>
      </SideDialog>
    </>
  )
}
