import { Box, Card, CardContent, Stack, styled } from "@mui/material"

export const DetailsBox = styled("div")(({ theme }) => ({
  borderRadius: "15px",
  boxShadow: theme.shadows["3"],
  padding: "25px",
  marginBottom: "15px",
  p: {
    color: theme.palette.grey["600"],
    margin: "0",
  },
}))

export const ProfileSection = styled("div")(() => ({
  position: "sticky",
  top: "20px",
}))

export const ProfileDetails = styled("div")(() => ({
  marginBottom: "25px",
  textAlign: "center",
  ".org_img": {
    width: "115px",
    height: "115px",
    objectFit: "cover",
    objectPosition: "center",
    display: "block",
    borderRadius: "50%",
    margin: "auto",
    marginBottom: "20px",
  },
  h2: {
    fontSize: "1.125rem",
    fontWeight: "600",
    margin: "0px",
    marginBottom: "10px",
  },
  p: {
    fontWeight: "500",
  },
}))

export const CountDetails = styled("div")(({ theme }) => ({
  background: theme.palette.grey["100"],
  border: `1px solid ${theme.palette.grey["300"]}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "15px",
  borderRadius: "15px",
  marginBottom: "25px",
  div: {
    textAlign: "center",
    flex: 1,
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    h3: {
      fontSize: "1rem",
      marginTop: "0px",
      marginBottom: "5px",
      color: theme.palette.primary.main,
      ".textWrap": {
        fontSize: "0.875rem",
        marginTop: "0px",
        marginBottom: "5px",
        color: theme.palette.primary.main,
        fontWeight: "600",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "100px",
      }
    },
    p: {
      fontWeight: "500",
    },
  },
}))

export const ContactInfoSection = styled("div")(() => ({}))

export const InfoList = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBlock: "7px",
  svg: {
    color: theme.palette.grey["600"],
  },
  a: {
    transitionDuration: "0.3s",
    color: theme.palette.grey["600"],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}))

export const InfoDesc = styled(Box)<{ highlight?: "true" | "false" }>(({ theme, highlight }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "15px",
  h4: {
    fontWeight: highlight === "true" ? "600" : "500",
    fontSize: highlight === "true" ? "0.875rem" : "0.8125rem",
    margin: "0px",
    color: highlight === "true" ? theme.palette.primary.main : "inherit",
  },
  p: {
    margin: "0px",
    fontSize: highlight === "true" ? "0.875rem" : "0.8125rem",
    color: highlight === "true" ? theme.palette.primary.main : theme.palette.grey["600"],
  },
}))

export const ContentBlock = styled(Box)(({ theme }) => ({
  padding: "20px",
  marginBottom: "25px",
  border: `1px solid ${theme.palette.grey["300"]}`,
  borderRadius: "15px",
  boxShadow: `0px 0px 0px 4px ${theme.palette.grey["100"]};`,
  "&:last-child": {
    marginBottom: "0px",
  },
  ".emp-details-main-tags": {
    flexDirection: "row",
    alignItems: "center",
    marginBlock: "10px",
    ".badge": {
      marginRight: "15px",
      padding: "12px 18px",
      ".label": {
        color: "#434343",
        marginRight: "5px",
        fontWeight: "600"
      }
    }
  },
  ".custom-label-value": {
    marginBlock: "7px",
    h4: {
      marginBlock: 0,
      minWidth: "180px",
      color: "#787878",
      fontWeight: "500"
    },
    p: {
      marginBlock: 0,
      color: "#434343",
      fontWeight: "600",
      "&.empasise": {
        color: "#0F2F51"
      }

    }
  },
  ".bold-text": {
    marginBlock: "5px",
    fontWeight: 600,
  },
  "&.credit-evaluation": {
    ".suggested-status": {
      p: {
        display: "flex",
        alignItems: "center"
      },
      ".badge": {
        marginLeft: "10px"
      }
    },
    ".c-score": {
      color: "rgb(93, 135, 255)",
      fontWeight: 500,
    },
    h3: {
      paddingBlock: "10px",
      fontWeight: 600
    },
    ".badge": {
      textTransform: "capitalize",
      marginLeft: '15px',
      display: "flex",
      alignItems: "end",
      svg: {
        marginRight: "5px"
      }
    },
    ".credit-content-holder": {
      ".h3": {
        marginBlock: 0
      }
    },

  },
  "&.remove-scroll-height": {
    ".table-scroll-body": {
      height: "auto"
    }
  },
  "&.payment-table-container,document-table-container": {
    ".custom-table": {
      paddingInline: 0
    }
  },
}))

export const ViewField = styled("div")(({ theme }) => ({
  marginBottom: "20px",
  borderBottom: `1px solid ${theme.palette.grey["300"]}`,
  paddingBottom: "5px",
  h4: {
    fontWeight: "500",
    marginTop: "0px",
    marginBottom: "8px",
  },
}))

export const DocumentDetailTable = styled("table")(({ theme }) => ({
  width: "100%",
  border: "none",
  textAlign: "center",
  borderCollapse: "collapse",
  "th, td": {
    border: "none",
  },
  ".download_icon": {
    marginInline: "auto",
  },
  th: {
    fontWeight: "600",
    fontSize: "0.875rem",
    paddingBlock: "10px",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  td: {
    paddingBlock: "15px",
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
  },
  tr: {
    "&:last-child": {
      td: {
        borderBottom: `none`,
      },
    },
  },
  "&.recent-doc-table": {
    th: {
      color: "#787878",
      fontWeight: "500",
    },
    td: {
      color: "#434343"
    },
    ".progress-container": {
      marginInline: "auto",
      width: "50px !important",
      height: "50px !important",
      ".MuiCircularProgress-root": {
        width: "50px !important",
        height: "50px !important",
      },
      ".badge": {
        marginInline: "auto"
      }
    }
  }
}))

export const FolderView = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: `1px solid ${theme.palette.grey["300"]}`,
  padding: "12px",
  borderRadius: "15px",
  ".folderDetails": {
    display: "flex",
    alignItems: "center",
    span: {
      fontWeight: "500",
    },
    svg: {
      width: "30px",
      height: "30px",
      marginRight: "15px",
      color: theme.palette.grey["600"],
    },
  },
}))

export const ContentHeight = styled(Box)(({ theme }) => ({
  height: "255px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.grey["500"],
    borderRadius: "10px",
  },
}))

export const ViewDetail = styled("div")(() => ({
  paddingBottom: "5px",
  h4: {
    fontWeight: 400,
    marginTop: "0px",
    marginBottom: "8px",
  },
  h5: {
    fontWeight: 600,
    marginTop: "0px",
    fontSize: "0.8175rem",
    marginBottom: "8px",
  },
  p: {
    fontWeight: 700,
    fontSize: "0.875rem",
    marginTop: "5px",
    marginBottom: "10px",
  }
}))

export const ActionStack = styled(Stack)({
  position: "absolute",
  top: "10px",
  right: "10px",
  zIndex: 1,
  opacity: 0,
  transition: "opacity 0.3s ease",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  backdropFilter: "blur(10px)",
  padding: "4px",
  borderRadius: "25px",
  display: "inline-block",
  justifyContent: "space-around",
});

export const CardWrapper = styled(Card)({
  maxWidth: 290,
  padding: 0,
  cursor: "pointer",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "&:hover .actions": {
    opacity: 1,
    display: "inline-block",
  },
});

export const CardContentWrapper = styled(CardContent)({
  padding: "14px",
  paddingBottom: "14px !important",
  h6: {
    margin: 0,
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "1.2rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "150px",
  },
  p: {
    margin: "0 !important",
    color: "#787878",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "1.4rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  }
})
