import { Navigate, Outlet } from "react-router-dom"
import { useLocation } from "src/hooks"
import { PAGE_URL } from "src/routes"
import { getAuthToken } from "src/utils"

export default function PublicRouting() {
  const location = useLocation()
  const authToken = getAuthToken()

  return !authToken ? (
    <Outlet />
  ) : (
    <Navigate to={(location.state as any)?.from ? (location.state as any)?.from : PAGE_URL.dashboard} />
  )
}
