import { Typography, Breadcrumbs, Link, styled, Box } from "@mui/material"
import { NavLink, useLocation, useNavigate } from "react-router-dom"

export const BreadcrumbContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  paddingTop: "5px",
  paddingBottom: "20px",
  h4: {
    color: theme.palette.text.primary,
    fontSize: "1.125rem",
  },
}))

export const BreadcrumbStyle = styled(Breadcrumbs)(({ theme }) => ({
  ".MuiBreadcrumbs-separator": {
    svg: {
      width: "15px",
      height: "15px",
      stroke: theme.palette.grey["600"],
    },
  },
  ".MuiBreadcrumbs-ol": {
    ".MuiBreadcrumbs-li": {
      p: {
        fontSize: "0.8125rem",
        color: theme.palette.grey["600"],
      },
      span: {
        fontSize: "0.8125rem",
        color: theme.palette.grey["600"],
        cursor: "pointer"
      },
      a: {
        "&.active": {
          color: theme.palette.primary.dark,
          fontWeight: 500,
          fontSize: "0.8125rem",
        },

      },
    },
  },
  ".mainBreadcrumb": {
    color: "#000", // Set the text color to black
    textDecoration: "none", // Ensure no underline is applied
  },
}))

interface BreadCrumbType {
  subtitle?: string
  items?: any[]
  title?: any
  children?: JSX.Element
  rightElement?: JSX.Element
}

const Breadcrumb = ({ subtitle, items, title, rightElement }: BreadCrumbType) => {

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <BreadcrumbContainer id="breadcrumb">
        <Box>
          {title && <Typography variant="h4">{title}</Typography>}
          {subtitle && (
            <Typography color="textSecondary" variant="h6" fontWeight={400} mt={0.8} mb={0}>
              {subtitle}
            </Typography>
          )}
          <BreadcrumbStyle
            separator={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 6l6 6l-6 6" />
              </svg>
            }
            sx={{ alignItems: "center", mt: items ? "10px" : "" }}
          >
            {items
              ? items.map(item => (
                <div key={item.title}>
                  {item.to ? (
                    <Link underline="none" color="inherit" component={NavLink} to={`${location.pathname}${location.search}`}>
                      {item.title}
                    </Link>
                  ) : item.subTo ? (
                    <span onClick={() => navigate(item.subTo)}>{item.title}</span>
                  ) : (
                    <Typography color="textPrimary">{item.title}</Typography>
                  )}
                </div>
              ))
              : ""}
          </BreadcrumbStyle>
        </Box>
        <Box flexGrow={1} />
        {rightElement}
      </BreadcrumbContainer>
    </>
  )
}

export default Breadcrumb
