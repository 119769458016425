import { Box, styled } from "@mui/material"
import { borderRadius,CenterFlex  } from "./custom"

import auth_logo from "src/assets/images/auth/auth_background.svg"

import { Link } from "react-router-dom"

export const AuthLayout = styled(Box)(() => ({
  background:
    "linear-gradient(140deg, rgba(207,220,246,1) 10%, rgba(255,255,255,1) 26%, rgba(255,255,255,1) 60%, rgba(229,237,255,1) 70%, rgba(195,214,255,1) 78%, rgba(109,149,255,1) 90%, rgba(14,76,255,1) 100%)",
  height: "100vh",
  position: "relative",
  "&:before": {
    content: '""',
    background: `url(${auth_logo})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
    zIndex: "0",
    opacity: "0.7",
  },
}))

export const SliderImgSection = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "100vh",
}))

export const SliderItem = styled("div")(() => ({}))

export const SliderImg = styled("img")(() => ({
  width: "100%",
}))

export const AuthFormGroup = styled("div")(() => ({
  marginBottom: "15px",
}))

export const FormSection = styled("div")(() => ({
  width: "100%",
  padding: "40px",
  background: "rgb(255 255 255 / 0.1)",
  backdropFilter: "blur(50px)",
  boxShadow: "0px 5px 20px rgb(0 0 0 / 0.1)",
  borderRadius: "15px",
  position: "relative",
  transitionDuration: "0.3s",
  button: {
    height: "45px",
  },
  ".auth_description": {
    marginBottom: "30px",
    h1: {
      fontSize: "1.75rem",
      fontWeight: "600",
      marginBottom: "10px",
    },
    p: {
      marginBottom: "0px",
    },
  },
}))

export const ResetPswdSection = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyItems: "center",
  ".resetpswdPic": {
    marginRight: "-45px",
    position: "relative",
    zIndex: "1",
    height: "400px",
    objectFit: "contain",
    objectPosition: "cover",
  },
}))

export const ForgetPswdSection = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyItems: "center",
  ".forgetpswdPic": {
    marginLeft: "-30px",
    position: "relative",
    zIndex: "1",
    height: "400px",
    objectFit: "contain",
    objectPosition: "cover",
  },
}))

export const InvalidSection = styled(Box)(() => ({
  background: "rgb(255 255 255 / 0.1)",
  backdropFilter: "blur(50px)",
  boxShadow: "0px 5px 20px rgb(0 0 0 / 0.1)",
  padding: "30px",
  borderRadius: "15px",
  ".pic": {
    width: "325px",
    display: "block",
    marginInline: "auto",
    marginBottom: "25px",
  },
}))

export const AuthTextFieldStyle = styled("div")<{ password?: boolean; error?: boolean }>(
  ({ theme, password, error }) => ({
    position: "relative",
    width: "100%",
    input: {
      width: "100%",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.grey["500"],
      height: "45px",
      borderRadius: borderRadius,
      padding: password ? "10px 60px 10px 50px" : "10px 20px 10px 50px",
      transitionDuration: "0.3s",
      ...(error && {
        borderColor: theme.palette.error.main,
      }),
      "&:focus": {
        ...(error
          ? { border: `1px solid ${theme.palette.error.main}` }
          : { border: `1px solid ${theme.palette.primary.main}` }),
      },
    },
    "input:focus + .icon": {
      ...(error
        ? {
            svg: {
              fill: theme.palette.error.main,
            },
          }
        : {
            svg: {
              fill: theme.palette.primary.main,
            },
          }),
    },
    ...(error && {
      "input + .icon": {
        svg: {
          fill: theme.palette.error.main,
        },
      },
    }),
    ".icon, .password_show_hide": {
      position: "absolute",
      transform: "translateY(-50%)",
      top: "50%",
      svg: {
        fill: theme.palette.grey["400"],
      },
    },
    ".icon": {
      left: "15px",
      display: "flex",
    },
    ".password_show_hide": {
      color: theme.palette.text.primary,
      right: "0px",
      button: {
        border: "none",
        background: "unset",
        fontWeight: "600",
        padding: "10px 15px",
      },
    },
    "input:disabled": {
      background: `url('data:image/svg+xml,<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12 14V18" stroke="${theme.palette.grey[
        "600"
      ].replace(
        "#",
        "%23"
      )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M6 10V8C6 7.65929 6.0284 7.32521 6.08296 7M18 10V8C18 4.68629 15.3137 2 12 2C10.208 2 8.59942 2.78563 7.5 4.03126" stroke="${theme.palette.grey[
        "600"
      ].replace(
        "#",
        "%23"
      )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M11 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15" stroke="${theme.palette.grey[
        "600"
      ].replace("#", "%23")}" stroke-width="1.5" stroke-linecap="round"></path></g></svg>')`,
      backgroundColor: theme.palette.grey["200"],
      backgroundRepeat: "no-repeat",
      backgroundSize: "18px 18px",
      backgroundPositionX: "calc(100% - 15px)",
      backgroundPositionY: "center",
      transitionDuration: "unset",
    },
  })
)

export const AuthLinks = styled(Link)(({ theme }) => ({
  ...CenterFlex(),
  fontWeight: "600",
  fontSize: "0.8125rem",
  color: theme.palette.primary.main,
  marginTop: "15px",
  svg: {
    marginRight: "5px",
  },
}))

export const AuthFormError = styled("div")(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: "5px",
  fontSize: "0.675rem",
}))
