import { useSelector, useLocation, usePermissionHook } from "src/hooks"
import { List, styled, useMediaQuery } from "@mui/material"
import { dispatch, toggleMobileSidebar } from "src/redux"
import NavItem from "./NavItem"
import NavCollapse from "./NavCollapse"
import NavGroup from "./NavGroup"
import { PERMISSION_MODULE } from "src/interface"
import useMenuItems from "./MenuItems"

const SideBarItemContainer = styled("div")`
  padding-inline: 15px;
`

const SidebarItems = () => {
  const { pathname } = useLocation()
  const Menuitems = useMenuItems();
  const pathDirect = pathname
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf("/"))
  const customizer = useSelector(state => state.customizer)
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"))
  const hideMenu: any = lgUp ? customizer.isCollapse && !customizer.isSidebarHover : ""
  const { getModuleGeneralAccess } = usePermissionHook()

  return (
    <SideBarItemContainer>
      <List sx={{ pt: 0 }} className="sidebarNav">
        {Menuitems.map(item => {
          const access = getModuleGeneralAccess(item.module as PERMISSION_MODULE)
          const navCollapse = {
            menu: item,
            pathDirect: pathDirect,
            hideMenu: hideMenu,
            pathWithoutLastPart: pathWithoutLastPart,
            level: 1,
            onClick: () => dispatch(toggleMobileSidebar()),
          }

          const navItem = {
            item,
            pathDirect: pathDirect,
            hideMenu: hideMenu,
            onClick: () => dispatch(toggleMobileSidebar()),
          }

          if (item.subheader) {
            return item.module ? (
              access ? (
                <NavGroup item={item} hideMenu={hideMenu} key={item.subheader} />
              ) : null
            ) : (
              <NavGroup item={item} hideMenu={hideMenu} key={item.subheader} />
            )
          } else if (item.children) {
            return item.module ? (
              access ? (
                <NavCollapse key={item.id} {...navCollapse} />
              ) : null
            ) : (
              <NavCollapse key={item.id} {...navCollapse} />
            )
          } else {
            return item.module ? (
              access ? (
                <NavItem key={item.id} {...navItem} />
              ) : null
            ) : (
              <NavItem key={item.id} {...navItem} />
            )
          }
        })}
      </List>
    </SideBarItemContainer>
  )
}
export default SidebarItems
