import { TablePaginationStyle } from "src/assets/styles/table";
import { dispatch, setFilterValue } from "src/redux";
import { useSelector } from "src/hooks";

export default function Pagination() {
  const filter = useSelector((state) => state.commonData.filter);
  const pageTotalCount = useSelector(
    (state) => state.commonData.pageTotalCount
  );

  const totalPages = Math.ceil(pageTotalCount / (filter?.limit ?? 10));

  const paginationHandler = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(setFilterValue({ ...filter, page: value }));
  };

  return pageTotalCount > (filter?.limit ?? 0) ? (
    <TablePaginationStyle
      color="secondary"
      count={totalPages}
      onChange={paginationHandler}
      shape="rounded"
    />
  ) : null;
}
