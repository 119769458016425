import { styled } from "@mui/material"
import SimpleBar from "simplebar-react";
import { useCallback, useEffect, useState } from "src/hooks";
import "simplebar-react/dist/simplebar.min.css";

const ContentContainer = styled(SimpleBar)<{ height: number }>(({ theme, height }) => ({
  overflowY: "auto",
  height: `calc(100vh - ${height}px)`,
  transition: "height .3s",
  ".simplebar-scrollbar": {
    "&:before": {
      background: theme.palette.grey["500"], // Customize the scrollbar thumb color
      borderRadius: "10px",
    },
  },
  ".simplebar-track.simplebar-vertical": {
    display: "none", // Hide the vertical scrollbar track
  },
  ".simplebar-track.simplebar-horizontal": {
    height: "8px", // Set the height of the horizontal scrollbar
  },
  ".simplebar-content": {
    display: "block", // Ensure content layout is correct inside SimpleBar
  },
  ".common-card": {
    padding: "12px 18px",
    borderRadius: "12px",
    boxShadow: "0 .1875rem .75rem 0 rgba(47,43,61,.14)",
    height: "100%",
    ".heading": {
      fontWeight: 500,
      marginBlock: 0,
      fontSize: "0.9375rem",
    },
    "&.avg-salary-adv": {
      padding: 0,
      ".content": {
        padding: "12px 18px 0 18px",
      },
    },
    ".icon-wrapper": {
      width: "max-content",
    },
  },
  ".organization-analytics-wrapper,.admin-dashboard-wrapper": {
    ".text-primary": {
      color: "#0F2F51",
    },
    ".org-analytics-slider": {
      maxWidth: "1000px",
      background: " linear-gradient(94.96deg, #0F2F51 2.55%, #2A4D99 92.84%)",
      ".slick-dots": {
        width: "unset",
        top: 20,
        right: 20,
        bottom: "unset",
        zIndex: 2,

        li: {
          width: "unset",
          height: "unset",
          button: {
            width: "20px",
            height: "8px",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderRadius: "12px",
            padding: 0,
            "&:before": {
              content: "unset",
            },
          },
          "&.slick-active": {
            button: {
              width: "35px",
              backgroundColor: "rgba(255, 255, 255)",
            },
          },
        },
      },
    },

    ".small-heading": {
      fontSize: "0.875rem !important",
      fontWeight: 400,
    },
    ".linear-progress": {
      ".count": {
        display: "none",
      },
    },
    ".req-report-progress": {
      marginBlock: "10px",
      ".MuiLinearProgress-bar": {
        borderRadius: "5px",
      },

      "&.total-request": {
        backgroundColor: "rgb(93 135 255/0.1)",
        ".MuiLinearProgress-bar": {
          backgroundColor: "rgb(93 135 255/0.8)",
        },
      },
      "&.total-reject": {
        backgroundColor: "rgb(252 100 45/0.1)",
        ".MuiLinearProgress-bar": {
          backgroundColor: "rgb(252 100 45/0.8)",
        },
      },
      "&.total-repaid": {
        backgroundColor: "rgb(85 208 126/0.1)",
        ".MuiLinearProgress-bar": {
          backgroundColor: "rgb(85 208 126/0.8)",
        },
      },
      "&.salary-advance": {
        marginBlock: "16px",
        backgroundColor: "rgb(85 208 126/0.5)",
        ".MuiLinearProgress-bar": {
          backgroundColor: "rgb(93 135 255/0.8)",
        },
      },
      "&.repayment-report-progress": {
        backgroundColor: "rgb(231 175 33 / 0.5)",
        marginLeft: "8px",
        ".MuiLinearProgress-bar": {
          backgroundColor: "rgb(85 208 126)",
        },
      },
    },
  },
  ".small-heading": {
    fontSize: "0.75rem",
    color: "#787878",
    marginBlock: 4,
    fontWeight: 400,
  },
}))

export default function ContentBody({
  children,
  styleProps,
  removeValues,
  autoHide
}: {
  children: JSX.Element
  styleProps?: any
  removeValues?: string[]
  autoHide?: boolean
}) {
  const [height, setHeight] = useState<number>(0)
  const customeTableFooter = removeValues?.includes("customeTableFooter")
    ? 0
    : (document.querySelector("#custom-table-footer")?.clientHeight as number) ?? 0
  const loanManagementProgressCardSection = removeValues?.includes("loanProgressCard")
    ? -90
    : (document.querySelector("#loan-progress-card")?.clientHeight as number)
      ? 195
      : 0
  const customTableHeight = removeValues?.includes("loanApproveTable")
    ? -200
    : (document.querySelector("#customLoanApproveTable")?.clientHeight as number) ?? 0

  const customLoanDetailHeight = removeValues?.includes("loanDetailCard")
    ? -200
    : (document.querySelector("#customLoanDetailCard")?.clientHeight as number) ?? 0


  const getMeasurement = useCallback(() => {
    const appHeader = document.querySelector("#header")?.clientHeight as number
    const breadcrumb = document.querySelector("#breadcrumb")?.clientHeight as number
    const contentHeader = removeValues?.includes("contentHeader")
      ? 0
      : (document.querySelector("#contentHeader")?.clientHeight as number) ?? 0
    const heightDeduction =
      appHeader + breadcrumb + contentHeader + customeTableFooter + loanManagementProgressCardSection + customTableHeight + customLoanDetailHeight + 35

    setHeight(heightDeduction)
  }, [removeValues, customeTableFooter, loanManagementProgressCardSection, customTableHeight, customLoanDetailHeight])

  useEffect(() => {
    getMeasurement()
    window.addEventListener("resize", getMeasurement)

    return () => window.removeEventListener("resize", getMeasurement)
  }, [getMeasurement])

  return (
    <ContentContainer
      {...styleProps}
      className={`content_card ${styleProps?.className}`}
      height={height}
      autoHide={autoHide}
    >
      {children}
    </ContentContainer>

  )
}
