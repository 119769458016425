import { Box, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router';
import { AuthLayout, SliderImgSection } from 'src/assets/styles/auth';

//Images
import auth_vector_1 from "src/assets/images/auth/vector.png";
import { CMButton } from 'src/assets/styles/custom';

const Wrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
});

function NotFound() {
    const navigate = useNavigate()

    return (
        <AuthLayout>

            <Wrapper>
                <Typography variant="h1" sx={{ fontSize: '64px', fontWeight: 'bold', color: '#333' }}>
                    404
                </Typography>
                <Typography variant="h5" sx={{ color: '#333', fontWeight: 500, marginTop: 2 }}>
                    Page Not Found <span role="img" aria-label="warning">⚠️</span>
                </Typography>
                <Typography variant="body1" sx={{ color: '#666', marginTop: '8px', marginBottom: 2 }}>
                    we couldn't find the page you are looking for
                </Typography>

                <CMButton variant="contained" onClick={() => navigate(-1)}>
                    Back to site
                </CMButton>

                <SliderImgSection sx={{ height: "auto", justifyContent: "center", zIndex: 1 }}>
                    <img src={auth_vector_1} alt={auth_vector_1} width={600} />
                </SliderImgSection>
            </Wrapper>
        </AuthLayout>
    );
}

export default NotFound;
