import { IDropdownOptions } from "src/interface"
import { noAlph, onlyNumber } from "./errorHandler"
import moment from "moment"
import { corporationTypes } from "src/resources"
import { useState, useEffect } from "src/hooks"

export const getAuthToken = () => localStorage.getItem("auth_token")

export const getAuthRefreshToken = () => localStorage.getItem("refresh_token")

export const deepClone = (data: any) => {
  return JSON.parse(JSON.stringify(data))
}

export const selectOptionFormater = (data: any[], [key1, key2]: string[]): IDropdownOptions[] => {
  return data.map((item: any) => {
    return { label: item[key1], value: item[key2] }
  })
}

export const debounceChange = (func: any) => {
  let timer: any = null

  return function (this: any, ...args: any[]) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      func.apply(this, args)
    }, 500)
  }
}

export const formatContactValue = (
  value: string,
  previousValue?: string,
  name?: "postCode" | "tax" | "accessCode"
) => {
  if (!value) return value
  const currentValue = value.replace(/[^\d]/g, "")
  if (name === "postCode") {
    return `${value.slice(0, 10)}`
  } else if (name === "tax") {
    return `${value.slice(0, 18).toUpperCase()}`
  }
  if (!previousValue || value.length > previousValue.length) {
    if (name === "accessCode") {
      return `${currentValue.slice(0, 6)}`
    } else {
      if (currentValue.length > 0) {
        return `+${currentValue.slice(0, 2)}-${currentValue.slice(2, 6)}${currentValue.slice(6, 12)}`
      } else {
        return ""
      }
    }
  } else {
    if (name === "accessCode") {
      return `${currentValue.slice(0, 6).toLowerCase()}`
    } else if (value) {
      return `+${currentValue}`
    } else {
      return value
    }
  }
}

export const contactNoValidator = (currentValue: string, prevValue: string) => {
  if (noAlph.test(currentValue) || currentValue === "") {
    return currentValue.slice(0, 10)
  }

  return prevValue
}

export const numberValidator = (currentValue: string, prevValue: string, allow?: "comma" | "dot") => {
  const regex = allow === "comma" ? onlyNumber : allow === "dot" ? noAlph : onlyNumber
  const phoneNumberArr = currentValue.includes(",") ? currentValue.split(",").filter((value) => value !== "") : [currentValue]
  if ((allow === "comma" && phoneNumberArr.every(number => regex.test(number) && number.length <= 10)) || currentValue === "") {
    return currentValue
  }

  return prevValue
}

export function bytesToSize(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes == 0) return "n/a"
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
  if (i == 0) return bytes + " " + sizes[i]

  return Math.ceil(bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i]
}

export const dateFormatter = (date: Date | string) => {
  return moment(date).format("DD-MM-YYYY").toString()
}
export const dateTimeFormatter = (date: Date | string) => {
  return moment(date).format("LLL")
}

export const dateAgoFormatter = (date: Date | string) => {
  return moment(date).fromNow()
}

export const getPathName = (path: string, index: number) => {
  return path.split("/")[index]
}

export const getCorporationTpe = (type: string) => {
  const corporation = corporationTypes.find((f: IDropdownOptions) => f?.value === type)

  return corporation?.label
}

export const numberDigitsFormatter = (value: string | number) => {
  const number = typeof value === "number" ? value : parseInt(value)

  return number > 9 ? number : `0${number}`
}

export const getTotalExperience = (dateString: string | null) => {
  const now = moment();
  const inputDate = moment(dateString);

  const duration = moment.duration(now.diff(inputDate));
  const months = Math.floor(duration.asMonths());

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  if (years > 0) {
    if (remainingMonths > 0) {
      return `${years}.${remainingMonths} years`;
    } else {
      return `${years} ${years > 1 ? 'years' : 'year'}`;
    }
  } else {
    return `${months} ${months > 1 ? 'months' : 'month'}`;
  }
}

export const maskAadharNumber = (aadharNumber: string) => {
  const aadharStr = aadharNumber.toString();

  // if (aadharStr.length !== 12) {
  //   throw new Error('Invalid Aadhar number');
  // }

  const masked = aadharStr.slice(0, -4).replace(/\d/g, '*') + aadharStr.slice(-4);

  return masked;
}

export const maskPANNumber = (panNumber: string) => {
  const panStr = panNumber.toString();

  // if (panStr.length !== 10) {
  //   throw new Error('Invalid PAN number');
  // }

  const masked = panStr.slice(0, 5).replace(/./g, '*') + panStr.slice(5);

  return masked;
}

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const formatStrings = (inputs: any) => {
  if (!isNaN(inputs)) {

    return inputs;
  } else if (inputs.endsWith('s')) {

    return inputs;
  } else {
    const parts = inputs.split('-');
    if (parts.length >= 2) {

      return `${parts[0]}-${parts[parts.length - 1]}`;
    }
  }

  return inputs;
}

export const formatDecimalNumber = (num: any) => {
  if (Number.isInteger(num)) {

    return num.toString();
  } else {

    return num.toFixed(2);
  }
}

export const getReadableFileType = (fileType: any) => {
  const mimeParts = fileType.split('/')[1]; 

  if (mimeParts === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {

    return 'word document';
  }

  return mimeParts;
};
