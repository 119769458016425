import { Suspense } from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import './styles.css'
import { store } from "./redux/store/Store";
import Spinner from "./views/spinner/Spinner";
import "./utils/i18n";
import GraphQLErrorHandler from "./apollo/GraphQLErrorHandler";
import ApolloConfigProvider from "./apollo/ApolloConfigProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <GraphQLErrorHandler>
      {(getGraphQLError: (message: any) => void, logout: () => void) => (
        <ApolloConfigProvider getGraphQLError={getGraphQLError} logout={logout}>
            <Suspense fallback={<Spinner />}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </Suspense>
        </ApolloConfigProvider>
      )}
    </GraphQLErrorHandler>
  </Provider>
);
