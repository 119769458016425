import { PAGE_URL } from "src/routes"

export const UsersBreadCrumb = [
  {
    title: "Management",
  },
  {
    title: "User Management",
  },
  {
    to: PAGE_URL.userManagementUsers,
    title: "Users",
  },
]

export const RolesBreadCrumb = [
  {
    title: "Management",
  },
  {
    title: "User Management",
  },
  {
    to: PAGE_URL.userManagementRoles,
    title: "Roles",
  },
]

export const OrgBreadcrumb = [
  {
    title: "Management",
  },
  {
    title: "Organization Management",
  },
  {
    to: PAGE_URL.organizationManagement,
    title: "Organizations",
  },
]

export const ViewOrgBreadcrumb = [
  {
    title: "Management",
  },
  {
    title: "Organization Management",
  },
  {
    subTo: PAGE_URL.organizationManagement,
    title: "Organizations",
  },
  {
    to: PAGE_URL.organizationManagementDetails,
    title: "View Organization",
  },
]

export const permissionBreadcrumb = [
  {
    title: "Management",
  },
  {
    title: "User Management",
  },
  {
    to: PAGE_URL.userManagementPermission,
    title: "Permission",
  },
]

export const customerBreadcrumb = [
  {
    title: "Management",
  },
  {
    title: "Customer Management",
  },
  {
    to: PAGE_URL.customerManagement,
    title: "Customers",
  },
]

export const customerDetailBreadcrumb = [
  {
    title: "Management",
  },
  {
    title: "Customer Management",
  },
  {
    subTo: PAGE_URL.customerManagement,
    title: "Customers",
  },
  {
    to: PAGE_URL.customerManagementDetails,
    title: "View Customer",
  },
]

export const employeeBreadcrumb = [
  {
    title: "Management",
  },
  {
    title: "Employee Management",
  },
  {
    to: PAGE_URL.customerManagement,
    title: "Employees",
  },
]

export const employeeDetailBreadcrumb = [
  {
    title: "Management",
  },
  {
    title: "Employee Management",
  },
  {
    subTo: PAGE_URL.customerManagement,
    title: "Employees",
  },
  {
    to: PAGE_URL.customerManagementDetails,
    title: "View Employee",
  },
]


export const loanBreadcrumb = [
  {
    title: "Advances",
  },
  {
    title: "Advance Management",
  },
  {
    to: PAGE_URL.loanManagementList,
    title: "Advances",
  },
]

export const loanRequestBreadcrumb = [
  {
    title: "Advances",
  },
  {
    title: "Advance Management",
  },
  {
    to: PAGE_URL.loanManagementRequest,
    title: "Advance Request",
  },
]

export const viewLoanRequestBreadcrumb = [
  {
    title: "Advances",
  },
  {
    title: "Advance Management",
  },
  {
    subTo: PAGE_URL.loanManagementRequest,
    title: "Advance Request",
  },
  {
    to: PAGE_URL.loanManagementDetails,
    title: "View Advance Request",
  },
]

export const loanTransactionBreadcrumb = [
  {
    title: "Advances",
  },
  {
    title: "Advance Management",
  },
  {
    to: PAGE_URL.loanManagementTransaction,
    title: "Transaction",
  },
]

export const loanApprovalBreadcrumb = [
  {
    title: "Advances",
  },
  {
    to: PAGE_URL.approvalWorkflowList,
    title: "Approval Workflow",
  },
]

export const loanApprovalListBreadcrumb = [
  {
    title: "Advances",
  },
  {
    subTo: PAGE_URL.approvalWorkflowList,
    title: "Approval Workflow",
  },
  {
    to: PAGE_URL.approvalWorkflowDetails,
    title: "View Approval Workflow",
  },
]

export const createLoanApprovalBreadcrumb = [
  {
    title: "Advances",
  },
  {
    subTo: PAGE_URL.approvalWorkflowList,
    title: "Approval Workflow",
  },
  {
    to: PAGE_URL.createApprovalWorkflow,
    title: "Create Approval Workflow",
  },
]

export const updateLoanApprovalBreadcrumb = [
  {
    title: "Advances",
  },
  {
    subTo: PAGE_URL.approvalWorkflowList,
    title: "Approval Workflow",
  },
  {
    to: PAGE_URL.createApprovalWorkflow,
    title: "Update Approval Workflow",
  },
]

export const orgLoanApprovalBreadcrumb = [
  {
    title: "Advances",
  },
  {
    to: PAGE_URL.organizationWorkflowList,
    title: "Approval Workflow",
  },
]

export const orgLoanApprovalListBreadcrumb = [
  {
    title: "Advances",
  },
  {
    subTo: PAGE_URL.organizationWorkflowList,
    title: "Approval Workflow",
  },
  {
    to: PAGE_URL.organizationWorkflowDetails,
    title: "Update Approval Workflow",
  },
]


export const createOrgLoanApprovalBreadcrumb = [
  {
    title: "Advances",
  },
  {
    subTo: PAGE_URL.organizationWorkflowList,
    title: "Approval Workflow",
  },
  {
    to: PAGE_URL.organizationWorkflowDetails,
    title: "Create Approval Workflow",
  },
]


export const faqBreadcrumb = [
  {
    title: "Frequently Asked Questions",
  },
  {
    to: PAGE_URL.faqManagementList,
    title: "View FAQ",
  },
]

export const settingBreadcrumb = [
  {
    title: "Advances",
  },
  {
    to: PAGE_URL.settingsList,
    title: "Settings & Configurations",
  },
]

export const viewSettingBreadcrumb = [
  {
    title: "Advances",
  },
  {
    subTo: PAGE_URL.settingsList,
    title: "Settings & Configurations",
  },
  {
    to: PAGE_URL.settingConfigure,
    title: "View Settings & Configurations",
  },
]

export const createSettingBreadcrumb = [
  {
    title: "Advances",
  },
  {
    subTo: PAGE_URL.settingsList,
    title: "Settings & Configurations",
  },
  {
    to: PAGE_URL.updateSettingConfigure,
    title: "Create Settings & Configurations",
  },
]

export const updateSettingBreadcrumb = [
  {
    title: "Advances",
  },
  {
    subTo: PAGE_URL.settingsList,
    title: "Settings & Configurations",
  },
  {
    to: PAGE_URL.updateSettingConfigure,
    title: "Update Settings & Configurations",
  },
]

export const auditBreadcrumb = [
  {
    title: "Auditing & Reports",
  },
  {
    to: PAGE_URL.auditTrailList,
    title: "Audit Trail",
  },
]

export const reportBreadcrumb = [
  {
    title: "Auditing & Reports",
  },
  {
    to: PAGE_URL.reportList,
    title: "Reports & Analytics",
  },
]

export const backupBreadcrumb = [
  {
    title: "System Maintenance",
  },
  {
    to: PAGE_URL.backupList,
    title: "Backups",
  },
]

export const notificationBreadcrumb = [
  {
    title: "System Maintenance",
  },
  {
    to: PAGE_URL.notificationManage,
    title: "Notification Management",
  },
]

export const emailTemplateBreadcrumb = [
  {
    title: "System Maintenance",
  },
  {
    to: PAGE_URL.email_templateList,
    title: "Email Templates",
  },
]

export const profileBreadcrumb = [
  {
    title: "Profile",
  },
  {
    to: PAGE_URL.profile,
    title: "Personal Information",
  },
]