import { ReportProblemRounded } from "@mui/icons-material"
import { Button } from "@mui/material"
import { ButtonRow, CMButton, DeleteModal } from "src/assets/styles/custom"
import { useEffect, useSelector } from "src/hooks"

interface IDeleteComponent {
  title?: any
  description?: any
  deleteHandler: () => void
  closeHandler: () => void
  resetHandler?: () => void
  deleteLabel?:string
}

export default function DeleteComponent(props: IDeleteComponent) {
  const disabledLoader = useSelector(state => state.commonData.disabledLoader)
  const { title, description, deleteHandler, closeHandler, resetHandler,deleteLabel } = props

  useEffect(() => {
    return () => {
      if (resetHandler) {
        setTimeout(() => resetHandler(), 500)
      }
    }
  }, [resetHandler])

  return (
    <>
      <DeleteModal>
        <div className="icon">
          <ReportProblemRounded />
        </div>
        {title && <h3>{title}</h3>}
        {description && <p>{description}</p>}
        <ButtonRow>
          <Button disabled={disabledLoader} variant="contained" color="inherit" onClick={closeHandler}>
            No, Keep It.
          </Button>
          <CMButton disabled={disabledLoader} variant="contained" color="error" onClick={deleteHandler}>
            {deleteLabel??"Yes, Delete!"}
          </CMButton>
        </ButtonRow>
      </DeleteModal>
    </>
  )
}
