export const userDetailsState = {
  _id: "",
  firstName: "",
  lastName: "",
  email: "",
  status: "",
  role: {
    _id: "",
    Role_Name: "",
    panel_type: "",
    Permissions: [
      {
        permission: {
          _id: "",
          label: "",
          name: "",
        },
        access_controll: {
          create: false,
          read: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  country: "",
  contact: "",
  is_default: false,
}
