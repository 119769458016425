import {
  ICreditInformation,
  IEligibilityCriteria,
  IEmployeeInformation,
  ILoanSettings,
  IOrganizationPolicies,
  IOrganizationUpdate,
  IRepaymentTerms,
  ISettingConfig,
  IWorkflowComponent,
} from "src/interface/common";

export const emptyLoanDetails: ILoanSettings = {
  loan_segment: {
    personal_loan: true,
    employee_loan: false,
    mortgage_loan: false,
    vehicle_loan: false,
    education_loan: false,
    loan_against_security: false,
  },
  loan_amount: {
    range_between: {
      min: 0,
      max: 0,
      status: false,
    },
    within_month_income: false,
  },
};

export const emptyEmployeeInformation: IEmployeeInformation = {
  employee_status: {
    full_time: false,
    part_time: false,
    contract_employee: false,
  },
  annual_income: {
    income_range: "0",
    status: false,
  },
};

export const emptyCreditInformation: ICreditInformation = {
  credit_score: {
    score_range: {
      min: 0,
      max: 0,
      status: false,
    },
    status: false,
  },
};

export const emptyRepaymentTerms: IRepaymentTerms = {
  repayment_schedule: {
    monthly: false,
    bi_monthly: false,
    quarterly: false,
    annual_payment: false,
    user_defined: {
      min: 0,
      max: 0,
      status: false,
    },
  },
  repayment_method: {
    direct_bank: false,
    manual: false,
    automatic: false,
    bulk_payment: false,
  },
};

export const emptyEligibilityCriteria: IEligibilityCriteria = {
  onWork_employee: false,
  notice_period_employee: false,
  probationary_employee: false,
  onSite_employee: false,
  work_from_home_employee: false,
};

export const emptyOrganizationPolicies: IOrganizationPolicies = {
  eligibility_criteria: emptyEligibilityCriteria,
};

export const emptyWorkflowComponent: IWorkflowComponent = {
  _id: "",
  name: "",
  label: "",
  description: "",
  status: false,
};

export const emptyOrganization: IOrganizationUpdate = {
  _id: "",
  org_name: "",
  email: "",
  contact: "",
  country: "",
  address_1st_line: "",
  address_2nd_line: "",
  city: "",
  post_code: "",
  website: "",
  founders_name: "",
  directors_name: "",
  sector: "",
  legal_structure: "",
  registration_info: "",
  no_of_employees: "",
  description: "",
  company_logo: "",
  account_holder_name: "",
  account_number: "",
  bank_name: "",
  branch_location: "",
  identifier_code: "",
  tax_payerId: "",
  tax_identification_number: "",
  license_permits: "",
  privacy_policy: "",
  terms_conditions: "",
  status: false,
  type: "",
  role: "",
  roleId: "",
  workflowId: "",
  organizationWorkflow: "",
  organizationWorkflowId: "",
};

// export const emptyWorkflow: IWorkflow = {
//   _id: "",
//   name: "",
//   components: Array(11).fill(emptyWorkflowComponent), // Assuming there are 11 components
//   organizations: [emptyOrganization],
//   status: false,
// };

export const settingState: ISettingConfig = {
  loan_details: emptyLoanDetails,
  employee_information: emptyEmployeeInformation,
  credit_information: emptyCreditInformation,
  repayment_terms: emptyRepaymentTerms,
  organization_policies: emptyOrganizationPolicies,
  workflow: {
    _id: "",
    name: "",
  },
  name: ""
};

const LoanSegmentData: any = {
  loan_segment: [
    {
      name: "Personal Advance",
      value: "personal_loan",
      status: true,
    },
    {
      name: "Employee Advance",
      value: "employee_loan",
      status: false,
    },
    {
      name: "Mortgage Advance",
      value: "mortgage_loan",
      status: false,
    },
    {
      name: "Vehicle Advance",
      value: "vehicle_loan",
      status: false,
    },
    {
      name: "Education Advance",
      value: "education_loan",
      status: false,
    },
    {
      name: "Advance Against Security",
      value: "loan_against_security",
      status: false,
    },
  ],
  loan_amount: [
    {
      name: "Within the month income of the Customers",
      value: "within_month_income",
      status: true,
      min: null,
      max: null,
    },
    {
      name: "Range Between",
      value: "range_between",
      status: false,
      min: 10000,
      max: 50000,
    },
  ],
};

const EmployeeInformationData: any = {
  employee_status: [
    {
      name: "Full Time",
      value: "full_time",
      status: true,
    },
    {
      name: "Part Time",
      value: "part_time",
      status: false,
    },
    {
      name: "Contract Employee",
      value: "contract_employee",
      status: false,
    },
  ],
  annual_income: [
    {
      name: "Choose Income variables",
      income_range: "above 50,000",
      status: true,
      value: "income_range",
    },
  ],
}

const CreditInformationData: any = {
  credit_score: [
    {
      name: "Don't calculate or Verify Credit Score for Advance Process",
      value: "no_credit",
      min: 0,
      max: 0,
      status: true,
    },
    {
      name: "Credit Score Variables",
      value: "score_range",
      min: 600,
      max: 750,
      status: false,
    },
  ],
}

const RepaymentTermsData: any = {
  repayment_schedule: [
    {
      name: "Monthly",
      value: "monthly",
      status: true,
      min: null,
      max: null,
    },
    {
      name: "Bi-Monthly",
      value: "bi_monthly",
      status: false,
      min: null,
      max: null,
    },
    {
      name: "Quarterly",
      value: "quarterly",
      status: false,
      min: null,
      max: null,
    },
    {
      name: "Annual Payment",
      value: "annual_payment",
      status: false,
      min: null,
      max: null,
    },
    {
      name: "User Defined",
      value: "user_defined",
      status: false,
      min: 2,
      max: 12,
    },
  ],
  repayment_method: [
    {
      name: "Direct Bank transfer to Kreon Financial",
      value: "direct_bank",
      status: true,
    },
    {
      name: "Payment gateways - Manual",
      value: "manual",
      status: false,
    },
    {
      name: "Automatic payment E-mandate",
      value: "automatic",
      status: false,
    },
    {
      name: "Employer Bulk Payment",
      value: "bulk_payment",
      status: false,
    },
  ],
  fees_charges: [
    {
      "name": "Advance Processing Fee",
      "value": "loan_processing_fee",
      "percentage": 0,
      "status": false,
      "grace": 0
    },
    {
      "name": "Late Payment",
      "value": "loan_late_payment",
      "percentage": 0,
      "status": false,
      "grace": 0
    }
  ]
}

const OrganizationPolicyData: any = {
  eligibility_criteria: [
    {
      name: "On-work Employees",
      value: "onWork_employee",
      status: true,
    },
    {
      name: "Employee on Notice period",
      value: "notice_period_employee",
      status: false,
    },
    {
      name: "Employee on Probationary Period",
      value: "probationary_employee",
      status: false,
    },
    {
      name: "On-Site Employees",
      value: "onSite_employee",
      status: false,
    },
    {
      name: "Work from Home Employees",
      value: "work_from_home_employee",
      status: false,
    },
  ],
}

export const defaultSettingState: any = {
  loan_details: LoanSegmentData,
  employee_information: EmployeeInformationData,
  credit_information: CreditInformationData,
  repayment_terms: RepaymentTermsData,
  organization_policies: OrganizationPolicyData,
  workflow: {
    _id: null,
    name: null,
  },
  name: ""
};
