import { Collapse, ListItemButton, ListItemIcon, styled } from "@mui/material"
import {  borderRadius } from "./custom"

export const ListIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "auto",
  marginRight: "10px ",
  svg: {
    fill: theme.palette.grey["600"],
    path: {
      fill: theme.palette.grey["600"],
    },
  },
  "&.parent_icon": {
    svg: {
      width: "22px",
      height: "22px",
      transitionDuration: "0.3s",
    },
  },
  "&.child_icon": {
    svg: {
      transitionDuration: "0.3s",
    },
  },
}))

export const ListText = styled("div")(() => ({
  fontSize: "0.8125rem",
  flex: "1 1 0",
  textOverflow: "ellipsis",
  overflow: "hidden",
  fontWeight: "400",
}))

export const ListItemBtn = styled(ListItemButton)(({ theme }) => ({
  whiteSpace: "nowrap",
  marginBottom: "4px",
  padding: "10px 15px",
  borderRadius: borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    ".MuiListItemIcon-root": {
      svg: {
        fill: theme.palette.primary.main,
        path: {
          fill: theme.palette.primary.main,
        },
        circle: {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  "&.Mui-selected": {
    color: "#ffffff !important",
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    ".MuiListItemIcon-root": {
      svg: {
        fill: "#ffffff",
        path: {
          fill: "#ffffff",
        },
        circle: {
          fill: "#ffffff",
        },
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&::before": {
        left: "150%",
      },
    },
    "&::before": {
      content: "''",
      background: "rgb(255 255 255/ 0.4)",
      width: "40%",
      height: "100%",
      top: "0%",
      left: "-125%",
      transform: "skew(45deg)",
      position: "absolute",
      transition: "left 500ms ease-out",
    },
  },
}))

export const SubMenuCollapse = styled(Collapse)(() => ({
  ".MuiListItemButton-root": {
    paddingLeft: "25px",
  },
}))