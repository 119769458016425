import {
  Box,
  Button,
  Card,
  Dialog,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  styled,
} from "@mui/material"

import disableLoader from "src/assets/images/loader/disableLoader.gif"
import { IKey, STATUS } from "src/interface"
import { iconIndicatorColor } from "src/theme/iconColors"
import { statusIndicatorColor } from "src/theme/statusColors"

export const borderRadius = "8px"
export const borderRadius_md = "15px"
export const formFieldHeight = "40px"
export const bodyTextSize = "13px"

export const CenterFlex = () => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const getHeight = () => {
  if (window.innerWidth > 1300) {
    return "calc(65vh - 150px)";
  } else {
    return "calc(78vh - 140px)";
  }
};

export const NavMenuIcon = styled("button")(({ theme }) => ({
  ...CenterFlex(),
  background: theme.palette.grey["200"],
  width: "40px",
  height: "40px",
  borderRadius: borderRadius,
  border: "none",
  transitionDuration: "0.3s",
  cursor: "pointer",
  svg: {
    display: "flex",
    width: "22px",
    height: "22px",
    path: {
      transitionDuration: "0.3s",
      fill: "#000000",
    },
    "path + path": {
      fill: "#000000",
    },
    circle: {
      fill: "#000000",
    },
  },
  "&:hover": {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    svg: {
      path: {
        fill: theme.palette.primary.main,
      },
      "path + path": {
        fill: theme.palette.primary.main,
      },
      circle: {
        fill: theme.palette.primary.main,
      },
    },
  },
}))

export const ContentCard = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  border: `1px solid ${theme.palette.grey["300"]}`,
  borderRadius: borderRadius_md,
  ".profit-loss-element": {
    span: {
      marginLeft: 5,
      svg: {
        width: 18,
        height: 18,
      },
    },
    "&.profit": {
      p: {
        color: "rgb(85, 208, 136)",
      },
      span: {
        svg: {
          color: "rgb(85, 208, 136)",
        },
      },
    },
    "&.loss": {
      p: {
        color: "rgb(233, 80, 80)",
      },
      span: {
        svg: {
          color: "rgb(233, 80, 80)",
        },
      },
    },
  },
  ".loan-details-tab-container": {
    position: "relative",
    boxShadow: "none",
    overflowY: "auto",
    height: getHeight(),
    ".MuiTabs-root": {
      paddingInline: "20px",
    },
    ".hide-scroll": {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey["500"],
      borderRadius: "10px",
    },
  },
  "&.overflow-hidden": {
    overflow: "hidden",
  },
  ".list-card": {
    marginBlock: 15,
    ".content": {
      ".pic": {
        width: 50,
        height: 50,
        img: {
          width: "100%",
          height: "100%",
          objectFit: "fill",
        },
      },
    },
    ".profit-loss-element": {
      span: {
        marginLeft: 5,
        svg: {
          width: 18,
          height: 18,
        },
      },
      "&.profit": {
        p: {
          color: "rgb(85, 208, 136)",
        },
        span: {
          svg: {
            color: "rgb(85, 208, 136)",
          },
        },
      },
      "&.loss": {
        p: {
          color: "rgb(233, 80, 80)",
        },
        span: {
          svg: {
            color: "rgb(233, 80, 80)",
          },
        },
      },
    },
  },
  "&.admin-default-revenue": {
    ".rounded-icon": {
      padding: "15px",
      svg: {
        width: 25,
        height: 25,
      },
    },
  },
  "&.sales-card": {
    marginBottom: "10px",
    height: "max-content",
  },
  ".amount-bold": {
    marginBlock: 3,
    fontWeight: "600 !important",
    fontSize: "1rem",
  },

  "&.salary-advance": {
    ".amount-bold": {
      marginTop: "10px",
    },
  },
  "&.admin-dashboard-sales-card": {
    ".count-content": {
      h2: {
        marginBlock: 8,
        fontSize: "1rem !important",
      },
      p: {
        marginBlock: 3,
      },
    },
  },
  ".details": {
    ".heading": {
      marginBlock: 10,
    },
  },
  ".mb-0": {
    marginBlock: 0,
  },
  ".font-semibold": {
    fontWeight: 600,
  },
}))

export const PlainCard = styled(Box)(() => ({
  ".content_card": {
    marginInline: "-15px",
    padding: "15px",
  },
}))

export const PlainDashboardCard = styled(Box)(() => ({
  ".content_card": {
    marginInline: "-15px",
    padding: "10px",
  },
}))

export const CardHeaderSection = styled(Box)(() => ({
  padding: "15px",
}))

export const Hr = styled("div")(({ theme }) => ({
  height: "1px",
  background: theme.palette.grey["500"],
}))

export const CMButton = styled(Button)(({ theme }) => ({
  borderRadius: borderRadius,
  fontWeight: "500",
  fontSize: "0.8125rem",
  paddingInline: "20px",
  "&.btn-in-active": {
    backgroundColor: "#ffeeee ",
    color: "rgb(221 0 0)",
    "&:hover": {
      backgroundColor: "rgb(221 0 0)",
      color: "white",
    },
  },
  "&:hover": {
    boxShadow: "none",
  },
  "&.max-h": {
    height: "max-content",
  },
  "&:disabled": {
    "&:before": {
      content: '""',
      background: `url(${disableLoader})`,
      backgroundColor: theme.palette.grey["200"],
      width: "100%",
      height: "100%",
      position: "absolute",
      left: "0",
      top: "0",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "center",
      backgroundPositionY: "center",
      backgroundSize: "155px",
      borderRadius: borderRadius,
      zIndex: "1",
    },
  },
  "&.approve": {
    width: "unset",
    height: "unset",
    padding: "5px 12px !important",
    backgroundColor: "rgb(85, 208, 136,0.1)",
    color: "rgb(85, 208, 136)",
    "&:hover": {
      backgroundColor: "rgb(85, 208, 136)",
      color: "white",
    },
  },
  "&.reject": {
    width: "unset",
    height: "unset",
    padding: "5px 12px !important",
    backgroundColor: "rgb(221, 0, 0,0.1)",
    color: "rgb(221, 0, 0)",
    "&:hover": {
      backgroundColor: "rgb(221, 0, 0,0.8)",
      color: "white",
    },
  },
}))

export const LButton = styled(Button)(() => ({
  borderRadius: borderRadius,
  fontWeight: "500",
  fontSize: "0.8125rem",
  paddingInline: "20px",
  "&.btn-in-active": {
    backgroundColor: "#ffeeee ",
    color: "rgb(221 0 0)",
    "&:hover": {
      backgroundColor: "rgb(221 0 0)",
      color: "white",
    },
  },
  "&:disabled": {
    pointerEvents: "auto",
    cursor: "not-allowed !important",
    backgroundColor: "#cccccc !important",
    color: "#666666 !important",
  },
  "&:hover": {
    boxShadow: "none",
  },
  "&.approve": {
    width: "unset",
    height: "unset",
    padding: "5px 12px !important",
    backgroundColor: "rgb(85, 208, 136,0.1)",
    color: "rgb(85, 208, 136)",
    "&:hover": {
      backgroundColor: "rgb(85, 208, 136)",
      color: "white",
    },
  },
  "&.reject": {
    width: "unset",
    height: "unset",
    padding: "5px 12px !important",
    backgroundColor: "rgb(221, 0, 0,0.1)",
    color: "rgb(221, 0, 0)",
    "&:hover": {
      backgroundColor: "rgb(221, 0, 0,0.8)",
      color: "white",
    },
  },
}))

export const LinkButton = styled("button")(() => ({
  padding: "0",
  border: "none",
  background: "unset",
  cursor: "pointer",
}))

export const CMDialogTitle = styled("h3")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "600",
  color: theme.palette.primary.main,
  margin: "0px",
  display: "flex",
  alignItems: "center",
  svg: {
    marginRight: "10px",
  },
}))

export const ButtonRow = styled(Box)(() => ({
  button: {
    marginRight: "10px",
    "&:last-child": {
      marginRight: "0px",
    },
  },
}))

export const IconButton = styled("button")(({ theme }) => ({
  ...CenterFlex(),
  border: `1px solid ${theme.palette.grey["500"]}`,
  background: "unset",
  width: "30px",
  height: "30px",
  padding: "0px",
  borderRadius: borderRadius,
  transitionDuration: "0.3s",
  position: "relative",
  svg: {
    width: "20px",
    height: "20px",
    path: {
      fill: theme.palette.grey["600"],
      transitionDuration: "0.3s",
    },
  },
  "&:hover": {
    background: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    svg: {
      path: {
        fill: theme.palette.primary.main,
      },
    },
  },
  ".indication": {
    position: "absolute",
    width: "6px",
    height: "6px",
    background: theme.palette.error.main,
    borderRadius: "50%",
    top: "-1px",
    right: "0px",
    boxShadow: `0px 2px 8px #ff0000`,
  },
}))

export const DownloadLoder = styled("div")(() => ({
  width: "18px",
  height: "3px",
  background: `rgb(157 199 255)`,
  display: "inline-block",
  position: "relative",
  marginTop: "12px",
  "&::after": {
    content: '""',
    left: "50%",
    bottom: "0",
    transform: "translate(-50%, 0)",
    position: "absolute",
    border: "6px solid transparent",
    borderTopColor: "rgb(157 199 255)",
    animation: "bump 0.4s ease-in-out infinite alternate",
  },
  " &::before": {
    content: '""',
    left: "50%",
    bottom: "11px",
    transform: "translate(-50%, 0)",
    position: "absolute",
    width: "3px",
    height: "7px",
    background: "rgb(157 199 255)",
    animation: " bump 0.4s ease-in-out infinite alternate",
  },
  "@keyframes bump": {
    "0%": {
      transform: "translate(-50%, 5px)",
    },
    "100%": {
      transform: "translate(-50%, -5px)",
    },
  },
}))

export const NoDataFound = styled("span")(() => ({
  textAlign: "center",
  img: {
    width: "350px",
    display: "block",
    marginInline: "auto",
    marginBottom: "25px",
  },
  h3: {
    marginTop: "0px",
    marginBottom: "10px",
    fontSize: "1.25rem",
  },
  p: {
    margin: "0",
  },
}))

export const DisableLoader = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "fixed",
  left: "0",
  top: "0",
  zIndex: "1400",
}))

export const DialogStyle = styled(Dialog)(({ theme }) => ({
  zIndex: 9999,
  ".MuiDialog-paper": {
    width: "100%",
    background: theme.palette.background.paper,
    padding: "20px",
    borderRadius: "15px",
  },
  "&.blur": {

    // ".MuiDialog-root": {
    //   zIndex: 9999
    // },

    ".MuiDialog-paper": {
      background: "rgb(0 0 0/0.7)",
    },
    ".MuiBackdrop-root": {
      backdropFilter: "blur(5px)",
    },
  },
  ".close": {
    ...CenterFlex(),
    height: "22px",
    width: "22px",
    borderRadius: "50%",
    position: "absolute",
    right: "10px",
    top: "10px",
    border: "none",
    boxShadow: "0px 5px 15px rgb(0 0 0/0.3)",
    svg: {
      width: "15px",
      height: "15px",
    },
  },
}))

export const DialogWrapper = styled("div")(() => ({}))

export const CommonModal = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingBlock: "15px",
  ".icon": {
    ...CenterFlex(),
    width: "65px",
    height: "65px",
    marginBottom: "25px",
    marginInline: "auto",
    background: theme.palette.info.light,
    borderRadius: "50%",
    svg: {
      width: "35px",
      height: "35px",
      color: theme.palette.info.main,
    },
  },
  h3: {
    fontSize: "1.375rem",
    marginTop: "0px",
    fontWeight: "600",
    marginBottom: "15px",
  },
  p: {
    marginTop: "0px",
    marginBottom: "25px",
    fontWeight: "500",
    color: theme.palette.grey["600"],
  },
  span: {
    marginTop: "0px",
    marginBottom: "25px",
    fontWeight: "500",
    color: theme.palette.grey["600"],

  }
}))

export const DeleteModal = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingBlock: "15px",
  ".icon": {
    ...CenterFlex(),
    width: "65px",
    height: "65px",
    marginBottom: "25px",
    marginInline: "auto",
    background: theme.palette.error.light,
    borderRadius: "50%",
    svg: {
      width: "35px",
      height: "35px",
      color: theme.palette.error.main,
    },
  },
  h3: {
    fontSize: "1.375rem",
    marginTop: "0px",
    fontWeight: "600",
    marginBottom: "15px",
  },
  p: {
    marginTop: "0px",
    marginBottom: "25px",
    fontWeight: "500",
    color: theme.palette.grey["600"],
  },
}))

export const DeleteButtonRow = styled("div")(() => ({
  display: "flex",
}))

export const StatusStyle = styled("span")<{ status: STATUS }>(({ status }) => ({
  ...CenterFlex(),
  width: "max-content",
  height: "25px",
  borderRadius: "25px",
  padding: "0 12px",
  background: `rgb(${statusIndicatorColor[status] ?? "93 135 255"}/0.1)`,
  color: `rgb(${statusIndicatorColor[status] ?? "93 135 255"})`,
  fontWeight: "500",
  fontSize: "0.75rem",
  svg: {
    width: "14px",
    marginRight: "5px",
    color: `rgb(${statusIndicatorColor[status]})`,
  },
}))

export const IConCardElement = styled("span")<{ status: string }>(({ status }) => ({
  ...CenterFlex(),
  borderRadius: "6px",
  background: `rgb(${iconIndicatorColor[status as IKey]}/0.1)`,
  color: `rgb(${iconIndicatorColor[status as IKey]})`,
  svg: {
    width: "18px",
    height: "18px",
    color: `rgb(${iconIndicatorColor[status as IKey]})`,
    fill: `rgb(${iconIndicatorColor[status as IKey]})`,
  },
  marginRight: "5px",
  padding: "10px",
  "&.rounded-icon": {
    borderRadius: "50%",
  },
}))

export const RolesCard = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: borderRadius,
  boxShadow: theme.shadows[3],
  padding: "25px",
  height: "170px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  p: {
    margin: "0px",
    color: theme.palette.grey["600"],
  },
  "&.empty_card": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    ".pic": {
      width: "150px",
    },
    ".empty_content": {
      textAlign: "right",
      paddingLeft: "25px",
      button: {
        marginBottom: "15px",
      },
    },
  },
}))

export const LoanCard = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: borderRadius,
  boxShadow: theme.shadows[3],
  padding: "15px",
  height: "130px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  p: {
    margin: "0px",
    color: theme.palette.grey["600"],
  },
  "&.empty_card": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    ".pic": {
      width: "150px",
    },
    ".empty_content": {
      textAlign: "right",
      paddingLeft: "25px",
      button: {
        marginBottom: "15px",
      },
    },
  },
}))

export const TopSection = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "30px",
}))

export const BottomSection = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ".content": {
    h5: {
      fontSize: "1rem",
      fontWeight: "500",
      margin: "0px",
    },
    button: {
      marginTop: "12px",
      position: "relative",
      zIndex: "1",
      color: theme.palette.primary.main,
      "&:before": {
        content: '""',
        transitionDuration: "0.3s",
        display: "inline-block",
        width: "calc(100% + 15px)",
        height: "calc(100% + 8px)",
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: "-1",
        borderRadius: borderRadius,
        background: theme.palette.secondary.main,
        transform: "translate(-50%, -50%)",
        opacity: "0",
      },
      "&:hover:before": {
        opacity: "1",
      },
      "&.delete": {
        color: theme.palette.error.main,
        marginLeft: "20px",
        "&:before": {
          background: theme.palette.error.light,
        },
      },
    },
  },
  ".copy": {
    ...CenterFlex(),
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    transitionDuration: "0.3s",
    svg: {
      width: "20px",
      color: theme.palette.grey["600"],
    },
    "&:hover": {
      background: theme.palette.grey["200"],
    },
  },
}))

export const UserList = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  zIndex: "5",
}))

export const UserPic = styled("span")(({ theme }) => ({
  marginLeft: "-12px",
  border: `1px solid ${theme.palette.background.paper}`,
  borderRadius: "50%",
  width: "35px",
  height: "35px",
  position: "relative",
  boxShadow: "5px 4px 7px rgb(0 0 0/0.08)",
  transitionDuration: "0.3s",
  background: theme.palette.background.default,
  cursor: "pointer",
  "&.count": {
    ...CenterFlex(),
    paddingLeft: "8px",
    marginLeft: "-8px",
    background: theme.palette.grey["200"],
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    display: "block",
    objectFit: "cover",
    objectPosition: "center",
  },
}))

export const PermissionListRow = styled("div")(({ theme }) => ({
  ".label": {
    color: theme.palette.grey["600"],
    marginBottom: "10px",
    display: "inline-block",
    fontWeight: "500",
    paddingTop: "10px",
  },
  ".permissions": {
    height: "74px",
    background: theme.palette.grey["200"],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
    padding: "8px 15px",
    borderRadius: borderRadius,
    ".MuiFormControlLabel-root": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      "&:last-child": {
        marginRight: "0px",
      },
      ".MuiSwitch-root, .MuiTypography-root ": {
        display: "flex",
      },
      ".MuiTypography-root ": {
        fontSize: "0.8125rem",
        justifyContent: "center",
        marginTop: "-2px",
        textTransform: "capitalize",
        fontWeight: "500",
      },
    },
  },
}))

export const FormSubHeading = styled("h4")(() => ({
  fontSize: "0.875rem",
  fontWeight: "600",
  marginTop: "0px",
  marginBottom: "20px",
}))

export const RolesChips = styled("span")(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  borderRadius: "25px",
  padding: "4px 15px",
  fontWeight: "500",
  margin: "5px",
  fontSize: "0.75rem",
}))

export const TabRow = styled(Tabs)(({ theme }) => ({
  width: "100%",
  borderBottom: `1px solid ${theme.palette.grey["300"]}`,
  ".MuiTabs-indicator": {
    height: "2px",
    boxShadow: `1px 1px 10px ${theme.palette.primary.main}`,
  },
}))

export const TabItem = styled(Tab)(() => ({
  fontSize: "0.8175rem",
  minHeight: "auto",
  paddingInline: "0px",
  marginRight: "30px",
  fontWeight: "500",
  transitionDuration: "0.3s",
  ".MuiSvgIcon-root": {
    width: "20px",
  },
  "&:last-child": {
    marginRight: "0px",
  },
}))

export const TitleStyle2 = styled("h4")(({ theme }) => ({
  fontSize: "0.875rem",
  marginTop: "0px",
  marginBottom: "20px",
  fontWeight: "600",
  position: "relative",
  span: {
    display: "inline-block",

    position: "relative",
    zIndex: "1",
    "&:before": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "-5px",
      zIndex: "0",
      background: `linear-gradient(to right, ${theme.palette.secondary.dark} 0%, rgba(255, 255, 255, 0) 100%)`,
      width: "75%",
      height: "5px",
    },
  },
}))

export const TitleStyle3 = styled("h5")(() => ({
  fontSize: "0.8175rem",
  fontWeight: "600",
}))

export const MyProfileModal = styled("div")(() => ({
  ...CenterFlex(),
  paddingBlock: "35px",
  button: {
    border: "none",
    background: "transparent",
    textAlign: "center",
    marginRight: "20px",
    padding: "0px",
    "&:last-child": {
      marginRight: "0px",
    },
    ".icon": {
      ...CenterFlex(),
      border: "1px solid rgb(255 255 255 / 0.7)",
      color: " rgb(255 255 255 / 0.7)",
      marginInline: "auto",
      marginBottom: "10px",
      width: "100px",
      height: "100px",
      borderRadius: "15px",
      transitionDuration: "0.3s",
      "&:hover": {
        background: "rgb(255 255 255 / 0.7)",
        color: "rgb(0 0 0 / 0.6)",
      },
      svg: {
        width: "40px",
        height: "40px",
      },
    },
    ".title": {
      fontSize: "0.8175rem",
      fontWeight: "500",
      color: " rgb(255 255 255 / 0.7)",
    },
  },
}))

export const DashboardStack = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: theme.spacing(3),
  marginTop: "10px",
  h3: {
    fontSize: "1rem",
    fontWeight: "600",
    margin: "0px",
  },
  h6: {
    color: "#787878",
    fontSize: "1rem",
    margin: "40px 0 0 0 !important",
  },
  p: {
    fontWeight: "500",
  },
}))

export const ApprovalBox = styled(Box)(({ theme }) => ({
  padding: "20px",
  width: "100%",
  borderRadius: 1,
  gap: theme.spacing(2),
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  alignItems: "center",
}))

export const DndCardWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "16px",
})

export const ContentContainer = styled(Box)(({ theme }) => ({
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.grey["500"],
    borderRadius: "10px",
  },
}))

export const StyledIconButton = styled(IconButton)<{
  color: string
  background: string
}>(({ color, background }) => ({
  color: color,
  background: background,
  borderRadius: "20%",
  width: "40px",
  height: "40px",
  border: "transparent",
  svg: {
    path: {
      fill: color,
    },
  },
}))

//Workflow components

export const CenterFlexContainer = styled(Box)({
  ...CenterFlex(),
})

export const ChartCurvedLine = styled(Box)(() => ({
  position: "absolute",
  backgroundColor: "#D3D3D3",
  height: "95%",
  width: "2px",
  left: "40px",
}))

export const ChartStraightLine = styled(Box)(({ theme }) => ({
  position: "absolute",
  backgroundColor: "#D3D3D3",
  height: "66%",
  width: "2px",
  left: "100px",
  top: "91px",
  [theme.breakpoints.down(1145)]: {
    top: "116px",
  },
}))

export const StepBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "30px",
  "&:last-child": {
    marginBottom: 0,
  },
})

export const HorizontalLine = styled(Box)(() => ({
  position: "absolute",
  width: "62px",
  height: "29px",
  borderTopRightRadius: "12px",
  borderTop: "2px solid #D3D3D3",
  borderRight: "2px solid #D3D3D3",
  borderBottom: "none",
  borderLeft: "none",
  left: "40px",
  top: "78px",
}))

export const ProfileSection = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  h6: {
    fontSize: "0.8175rem",
    fontWeight: 600,
    marginTop: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  p: {
    margin: 0,
    fontSize: "0.85rem",
    fontWeight: 400,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}))

export const DetailSection = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  marginBottom: "15px",
  p: {
    fontSize: "0.875rem",
    margin: "10px 16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
})

export const DetailProfileSection = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  p: {
    fontSize: "0.875rem",
    margin: "10px 16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  span: {
    fontWeight: 600,
  },
})

export const UserInfo = styled("div")({
  display: "flex",
  alignItems: "center",
  h6: {
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "1.2rem",
    margin: 0,
  },
  p: {
    margin: 0,
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.75,
    color: "#787878",
  },
  span: {
    margin: 0,
    fontSize: "0.775rem",
    fontWeight: 400,
    lineHeight: 1.75,
    color: "#787878",
  },
})

// Loan Request
export const StatusBadge = styled("span")(({ }) => ({
  width: "max-content",
  display: "flex",
  alignItems: "center",
  "&.Active": {
    color: "rgb(85, 208, 136)",
  },
  "&.InActive": {
    color: "rgb(233, 80, 80)",
  },
  "&.approved": {
    color: "rgb(85, 208, 136)",
  },
  "&.rejected": {
    color: "rgb(233, 80, 80)",
  },
  "&.pending": {
    color: "rgb(231, 175, 33)",
  },
  "&.badge-Active,&.badge-cleared,&.badge-good": {
    color: "rgb(85, 208, 136)",
    backgroundColor: "rgb(85, 208, 136,0.1)",
  },
  "&.badge-approved,&.badge-cleared,&.badge-good": {
    color: "rgb(85, 208, 136)",
    backgroundColor: "rgb(85, 208, 136,0.1)",
  },
  "&.badge-perfect": {
    color: "rgb(93, 135, 255)",
    backgroundColor: "rgb(93, 135, 255,0.1)",
  },
  "&.badge-rejected,&.badge-bad": {
    color: "rgb(233, 80, 80)",
    backgroundColor: "rgb(233, 80, 80,0.1)",
  },
  "&.badge-pending,&.badge-average": {
    color: "rgb(231, 175, 33)",
    backgroundColor: "rgb(231, 175, 33,0.1)",
  },
  "&.badge-": {
    color: "rgb(93, 135, 255)",
    backgroundColor: "rgb(93, 135, 255,0.1)",
  },
  "&.badge": {
    padding: "5px 12px",
    borderRadius: "6px",
    height: "max-content",
  },
  "&.bordered": {
    padding: "10px 30px",
    borderRadius: "24px",
  },
  "&.bordered-verified": {
    color: "rgb(85, 208, 136)",
    backgroundColor: "rgb(85, 208, 136,0.1)",
  },
  "&.bordered-approved": {
    color: "rgb(85, 208, 136)",
    backgroundColor: "rgb(85, 208, 136,0.1)",
  },
  "&.bordered-obsolete": {
    color: "rgb(233, 80, 80)",
    backgroundColor: "rgb(233, 80, 80,0.1)",
  },
  "&.bordered-pending": {
    color: "rgb(231, 175, 33)",
    backgroundColor: "rgb(231, 175, 33,0.1)",
  },
  ".start-icon": {
    marginRight: "5px",
  },
  ".icon": {
    width: 16,
    height: 16,
  },
}))

// FAQ export styles
export const FAQContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  padding: "24px 100px",
})

export const FAQList = styled(List)(({ theme }) => ({
  width: "50%",
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  zIndex: 2,
  position: "relative",
  paddingTop: 0,
  marginTop: "40px",
}))

export const FAQDetailsCard = styled(Card)(() => ({
  flex: 1,
  marginLeft: "-50px",
  padding: "16px 0px 16px 42px",
  backgroundColor: "#f0f4ff",
  borderRadius: "35px",
  boxShadow: "none",
  height: "auto",
  h6: {
    fontSize: "1.125rem",
    lineHeight: "28px",
    fontWeight: "600",
    margin: 0,
  },
  p: {
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "32px",
    marginTop: "20px",
  },
}))

export const AddNewButton = styled("button")(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
}))

export const FAQListItem = styled(ListItemButton)(({ theme }) => ({
  padding: "15px 35px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  background: "#fafbff",
}))

export const FAQListItemText = styled(ListItemText)(() => ({
  p: {
    fontSize: "0.8125rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
  },
}))

//loanrequest

export const UserText = styled("div")({
  marginLeft: "16px",
  "&.authority-details": {
    h6: {
      fontSize: "0.875rem",
      margin: 0,
    },
    p: {
      fontSize: "0.75rem",
    },
  },
  ".authority-label": {
    fontSize: "0.75rem",
    borderRadius: "6px",
    padding: "5px 12px",
    background: "#ECF2FF",
    color: " #0F2F51",
    marginBlock: "5px",
    fontWeight: 600,
    ".level-count": {
      marginLeft: "3px",
      color: " #0F2F51",
      fontWeight: 500,
    },
  },
})

export const StyledDivider = styled(Divider)<{
  height: string
}>(({ height }) => ({
  height: height,
  marginTop: "5px",
  backgroundColor: "rgba(0, 0, 0, 0.12)",
  borderColor: "#ECECEC",
}))

export const LoaderContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}))

export const FlexList = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "space-between",
  ".branch-request-card": {
    flexDirection: "column",
    ".content": {
      padding: "5px"
    }
  },
  ".count-content": {
    ".pb-5": {
      paddingBlock: "5px",
    },
  },
  ".count-content-image": {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  ".pic": {
    "&.icon-card": {
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      width: "unset !important",
      height: "unset !important",
      svg: {
        width: 20,
        height: 20,
      },
      "&.New_Application": {
        backgroundColor: "rgb(25 118 210 / 0.1)",
        color: "rgb(25 118 210)",
      },
      "&.Preliminary_Review": {
        backgroundColor: "rgb(18 140 126 / 0.1)",
        color: "rgb(18 140 126)",
      },
      "&.Document_Scrutiny": {
        backgroundColor: "rgb(252 100 45 / 0.1)",
        color: "rgb(252 100 45)",
        svg: {
          color: "rgb(252 100 45)",
        },
      },
      "&.Disbursement": {
        backgroundColor: "rgb(219 68 55 / 0.1)",
        color: "rgb(219 68 55)",
      },
      "&.Credit_Evaluation": {
        backgroundColor: "rgb(244 180 0 / 0.1)",
        color: "rgb(244 180 0)",
      },
      "&.Risk_Assessment_Compliance": {
        backgroundColor: "rgb(225 48 108 / 0.1)",
        color: "rgb(225 48 108)",
        svg: {
          fill: "rgb(225 48 108)",
        },
      },
      "&.Management_Approval": {
        backgroundColor: "rgb(52 183 241 / 0.1)",
        color: "rgb(52 183 241)",
      },
      "&.Loan_Documents": {
        backgroundColor: "rgb(120 194 87 / 0.1)",
        color: "rgb(120 194 87)",
      },
      "&.default": {
        backgroundColor: "rgb(93 135 255 / 0.1)",
        color: "rgb(93 135 255)",
        svg: {
          fill: "rgb(93 135 255)",
        },
      },
      "&.sales-count": {
        width: "50px !important",
        height: "50px !important",
      },
      "&.completed": {
        backgroundColor: "rgb((85, 208, 136 / 0.1)",
        color: "rgb((85, 208, 136)",
        svg: {
          fill: "rgb((85, 208, 136)",
        },
      },
    },
  },
}))

export const StyledDatePickerInput = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: "5px",
  input: {
    border: `1px solid ${theme.palette.grey["500"]}`,
    height: "40px",
    borderRadius: borderRadius,
    transitionDuration: "0.3s",
    width: "285px",
    paddingInline: "12px",
    '&.error': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  '& .react-datepicker-popper': {
    zIndex: 1600, // Ensure popper is on top of everything inside the dialog
  },
  '& .react-datepicker__day--selected': {
    backgroundColor: '#3f51b5',
    color: '#fff',
  },
  '& .react-datepicker__header': {
    backgroundColor: '#f4f6f8',
    borderColor: '#e0e0e0',
  },
  '& .react-datepicker__day--keyboard-selected': {
    backgroundColor: '#3f51b5',
    color: '#fff',
  },
  '& .react-datepicker__day': {
    '&:hover': {
      backgroundColor: '#3f51b5',
      color: '#fff',
    },
  },
  '.error-message': {
    color: theme.palette.error.main,
    marginTop: '4px',
    position: 'absolute',
    bottom: '-30px',
    left: '7px',
    fontSize: '0.6875rem',
  }
}));
