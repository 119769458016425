import { Box } from "@mui/material"
import { fadeAnimation } from "src/resources"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export default function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      className={fadeAnimation}
      px={3}
      py={2}
      hidden={value !== index}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}
