import { Box, Grid, Skeleton } from "@mui/material";
import { ProfileSection, DetailsBox, ProfileDetails, CountDetails, ContactInfoSection, InfoList, ContentBlock } from "src/assets/styles/details";

export default function OrganizationLoader() {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item lg={3.5}>
          <ProfileSection>
            <DetailsBox>
              <ProfileDetails>
                <Skeleton className="org_img" variant="circular" />
                <Skeleton variant="text" width={250} style={{ marginInline: "auto" }} />
                <Skeleton variant="text" width={125} style={{ marginInline: "auto" }} />
              </ProfileDetails>
              <CountDetails>
                <div>
                  <Skeleton width={100} height={45} variant="rounded" />
                </div>
                <div>
                  <Skeleton width={100} height={45} variant="rounded" />
                </div>
                <div>
                  <Skeleton width={100} height={45} variant="rounded" />
                </div>
              </CountDetails>

              <ContactInfoSection>
                <InfoList>
                  <Skeleton variant="circular" width={30} height={30} />
                  <Skeleton variant="text" width={250} />
                </InfoList>

                <InfoList>
                  <Skeleton variant="circular" width={30} height={30} />
                  <Skeleton variant="text" width={250} />
                </InfoList>

                <InfoList>
                  <Skeleton variant="circular" width={30} height={30} />
                  <Skeleton variant="text" width={250} />
                </InfoList>

                <InfoList>
                  <Skeleton variant="circular" width={30} height={30} />
                  <Skeleton variant="text" width={250} />
                </InfoList>
              </ContactInfoSection>
            </DetailsBox>
          </ProfileSection>
        </Grid>
        <Grid item lg={8.5}>
          <DetailsBox>
            <ContentBlock>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={500} />
              <Skeleton variant="text" width={350} />
            </ContentBlock>

            <ContentBlock>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={500} />
              <Skeleton variant="text" width={700} />
              <Skeleton variant="text" width={350} />
            </ContentBlock>

            <ContentBlock>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={500} />
              <Skeleton variant="text" width={350} />
              <Skeleton variant="text" width={250} />
            </ContentBlock>

            <ContentBlock>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={500} />
              <Skeleton variant="text" width={350} />
            </ContentBlock>
          </DetailsBox>
        </Grid>
      </Grid>
    </Box>
  )
}
