import { styled } from "@mui/material"

interface ISideHeader {
  children: JSX.Element
}

export const SideHeader = styled("div")(({ theme }) => ({
  padding: "10px",
  ".side_header_section": {
    background: theme.palette.secondary.main,
    padding: "10px 20px",
    height:"50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center",
    button: {
      border: "none",
      height: "26px",
      width: "26px",
      svg: {
        width: "22px",
        height: "22px",
        path: {
          fill: theme.palette.primary.main,
        },
      },
      "&:hover": {
        background: theme.palette.background.paper,
      },
    },
  },
}))

export default function SideDialogSideHeader(props: ISideHeader) {
  const { children } = props

  return (
    <SideHeader id="sideDialogHeader">
      <div className="side_header_section">{children}</div>
    </SideHeader>
  )
}
