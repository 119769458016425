import { ColumnDef } from "@tanstack/react-table";
import { CRUD, IDropdownOptions, IPremissions, ITableActionColumn } from "src/interface";
import TableAction from "src/components/dataTable/TableAction";
import TableDetailsCard from "src/components/dataTable/TableDetailsCard";
import StatusTag from "src/components/shared/StatusTag";
import { Box, IconButton } from "@mui/material";
import { ButtonRow, LButton, RolesChips } from "src/assets/styles/custom";
import { dateFormatter, dateTimeFormatter } from "src/utils";
import { CloudDownloadOutlined } from "@mui/icons-material";
import { useCallback, useMutation } from "src/hooks";
import { VIEW_URL } from "src/api";
import { countryList, mutationOptions } from ".";
import React from "react";
import { formatStrings, getTotalExperience } from "src/utils/basicHelpers";
import moment from "moment";

export const usersColumns = (
  actionFunction: ({ CRUD, data }: ITableActionColumn) => void,
  access: IPremissions["access_controll"]
): ColumnDef<any>[] => {
  return [
    {
      header: () => <Box textAlign="left">Name</Box>,
      accessorKey: "firstName",
      cell: ({ row }) => (
        <TableDetailsCard
          {...{
            img: row.original.profile,
            first_name: row.original.first_name,
            last_name: row.original.last_name,
            name: `${row.original.firstName} ${row.original.lastName}`,
            description: row.original.email,
          }}
        />
      ),
    },
    {
      header: "Contact",
      accessorKey: "contact",
      cell: ({ row }) => <a href={`tel:+91${row.original.contact}`}>+91 - {row.original.contact}</a>,
    },
    {
      header: "Country",
      accessorKey: "country",
    },
    {
      header: "Roles",
      accessorKey: "roles",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <Box display="flex">
          <StatusTag status={row.original.status} />
        </Box>
      ),
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            deleteHandler: access.delete
              ? () => actionFunction({ CRUD: CRUD.delete, data: row.original })
              : undefined,
            editHandler: access.edit ? () => actionFunction({ CRUD: CRUD.edit, data: row.original }) : undefined,
            viewHandler: access.read ? () => actionFunction({ CRUD: CRUD.view, data: row.original }) : undefined,
          }}
        />
      ),
    },
  ]
}

export const organizationColumns = (
  actionFunction: ({ CRUD, data }: ITableActionColumn) => void,
  pageNavigation: (id: string) => void,
  access: IPremissions["access_controll"]
): ColumnDef<any>[] => {
  return [
    {
      header: () => <Box textAlign="left">Organization Name</Box>,
      accessorKey: "org_name",
      cell: ({ row }) => (
        <TableDetailsCard
          {...{
            img: row.original.company_logo?.[0]?.file,
            first_name: row.original.org_name,
            name: row.original.org_name,
            description: row.original.email,
          }}
        />
      ),
    },
    {
      header: "Number of Employee",
      accessorKey: "no_of_employee",
      cell: ({ row }) => row.original.no_of_employees ? row.original.no_of_employees : "-",
    },
    {
      header: "Founders",
      accessorKey: "founders",
      cell: ({ row }) => row.original.founders_name.join(",") ? row.original.founders_name.join(",") : "-",
    },
    {
      header: "Contact",
      accessorKey: "contact",
      cell: ({ row }) =>
        row.original.contact.filter((contact: any) => contact.trim() !== "").length > 0 ? (
          row.original.contact.filter((contact: any) => contact.trim() !== "").map((item: string, i: number) => (
            <React.Fragment key={i}>
              <a href={`tel:+91${item}`}>
                +91 {item}
              </a>
              {/* Add a comma if it's not the last item */}
              {i < row.original.contact.length - 1 && ', '}
            </React.Fragment>
          ))
        ) : (
          "-"
        )
    },
    {
      header: "Country",
      accessorKey: "country",
      cell: ({ row }) => row.original.country ? countryList.find((f: IDropdownOptions) => f?.value === row.original.country)?.label : "-",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <Box display="flex">
          <StatusTag status={row.original.status} />
        </Box>
      ),
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            editHandler: access.edit ? () => actionFunction({ CRUD: CRUD.edit, data: row.original }) : undefined,
            viewHandler: access.read ? () => pageNavigation(row.original._id) : undefined,
          }}
          disable={row.original.status}
        />
      ),
    },
  ]
}

export const permissionColumns = (): ColumnDef<any>[] => {
  return [
    {
      header: () => <Box textAlign="left">Name</Box>,
      accessorKey: "name",
      cell: ({ row }) => <Box textAlign="left">{row.original.name}</Box>,
    },
    {
      header: () => <Box textAlign="left">Assigned To</Box>,
      accessorKey: "roleNames",
      cell: ({ row }) => (
        <>
          <Box py={2} display="flex" flexWrap="wrap" maxWidth="500px">
            {row.original.roleNames.map((item: string, i: number) => (
              <RolesChips key={i}>{item}</RolesChips>
            ))}
          </Box>
        </>
      ),
    },
    {
      header: "Updated Date",
      accessorKey: "updatedAt",
      cell: ({ row }) => <>{dateTimeFormatter(row.original.updatedAt)}</>,
    },
  ]
}

export const organizationEmployessColumns = (): ColumnDef<any>[] => {
  return [
    {
      header: "Employee ID",
      accessorKey: "employee_id",
    },
    {
      header: () => <Box textAlign="left">Employee Name</Box>,
      accessorKey: "employeeName",
      cell: ({ row }) => (
        <>
          <TableDetailsCard
            {...{
              img: row.original.profile,
              first_name: row.original.first_name,
              last_name: row.original.last_name,
              name: `${row.original.first_name} ${row.original.last_name}`,
              description: row.original.email,
            }}
          />
        </>
      ),
    },
    {
      header: () => <Box textAlign="left">Roles</Box>,
      accessorKey: "job_title",
      cell: ({ row }) => (
        <>
          <Box textAlign="left">{row.original.job_title}</Box>
        </>
      ),
    },
    {
      header: "Contact No.",
      accessorKey: "contact",
      cell: ({ row }) => row.original.contact_number.phones?.[0].number,
    },
    {
      header: "Date of Joining",
      accessorKey: "joining_date",
      cell: ({ row }) => dateFormatter(row.original.joining_date),
    },
  ]
}

export const organizationRolesColumns = (): ColumnDef<any>[] => {
  return [
    {
      header: () => <Box textAlign="left">Roles</Box>,
      accessorKey: "role",
      cell: ({ row }) => (
        <>
          <Box textAlign="left">{row.original.role}</Box>
        </>
      ),
    },
    {
      header: "Total Users",
      accessorKey: "totalUsers",
    },
    {
      header: "Created Date",
      accessorKey: "createdDate",
    },
  ]
}

export const customersColumns = (pageNavigation: (id: string) => void): ColumnDef<any>[] => {
  return [
    {
      header: "Employee ID",
      accessorKey: "employee_id",
      cell: ({ row }) => row.original?.employee_id ? formatStrings(row.original?.employee_id) : "-",
    },
    {
      header: () => <Box textAlign="left">Employee Name</Box>,
      accessorKey: "first_name",
      cell: ({ row }) => (
        <TableDetailsCard
          {...{
            img: row.original?.profile,
            first_name: row.original.first_name,
            last_name: row.original.last_name,
            name: row.original?.first_name && `${row.original?.first_name} ${row.original?.last_name}`,
            description: row.original?.email ? row.original?.email : "-",
          }}
        />
      ),
    },
    {
      header: () => <Box textAlign="left">Organization Name</Box>,
      accessorKey: "org_name",
      cell: ({ row }) => <Box textAlign="left">{row.original?.org_name}</Box>,
    },
    {
      header: "Job",
      accessorKey: "job_title",
      cell: ({ row }) => row.original?.job_title ? row.original?.job_title : "-",
    },
    {
      header: "Mobile Number",
      accessorKey: "contact_number",
      cell: ({ row }) => {
        const phoneNumber = row.original.contact_number?.phones?.[0]?.number;

        return phoneNumber ? phoneNumber : "-";
      }
    },
    {
      header: "Experience",
      accessorKey: "exp_years",
      cell: ({ row }) => row.original?.joining_date ? getTotalExperience(row.original?.joining_date) : "-",
    },
    {
      header: "Monthly Salary",
      accessorKey: "basic",
      cell: ({ row }) => row.original?.compensation?.amount ? Math.round(row.original?.compensation?.amount) : "-",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <Box display="flex">
          <StatusTag status={row.original?.status} />
        </Box>
      ),
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            viewHandler: () => pageNavigation(row.original?._id),
          }}
        />
      ),
    },
  ]
}

export const repaymentColumn = (): ColumnDef<any>[] => {
  return [
    {
      header: "Advance ID",
      accessorKey: "loanId",
      cell: ({ row }) => row.original.loanId,
    },

    {
      header: "Advance Type",
      accessorKey: "loanType",
      cell: ({ row }) => row.original.loanType,
    },
    {
      header: "Advance Amount",
      accessorKey: "loanAmount",
      cell: ({ row }) => row.original.loanAmount,
    },
    {
      header: "Tenure",
      accessorKey: "tenure",
      cell: ({ row }) => row.original.tenure,
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => row.original.paidStatus,
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) => <>{dateTimeFormatter(row.original.createdAt)}</>,
    },
  ]
}

export const repaymentPaidColumn = (): ColumnDef<any>[] => {
  return [
    {
      header: "Advance ID",
      accessorKey: "loanId",
      cell: ({ row }) => row.original.loanId,
    },

    {
      header: "Advance Type",
      accessorKey: "loanType",
      cell: ({ row }) => row.original.loanType,
    },
    {
      header: "Advance Amount",
      accessorKey: "loanAmount",
      cell: ({ row }) => row.original.loanAmount,
    },
    {
      header: "Tenure",
      accessorKey: "tenure",
      cell: ({ row }) => row.original.tenure,
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => row.original.paidStatus,
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) => <>{dateTimeFormatter(row.original.createdAt)}</>,
    },
  ]
}

export const loanRequestColumn = (): ColumnDef<any>[] => {
  return [
    {
      header: "Application No",
      accessorKey: "applicationNo",
      cell: ({ row }) => row.original.applicationNo,
    },
    {
      header: "Advance Type",
      accessorKey: "loanType",
      cell: ({ row }) => row.original.loanType,
    },
    {
      header: "Advance Amount",
      accessorKey: "loanAmount",
      cell: ({ row }) => row.original.loanAmount,
    },
    {
      header: "Tenure",
      accessorKey: "tenure",
      cell: ({ row }) => row.original.tenure,
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) =>
        row.original.status === "Waiting_For_Confirm"
          ? "Pending"
          : row.original.status === "New_Application"
            ? "New Application"
            : row.original.status,
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) => <>{dateTimeFormatter(row.original.createdAt)}</>,
    },
  ]
}

export const loanColumn = (
  actionFunction: ({ CRUD, data }: ITableActionColumn) => void,
  pageNavigation: (id: string) => void,
  access: IPremissions["access_controll"]
): ColumnDef<any>[] => {
  return [
    {
      header: "Advance ID",
      accessorKey: "loanId",
      cell: ({ row }) =>
        row?.original?.loanId
          ?.split("-")
          .slice(0, 1)
          .concat(row?.original?.loanId?.split("-").slice(-1))
          .join("-"),
    },
    {
      header: () => <Box textAlign="left">User Details</Box>,
      accessorKey: "userName",
      cell: ({ row }) => (
        <TableDetailsCard
          {...{
            img: row.original.employee.profile,
            first_name: row.original.employee.first_name,
            last_name: row.original.employee.last_name,
            name: `${row.original.employee.first_name}`,
            description: row.original.employee.email,
          }}
        />
      ),
    },
    {
      header: "Organization Name",
      accessorKey: "org_name",
      cell: ({ row }) => row.original.employee.org_name,
    },
    {
      header: "Advance Amount",
      accessorKey: "loanAmount",
    },
    {
      header: "Advance Tenure",
      accessorKey: "loanTenure",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <Box display="flex">
          <StatusTag status={row.original.status} />
        </Box>
      ),
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            viewHandler: access.read ? () => pageNavigation(row.original._id) : undefined,
          }}
        />
      ),
    },
  ]
}

export const loanTransactionColumn = (
  actionFunction: ({ CRUD, data }: ITableActionColumn) => void,
  pageNavigation: (id: string) => void,
  access: IPremissions["access_controll"]
): ColumnDef<any>[] => {
  return [
    {
      header: "Advance ID",
      accessorKey: "loan_id",
    },
    {
      header: () => <Box textAlign="left">User Details</Box>,
      accessorKey: "name",
      cell: ({ row }) => (
        <TableDetailsCard
          {...{
            name: `${row.original.name}`,
          }}
        />
      ),
    },
    {
      header: "Amount",
      accessorKey: "amount",
    },
    {
      header: "Payment Method",
      accessorKey: "payment_method",
    },
    {
      header: "Activity",
      accessorKey: "activity",
    },
    {
      header: "Date",
      accessorKey: "date_time",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            viewHandler: access.read ? () => pageNavigation(row.original.loan_id) : undefined,
          }}
        />
      ),
    },
  ]
}

export const loanTransactionAdminBulkPaymentColumn = (pageNavigation: (id: string) => void): ColumnDef<any>[] => {
  return [
    {
      header: "Organization Name",
      accessorKey: "organization",
      cell: ({ row }) => row.original.organization.account_holder_name
    },
    {
      header: "Payment Date",
      accessorKey: "paidDate",
      cell: ({ row }) => `Payment of ${moment(row.original.paidDate).format('ll')}`
    },
    {
      header: "Total Amount",
      accessorKey: "amount",
      cell: ({ row }) => row.original.amount
    },
    {
      header: "Total Employees",
      accessorKey: "userCounts",
      cell: ({ row }) => row.original.userCounts
    },
    {
      header: "Reference No",
      accessorKey: "transactionRefNo",
      cell: ({ row }) => row.original.transactionRefNo
    },
    {
      header: "Status",
      accessorKey: "verify",
      cell: ({ row }) => (
        row.original.verify ? "Verified" : "Pending"
      ),
    },
    {
      header: "Action",
      accessorKey: "view",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            viewHandler: () => pageNavigation(row.original._id),
          }}
        />
      ),
    }
  ]
}


export const loanTransactionBulkPaymentColumn = (): ColumnDef<any>[] => {
  return [
    {
      header: "Payment Date",
      accessorKey: "paidDate",
      cell: ({ row }) => `Payment of ${moment(row.original.paidDate).format('ll')}`
    },
    {
      header: "Total Amount",
      accessorKey: "amount",
      cell: ({ row }) => row.original.amount
    },
    {
      header: "Total Employees",
      accessorKey: "userCounts",
      cell: ({ row }) => row.original.userCounts
    },
    {
      header: "Reference No",
      accessorKey: "transactionRefNo",
      cell: ({ row }) => row.original.transactionRefNo
    },
    {
      header: "Status",
      accessorKey: "verify",
      cell: ({ row }) => (
        row.original.verify ? "Verified" : "Pending"
      ),
    }
  ]
}

export const paymentHistoryColumn = (): ColumnDef<any>[] => {
  return [
    {
      header: "Amount",
      accessorKey: "amount",
    },
    {
      header: "Payment Method",
      accessorKey: "paymentMethod",
    },
    {
      header: "Received Date",
      accessorKey: "receivedDate",
    },
  ]
}

export const loanRequestColumns = (
  actionFunction: ({ CRUD, data }: ITableActionColumn) => void,
  pageNavigation: (id: string) => void,
  access: IPremissions["access_controll"]
): ColumnDef<any>[] => {
  return [
    {
      header: "Application No",
      accessorKey: "loanId",
      cell: ({ row }) =>
        row.original.applicationNo
          ?.split("-")
          .slice(0, 1)
          .concat(row?.original?.applicationNo?.split("-").slice(-1))
          .join("-"),
    },
    {
      header: "Order ID",
      accessorKey: "orderId",
      cell: ({ row }) =>
        row?.original?.orderId ? row?.original?.orderId : '-',
    },
    {
      header: () => <Box textAlign="left">User Details</Box>,
      accessorKey: "first_name",
      cell: ({ row }) => (
        <TableDetailsCard
          {...{
            img: row.original.employee.profile,
            first_name: row.original.employee.first_name,
            last_name: row.original.employee.last_name,
            name: `${row.original.employee.first_name}  ${row.original.employee.last_name}`,
            description: row.original.employee.email,
          }}
        />
      ),
    },
    {
      header: "Organization Name",
      accessorKey: "organizationName",
      cell: ({ row }) => (row.original.organization.org_name ? row.original.organization.org_name : "-"),
    },
    {
      header: "Advance Type",
      accessorKey: "loanType",
      cell: ({ row }) => row.original.loanType,
    },
    {
      header: "Advance Amount",
      accessorKey: "loanAmount",
      cell: ({ row }) => row.original.loanAmount,
    },
    {
      header: "Processing Fee",
      accessorKey: "processingFee",
      cell: ({ row }) => row.original.processingFee ? row.original.processingFee : "-",
    },
    {
      header: "Credit Amount",
      accessorKey: "creditAmount",
      cell: ({ row }) => row.original.creditAmount ? row.original.creditAmount : "-",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <Box display="flex">
          <StatusTag status={row?.original?.status?.split("_").join(" ")} />
        </Box>
      ),
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) => <>{dateTimeFormatter(row.original.createdAt)}</>,
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            viewHandler: access.read ? () => pageNavigation(row.original._id) : undefined,
          }}
        />
      ),
    },
  ]
}

export const organizationApprovalColumns = (): // actionFunction: ({ CRUD, data }: ITableActionColumn) => void,
  ColumnDef<any>[] => {
  return [
    {
      header: "Employee ID",
      accessorKey: "employee_id",
      cell: ({ row }) => row.original.employee_id,
    },
    {
      header: () => <Box textAlign="left">Employee Name</Box>,
      accessorKey: "first_name",
      cell: ({ row }) => (
        <TableDetailsCard
          {...{
            name: `${row.original.first_name}  ${row.original.last_name}`,
          }}
        />
      ),
    },
    {
      header: "Branch Name",
      accessorKey: "branch_location",
      cell: ({ row }) => row.original.organization.branch_location,
    },
    {
      header: "Designation",
      accessorKey: "job_title",
      cell: ({ row }) => row.original.job_title,
    },
    {
      header: "Approval Level",
      accessorKey: "loanAmount",
      cell: "Level",
    },
    {
      header: "Login Access",
      accessorKey: "tenure",
      cell: "Yes / Default",
    },
    {
      header: "Official Email ID",
      accessorKey: "createdAt",
      cell: ({ row }) => <>{row.original.email}</>,
    },
    {
      header: "Action",
      accessorKey: "action",

      // cell: ({ row }) => (
      //   <TableAction
      //     actionProps={{
      //       viewHandler: access.read
      //         ? () => pageNavigation(row.original._id)
      //         : undefined,
      //     }}
      //   />
      // ),
    },
  ]
}

export const approveLoanListColumn = (
  pageNavigation: (id: string) => void,
  access: IPremissions["access_controll"]
): ColumnDef<any>[] => {
  return [
    {
      header: "Advance ID",
      accessorKey: "loan_id",
      cell: ({ row }) => row.original.loanId,
    },
    {
      header: "Advance Amount",
      accessorKey: "loanAmount",
      cell: ({ row }) => row.original.loanAmount,
    },
    {
      header: "Tenure",
      accessorKey: "loanTenure",
      cell: ({ row }) => row.original.loanTenure,
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <Box display="flex">
          <StatusTag status={row.original.status} />
        </Box>
      ),
    },
    {
      header: "Disbursement Date",
      accessorKey: "disbursementDate",
      cell: ({ row }) => <>{new Date(row.original.disbursementDate).toLocaleDateString()}</>,
    },
    {
      header: "Bank Name",
      accessorKey: "bankName",
      cell: ({ row }) => row.original.bankName,
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            viewHandler: access.read ? () => pageNavigation(row.original._id) : undefined,
          }}
        />
      ),
    },
  ]
}

export const approveLoanDocsColumn = (
  pageNavigation: (id: string) => void,
  access: IPremissions["access_controll"]
): ColumnDef<any>[] => {
  return [
    {
      header: "Advance ID",
      accessorKey: "loan_id",
      cell: ({ row }) => row.original.loanId,
    },
    {
      header: "Submitted Date & Time",
      accessorKey: "created_at",
      cell: ({ row }) => <>{dateTimeFormatter(row.original.updatedAt)}</>,
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            viewHandler: access.read ? () => pageNavigation(row.original._id) : undefined,
          }}
        />
      ),
    },
  ]
}

export const organizationReportsColumn = [
  {
    header: "Organizations",
    accessorKey: "organizations",
  },
  {
    header: "Total Advance",
    accessorKey: "totalLoan",
  },
  {
    header: "Repayment",
    accessorKey: "repayment",
  },
  {
    header: "Non-repayment",
    accessorKey: "nonRepayment",
  },
]
export const profitRatioColumn = [
  {
    header: "Organizations",
    accessorKey: "organizations",
  },
  {
    header: "Received",
    accessorKey: "received",
  },
  {
    header: "Pending",
    accessorKey: "pending",
  },
  {
    header: "Profit / Earned",
    accessorKey: "profitEarned",
  },
]
export const organizationReportsData = [
  { organizations: "Zoho", totalLoan: "₹ 40000", repayment: "₹ 20000", nonRepayment: "₹ 20000" },
  { organizations: "Accenture", totalLoan: "₹ 70000", repayment: "₹ 30000", nonRepayment: "₹ 40000" },
  { organizations: "TCS", totalLoan: "₹ 80000", repayment: "₹ 30000", nonRepayment: "₹ 50000" },
  { organizations: "Amazon", totalLoan: "₹ 90000", repayment: "₹ 50000", nonRepayment: "₹ 40000" }
]
export const profitRatioData = [
  { organizations: "Zoho", loanDisbursed: "90 %", received: "70 %", profitEarned: "20 %" },
  { organizations: "Accenture", loanDisbursed: "95 %", received: "70 %", profitEarned: "70 %" },
  { organizations: "TCS", loanDisbursed: "60 %", received: "70 %", profitEarned: "70 %" },
  { organizations: "Amazon", loanDisbursed: "75 %", received: "70 %", profitEarned: "70 %" }
]


export const BackupColumn = (): ColumnDef<any>[] => {
  const [getViewedUrl] = useMutation(VIEW_URL, mutationOptions);

  const handleViewDocument = useCallback(
    async (url: string) => {
      const res = await getViewedUrl({
        variables: {
          image: url
        },
      });
      if (res.data) {
        downloadDocumentFile(res.data.getViewedUrl)
      }
    },
    [getViewedUrl]
  );

  const downloadDocumentFile = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return [
    {
      header: "Name",
      accessorKey: "name",
      cell: ({ row }) => row.original.name,
    },

    {
      header: "Size",
      accessorKey: "size",
      cell: ({ row }) => row.original.size,
    },
    {
      header: "IP Address",
      accessorKey: "ipAddress",
      cell: ({ row }) => row.original.ipAddress,
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) => dateTimeFormatter(row.original.createdAt),
    },
    {
      header: "Action",
      accessorKey: "url",
      cell: ((row) => {
        return (
          <>
            <IconButton
              title="Download"
              className="download_icon"
              onClick={() =>
                handleViewDocument(row.row.original.url)
              }
            >
              <CloudDownloadOutlined />
            </IconButton>
          </>)
      })
    }
  ];
};

export const AuditColumn = (): ColumnDef<any>[] => {

  return [
    {
      header: "UserID",
      accessorKey: "id",
      cell: ({ row }) => row.original.userId ? row.original.userId : "-",
    },
    {
      header: "Username",
      accessorKey: "name",
      cell: ({ row }) => row.original.userName ? row.original.userName : "-",
    },
    {
      header: "Subject",
      accessorKey: "details",
      cell: ({ row }) => row.original.category ? row.original.category.split("_").join(" ") : "-",
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => row.original.action,
    },
    {
      header: "IP Address",
      accessorKey: "ip",
      cell: ({ row }) => row.original.ip,
    },
    {
      header: "Browser",
      accessorKey: "browserName",
      cell: ({ row }) => row.original.browserName ? row.original.browserName : "-",
    },

    {
      header: "Date & Time",
      accessorKey: "updatedAt",
      cell: ({ row }) => dateTimeFormatter(row.original.updatedAt),
    }
  ];
};

export const EmailTemplateColumn = (
  actionFunction: ({ CRUD, data }: ITableActionColumn) => void,
  pageNavigation: (id: string, type: string) => void,
  access: IPremissions["access_controll"]
): ColumnDef<any>[] => {

  return [
    {
      header: "Template Name",
      accessorKey: "name",
      cell: ({ row }) => row?.original?.name.split("_").join(" "),
    },

    {
      header: "Subject",
      accessorKey: "subject",
      cell: ({ row }) => row.original.subject,
    },
    {
      header: "Last Modified",
      accessorKey: "updatedAt",
      cell: ({ row }) => dateTimeFormatter(row.original.updatedAt),
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: ({ row }) => (
        <TableAction
          actionProps={{
            editHandler: access.edit
              ? () => pageNavigation(row.original._id, "edit")
              : undefined,
            viewHandler: access.read
              ? () => pageNavigation(row.original._id, "view")
              : undefined,
          }}
        />
      ),
    },
  ];
};

export const OrganizationDeactivateColumn = (pageNavigation: (id: string, status: boolean) => void): ColumnDef<any>[] => {

  return [
    {
      header: () => <Box textAlign="left">Organization Name</Box>,
      accessorKey: "org_name",
      cell: ({ row }) => (
        <TableDetailsCard
          {...{
            name: row.original?.user?.org_name,
            description: row.original?.user?.email,
          }}
        />
      ),
    },
    {
      header: "Number of Employee",
      accessorKey: "no_of_employee",
      cell: ({ row }) => row.original.totalEmployeeCount ? row.original.totalEmployeeCount : "-",
    },
    {
      header: "Active Employee Count",
      accessorKey: "activeEmployeeCount",
      cell: ({ row }) => row.original.activeEmployeeCount ? row.original.activeEmployeeCount : "-",
    },
    {
      header: "Active Advance Request",
      accessorKey: "activeLoanRequest",
      cell: ({ row }) => row.original.activeLoanRequest ? row.original.activeLoanRequest : "-",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => row.original.status ? row.original.status : "-",
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: (({ row }) => {
        return (
          <>
            <ButtonRow display="flex" >
              <LButton
                variant="contained"
                className="approve"
                onClick={() => pageNavigation(row.original.user._id, true)}
              >
                Accept
              </LButton>
              <LButton
                variant="contained"
                className="reject"
                onClick={() => pageNavigation(row.original.user._id, false)}
              >
                Reject
              </LButton>
            </ButtonRow>
          </>)
      })
    },
  ];
};


export const repaymentAdminLoanColumn = (): ColumnDef<any>[] => {
  return [
    {
      header: "Advance ID",
      accessorKey: "loan_id",
      cell: ({ row }) => row.original.loanId,
    },
    {
      header: () => <Box textAlign="left">Customer Details</Box>,
      accessorKey: "userName",
      cell: ({ row }) => (
        <TableDetailsCard
          {...{
            img: row.original.employee.profile,
            first_name: row.original.employee.first_name,
            last_name: row.original.employee.last_name,
            name: `${row.original.employee?.first_name} ${row.original.employee?.last_name}`,
            description: row.original.employee.email,
          }}
        />
      ),
    },
    {
      header: "Organization Name",
      accessorKey: "org_name",
      cell: ({ row }) => row.original?.employee?.org_name,
    },
    {
      header: "Advance Type",
      accessorKey: "loanType",
      cell: ({ row }) => row.original.loanType,
    },
    {
      header: "Advance Amount",
      accessorKey: "loanAmount",
      cell: ({ row }) => row.original.loanAmount,
    },
    {
      header: "Tenure",
      accessorKey: "tenure",
      cell: ({ row }) => row.original.tenure,
    },

    // {
    //   header: "Transaction Ref No`",
    //   accessorKey: "transactionRefNo",
    //   cell: ({ row }) => row.original.transactionRefNo ? row.original.transactionRefNo : "-",
    // },

    {
      header: "Repayment Date",
      accessorKey: "repaymentDate",
      cell: ({ row }) => <>{new Date(row.original.repaymentDate).toLocaleDateString()}</>,
    },
    {
      header: "Status",
      accessorKey: "paidStatus",
      cell: ({ row }) => (
        <Box display="flex">
          <StatusTag status={row.original.paidStatus} />
        </Box>
      ),
    }
  ]
}
