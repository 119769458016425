import { TabProps } from "@mui/material"
import { TabItem, TabRow } from "src/assets/styles/custom"
import { ITab } from "src/interface"

interface ICustomTab {
  value: any
  data: ITab[]
  styleProps?: TabProps
  handleChange: (_event: React.SyntheticEvent, tabValue: number) => void
}

export default function CustomTab(props: ICustomTab) {
  const { data, styleProps, handleChange, value } = props

  return (
    <>
      <TabRow value={value} onChange={handleChange}>
        {data.map((item: ITab, i: number) => {
          return (
            <TabItem
              {...styleProps}
              disableRipple
              label={item.label}
              value={item.id}
              icon={<item.icon />}
              key={i}
            />
          )
        })}
      </TabRow>
    </>
  )
}
