import { BoxProps } from "@mui/material"
import { CheckboxInput } from "src/assets/styles/form"
import { IChangeEvent } from "src/interface"

interface ICheckbox {
  id: string
  onChange: (e: IChangeEvent) => void
  value: any
  checked: boolean
  styleProps?: BoxProps
}

export default function FormCheckbox(props: ICheckbox) {
  const { id, onChange, value, checked, styleProps } = props

  return (
    <>
      <CheckboxInput {...styleProps}>
        <label>
          <input id={id} type="checkbox" onChange={onChange} value={value} checked={checked} />
          <span className="checked"></span>
        </label>
      </CheckboxInput>
    </>
  )
}
