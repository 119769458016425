import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import toast from "react-hot-toast"
import usePermissionHook from "src/hooks/usePermissionHook"
import useQueryParamsHook from "src/hooks/useQueryParamsHook"
import { PERMISSION_MODULE } from "src/interface"
import { PAGE_URL } from "src/routes"

function RedirectComponent({ label }: { label: string }) {
  useEffect(() => {
    toast.error(`You do not have access to ${label}.`)
  }, [label])

  return <Navigate to={PAGE_URL.dashboard} />
}

export default function ModuleProvider({
  children,
  module,
}: {
  children: JSX.Element
  module: PERMISSION_MODULE
}) {
  const { accessControl, permissions } = usePermissionHook(module)

  const { queryValue } = useQueryParamsHook()
  const id = queryValue.get("id")
  const redirectStatus =
    (accessControl.create && !id) ||
    (accessControl.edit && id) ||
    (accessControl.read && id)

  return redirectStatus ? children : <RedirectComponent label={permissions.name} />
}
