import _ from "lodash"
import { createTheme } from "@mui/material/styles"
import components from "./Components"
import typography from "./Typography"
import { shadows, darkshadows } from "./Shadows"
import { baseDarkTheme, baselightTheme } from "./DefaultColors"
import * as locales from "@mui/material/locale"
import { useEffect, useSelector } from "src/hooks"

export const BuildTheme = (config: any = {}) => {
  const customizer = useSelector(state => state.customizer)
  const defaultTheme = customizer.activeMode === "dark" ? baseDarkTheme : baselightTheme
  const defaultShadow = customizer.activeMode === "dark" ? darkshadows : shadows
  const baseMode = {
    palette: {
      mode: customizer.activeMode,
    },
    shadows: defaultShadow,
    typography: typography,
  }
  const theme = createTheme(
    _.merge({}, baseMode, defaultTheme, locales, defaultTheme, {
      direction: config.direction,
    })
  )
  theme.components = components(theme)

  return theme
}

const ThemeSettings = () => {
  const activDir = useSelector(state => state.customizer.activeDir)
  const activeTheme = useSelector(state => state.customizer.activeTheme)
  const theme = BuildTheme({
    direction: activDir,
    theme: activeTheme,
  })
  useEffect(() => {
    document.dir = activDir
  }, [activDir])

  return theme
}

export { ThemeSettings }
