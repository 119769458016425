import { Navigate, Outlet } from "react-router-dom";
import { getAuthToken } from "src/utils";
import Main from "src/Main";

export default function PrivateRouting() {
  const authToken = getAuthToken();

  return authToken ? (
    <Main>
      <Outlet />
    </Main>
  ) : (
    <Navigate
      to={`auth/login`}
      replace
    />
  );
}
