import React from "react"
import { NavLink } from "react-router-dom"

// mui imports
import { List, styled, Chip, useTheme, Typography } from "@mui/material"
import MenuIcon from "src/assets/icon/MenuIcon"
import { ListIcon, ListItemBtn, ListText } from "src/assets/styles/sideMenu"
import { useTranslation } from "src/hooks"
import { PAGE_URL } from "src/routes"
import { getPathName } from "src/utils"

type NavGroup = {
  [x: string]: any
  id?: string
  navlabel?: boolean
  subheader?: string
  title?: string
  icon?: any
  href?: string
  children?: NavGroup[]
  chip?: string
  chipColor?: any
  variant?: string | any
  external?: boolean
  level?: number
  onClick?: React.MouseEvent<HTMLButtonElement, MouseEvent>
}

interface ItemType {
  item: NavGroup
  hideMenu?: any
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  level?: number | any
  pathDirect: string
}

const NavItem = ({ item, level, pathDirect, hideMenu, onClick }: ItemType) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const ListItemStyled = styled(ListItemBtn)(() => ({
    color:
      level > 1 && pathDirect === item?.href
        ? `${theme.palette.primary.main}!important`
        : theme.palette.text.secondary,
  }))

  const listItemProps: {
    component: any
    href?: string
    target?: any
    to?: any
  } = {
    component: item?.external ? "a" : NavLink,
    to: item?.href,
    href: item?.external ? item?.href : "",
    target: item?.external ? "_blank" : "",
  }

  const {
    loanApprovalDetail,
    loanManagementDetails,
    loanManagementTransactionDetail,
    customerManagementDetails,
    organizationManagementDetails,
    approvalWorkflowDetails,
    organizationWorkflowDetails,
    email_template_details,
    settingConfigure
  } = PAGE_URL;
  const prevLocation = [
    getPathName(loanApprovalDetail, 2),
    getPathName(loanManagementDetails, 2),
    getPathName(loanManagementTransactionDetail, 2),
    getPathName(customerManagementDetails, 2),
    getPathName(organizationManagementDetails, 2),
    getPathName(approvalWorkflowDetails, 2),
    getPathName(organizationWorkflowDetails, 2),
    getPathName(email_template_details, 2),
    getPathName(settingConfigure, 2),
  ].includes(getPathName(location.pathname, 2))
    ? `/${getPathName(location.pathname, 1)}/${getPathName(
      location.pathname,
      2
    )}`
    : location.pathname;

  return (
    <List component="li" disablePadding key={item?.id && item.title}>
      <ListItemStyled
        {...listItemProps}
        disabled={item?.disabled}
        selected={
          prevLocation === item.href
        }
        onClick={onClick}
      >
        <ListIcon className={level > 1 ? "child_icon" : "parent_icon"}>
          <MenuIcon icon={item.icon} />
        </ListIcon>
        <ListText>
          {hideMenu ? "" : <>{t(`${item?.title}`)}</>}
          {item?.subtitle ? <Typography variant="caption">{hideMenu ? "" : item?.subtitle}</Typography> : ""}
        </ListText>

        {!item?.chip || hideMenu ? null : (
          <Chip
            color={item?.chipColor}
            variant={item?.variant ? item?.variant : "filled"}
            size="small"
            label={item?.chip}
          />
        )}
      </ListItemStyled>
    </List>
  )
}

export default NavItem
