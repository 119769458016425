import { useCallback } from "react"
import { IDropdownOptions } from "src/interface"
import { countryList } from "src/resources/options"

export default function useCountryCode() {
  const getCountryName = useCallback((code: string) => {
    return countryList.find((f: IDropdownOptions) => f?.value === code)?.label as string
  }, [])

  return { getCountryName }
}
