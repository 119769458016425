export type widthSize = "small" | "medium" | "large"

export enum CRUD {
  edit = "EDIT",
  delete = "DELETE",
  view = "VIEW",
  create = "CREATE",
}

export enum PERMISSION_MODULE {
  Admin_Dashboard = "Admin_Dashboard",
  Admin_Analytics_Dashboard = "Admin_Analytics_Dashboard",
  Organization_Dashboard = "Organization_Dashboard",
  Employee_Dashboard = "Employee_Dashboard",
  User_Management = "User_Management",
  Organization_Management = "Organization_Management",
  Loan_Setup = "Loan_Setup",
  Role_Management = "Role_Management",
  Employee_Management = "Employee_Management",
  Customer_Management = "Customer_Management",
  Workflow_Management = "Workflow_Management",
  Settings_Configuration_Management = 'Settings_Configuration_Management',
  Organization_Workflow_Management = "Organization_Workflow_Management",
  Loans_Request_Management = "Loans_Request_Management",
  Loans_Transaction_Management = "Loans_Transaction_Management",
  Loans_Management = "Loans_Management",
  FAQ = "FAQ",
  Backups = "Backups",
  Reports_Analytics = "Reports_Analytics",
  Audit_Trails = "Audit_Trails",
  Email_Template = "Email_Template"
}

export type ISizes = "small" | "medium" | "large"

export enum STATUS {
  All = "all",
  Active = "Active",
  InActive = "InActive",
  Pending = "Pending",
  Revoked = "Revoked",
  Success = "Success",
  Failure = "Failure",
  Approved = "Approved",
  Rejected = "Rejected",
  Verified = "Verified",
  Open = "Open",
  Paid = "Paid",
  Closed = "Closed"
}

export enum ORG_STATUS {
  All = "all",
  Accepted = "Accepted",
  Draft = "Draft",
  Invited = "Invited",
  Deactivated = "Deactivated",
}

export enum PAYMENT_STATUS {
  All = "all",
  Paid = "Paid",
  Unpaid = "Unpaid",
  Overdue = "Overdue",
  Bulk_Payment = "Bulk_Payment"
}

export enum LOAN_REQUEST {
  All = "all",
  Approved = "Approved",
  Disbursed = "Disbursed",
  Pending = "Pending",
  Rejected = "Rejected",
}

export enum LOAN_STATUS {
  All = "all",
  Open = "Open",
  Paid = "Paid",
  Closed = "Closed",
}

export enum ORG_DETAILS_TAB {
  company_details = "company_details",
  banking_information = "banking_information",
  documents = "documents",
  employees = "employees",
  roles = "roles",
  notification = "notification",
  loan_configuration = "loan_configuration",
}

export enum APPROVE_WORKFLOW_TAB {
  Active = "Active",
  Inactive = "Inactive",
}

export enum CUS_DETAILS_TAB {
  employee_profile = "employee_profile",
  salary_info = "salary_info",
  banking_details = "banking_details",
  loan_request = "loan_request",
  repayments = "repayments",
  documents = "documents",
}

export enum LOAN_TAB {
  loan_id = "loan_id",
  all_loans = "all_loans",
  payments = "payments",
  documents = "documents",
  view_employee_info = "view_employee_info"
}

export enum AUDIT_TAB {
  Role_Management = "Role_Management",
  Loan_Management = "Loan_Management",
  User_Management = "User_Management",
  Customer_Management = "Customer_Management",
  Organization_Management = "Organization_Management",
  Workflow_Management = "Workflow_Management",
  Settings_Configuration_Management = "Settings_Configuration_Management",
  Backup_Management = "Backup_Management",
  Notification_Management = "Notification_Management",
  Reports_Management = "Reports_Management",
  Login_Management = "Login_Management",
  FAQ_Management = "FAQ_Management"
}

export enum AUDIT_ACTION_STATUS {
  All = "all",
  Login = "Login",
  Create = "Created",
  Update = "Updated",
  Delete = "Deleted",
  Logout = "Logout"
}

export enum AUDIT_BROWSER_STATUS {
  All = "all",
  Chrome = "Chrome",
  Safari = "Safari",
  Mozilla = "Mozilla",
  Microsoft_edge = "Microsoft_edge",
  Opera = "Opera",
}

export enum PROFILE_SIDEBAR {
  personal_information = "personal_information",
  account_detail = "account_detail",
  security_settings = "security_settings",
  preference = "preference",
  activity = "activity",
  support = "support",
  legal = "legal",
  deactivate = "deactivate"
}
