import { useEffect, useLocation, useQueryParamsHook, useSelector, useState } from "src/hooks"
import { Skeleton, useTheme } from "@mui/material"
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { CustomTable, EmptyColumn, RowsPerPageStyle, SortIcon, TableFooterSection } from "src/assets/styles/table"

//Components
import { Pagination } from "src/templates"
import { ColumnFilter, ContentBody, FormCheckbox, ImgTag, SearchFilter, SelectDropdown } from "src/components"

//Style
import { NoDataFound } from "src/assets/styles/custom"
import { pagination, rowsPerPageList } from "src/resources"
import { deepClone } from "src/utils"
import { IChangeEvent, IDropdownEvent } from "src/interface"
import { dispatch, setFilterValue, setPageTotalCount } from "src/redux"

//Images
import no_data from "src/assets/images/backgrounds/no_record.svg"
import { PAGE_URL } from "src/routes"

interface IDataTable {
  headerColumn: ColumnDef<any>[]
  dataColumn: any[]
  columnWithVisibility?: string[]
  searchFilter?: {
    placeholder?: string
  }
  loader?: boolean
  enableCheckbox?: boolean
  selectedValues?: string[]
  multiSelectHandler?: (values: []) => void
}

export default function useDataTable(props: IDataTable) {
  const theme = useTheme()
  const location = useLocation()
  const {
    dataColumn,
    headerColumn,
    columnWithVisibility,
    searchFilter,
    loader,
    enableCheckbox,
    selectedValues,
    multiSelectHandler,
  } = props

  const [columnVisibility, setColumnVisibility] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const filter = useSelector((state) => state.commonData.filter);
  const pageTotalCount = useSelector(
    (state) => state.commonData.pageTotalCount
  );
  const { queryValue } = useQueryParamsHook();
  const role = queryValue.get("role");
  const status = queryValue.get("status");
  const dateWise = queryValue.get("dateWise");
  const search = queryValue.get("search");
  const organization = queryValue.get("organization");
  const users = queryValue.get("users");
  const category = queryValue.get("category");
  const action = queryValue.get("action");
  const browsers = queryValue.get("browsers");

  console.log({ pageTotalCount })

  const table = useReactTable({
    columns: headerColumn,
    data: dataColumn,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  })

  const changeHandler = (array: any[]) => (multiSelectHandler as ([]) => void)(array)

  const multiSelectCheckBox = (e: IChangeEvent) => {
    const { value } = e.target;
    const selectAllValues = dataColumn.map(f => f._id);

    let updatedValues: string[] = [];

    if (value === "all") {
      // Toggle all rows
      if (selectedValues?.length === dataColumn.length) {
        updatedValues = [];
      } else {
        updatedValues = [...selectAllValues];
      }
    } else {
      // Toggle individual rows
      if (selectedValues?.includes(value)) {
        updatedValues = selectedValues.filter(item => item !== value);
      } else {
        updatedValues = [...(selectedValues || []), value];
      }

      // Handle select all logic
      if (updatedValues.length === dataColumn.length) {
        updatedValues = [...selectAllValues];
      }
    }

    changeHandler(updatedValues);
  };

  const colSpan = enableCheckbox ? headerColumn.length + 1 : headerColumn.length

  const tables = (
    <>
      <CustomTable className="custom-table">
        <div style={{ overflowX: "auto", position: "relative" }}>
          <ContentBody removeValues={[location.pathname === PAGE_URL.loanManagementRequest && window.innerWidth < 1300 ? "loanApproveTable" : ""]} styleProps={{ className: "table-scroll-body" }} autoHide={false}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "#fff",
                }}
                id="custom-table-header"
              >
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {enableCheckbox && (
                      <th align="left">
                        <FormCheckbox
                          id="all"
                          value="all"
                          onChange={multiSelectCheckBox}
                          checked={selectedValues?.length === dataColumn.length}
                        />
                      </th>
                    )}
                    {headerGroup.headers.map(header => {
                      return (
                        <th key={header.id} colSpan={header.colSpan} align="left">
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                          <SortIcon onClick={header.column.getToggleSortingHandler()}>
                            <svg viewBox="0 0 467 255" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M36.0627 254.75L243.729 254.75L430.73 254.75C462.73 254.75 478.73 216.084 456.064 193.417L283.396 20.7499C255.729 -6.91676 210.729 -6.91677 183.063 20.7499L117.396 86.4166L10.3961 193.417C-11.9373 216.084 4.06273 254.75 36.0627 254.75Z"
                                fill={
                                  header.column.getIsSorted() === "asc"
                                    ? theme.palette.grey["600"]
                                    : theme.palette.grey["500"]
                                }
                              />
                            </svg>

                            <svg viewBox="0 0 467 255" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M430.627 0H222.96H35.9591C3.95907 0 -12.0409 38.6667 10.6257 61.3337L183.293 234C210.96 261.667 255.96 261.667 283.627 234L349.293 168.334L456.293 61.3337C478.627 38.6667 462.627 0 430.627 0Z"
                                fill={
                                  header.column.getIsSorted() === "desc"
                                    ? theme.palette.grey["600"]
                                    : theme.palette.grey["500"]
                                }
                              />
                            </svg>
                          </SortIcon>
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <td colSpan={colSpan} style={{ paddingInline: "0px" }}>
                      <EmptyColumn>
                        <div style={{ width: "100%" }}>
                          {[...new Array(10)].map((_row: number, r: number) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                key={r}
                              >
                                {[...new Array(colSpan)].map((_cell: number, c: number) => {
                                  return (
                                    <Skeleton
                                      width={`calc(${100 / colSpan}% - 5px)`}
                                      height="40px"
                                      style={{ marginBottom: "10px" }}
                                      key={c}
                                      variant="rectangular"
                                    />
                                  )
                                })}
                              </div>
                            )
                          })}
                        </div>
                      </EmptyColumn>
                    </td>
                  </tr>
                ) : table.getRowModel().rows?.length > 0 ? (
                  table.getRowModel().rows?.map((row, i) => {
                    const id = table.getRowModel().rows[i].original._id

                    return (
                      <tr key={row.id}>
                        {enableCheckbox && (
                          <td align="left">
                            <FormCheckbox
                              id={id}
                              checked={selectedValues?.includes(id) as boolean}
                              value={id}
                              onChange={multiSelectCheckBox}
                            />
                          </td>
                        )}
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id} align="left">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={colSpan}>
                      <EmptyColumn>
                        <NoDataFound>
                          <ImgTag src={no_data} alt="no data" />
                          <h3>No Data found</h3>
                          <p>We haven't found any data's</p>
                        </NoDataFound>
                      </EmptyColumn>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </ContentBody>
        </div>

        {/* {pageTotalCount > (filter?.limit ?? 0) && ( */}

        {pageTotalCount !== 0 && pageTotalCount > 10 && (
          <TableFooterSection id="custom-table-footer">
            <div className="leftElement">
              <RowsPerPageStyle>
                <label>Row Per page</label>
                <SelectDropdown
                  {...{
                    name: "rowsPerPage",
                    onChange: (e: IDropdownEvent) =>
                      dispatch(
                        setFilterValue({
                          ...filter,
                          page: 1,
                          limit: e.value,
                        })
                      ),
                    selected: filter?.limit,
                    options: rowsPerPageList,
                  }}
                />
              </RowsPerPageStyle>
            </div>
            <div className="rightElement">
              <Pagination />
            </div>
          </TableFooterSection>
        )}
      </CustomTable>
    </>
  )

  useEffect(() => {
    return () => {
      dispatch(setFilterValue(undefined))
      dispatch(setPageTotalCount(0))
    }
  }, [])

  useEffect(() => {
    const queryValues = deepClone({
      search: search,
      role: role?.split(","),
      status: (status?.split(",") || []).filter(value => value !== "all"),
      dateWise: dateWise,
      organization: (organization?.split(",") || []).filter(value => value !== "all"),
      users: (users?.split(",") || []).filter(value => value !== "all"),
      category: (category?.split(",") || []).filter(value => value !== "all"),
      action: (action?.split(",") || []).filter(value => value !== "all"),
      browsers: (browsers?.split(",") || []).filter(value => value !== "all"),

      ...pagination,
    })

    if (!role) {
      delete queryValues.role
    }

    if (!status) {
      delete queryValues.status
    }

    if (!dateWise) {
      delete queryValues.dateWise
    }

    if (!search) {
      delete queryValues.search
    }
    if (!organization) {
      delete queryValues.organization
    }

    if (!users) {
      delete queryValues.users;
    }

    if (!category) {
      delete queryValues.category;
    }

    if (!action) {
      delete queryValues.action;
    }

    if (!browsers) {
      delete queryValues.browsers;
    }

    dispatch(setFilterValue(queryValues));
  }, [role, status, dateWise, search, organization, users, category, action, browsers]);

  return {
    tables,
    columnFilter: (
      <ColumnFilter
        tableInstance={table}
        columns={table.getAllLeafColumns()}
        columnWithVisibility={columnWithVisibility}
      />
    ),
    searchFilter: <SearchFilter placeholder={searchFilter?.placeholder} />,
  }
}
