import { Close, Search } from "@mui/icons-material";
import { styled } from "@mui/material";
import { IconButton, borderRadius } from "src/assets/styles/custom";
import { useEffect, useQueryParamsHook, useRef, useSelector, useState } from "src/hooks";
import { IChangeEvent } from "src/interface";
import { debounceChange } from "src/utils";

interface ISearchFilter {
  placeholder?: string;
}

const SearchFilterStyle = styled("div")<{ shrink: boolean }>(({ theme, shrink }) => ({
  position: "relative",
  marginRight: "10px",
  display: "flex",
  overflow: "hidden",
  input: {
    border: `1px solid ${theme.palette.grey["500"]}`,
    height: "30px",
    borderRadius: borderRadius,
    marginRight: "8px",
    transitionDuration: "0.3s",
    width: shrink ? "280px" : "0px",
    paddingInline: shrink ? "10px" : "0px",
    opacity: shrink ? 1 : 0,
  },
  button: {
    transitionDuration: "0.3s",
    ...(shrink && { borderRadius: "50%" }),
  },
}));

export default function SearchFilter(props: ISearchFilter) {
  const { placeholder } = props;
  const [enable, setEnable] = useState<boolean>(false);
  const { queryValue, updateQueryParam } = useQueryParamsHook();
  const filter = useSelector((state) => state.commonData.filter);
  const ref = useRef<HTMLInputElement>(null);

  const searchQuery = queryValue.get("search");

  const clearSearch = () => {
    if (ref.current) ref.current.value = "";
    updateQueryParam([], ["search"]);
  };

  const searchHandler = debounceChange((e: IChangeEvent) => {
    if (e.target.value.length > 0) {
      updateQueryParam([{ name: "search", value: e.target.value }]);
    } else {
      clearSearch();
    }
  });

  useEffect(() => {
    // Show input and populate with searchQuery if it exists
    if (searchQuery) {
      setEnable(true);
      if (ref.current) ref.current.value = searchQuery;
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!enable && !searchQuery) {
      setTimeout(() => {
        if (ref.current) ref.current.value = "";
      }, 300);
    } else if (enable) {
      setTimeout(() => {
        ref.current?.focus();
      }, 300);
    }
  }, [enable, searchQuery]);

  return (
    <SearchFilterStyle shrink={enable}>
      <div
        className={`animate__animated animate__faster ${enable ? "animate__fadeInRight" : "animate__fadeOutRight"
          }`}
      >
        <input
          type="text"
          ref={ref}
          onChange={searchHandler}
          placeholder={placeholder || "Search here..."}
        />
      </div>
      <IconButton
        onClick={() => {
          if (enable) {
            setEnable(false);
            if (filter?.search && filter?.search?.length > 0) {
              clearSearch();
            }
          } else {
            setEnable(true);
          }
        }}
      >
        {enable ? <Close /> : <Search />}
      </IconButton>
    </SearchFilterStyle>
  );
}
