import { IOrganizationErrorState } from "src/interface";

export const organizationState = {
  _id: "",
  status: "",
  sector: "",
  registration_info: "",
  post_code: "",
  org_name: "",
  no_of_employees: "",
  legal_structure: "",
  founders_name: "",
  email: "",
  directors_name: "",
  description: "",
  country: "",
  contact: "",
  companyId: "",
  company_logo: [],
  city: "",
  address_2nd_line: "",
  address_1st_line: "",
  website: "",
  tax_payerId: "",
  tax_identification_number: "",
  identifier_code: "",
  branch_location: "",
  bank_name: "",
  account_number: "",
  account_holder_name: "",
  terms_conditions: [],
  privacy_policy: [],
  license_permits: [],
  settingConfiguration: [],
  type: ""
};

export const organizationErrorState = {
  sector: "",
  registration_info: "",
  post_code: "",
  org_name: "",
  no_of_employees: "",
  legal_structure: "",
  founders_name: "",
  email: "",
  directors_name: "",
  description: "",
  country: "",
  contact: "",
  company_logo: "",
  city: "",
  address_2nd_line: "",
  address_1st_line: "",
  tax_payerId: "",
  tax_identification_number: "",
  identifier_code: "",
  branch_location: "",
  bank_name: "",
  account_number: "",
  account_holder_name: "",
  terms_conditions: "",
  privacy_policy: "",
  license_permits: "",
  website: "",
} as IOrganizationErrorState;
