import { useMemo } from "react"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, from, fromPromise, split } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { REFRESH_TOKEN } from "src/api"
import toast from "react-hot-toast"

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_BASE_URL,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_API_WB_BASE_URL || "ws://192.168.7.42:4000/graphql",
    on: {
      connected: () => console.log("WebSocket connected"),
      connecting: () => console.log("WebSocket connecting..."),
      closed: (event) => console.log("WebSocket closed", event),
      error: (error) => console.log("WebSocket error", error),
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return definition.kind === "OperationDefinition" && definition.operation === "subscription"
  },
  wsLink,
  httpLink
)

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("auth_token")
  const refreshToken = localStorage.getItem("refresh_token")

  // add the authorization to the headers
  operation.setContext(
    token
      ? {
        headers: {
          authorization: token,
          refreshToken,
          "Apollo-Require-Preflight": true,
        },
      }
      : {}
  )

  return forward(operation)
})

const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_API_BASE_URL || "http://localhost:4000/graphql",
  cache: new InMemoryCache(),
});

// Function to get a new token using the mutation
const getNewToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token"); // Assuming the refresh token is stored locally

  const response = await apolloClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: { refreshToken },
  });

  // Extract the access token and refresh token from the response
  const { accessToken, refreshToken: newRefreshToken } = response.data.refreshTokenGenerate;

  // Store the new tokens
  localStorage.setItem("auth_token", accessToken);
  localStorage.setItem("refresh_token", newRefreshToken);

  return accessToken; // Return the new access token
};

export default function ApolloConfigProvider({
  getGraphQLError,
  children,
  logout,
}: {
  getGraphQLError: (message: any) => void
  children: JSX.Element
  logout: () => void
}) {
  const apolloClient = useMemo(() => {
    const client: any = new ApolloClient({
      defaultOptions: {
        query: {
          fetchPolicy: "network-only",
          errorPolicy: "all",
        },
        mutate: {
          fetchPolicy: "network-only",
          errorPolicy: "all",
        },
      },
      link: from([
        onError(({ graphQLErrors, operation, forward }) => {
          if (graphQLErrors) {

            for (const err of graphQLErrors) {
              switch (err.message) {
                case "TokenExpiredError":
                  return fromPromise(
                    getNewToken().catch(() => {

                      return;
                    })
                  )
                    .filter((value) => Boolean(value))
                    .flatMap((accessToken) => {
                      const oldHeaders = operation.getContext().headers;

                      // modify the operation context with a new token
                      operation.setContext({
                        headers: {
                          ...oldHeaders,
                          authorization: `Bearer ${accessToken}`,
                        },
                      });

                      // retry the request, returning the new observable
                      return forward(operation);
                    });
                case "user not found":
                  toast.error("User not found")
                  logout();
                  break;
                case "Invalid Token":
                  logout()
              }
              break
            }
          }
        }),
        authMiddleware,
        splitLink,
      ]),
      cache: new InMemoryCache({ addTypename: false }),
    })

    return client;
  }, [getGraphQLError, logout])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
