import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import MenuList from "@mui/material/MenuList"
import {
  CheckboxGroup,
  Dropdown,
  DropdownButton,
  DropdownContainer,
  DropdownInner,
  FormError,
  MultiDropdownItem,
  SelectedChips,
} from "src/assets/styles/form"
import { IChangeEvent, IDropdownOptions, ISelectDropdown } from "src/interface"
import { deepClone } from "src/utils"
import { FormCheckbox } from "src/components"
import { useEffect, useRef, useState } from "src/hooks"
import { CancelOutlined } from "@mui/icons-material"

interface IState {
  visible: boolean
}

type ISelectDropdownProps = ISelectDropdown & {
  selected: any[]
}

export default function MultiSelectDropdown(props: ISelectDropdownProps) {
  const { name, selected, onChange, options, placeholder, error, view } = props
  const [state, setState] = useState<IState>({
    visible: false,
  })
  const { visible } = state

  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setState(state => ({ ...state, visible: !state.visible }))
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setState(state => ({ ...state, visible: false }))
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      setState(state => ({ ...state, visible: false }))
    } else if (event.key === "Escape") {
      setState(state => ({ ...state, visible: false }))
    }
  }

  useEffect(() => {
    if (visible) {
      anchorRef.current!.focus()
    } else {
      anchorRef.current!.blur()
    }
  }, [visible])

  const multiSelectHandler = (e: IChangeEvent) => {
    const { value } = e.target
    const selectAll = options.map((item: IDropdownOptions) => item?.value)
    const removeStateValue = selected.filter(f => f !== value && f !== "all")
    const removeOptionAll = options.filter(f => f?.value !== "all")
    if (selected.includes(value)) {
      if (value === "all") {
        onChange({ name, value: [] })
      } else {
        onChange({ name, value: removeStateValue })
      }
    } else {
      if (value === "all") {
        onChange({ name, value: selectAll })
      } else {
        const cloneData = deepClone(selected)
        cloneData.push(value)
        onChange({
          name,
          value: cloneData.length === removeOptionAll.length ? selectAll : cloneData,
        })
      }
    }
  }

  const handleChipRemove = (value: any) => {
    const newSelected = selected.filter(item => item !== value)
    const selectAll = options.map((item: IDropdownOptions) => item?.value)
    
    if (selected.includes("all")) {
      newSelected.splice(newSelected.indexOf("all"), 1)
    }
    
    if (value === "all" || newSelected.length !== selectAll.length - 1) {
      onChange({ name, value: newSelected })
    } else {
      onChange({ name, value: [] })
    }
  }

  return (
    <>
      <DropdownContainer>
        <DropdownButton
          ref={anchorRef}
          aria-controls={visible ? "composition-menu" : undefined}
          aria-expanded={visible ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          error={(error as string)?.length > 0}
          multiple
          view={view}
        >
          {selected.length > 0 ? (
            options
              .filter((f: IDropdownOptions) => selected.includes(f?.value) && f?.value !== "all")
              .map((item: IDropdownOptions, i: number) => {
                return (
                  <SelectedChips key={i}>
                    {item?.label}
                    <CancelOutlined
                      onClick={(e) => {
                        e.stopPropagation()
                        handleChipRemove(item?.value)
                      }}
                      style={{ cursor: "pointer", marginLeft: 4, fontSize: "1.3rem" }}
                    />
                  </SelectedChips>
                )
              })
          ) : (
            <span className="value placeholder">{placeholder ? placeholder : "Select"}</span>
          )}
          <span className="higlight_shadow"></span>
        </DropdownButton>
        <Dropdown open={visible} anchorEl={anchorRef.current} placement="bottom-start" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-start" ? "center top" : "center bottom",
              }}
            >
              <DropdownInner>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={visible} onKeyDown={handleListKeyDown}>
                    {options.length > 0 ? (
                      options.map((item: IDropdownOptions, i: number) => {
                        return (
                          <MultiDropdownItem key={i}>
                            <CheckboxGroup>
                              <FormCheckbox
                                {...{
                                  id: item?.value,
                                  onChange: multiSelectHandler,
                                  value: item?.value,
                                  checked: selected.includes(item?.value),
                                }}
                              />
                              <label className="label" htmlFor={item?.value}>
                                {item?.label}
                              </label>
                            </CheckboxGroup>
                          </MultiDropdownItem>
                        )
                      })
                    ) : (
                      <span className="no_data_found">No options found</span>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </DropdownInner>
            </Grow>
          )}
        </Dropdown>
      </DropdownContainer>
      {error && <FormError>{error}</FormError>}
    </>
  )
}
