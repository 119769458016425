import React from "react"
import { Slide, Dialog, styled } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import { widthSize } from "src/interface"

interface ISideDialog {
  open: boolean
  closeHandler: () => void
  children: JSX.Element
  size: widthSize
  blurbackdrop?: "true" | "false"
}

interface IDialog {
  size: widthSize
}

const SideDialogStyle = styled(Dialog)<IDialog>(({ theme, size }) => ({
  ".MuiDialog-paper": {
    background: theme.palette.background.paper,
    margin: "0px",
    minWidth: "300px",
    maxWidth: "none",
    maxHeight: "max-content",
    height: "100%",
    width: size === "medium" ? "450px" : size === "large" ? "35%" : "auto",
    borderRadius: "0px",
    marginLeft: "auto",
  },

  // "&.blur": {
  //   zIndex: 9999,
  // }
  
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />
})

export default function SideDialog(props: ISideDialog) {
  const { open, closeHandler, children, size, blurbackdrop } = props

  return (
    <SideDialogStyle
      size={size}
      open={open}
      TransitionComponent={Transition}
      transitionDuration={500}
      onClose={closeHandler}
      className={blurbackdrop ? "blur" : ""}
    >
      {children}
    </SideDialogStyle>
  )
}
