import { IHrmsEmployeeState } from "src/interface";

export const initialHrmsEmployeeState: IHrmsEmployeeState = {
    _id: '',
    employee_id: '',
    first_name: '',
    last_name: '',
    email: '',
    hashMail: '',
    gender: 'male',
    marital_status: '',
    workAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        addressType: '',
    },
    permanentAddress: null,
    presentAddress: null,
    dob: '',
    job_title: '',
    manager: '',
    employmentType: '',
    department: '',
    profile: '',
    organizationId: '',
    organization: {
        _id: '',
        org_name: '',
        email: '',
    },
    org_name: '',
    exp_years: null,
    resign_submitted_date: '',
    attendance_percentage: null,
    probation_period: '',
    contact_number: {
        phoneEmails: null,
        phones: [],
    },
    joining_date: '',
    employess_documents: null,
    branch_location: '',
    compensation: {
        type: '',
        planId: '',
        amount: null,
        percentage: null,
        currency: '',
        payPeriod: '',
        frequency: '',
    },
    leaveRequests: [],
    bankAccounts: [],
    employeeKYC: {
        aadharNumber: null,
        panNumber: '',
        panVerify: false,
        aadharVerify: false,
    },
    type: '',
    isNew: true,
    isLoanRaised: false,
    loans: [],
    role: '',
    status: 'Pending',
    activeapplication: '',
};