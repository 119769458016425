import { uniqueId } from "lodash"
import { useSelector } from "src/hooks"
import { PERMISSION_MODULE } from "src/interface"
import { PAGE_URL } from "src/routes"

export interface MenuitemsType {
  [x: string]: any
  id?: string
  navlabel?: boolean
  subheader?: string
  title?: string
  icon?: any
  href?: string
  children?: MenuitemsType[]
  chip?: string
  chipColor?: string
  variant?: string
  external?: boolean
  module?: string
}

const useMenuItems = () => {
  const userDetails = useSelector((state) => state.userData.userDetails);

  const Menuitems: MenuitemsType[] = [
    {
      navlabel: true,
      subheader: "Overview",
    },
    {
      id: uniqueId(),
      title: "Dashboard",
      icon: "dashboard",
      href: "/menulevel/",
      module: PERMISSION_MODULE.Admin_Dashboard,
      children: [
        {
          id: uniqueId(),
          title: "Default",
          icon: "dot",
          href: PAGE_URL.dashboard,
          module: PERMISSION_MODULE.Admin_Dashboard,
        },
        {
          id: uniqueId(),
          title: "Analytics",
          icon: "dot",
          href: PAGE_URL.dashboardAnalytics,
          module: PERMISSION_MODULE.Admin_Analytics_Dashboard,
        },
      ],
    },
    {
      id: uniqueId(),
      title: "Dashboard",
      icon: "dashboard",
      href: PAGE_URL.dashboard,
      module: PERMISSION_MODULE.Organization_Dashboard,
    },
    {
      id: uniqueId(),
      title: "Dashboard",
      icon: "dashboard",
      href: PAGE_URL.dashboard,
      module: PERMISSION_MODULE.Employee_Dashboard,
    },
    {
      navlabel: true,
      subheader: "Management",
      module: userDetails?.type === "Owner"
        ? PERMISSION_MODULE.Organization_Management
        : PERMISSION_MODULE.Employee_Management,
    },
    {
      id: uniqueId(),
      title: "User Management",
      icon: "users",
      href: "/",
      module: PERMISSION_MODULE.User_Management,
      children: [
        {
          id: uniqueId(),
          title: "Users",
          icon: "dot",
          href: PAGE_URL.userManagementUsers,
          module: PERMISSION_MODULE.User_Management || PERMISSION_MODULE.Role_Management,
        },
        {
          id: uniqueId(),
          title: "Roles",
          icon: "dot",
          href: PAGE_URL.userManagementRoles,
          module: PERMISSION_MODULE.Role_Management,
        },
        {
          id: uniqueId(),
          title: "Permission",
          icon: "dot",
          href: PAGE_URL.userManagementPermission,
          module: PERMISSION_MODULE.Role_Management,
        },
      ],
    },
    {
      id: uniqueId(),
      title: "Organization Management",
      icon: "building",
      href: PAGE_URL.organizationManagement,
      module: PERMISSION_MODULE.Organization_Management,
    },
    {
      id: uniqueId(),
      title: "Customers Management",
      icon: "clownMask",
      href: PAGE_URL.customerManagement,
      module: PERMISSION_MODULE.Customer_Management,
    },
    {
      id: uniqueId(),
      title: "Employee Management",
      icon: "clownMask",
      href: PAGE_URL.customerManagement,
      module: PERMISSION_MODULE.Employee_Management,
    },
    {
      navlabel: true,
      subheader: "Advances",
      module: PERMISSION_MODULE.Loans_Request_Management || PERMISSION_MODULE.Loans_Management || PERMISSION_MODULE.Loans_Transaction_Management,
    },
    {
      id: uniqueId(),
      title: "Advance Management",
      icon: "documents",
      href: PAGE_URL.loanManagement,
      module: PERMISSION_MODULE.Loans_Request_Management || PERMISSION_MODULE.Loans_Management || PERMISSION_MODULE.Loans_Transaction_Management,
      children: [
        {
          id: uniqueId(),
          title: "Advances",
          icon: "dot",
          href: PAGE_URL.loanManagementList,
          module: PERMISSION_MODULE.Loans_Management,
        },
        {
          id: uniqueId(),
          title: "Advance Request",
          icon: "dot",
          href: PAGE_URL.loanManagementRequest,
          module: PERMISSION_MODULE.Loans_Request_Management,
        },
        {
          id: uniqueId(),
          title: "Transaction",
          icon: "dot",
          href: PAGE_URL.loanManagementTransaction,
          module: PERMISSION_MODULE.Loans_Transaction_Management,
        },
      ],
    },
    {
      id: uniqueId(),
      title: "Approval Workflow",
      icon: "flow",
      href: PAGE_URL.approvalWorkflowList,
      module: PERMISSION_MODULE.Workflow_Management,
    },
    {
      id: uniqueId(),
      title: "Organization Workflow",
      icon: "flow",
      href: PAGE_URL.organizationWorkflowList,
      module: PERMISSION_MODULE.Organization_Workflow_Management,
    },
    {
      id: uniqueId(),
      title: "Setting & Configurations",
      icon: "devices",
      href: PAGE_URL.settingsList,
      module: PERMISSION_MODULE.Settings_Configuration_Management,
    },
    {
      navlabel: true,
      subheader: "Auditing & Reports",
      module: PERMISSION_MODULE.Reports_Analytics || PERMISSION_MODULE.Audit_Trails,
    },
    {
      id: uniqueId(),
      title: "Audit Trail",
      icon: "stamp",
      href: PAGE_URL.auditTrailList,
      module: PERMISSION_MODULE.Audit_Trails,
    },
    {
      id: uniqueId(),
      title: "Reports & Analytics",
      icon: "pieChart",
      href: PAGE_URL.reportList,
      module: PERMISSION_MODULE.Reports_Analytics,
    },
    {
      navlabel: true,
      subheader: "System Maintenance",
    },
    {
      id: uniqueId(),
      title: "Backups",
      icon: "backups",
      href: PAGE_URL.backupList,
      module: PERMISSION_MODULE.Backups,
    },
    {
      id: uniqueId(),
      title: "FAQs",
      icon: "faq",
      href: PAGE_URL.faqManagementList,
      module: PERMISSION_MODULE.FAQ,
    },
    {
      id: uniqueId(),
      title: "Notification Management",
      icon: "notification",
      href: PAGE_URL.notificationManage,
    },
    {
      id: uniqueId(),
      title: "Email Templates",
      icon: "email",
      href: PAGE_URL.email_templateList,
      module: PERMISSION_MODULE.Email_Template,
    },
  ]

  return Menuitems;
};

export default useMenuItems;
