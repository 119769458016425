import React from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Box, Divider, MenuItem, MenuList, Popper, Typography, styled } from "@mui/material";

// Hooks
import { useState, useRef, useEffect, useCallback, useLazyQuery, useNavigate } from "src/hooks";

// styles
import { borderRadius, formFieldHeight } from "src/assets/styles/custom";
import { TableDetailsCard } from "src/components";

// Route
import { PAGE_URL } from "src/routes";

// Utils
import { useDebounce } from "src/utils";
import { GLOBAL_SEARCH } from "src/api";
import { queryOptions } from "src/resources";

// Interface
import { CUS_DETAILS_TAB, IChangeEvent, ORG_DETAILS_TAB } from "src/interface";

const SearchBar = styled("div")(({ theme }) => ({
  position: "relative",
  width: "350px",
  input: {
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderRadius: borderRadius,
    height: formFieldHeight,
    padding: "10px 15px 10px 50px",
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  ".MuiSelect-icon": {
    top: "50%",
    transform: " translateY(-50%)",
    width: "16px",
    height: "16px",
    right: "15px",
  },
  ".icon": {
    position: "absolute",
    left: "15px",
    top: "50%",
    transform: "translateY(-50%)",
    display: "flex",
    svg: {
      width: "22px",
      height: "22px",
    },
  },
}));

const PopperContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  minWidth: 347,
  height: "500px",
  overflow: "hidden",
  ".simplebar-scrollbar": {
    "&:before": {
      background: theme.palette.grey["500"],
      borderRadius: "10px",
    },
  },
  ".simplebar-content": {
    display: "block",
  },
}));

const NoDataMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(4),
  textAlign: 'center',
}));

const Search = () => {
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [filteredData, setFilteredData] = useState<any>({ organizations: [], employees: [] });
  const inputRef = useRef<HTMLInputElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const [globalSearch] = useLazyQuery(GLOBAL_SEARCH, queryOptions);

  const debouncedInputValue = useDebounce(inputValue, 500);

  const handleInputChange = (e: IChangeEvent) => {
    setInputValue(e.target.value);
    if (e.target.value.length > 0) {
      setAnchorEl(inputRef.current);
    } else {
      setAnchorEl(null);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (inputValue.trim()) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (!inputValue) {
        handleClosePopper();
      }
    }, 200);
  };

  const fetchAllSearchData = useCallback(async () => {
    if (debouncedInputValue) {
      const response = await globalSearch({
        variables: {
          query: debouncedInputValue
        }
      });
      const { data } = response;

      if (data?.globalSearch) {
        setFilteredData(data?.globalSearch);
      }
    }
  }, [debouncedInputValue, globalSearch]);

  useEffect(() => {
    fetchAllSearchData()
  }, [fetchAllSearchData])

  const handleClosePopper = () => {
    setAnchorEl(null);
    setInputValue('');
  };


  const hasData = filteredData?.organizations?.length > 0 || filteredData?.employees?.length > 0 || filteredData?.loanRequests?.length > 0

  // Click outside detection
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popperRef.current && !popperRef.current.contains(event.target as Node) &&
        inputRef.current && !inputRef.current.contains(event.target as Node)) {
        handleClosePopper();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getTotalItems = () => {
    const totalOrg = filteredData?.organizations?.length;
    const totalEmp = filteredData?.employees?.length;
    const totalLoan = filteredData?.loanRequests?.length;

    return totalOrg + totalEmp + totalLoan;
  };

  const totalItems = getTotalItems();

  const containerHeight = totalItems > 5 ? "300px" : totalItems > 0 ? "auto" : "100px";

  return (
    <Box>
      <SearchBar>
        <span className="icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.875 21.625C14.0241 21.625 15.1619 21.3987 16.2235 20.9589C17.2851 20.5192 18.2497 19.8747 19.0622 19.0622C19.8747 18.2497 20.5192 17.2851 20.9589 16.2235C21.3987 15.1619 21.625 14.0241 21.625 12.875C21.625 11.7259 21.3987 10.5881 20.9589 9.52652C20.5192 8.46492 19.8747 7.50033 19.0622 6.68782C18.2497 5.8753 17.2851 5.23078 16.2235 4.79105C15.1619 4.35133 14.0241 4.125 12.875 4.125C10.5544 4.125 8.32876 5.04687 6.68782 6.68782C5.04687 8.32876 4.125 10.5544 4.125 12.875C4.125 15.1956 5.04687 17.4212 6.68782 19.0622C8.32876 20.7031 10.5544 21.625 12.875 21.625ZM10.4837 7.1C11.2419 6.78623 12.0545 6.62483 12.875 6.625C13.0408 6.625 13.1997 6.55915 13.3169 6.44194C13.4342 6.32473 13.5 6.16576 13.5 6C13.5 5.83424 13.4342 5.67527 13.3169 5.55806C13.1997 5.44085 13.0408 5.375 12.875 5.375C10.8859 5.375 8.97822 6.16518 7.5717 7.5717C6.16518 8.97822 5.375 10.8859 5.375 12.875C5.375 13.0408 5.44085 13.1997 5.55806 13.3169C5.67527 13.4342 5.83424 13.5 6 13.5C6.16576 13.5 6.32473 13.4342 6.44194 13.3169C6.55915 13.1997 6.625 13.0408 6.625 12.875C6.62489 11.6387 6.99146 10.4301 7.67832 9.40214C8.36519 8.37418 9.3415 7.57302 10.4837 7.1Z"
              fill="#95A1C2"
            />
            <path
              d="M23.5 23.5L19.125 19.125"
              stroke="black"
              strokeWidth="0.5"
              strokeLinecap="round"
            />
            <path
              d="M11.625 22.25C17.493 22.25 22.25 17.493 22.25 11.625C22.25 5.75697 17.493 1 11.625 1C5.75697 1 1 5.75697 1 11.625C1 17.493 5.75697 22.25 11.625 22.25Z"
              stroke="black"
              strokeWidth="0.5"
            />
          </svg>
        </span>

        <input
          type="text"
          placeholder="Search"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
        />
      </SearchBar>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        ref={popperRef}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 0],
            },
          },
        ]}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <PopperContainer sx={{ height: containerHeight }}>
          <SimpleBar style={{ maxHeight: "100%" }}>
            {hasData ? (
              <MenuList>
                {filteredData.organizations.length > 0 && (
                  <>
                    <Typography variant="subtitle2" sx={{ padding: "0 10px", fontSize: "1rem", color: "#0F2F51", fontWeight: 600, marginBottom: "8px" }}>
                      Organizations
                    </Typography>
                    {filteredData.organizations.map((org: any) => (
                      <MenuItem
                        key={org.orgName}
                        onClick={() => navigate(`${PAGE_URL.organizationManagementDetails}?tab=${ORG_DETAILS_TAB.company_details}&id=${org._id}`)}
                      >
                        <TableDetailsCard
                          {...{
                            img: org.profile,
                            first_name: org.name,
                            name: org.name,
                            description: org.noOfEmployees,
                          }}
                        />
                      </MenuItem>
                    ))}
                  </>
                )}

                {filteredData.employees.length > 0 && filteredData.organizations.length > 0 && (
                  <Divider sx={{ margin: "10px 0" }} />
                )}

                {filteredData.employees.length > 0 && (
                  <>
                    <Typography variant="subtitle2" sx={{ padding: "0 10px", fontSize: "1rem", color: "#0F2F51", fontWeight: 600, marginBottom: "8px" }}>
                      Employees
                    </Typography>
                    {filteredData.employees.map((emp: any) => (
                      <MenuItem key={emp.employeeId}
                        onClick={() => navigate(`${PAGE_URL.customerManagementDetails}?tab=${CUS_DETAILS_TAB.employee_profile}&id=${emp._id}`)}>
                        <TableDetailsCard
                          {...{
                            img: emp.profile,
                            first_name: emp.name,
                            name: emp.name,
                            description: emp.organization,
                          }}
                        />
                      </MenuItem>
                    ))}
                  </>
                )}

                {filteredData.loanRequests.length > 0 && filteredData.organizations.length > 0 && (
                  <Divider sx={{ margin: "10px 0" }} />
                )}

                {filteredData.loanRequests.length > 0 && (
                  <>
                    <Typography variant="subtitle2" sx={{ padding: "0 10px", fontSize: "1rem", color: "#0F2F51", fontWeight: 600, marginBottom: "8px" }}>
                      Advances
                    </Typography>
                    {filteredData.loanRequests.map((loan: any) => (
                      <MenuItem key={loan.employeeId}
                        onClick={() => navigate(`${PAGE_URL.loanManagementDetails}?id=${loan._id}`)}>
                        <TableDetailsCard
                          {...{
                            img: loan.profile,
                            first_name: loan.applicationNo,
                            name: loan.applicationNo,
                            description: loan.name,
                          }}
                        />
                      </MenuItem>
                    ))}
                  </>
                )}
              </MenuList>
            ) : (
              <NoDataMessage>No Data Found</NoDataMessage>
            )}
          </SimpleBar>
        </PopperContainer>
      </Popper>
    </Box>
  );
};

export default Search;
