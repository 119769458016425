import { Tooltip } from "@mui/material"
import { ActionButtonStyle } from "src/assets/styles/table"

interface ITableAction {
  url?: string
  disable?: any
  actionProps: {
    viewHandler?: () => void
    editHandler?: () => void
    deleteHandler?: () => void
  }
}

export default function TableAction(props: ITableAction) {
  const { actionProps, disable } = props
  const { deleteHandler, editHandler, viewHandler } = actionProps

  return (
    <>
      <ActionButtonStyle>
        {viewHandler && <ViewBtnIcon handler={viewHandler} disable={disable === "Deactivated"} />}
        {editHandler && (
          <Tooltip title="Edit">
            <span>
              <button className="edit" onClick={editHandler} disabled={disable === "Deactivated"} style={{ cursor: disable === "Deactivated" ? "not-allowed" : "pointer" }}>
                <svg viewBox="0 0 444 493" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M43.0893 1.40937C23.4948 5.67405 7.01239 21.8107 1.82562 41.7509C0.32722 47.7445 0.096697 72.5258 0.096697 246.34C0.096697 464.185 -0.364349 450.123 7.24291 464.415C14.2739 477.555 30.18 488.966 45.5098 491.732C49.7744 492.539 71.9047 493 104.178 493C153.049 493 155.93 492.885 155.008 490.925C150.628 481.013 148.784 473.29 148.784 464.53V455.079L99.4522 454.733L50.005 454.387L45.8555 451.16C37.4414 444.821 38.133 462.34 38.133 245.994V50.7413L40.784 46.9377C42.1672 44.863 44.8182 41.9814 46.5471 40.7136C49.7744 38.2931 50.8118 38.2931 123.427 37.9473L197.079 37.7168L197.425 99.7275L197.77 161.623L201.689 169.691C208.49 183.523 221.284 193.435 236.152 196.662C240.302 197.469 266.351 197.93 318.449 197.93H394.522L394.061 187.787C393.139 168.654 397.749 174.302 308.076 84.7435C233.386 10.1693 228.545 5.55879 221.975 3.02304L215.06 0.256758L132.648 0.0262349C66.3721 -0.0890267 48.8523 0.141496 43.0893 1.40937ZM286.983 159.664C251.828 159.894 244.912 159.664 241.8 158.165C234.539 154.823 234.654 155.514 234.308 108.026L233.962 64.8032L281.335 112.06L328.592 159.318L286.983 159.664Z"
                    fill="#292D32"
                  />
                  <path
                    d="M375.273 222.366C372.161 222.942 366.167 225.017 362.018 226.976C355.102 230.204 348.187 236.773 273.728 311.348C199.153 385.807 192.584 392.722 189.356 399.638C185.783 407.245 171.836 460.266 171.836 466.259C171.836 479.399 185.437 493 198.577 493C204.916 493 256.784 479.399 264.853 475.711C272.229 472.253 277.647 467.066 353.489 391.109C428.639 316.073 434.633 309.734 437.975 302.703C457.916 259.941 421.608 213.491 375.273 222.366Z"
                    fill="#292D32"
                  />
                </svg>
              </button>
            </span>
          </Tooltip>
        )}
        {deleteHandler && <DeleteBtnIcon handler={deleteHandler} />}
      </ActionButtonStyle>
    </>
  )
}

interface IBtnProps {
  handler?: () => void
  disable?: any
}
export const DeleteBtnIcon = (props: IBtnProps) => {
  const { handler } = props

  return (
    <Tooltip title="Delete">
      <span>
        <button className="delete" onClick={handler}>
          <svg viewBox="0 0 438 591" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M405.244 44.9198H283.6V32.7902C283.6 14.7112 269.07 0 251.211 0H186.767C168.904 0 154.373 14.7112 154.373 32.7921V44.9217H32.7302C14.6831 44.9217 0 59.4809 0 77.3742V98.8172C0 112.266 8.29278 123.827 20.0715 128.744C23.8033 203.085 45.4001 562.507 46.372 578.657C46.7885 585.592 52.5334 591.003 59.4809 591.003H378.496C385.443 591.003 391.19 585.592 391.605 578.657C392.576 562.509 414.171 203.085 417.905 128.744C429.684 123.827 437.977 112.266 437.977 98.8172V77.3723C437.977 59.4791 423.293 44.9198 405.244 44.9198ZM180.64 32.7921C180.64 29.1936 183.388 26.2667 186.767 26.2667H251.211C254.588 26.2667 257.335 29.1936 257.335 32.7921V44.9217H180.642L180.64 32.7921ZM26.2629 77.3723C26.2629 73.9614 29.1635 71.1865 32.7264 71.1865H405.243C408.806 71.1865 411.706 73.9595 411.706 77.3723V98.8153C411.706 102.228 408.806 105.005 405.243 105.005H32.7283C29.1654 105.005 26.2648 102.23 26.2648 98.8153L26.2629 77.3723ZM366.122 564.734H71.845C67.9895 500.48 50.3082 205.117 46.4977 131.272H391.468C387.657 205.117 369.978 500.48 366.122 564.734Z"
              fill="#292D32"
            />
            <path
              d="M218.987 178.473C211.734 178.473 205.854 184.353 205.854 191.606V522.021C205.854 529.274 211.734 535.154 218.987 535.154C226.241 535.154 232.121 529.274 232.121 522.021V191.606C232.121 184.353 226.241 178.473 218.987 178.473Z"
              fill="#292D32"
            />
            <path
              d="M130.244 191.447C129.865 184.205 123.664 178.619 116.444 179.017C109.202 179.394 103.635 185.572 104.014 192.816L121.242 522.706C121.607 529.715 127.405 535.154 134.345 535.154C134.576 535.154 134.808 535.148 135.041 535.135C142.283 534.758 147.85 528.58 147.471 521.336L130.244 191.447Z"
              fill="#292D32"
            />
            <path
              d="M321.531 179.017C314.215 178.598 308.11 184.203 307.731 191.447L290.5 521.336C290.121 528.58 295.688 534.758 302.93 535.135C303.164 535.148 303.395 535.154 303.626 535.154C310.564 535.154 316.363 529.717 316.729 522.705L333.96 192.816C334.339 185.572 328.775 179.394 321.531 179.017Z"
              fill="#292D32"
            />
          </svg>
        </button>
      </span>
    </Tooltip>
  )
}
export const ViewBtnIcon = (props: IBtnProps) => {
  const { handler, disable } = props

  return (
    <Tooltip title="View">
      <span>
        <button className="view" disabled={disable} onClick={handler} style={{ cursor: disable ? "not-allowed" : "pointer" }}>
          <ViewIcon />
        </button>
      </span>
    </Tooltip>
  )
}

export const ViewIcon = () => {
  return (
    <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61.3846 1.43261C34.6846 7.23261 12.1846 27.7326 3.08456 54.4326L0.384557 62.4326L0.0845569 243.433C-0.115443 421.233 -0.115443 424.533 1.88456 432.433C6.08456 449.533 17.7846 467.133 31.7846 477.433C38.5846 482.333 49.7846 487.733 58.7846 490.233C66.3846 492.433 66.8846 492.433 180.885 492.733C264.085 492.933 298.085 492.733 305.185 491.833C310.585 491.233 314.685 490.433 314.385 490.133C314.085 489.833 310.185 488.533 305.585 487.233C287.985 482.133 268.785 471.933 253.085 459.433L243.785 451.933H158.085C63.2846 451.933 67.5846 452.233 57.3846 445.433C50.8846 441.033 46.8846 436.233 43.0846 428.233L40.3846 422.433L40.0846 249.433C39.8846 119.533 40.0846 75.0326 40.9846 70.9326C41.5846 67.9326 43.1846 63.4326 44.3846 60.9326C47.1846 55.3326 55.0846 47.4326 60.3846 44.7326C69.3846 40.0326 70.6846 39.9326 138.985 39.9326H203.585L206.985 42.2326C209.285 43.8326 211.185 46.3326 212.885 50.4326L215.385 56.4326L215.885 100.433C216.385 148.033 216.385 147.333 222.085 152.633C227.585 157.733 228.985 157.933 270.085 157.933C310.485 157.933 318.785 158.633 325.285 162.233C332.485 166.333 333.385 168.333 333.685 183.333L334.085 196.733L345.185 197.433C351.385 197.733 359.785 198.733 363.885 199.533C376.485 201.933 375.185 204.733 374.685 175.133C374.285 151.633 374.085 148.933 371.985 143.833C366.785 130.533 356.185 117.333 328.885 90.4326C318.285 79.9326 305.985 67.6326 301.585 62.9326C297.185 58.2326 286.985 48.0326 278.985 40.1326C253.985 15.6326 241.585 6.03261 229.685 2.03261C223.585 -0.0673902 221.985 -0.0673902 145.485 0.0326098C84.7846 0.0326098 66.0846 0.43261 61.3846 1.43261Z"
        fill="#292D32"
      />
      <path
        d="M325.484 237.533C287.184 244.433 254.284 272.833 241.584 310.033C224.384 360.033 247.184 416.133 294.684 440.633C311.884 449.533 325.584 452.933 344.584 452.933C367.684 452.933 391.084 445.333 408.484 432.233L411.584 429.933L413.884 432.433C415.884 434.533 415.984 435.133 414.784 436.533C413.884 437.633 413.384 440.633 413.384 444.933C413.384 450.533 413.784 452.233 416.084 455.533C417.584 457.733 430.684 471.233 445.184 485.733L471.584 511.933H482.184H492.884L500.284 504.833C509.184 496.533 511.884 491.533 511.884 483.833C511.884 479.433 511.284 477.433 508.984 473.833C507.384 471.433 494.284 457.633 479.784 443.333C457.984 421.733 452.684 416.933 449.384 416.033C444.384 414.633 441.384 414.633 436.884 415.933C433.984 416.733 433.184 416.633 431.184 414.733L428.984 412.633L431.784 409.033C444.984 392.033 452.884 368.033 452.884 344.633C452.884 325.633 449.484 311.933 440.584 294.733C418.884 252.733 371.584 229.233 325.484 237.533ZM363.884 269.033C390.584 275.733 412.984 298.233 420.484 325.933C423.184 335.733 422.984 354.133 420.084 364.233C412.184 391.833 391.784 412.233 364.184 420.133C354.084 423.033 335.684 423.233 325.884 420.533C269.584 405.333 247.884 338.833 284.884 294.733C304.384 271.333 334.184 261.633 363.884 269.033Z"
        fill="#292D32"
      />
    </svg>
  )
}
