import { Close, NotificationsActiveOutlined } from '@mui/icons-material';
import { Box, Grid, styled, IconButton } from '@mui/material';
import { useNavigate } from 'react-router';
import { CMDialogTitle } from 'src/assets/styles/custom';
import { CUS_DETAILS_TAB } from 'src/interface';
import { PAGE_URL } from 'src/routes';
import { SideDialogSideHeader, SideDialogContent } from 'src/templates';
import { dateAgoFormatter } from 'src/utils';

const NotificationHeader = styled(Box)(() => ({
    background: '#0F2F51',
    padding: '0px 4px',
    borderRadius: '4px',
    display: 'inline-block',
    color: '#fff',
    fontSize: '10px',
}));

const NotificationTitle = styled('div')(() => ({
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "1.2rem",
    marginBottom: "0.35em"
}));

const NotificationDescription = styled('div')(({ theme }) => ({
    fontSize: '0.755rem', // Replicates variant="body2"
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
    lineHeight: "1.2rem"
}));

const NotificationFooter = styled('div')(({ theme }) => ({
    fontSize: '0.75rem', // Replicates variant="body2"
    color: theme.palette.primary.main,
}));

const CMDialogDeleteTitle = styled('p')(() => ({
    fontSize: '0.8125rem',
    fontWeight: 500,
    color: "#0F2F51",
    textDecoration: "underline",
    cursor: "pointer"
}))

interface INotificationSidebarProps {
    notifications: any;
    handleDelete: any;
    handleAllDelete: any;
    navigateRoute: any;
}

const NotificationSidebar: React.FC<INotificationSidebarProps> = ({ notifications, handleDelete, handleAllDelete, navigateRoute }) => {
    const navigate = useNavigate()

    const navigateUser = (e: any, id: any) => {
        e.stopPropagation()
        navigate(`${PAGE_URL.customerManagementDetails}?tab=${CUS_DETAILS_TAB.employee_profile}&id=${id}`)
    }

    return (
        <>
            <SideDialogSideHeader>
                <>
                    <CMDialogTitle>
                        <NotificationsActiveOutlined /> Notifications
                    </CMDialogTitle>

                    {notifications?.length !== 0 &&
                        <CMDialogDeleteTitle onClick={() => handleAllDelete()}>
                            Clear All
                        </CMDialogDeleteTitle>
                    }
                </>
            </SideDialogSideHeader>
            <SideDialogContent>
                <>
                    <Grid container spacing={2}>
                        {notifications?.map((notification: any, index: number) => (
                            <Grid item xs={12} key={index}>
                                <Box sx={{ padding: '10px', borderBottom: '1px solid #ECECEC', cursor: "pointer" }} onClick={() => navigateRoute(notification.mobileRedirect.id, notification.title, notification._id)}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <NotificationHeader>
                                                {notification?.from?.employee_id}
                                            </NotificationHeader>
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                className="close-button"
                                                sx={{ left: '20px' }}
                                                size="small"
                                                onClick={(event) => handleDelete(event, notification._id)}
                                            >
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <NotificationTitle>{notification?.title}</NotificationTitle>
                                    <NotificationDescription>{notification?.description}</NotificationDescription>
                                    <NotificationFooter>
                                        <span onClick={(e) => navigateUser(e, notification?.from?.sender)}>{notification?.from?.first_name} {notification?.from?.last_name}</span> -{' '}
                                        {dateAgoFormatter(notification?.createdAt)}
                                    </NotificationFooter>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </>
            </SideDialogContent>
        </>
    );
};

export default NotificationSidebar;
