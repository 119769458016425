import { ILoanDetails } from "src/interface";


export const LoanDetailState: ILoanDetails = {
  _id: "",
  employee: {
    _id: "",
    employee_id: "",
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    marital_status: "",
    dob: "",
    job_title: "",
    profile: "",
    organizationId: "",
    org_name: "",
    exp_years: "",
    resign_submitted_date: "",
    probation_period: "",
    contact_number: [],
    joining_date: "",
    compensation:{
    amount:0,
    annualIncome:0,
    currency:"",
    frequency:"",
    payPeriod:"",
    percentage:null,
    planId:"",
    type:""
    },
    salary: {
      _id: "",
      basic: 0,
      basic_arrears: 0,
      conveyance: 0,
      conveyance_arrears: 0,
      HRA: 0,
      HRA_arrears: 0,
      medical_allowance: 0,
      medical_allowance_arrears: 0,
      special_allowance: 0,
      employee_pf: 0,
      professional_tax: 0,
      total_salary: 0,
      annual_income: 0,
    },
    salaryId: "",
    account: {
      _id: "",
      account_holder_name: "",
      bank_name: "",
      identifier_code: "",
      account_number: "",
      branch_location: "",
    },
    accountId: "",
    loan_details: "",
    repayment_details: "",
    employess_documents: "",
    type: "",
    isNew: false,
    loans: "",
  },
  organization: {
    _id: "",
    org_name: "",
    email: "",
    contact: [],
    country: "",
    address_1st_line: "",
    address_2nd_line: "",
    city: "",
    post_code: "",
    website: "",
    founders_name: [],
    directors_name: [],
    sector: "",
    legal_structure: "",
    registration_info: "",
    no_of_employees: "",
    description: "",
    company_logo: [],
    account_holder_name: "",
    account_number: "",
    bank_name: "",
    branch_location: "",
    identifier_code: "",
    tax_payerId: "",
    tax_identification_number: "",
    license_permits: "",
    privacy_policy: "",
    terms_conditions: "",
    status: "",
    type: "",
    role: {
      _id: "",
      Role_Name: "",
      panel_type: "",
      Permissions: "",
    },
    roleId: "",
  },
  employeeId: "",
  organizationId: "",
  applicationNo: "",
  status: "",
  loanType: "",
  loanAmount: 0,
  tenure: "",
  documents: [],
  isMove: false,
  workflow: {
    _id: "",
    name: "",
    components: [],
    organizations: [],
    status: "",
  },
  workflowId: "",
  createdAt: "",
  loanFlowLogs: [],
  organizationApprovalStatus:[],
  ownerApprovalStatus:[]
};