import { Avatar, Box, BoxProps, styled } from "@mui/material"
import { useSelector } from "src/hooks"
import { ISizes } from "src/interface"
import { deepClone } from "src/utils"

type IProfilePic = BoxProps & { size: ISizes }

interface ProfileAvatarProps {
  src?: string | null;
  firstName?: any;
  lastName?: any;
  size?: boolean;
}

export const ProfilePicContainer = styled(Box)<BoxProps & { size: ISizes }>(() => ({
  display: "flex",
  marginInline: "auto",
}))


export const ProfilePicStyle = styled("img")<{ size: ISizes }>(({ size }) => ({
  ...(size === "medium"
    ? {
      width: "90px",
      height: "90px",
    }
    : size === "large"
      ? {
        width: "115px",
        height: "115px",
      }
      : {
        width: "45px",
        height: "45px",
      }),
  display: "block",
  borderRadius: "50%",
  objectFit: "cover",
  objectPosition: "center",
}))

export default function ProfilePic(props: IProfilePic) {
  const userDetails = useSelector(state => state.userData.userDetails)

  const { profile, type } = userDetails

  const cloneProps = deepClone(props)
  delete cloneProps.size

  return (
    <ProfilePicContainer {...cloneProps}>
      <ProfileAvatar src={type === "Organization" ? userDetails?.company_logo?.[0]?.file : profile} firstName={userDetails?.first_name} lastName={userDetails?.last_name} size={true}/>
    </ProfilePicContainer>
  )
}

export const ProfileAvatar = styled(({ src, firstName, lastName, ...props }: ProfileAvatarProps) => (
  <Avatar src={src || undefined} {...props}>
    {!src && `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`}
  </Avatar>
))(({ theme, size }) => ({
  width: size ? "35px" : theme.spacing(8),
  height: size ? "35px" : theme.spacing(8),
  marginRight: theme.spacing(2),
  backgroundColor: '#edf2fa',
  color: '#000',
  textTransform: "uppercase",
  fontSize: size ? "1rem" : "1.5rem",
  fontWeight: 600,
}));

