import { useRef } from "react"
import toast from "react-hot-toast"
import { setDisabledLoader } from "src/redux/reducer/CommonDataSlice"
import { dispatch } from "src/redux/store/Store"

export default function useToastController() {
  const ref = useRef<any>(null)

  const triggerBefore = (message?: any) => {
    dispatch(setDisabledLoader(true))
    ref.current = toast.loading(message ? message : "Processing...")
  }

  const triggerAfter = (message?: any) => {
    dispatch(setDisabledLoader(false))
    toast.dismiss(ref.current)
    if (message) {
      toast.success(message)
    }
  }

  const triggerError = (message?: any) => {
    dispatch(setDisabledLoader(false))
    toast.dismiss(ref.current)
    if (message) {
      toast.error(message)
    }
  }

  return { triggerBefore, triggerAfter, triggerError }
}
