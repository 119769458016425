import { useCallback } from "react"
import toast from "react-hot-toast"
import { dispatch, setDisabledLoader, setIsAuthentication } from "src/redux"

export default function GraphQLErrorHandler({ children }: { children: any }) {
  const getGraphQLError = useCallback((message: any) => {
    toast.error(message)
    dispatch(setDisabledLoader(false))
  }, [])

  const logout = useCallback(() => {
    localStorage.removeItem("auth_token")
    localStorage.removeItem("refresh_token");
    dispatch(setIsAuthentication(false))
  }, [])

  return children(
    (message: any) => getGraphQLError(message),
    () => logout()
  )
}
