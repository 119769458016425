import {
  Box,
  Badge,
  Menu,
  Typography,
  Chip,
  Stack,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  styled,
} from "@mui/material";
import { Close } from "@mui/icons-material";

// Component
import { Scrollbar } from "src/components";
import { SideDialog } from "src/templates";
import NotificationSidebar from "./NotificationSidebar";

// Styles
import { CMButton, NavMenuIcon } from "src/assets/styles/custom";

// API Calling
import { DELETE_ALL_NOTIFICATION, DELETE_NOTIFICATION, GET_ALL_NOTIFICATION_BY_USER, GET_NOTIFICATIONS_COUNT, GET_NOTIFICATION_COUNT_BY_USER } from "src/api";
import { mutationOptions, queryOptions } from "src/resources";

// Hooks
import { useCallback, useEffect, useState, useSelector, useLazyQuery, useSubscription, useMutation, useNavigate } from "src/hooks";
import { dateAgoFormatter } from "src/utils";
import { PAGE_URL } from "src/routes";
import { setTotalNotifications } from "src/redux/reducer/CommonDataSlice";
import { dispatch } from "src/redux";

const NoDataMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(3),
  textAlign: 'center',
  "h1": {
    fontSize: '1rem'
  }
}));

interface IState {
  notifications: any[]
  apiVariables: {
    index: number
    limit: number
  }
  totalNotifications: number
  newNotifications: number
  notificationLoading: boolean
}

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [visible, setVisible] = useState(false)
  const [state, setState] = useState<IState>({
    notifications: [],
    apiVariables: {
      index: 1,
      limit: 5,
    },
    totalNotifications: 0,
    newNotifications: 0,
    notificationLoading: false,
  })
  const { notifications, newNotifications, totalNotifications } = state
  const token = localStorage.getItem("auth_token")
  const navigate = useNavigate()

  const userDetails = useSelector(state => state.userData.userDetails)
  const totalNotificationCount = useSelector((state) => state.commonData.totalNotifications);
  const { type } = userDetails
  const [getAllNotificationsByUser] = useLazyQuery(GET_ALL_NOTIFICATION_BY_USER, queryOptions)
  const [getNotificationCountByUser] = useLazyQuery(GET_NOTIFICATION_COUNT_BY_USER, queryOptions)

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, mutationOptions)
  const [deleteAllNotification] = useMutation(DELETE_ALL_NOTIFICATION, mutationOptions)

  useSubscription(GET_NOTIFICATIONS_COUNT, {
    variables: {
      userId:
        type === "Owner" || type === "owner_Users"
          ? userDetails.adminId
          : type === "Organization"
            ? userDetails.orgId
            : userDetails._id,
    },
    onData: ({ data }) => {
      const notificationData = data?.data;

      if (notificationData && notificationData.notificationCount !== newNotifications) {
        setState((prevState: IState) => ({
          ...prevState,
          newNotifications: notificationData.notificationCount,
        }));

        // Only fetch data if user is logged in
        if (token) {
          fetchAllNotificationList();
          fetchNotificationCount();
        }
      }
    },
  });


  const fetchNotificationCount = useCallback(async () => {
    const response = await getNotificationCountByUser({ variables: {} })
    const { data } = response;

    if (data) {
      setState((prevState: any) => ({
        ...prevState,
        totalNotifications: data.getNotificationCountByUser,
      }));
      dispatch(setTotalNotifications(data.getNotificationCountByUser));

    }
  }, [getNotificationCountByUser]);

  const fetchAllNotificationList = useCallback(async () => {
    const response = await getAllNotificationsByUser({
      fetchPolicy: "no-cache",
    })
    const { data } = response;

    if (data) {
      setState((prevState: any) => ({
        ...prevState,
        notifications: data.getAllNotificationsByUser,
        notificationLoading: false,
      }));
    }
  }, [getAllNotificationsByUser]);


  const handleDelete = async (event: React.MouseEvent, _id: string) => {
    event.stopPropagation()

    try {

      const response = await deleteNotification({
        variables: {
          deleteNotificationId: _id,
        },
      });
      const { data } = response

      if (data) {
        fetchAllNotificationList();
        fetchNotificationCount()
      }
    } catch (error) {
    }
  };

  const handleAllDelete = async () => {
    try {
      if (notifications.length === 0) {
        console.warn("No notifications to delete.");

        return;
      }

      const notificationIds = notifications.map((data) => data._id);

      if (!notificationIds.every((id) => id)) {
        console.error("Invalid notification IDs detected.");

        return;
      }

      const response = await deleteAllNotification({
        variables: {
          deleteAllNotificationId: notificationIds,
        },
      });

      const { data, errors } = response;

      if (errors) {
        console.error("Error deleting notifications:", errors);

        return;
      }

      if (data) {
        await fetchAllNotificationList();
        await fetchNotificationCount();
      } else {
        console.warn("No data returned from deleteAllNotification.");
      }
    } catch (error) {
      console.error("Failed to delete notifications:", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchAllNotificationList();
      fetchNotificationCount();
    }
  }, [token, fetchAllNotificationList, fetchNotificationCount]);


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationOpen = () => {
    setVisible(true)
    handleClose()
  }

  const navigateRoute = async (id: any, title: string, _id: string) => {

    if (title === "Account Deactivate Request") {
      navigate(`${PAGE_URL.organizationManagement}?tab=Deactivated`);
    } else {
      navigate(`${PAGE_URL.loanManagementDetails}?id=${id}`);
    }
    try {

      const response = await deleteNotification({
        variables: {
          deleteNotificationId: _id,
        },
      });
      const { data } = response

      if (data) {
        fetchAllNotificationList();
        fetchNotificationCount()
      }
    } catch (error) {
    }
  };

  const containerHeight = notifications?.length > 5 ? "300px" : notifications?.length > 0 ? "auto" : "100px";

  return (
    <>
      <NavMenuIcon onClick={handleClick}>
        {+totalNotificationCount === 0 ?
          <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.4"
              d="M18.8751 8.50004V9.32137C18.8751 10.3072 19.1551 11.2709 19.6824 12.091L20.9751 14.1012C22.1546 15.9375 21.2539 18.433 19.2017 19.0129C13.8395 20.5318 8.16069 20.5318 2.79841 19.0129C0.746239 18.433 -0.154427 15.9375 1.02507 14.1012L2.31774 12.091C2.84691 11.2643 3.12753 10.303 3.12624 9.32137V8.50004C3.12624 3.98971 6.65191 0.333374 11.0001 0.333374C15.3482 0.333374 18.8751 3.98971 18.8751 8.50004Z"
              fill="#B7B7B7"
            />
            <path
              d="M5.4502 19.6359C5.8314 20.8073 6.57356 21.8281 7.57037 22.552C8.56718 23.2759 9.76752 23.6658 10.9994 23.6658C12.2314 23.6658 13.4317 23.2759 14.4285 22.552C15.4253 21.8281 16.1675 20.8073 16.5487 19.6359C12.8795 20.3242 9.12053 20.3242 5.4502 19.6359Z"
              fill="black"
            />
          </svg>
          :
          <Badge color="primary" badgeContent={totalNotificationCount}>
            {/* Notification Icon */}
            <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.4"
                d="M18.8751 8.50004V9.32137C18.8751 10.3072 19.1551 11.2709 19.6824 12.091L20.9751 14.1012C22.1546 15.9375 21.2539 18.433 19.2017 19.0129C13.8395 20.5318 8.16069 20.5318 2.79841 19.0129C0.746239 18.433 -0.154427 15.9375 1.02507 14.1012L2.31774 12.091C2.84691 11.2643 3.12753 10.303 3.12624 9.32137V8.50004C3.12624 3.98971 6.65191 0.333374 11.0001 0.333374C15.3482 0.333374 18.8751 3.98971 18.8751 8.50004Z"
                fill="#B7B7B7"
              />
              <path
                d="M5.4502 19.6359C5.8314 20.8073 6.57356 21.8281 7.57037 22.552C8.56718 23.2759 9.76752 23.6658 10.9994 23.6658C12.2314 23.6658 13.4317 23.2759 14.4285 22.552C15.4253 21.8281 16.1675 20.8073 16.5487 19.6359C12.8795 20.3242 9.12053 20.3242 5.4502 19.6359Z"
                fill="black"
              />
            </svg>
          </Badge>
        }
      </NavMenuIcon>
      {/* Notification Dropdown Menu */}
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        sx={{
          zIndex: 9999,
          "&.MuiModal-hidden": {
            zIndex: 1300
          },
          "& .MuiMenu-paper": {
            width: "360px",
            borderRadius: "8px",
          },
        }}
      >
        <Stack direction="row" py={2} px={4} justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Notifications</Typography>
          {notifications?.length > 0 &&
            <Chip label={`${totalNotifications} new`} color="primary" size="small" />
          }
        </Stack>
        <Scrollbar sx={{ height: containerHeight, overflowY: "auto" }}>
          {notifications?.length > 0 ?
            <List>
              {notifications?.map((notification) => (
                <ListItem
                  key={notification._id}
                  sx={{
                    '&:hover .close-button': { display: 'block' },
                    position: 'relative',
                    borderBottom: '1px solid #f0f0f0',
                    py: 1.5,
                    cursor: "pointer"
                  }}
                  onClick={() => navigateRoute(notification.mobileRedirect.id, notification.title, notification._id)}
                >
                  <ListItemAvatar>
                    <Avatar src={notification.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <Typography variant="subtitle2" component="span" sx={{ fontSize: "13px", fontWeight: 600 }}>
                          {notification.title}
                        </Typography>
                      </>
                    }
                    secondary={
                      <>
                        <Typography variant="body2" color="textSecondary" sx={{ fontSize: "11px", fontWeight: 400 }}>
                          {notification.description}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" sx={{ fontSize: "10px", fontWeight: 400 }}>
                          {dateAgoFormatter(notification.createdAt)}
                        </Typography>
                      </>
                    }
                    sx={{ maxWidth: "240px" }}
                  />
                  {/* Close Button */}
                  <Box display='flex' flexDirection='column' alignItems='center' mb={5.2} ml={2}>
                    {notification.isNew && <Badge variant="dot" color="primary" />}

                    <IconButton
                      className="close-button"
                      sx={{ display: 'none', position: 'absolute', marginTop: "6px" }}
                      size="small"
                      onClick={(event) => handleDelete(event, notification._id)}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>
            :
            <NoDataMessage>
              <h1>No notification to show</h1>
            </NoDataMessage>
          }
        </Scrollbar>
        {notifications?.length > 0 &&
          <Box p={2}>
            <CMButton variant="contained" color="primary" fullWidth onClick={() => handleNotificationOpen()}>
              View all notifications
            </CMButton>
          </Box>
        }
      </Menu>

      <SideDialog
        size="medium"
        open={visible}
        closeHandler={() => setVisible(false)}
        blurbackdrop="true"
      >
        <NotificationSidebar notifications={notifications} handleDelete={handleDelete} handleAllDelete={handleAllDelete} navigateRoute={navigateRoute} />
      </SideDialog>
    </>
  );
};

export default Notifications;
