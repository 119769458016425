import { FormError, FormHint, FormInputStyle } from "src/assets/styles/form"
import { IFormFieldProps } from "src/interface"

export default function FormTextInput(props: IFormFieldProps) {
  const { name, value, onChange, disabled, error, placeholder, view, prefixEl, suffixEl, formHint,maxLength,onBlur } = props
  const formProps = { name, value, onChange, disabled: disabled || view, placeholder,maxLength,onBlur }

  return (
    <>
      <FormInputStyle prefixEl={prefixEl} suffixEl={suffixEl} view={view} error={(error as string)?.length > 0}>
        {prefixEl && <span className="prefix">{prefixEl}</span>}
        <input type="text" {...formProps} autoComplete="off" />
        <span className="higlight_shadow"></span>
        {suffixEl && <span className="suffix">{suffixEl}</span>}
      </FormInputStyle>
      {formHint && <FormHint>{formHint}</FormHint>}
      {error && <FormError>{error}</FormError>}
    </>
  )
}