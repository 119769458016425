import { useSelector } from "src/hooks"
import { Fragment, lazy } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { IRouting } from "src/interface"
import { ThemeSettings } from "src/theme"
import { Loadable, RTL } from "src/layouts"
import { ResponseAlert } from "src/templates"

//styles
import { CssBaseline, ThemeProvider } from "@mui/material"
import { DisableLoader } from "./assets/styles/custom"
import GlobalStyle from "./assets/styles/globalStyle"

//Routing's
import { PAGE_URL, Router } from "src/routes"
import { PrivateRouting, PublicRouting, AccessRestrictRouting } from "src/auth"

//Components
import { ScrollToTop } from "src/components"
import NotFound from "./views/pages/notFound"

const PasswordResetRoute = Loadable(lazy(() => import("./auth/PasswordResetRoute")))

const App = () => {
  const theme = ThemeSettings()
  const customizer = useSelector(state => state.customizer)
  const userDetails = useSelector(state => state.userData.userDetails)
  const is_authenticated = useSelector(state => state.userData.is_authenticated)
  const disabledLoader = useSelector(state => state.commonData.disabledLoader)

  const routeAccess = userDetails.adminId !== ""

  const routingRecurstion = (parent: IRouting, i: number) => {
    return (
      <Fragment key={i}>
        {/* Redirect to Homepage based upon login*/}
        <Route path="/" element={<Navigate to={is_authenticated ? PAGE_URL.dashboard : PAGE_URL.login} />} />
        {/* Reset password redirecting page */}
        <Route path={PAGE_URL.redirectUser} element={<PasswordResetRoute />} />

        <Route path="*" element={<NotFound />} />

        <Route element={parent.isProtected ? <PrivateRouting /> : <PublicRouting />}>
          {/* Page routing */}
          <Route path={parent.path} element={parent.element}>
            {parent.children.map((child: IRouting, j: number) => {
              if (child.children.length > 0) {
                return routingRecurstion(child, j)
              } else {
                if (child.module) {
                  return (
                    <Route
                      key={j}
                      element={<AccessRestrictRouting routeAccess={routeAccess} module={child.module} />}
                    >
                      <Route path={child.path} element={child.element} />
                    </Route>
                  )
                } else {
                  return <Route key={j} path={child.path} element={child.element} />
                }
              }
            })}
          </Route>
        </Route>
      </Fragment>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ScrollToTop>
          <Routes>
            {Router.map((item: IRouting, i: number) => {
              return routingRecurstion(item, i)
            })}
          </Routes>
        </ScrollToTop>
        <ResponseAlert />
        {disabledLoader && <DisableLoader />}
      </RTL>
    </ThemeProvider>
  )
}

export default App
