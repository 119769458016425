import { styled, Container, useTheme, Box, IconButton } from "@mui/material"
import { Outlet, useLocation } from "react-router-dom"
import { useCallback, useEffect, useLazyQuery, useSelector, useState } from "src/hooks"
import Header from "./header/Header"
import Sidebar from "./sidebar/Sidebar"
import { GET_BANNER } from "src/api"
import { queryOptions } from "src/resources"
import { Close, InfoOutlined } from "@mui/icons-material"
import { PAGE_URL } from "src/routes"

const MainWrapper = styled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  width: "100%",
}))

const PageWrapper = styled("div")(() => ({
  display: "flex",
  paddingBottom: "30px",
  flexDirection: "column",
  zIndex: 1,
  backgroundColor: "transparent",
  flex: 1,
  minWidth: 0,
}))

const FullLayout = () => {
  const [state, setState] = useState({
    getBannerData: {
      status: false,
      data: {
        content: "",
        label: ""
      }
    },
    loader: true,
    showBanner: false
  })

  const { getBannerData, showBanner } = state
  const location = useLocation();

  const customizer = useSelector(state => state.customizer)

  const theme = useTheme()

  const [getBanner] = useLazyQuery(GET_BANNER, queryOptions);

  const fetchBanner = useCallback(async () => {
    if (location.pathname === PAGE_URL.customerManagement) {

      const response = await getBanner({ variables: {} })
      const { data } = response;

      if (data?.getBanner) {
        setState((prevState: any) => ({
          ...prevState,
          getBannerData: data?.getBanner,
          loader: false,
          showBanner: true
        }));
      }
    }
  }, [getBanner, location]);

  useEffect(() => {
    fetchBanner();
  }, [fetchBanner]);

  const checkBanner = location.pathname === PAGE_URL.customerManagement && showBanner && getBannerData?.status

  return (
    <>
      {checkBanner &&
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="#f0f4ff" // Light blue background
          padding="5px"
          borderRadius="8px"
          boxShadow={1}
        >
          <div style={{ textAlign: "center", display: "flex", alignItems: "end", color: "#0F2F51" }}>
            <InfoOutlined /> <p style={{ margin: 0, marginLeft: "8px" }}>{getBannerData?.data?.content}</p>
          </div>

          <IconButton
            edge="end"
            color="primary"
            aria-label="close"
            onClick={() =>
              setState((prevState: any) => ({
                ...prevState,
                showBanner: false
              }))}
          >
            <Close />
          </IconButton>
        </Box >
      }
      <MainWrapper className={customizer.activeMode === "dark" ? "darkbg mainwrapper" : "mainwrapper"}>
        <Sidebar getBannerStatus={checkBanner} />
        <PageWrapper
          className="page-wrapper"
          sx={{
            ...(customizer.isCollapse && {
              [theme.breakpoints.up("lg")]: { ml: `${customizer.MiniSidebarWidth}px` },
            }),
          }}
        >
          <Header />
          <Container
            sx={{
              maxWidth: "100%!important",
              paddingInline: "30px !important",
            }}
          >
            <Outlet />
          </Container>
        </PageWrapper>
      </MainWrapper>
    </>
  )
}

export default FullLayout
