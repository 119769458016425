import { createSlice } from "@reduxjs/toolkit";
import { IFilterValues, IUserManagementPayload, IRoles } from "src/interface";
import {
  IOrganizationPayload,
  IRolesManagementPayload,
} from "src/interface/redux";
import {
  organizationReduxState,
  rolesManagementState,
  userManagementState,
  approvalWorkflowReduxState,
  settingsReduxState
} from "src/utils";
import { loanReduxState } from "src/utils/stateValues/reduxStates";

interface StateType {
  userManagement: IUserManagementPayload;
  allRoles: IRoles[];
  disabledLoader: boolean;
  filter: IFilterValues;
  pageTotalCount: number;
  roleManagement: IRolesManagementPayload;
  organizationManagement: IOrganizationPayload;
  loanManagement: any;
  paymentHistory: any;
  approvalWorkflow: any;
  settingConfig: any;
  emailTemplate: any;
  totalNotifications: number;
  notifications: any[];
  currentRoute: any;
}

const initialState = {
  userManagement: userManagementState,
  allRoles: [],
  disabledLoader: false,
  filter: undefined,
  pageTotalCount: 0,
  roleManagement: rolesManagementState,
  organizationManagement: organizationReduxState,
  loanManagement: loanReduxState,
  paymentHistory: null,
  approvalWorkflow: approvalWorkflowReduxState,
  settingConfig: settingsReduxState,
  emailTemplate: [],
  totalNotifications: 0,
  notifications: [],
  currentRoute: ''
} as StateType;

export const CommonDataSlice = createSlice({
  name: "common_data",
  initialState,
  reducers: {
    setUserManagement: (state: StateType, action) => {
      state.userManagement = action.payload;
    },
    setAllRoles: (state: StateType, action) => {
      state.allRoles = action.payload;
    },
    setDisabledLoader: (state: StateType, action) => {
      state.disabledLoader = action.payload;
    },
    setFilterValue: (state: StateType, action) => {
      state.filter = action.payload;
    },
    setPageTotalCount: (state: StateType, action) => {
      state.pageTotalCount = action.payload;
    },
    setRoleManagement: (state: StateType, action) => {
      state.roleManagement = action.payload;
    },
    setOrganizationManagement: (state: StateType, action) => {
      state.organizationManagement = action.payload;
    },
    setLoanManagement: (state: StateType, action) => {
      state.loanManagement = action.payload;
    },
    setPaymentHistory: (state: StateType, action) => {
      state.paymentHistory = action.payload;
    },
    setApprovalWorkflow: (state: StateType, action) => {
      state.approvalWorkflow = action.payload;
    },
    setSettingConfig: (state: StateType, action) => {
      state.settingConfig = action.payload;
    },
    setEmailTemplate: (state: StateType, action) => {
      state.emailTemplate = action.payload;
    },
    setTotalNotifications(state, action) {
      state.totalNotifications = action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setRoute: (state, action) => {
      state.currentRoute = action.payload;
    },
  },
});

export const {
  setUserManagement,
  setAllRoles,
  setDisabledLoader,
  setFilterValue,
  setPageTotalCount,
  setRoleManagement,
  setOrganizationManagement,
  setLoanManagement,
  setPaymentHistory,
  setApprovalWorkflow,
  setSettingConfig,
  setEmailTemplate,
  setTotalNotifications,
  setNotifications,
  setRoute
} = CommonDataSlice.actions;

export default CommonDataSlice.reducer;
