import { ClickAwayListener, Grow, MenuList } from "@mui/material"
import { Column, Table } from "@tanstack/react-table"
import { useEffect, useRef, useState } from "src/hooks"
import { IconButton } from "src/assets/styles/custom"
import { DropdownInner } from "src/assets/styles/form"
import { ColumnFilterDropdown, FilterItem } from "src/assets/styles/table"

export default function ColumnFilter({
  columns,
  tableInstance,
  columnWithVisibility,
}: {
  columns: Column<any, unknown>[]
  tableInstance: Table<any>
  columnWithVisibility?: string[]
}) {
  const [visible, setVisible] = useState<boolean>(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setVisible(!visible)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setVisible(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      setVisible(false)
    } else if (event.key === "Escape") {
      setVisible(false)
    }
  }

  const prevOpen = useRef(visible)
  useEffect(() => {
    if (prevOpen.current === true && visible === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = visible
  }, [visible])

  return (
    <>
      <IconButton
        title="Column Filter"
        ref={anchorRef}
        aria-controls={visible ? "composition-menu" : undefined}
        aria-expanded={visible ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <svg width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.7375 0.564072C5.43281 1.95938 1.75156 5.7297 0.475 10.0641C0.059375 11.5188 0 17.3375 0 66.5297C0 119.522 0.0296875 121.422 0.564062 123.173C1.92969 127.538 5.67031 131.248 10.0641 132.525C11.4594 132.941 14.0125 133 28.3219 133C44.4125 133 45.0062 132.97 45.9562 132.406C47.3812 131.516 48.0937 130.18 48.0937 128.25C48.0937 126.32 47.3812 124.984 45.9562 124.094C45.0062 123.53 44.4125 123.5 29.8359 123.5C21.5234 123.5 14.1609 123.352 13.5375 123.203C12.0828 122.877 11.0141 122.105 10.2422 120.798C9.70781 119.878 9.64844 119.166 9.55937 112.13L9.47031 104.5H35.6844C61.1266 104.5 61.9281 104.47 62.7594 103.906C63.2641 103.609 63.9469 102.838 64.3328 102.214L65.0156 101.086V87.0734V73.0609L79.5328 72.9719C93.7531 72.8828 94.0797 72.8828 94.9109 72.2594C96.3656 71.1906 97.0781 69.825 97.0781 68.1922C97.0781 66.3219 96.1875 64.6891 94.7031 63.8578C93.6047 63.2344 93.3078 63.2344 79.2953 63.2344H65.0156V52.25V41.2656H87.875H110.734V43.1656C110.734 44.6203 110.883 45.2438 111.417 46.0453C112.456 47.5891 113.852 48.3906 115.544 48.3906C117.325 48.3906 118.453 47.8266 119.552 46.4016L120.383 45.3328L120.472 29.2125C120.531 15.4672 120.472 12.7953 120.086 11.1031C118.928 6.02657 115.306 2.07813 110.467 0.59376C108.627 1.00136e-05 107.469 1.00136e-05 59.9984 0.0296975C13.7453 0.0296975 11.3406 0.059385 9.7375 0.564072ZM108.27 10.1531C108.775 10.45 109.517 11.1031 109.903 11.6375C110.586 12.6172 110.586 12.7063 110.675 22.0281L110.764 31.4688H60.1172H9.5V22.7703C9.5 17.7531 9.61875 13.6266 9.79687 13.0031C10.1828 11.6672 11.5781 10.1828 12.8547 9.82657C13.4187 9.64845 32.5375 9.55938 60.5625 9.58907C104.322 9.64845 107.38 9.67813 108.27 10.1531ZM55.2187 52.25V63.2344H32.3594H9.5V52.25V41.2656H32.3594H55.2187V52.25ZM55.2187 84.0156V95H32.3594H9.5V84.0156V73.0313H32.3594H55.2187V84.0156Z"
            fill="black"
          />
          <path
            d="M125.608 50.3203C121.244 51.3 123.055 49.6672 88.9734 83.6891C71.6953 100.938 57.4453 115.247 57.2969 115.514C56.8219 116.345 49.875 146.27 49.875 147.398C49.875 149.981 51.9234 152 54.5359 152C56.6437 152 85.9156 145.053 86.925 144.311C87.3703 143.984 101.65 129.794 118.631 112.753C149.328 82.0266 149.566 81.7594 150.694 79.4141L151.852 77.0391V73.15V69.2906L150.694 66.9156C149.655 64.8375 148.942 63.9766 144.014 58.9891C140.986 55.9016 137.809 52.9625 136.978 52.3984C133.861 50.3203 129.289 49.4891 125.608 50.3203ZM136.503 65.4016C144.637 73.5063 144.667 72.7344 135.434 82.0266L128.695 88.7656L120.917 80.9875L113.109 73.1797L119.136 67.1531C125.637 60.5922 126.38 59.9688 127.775 59.6719C130.031 59.1969 130.714 59.6422 136.503 65.4016ZM114.148 87.875L121.867 95.5938L101.68 115.781L81.4922 135.969L71.4281 138.344C65.8766 139.65 61.3344 140.689 61.3047 140.659C61.275 140.6 62.3437 135.998 63.65 130.388L66.0547 120.234L86.0937 100.195C97.1078 89.1813 106.192 80.1563 106.281 80.1563C106.37 80.1563 109.903 83.6297 114.148 87.875Z"
            fill="black"
          />
        </svg>
        {!tableInstance.getIsAllColumnsVisible() && <span className="indication"></span>}
      </IconButton>
      <ColumnFilterDropdown
        open={visible}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "center top" : "center bottom",
            }}
          >
            <DropdownInner>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={visible} onKeyDown={handleListKeyDown}>
                  {columnWithVisibility?.includes("all") && (
                    <FilterItem>
                      <span className="label">All</span>
                      <input
                        type="checkbox"
                        checked={tableInstance.getIsAllColumnsVisible()}
                        onChange={tableInstance.getToggleAllColumnsVisibilityHandler()}
                      />
                      <span className="checked"></span>
                    </FilterItem>
                  )}

                  {columns
                    .filter(f => columnWithVisibility?.includes(f.id))
                    .map(column => {
                      return (
                        <FilterItem key={column.id}>
                          <span className="label">{(column.columnDef as any).header}</span>
                          <input
                            {...{
                              type: "checkbox",
                              checked: column.getIsVisible(),
                              onChange: column.getToggleVisibilityHandler(),
                            }}
                          />
                          <span className="checked"></span>
                        </FilterItem>
                      )
                    })}
                </MenuList>
              </ClickAwayListener>
            </DropdownInner>
          </Grow>
        )}
      </ColumnFilterDropdown>
    </>
  )
}
