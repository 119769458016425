import { Box, Pagination, Popper, PopperProps, styled } from "@mui/material";
import { CenterFlex } from "./custom";

export const SortIcon = styled("span")(() => ({
  position: "absolute",
  display: "inline-block",
  marginLeft: "20px",
  cursor: "pointer",
  right: "5px",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: "1",
  svg: {
    width: "8px",
    height: "8px",
    display: "block",
  },
}));

export const ActionButtonStyle = styled(Box)(({ theme }) => ({
  ...CenterFlex(),
  button: {
    ...CenterFlex(),
    border: "none",
    background: "unset",
    cursor: "pointer",
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    padding: "0px",
    transitionDuration: "0.3s",
    marginRight: "8px",
    svg: {
      width: "18px",
      height: "18px",
      path: {
        transitionDuration: "0.3s",
        fill: theme.palette.grey["600"],
      },
    },
    "&:hover": {
      transform: "translateY(-3px)",
      background: theme.palette.secondary.main,
      svg: {
        path: {
          fill: theme.palette.secondary.contrastText,
        },
      },
    },
    "&.delete": {
      "&:hover": {
        background: theme.palette.error.light,
        svg: {
          path: {
            fill: theme.palette.error.main,
          },
        },
      },
    },
  },
}));

export const CustomTable = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  paddingInline: "15px",
  textAlign: "center",
  table: {
    width: "100%",
    borderCollapse: "collapse",
    overflowX: "auto",
    a: {
      fontWeight: "500",
      color: "inherit",
    },
    thead: {
      tr: {
        th: {
          borderBottom: `1px solid ${theme.palette.grey["300"]}`,
          padding: "10px 20px",
          fontWeight: "600",
          position: "relative",
          whiteSpace: "nowrap"
        },
      },
    },
    tbody: {
      tr: {
        transitionDuration: "0.3s",
        "&:hover": {
          background: theme.palette.grey["100"],
        },
        td: {
          padding: "0px 20px",
          borderBottom: `1px solid ${theme.palette.grey["300"]}`,
          height: "55px",
          whiteSpace: "nowrap"
        },
      },
    },
  },
}));

export const EmptyColumn = styled("span")(() => ({
  height: "400px",
  ...CenterFlex(),
}));

export const TableFooterSection = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "25px 20px 15px",
  bottom: 0,
  zIndex: 1,
  background: "#fff",
}));

export const RowsPerPageStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  label: {
    marginRight: "10px",
    color: theme.palette.grey["600"],
  },
}));

export const FilterOption = styled("span")(({ theme }) => ({
  display: "flex",
  width: "100%",
  transitionDuration: "0.3s",
  "&:hover": {
    background: theme.palette.secondary.main,
  },
}));

export const ColumnFilterDropdown = styled((props: PopperProps) => (
  <Popper
    nonce={undefined}
    onResize={undefined}
    onResizeCapture={undefined}
    {...props}
  />
))(() => ({
  zIndex: 5,
}));

export const FilterItem = styled("label")(({ theme }) => ({
  padding: "10px 35px 10px 15px",
  display: "flex",
  position: "relative",
  cursor: "pointer",
  transitionDuration: "0.3s",
  textTransform: "capitalize",
  ".label": {
    position: "relative",
    zIndex: "1",
    width: "100%",
  },
  input: {
    visibility: "hidden",
    position: "absolute",
    left: "0",
    top: "0",
  },
  ".checked": {
    display: "inline-block",
    position: "absolute",
    right: "15px",
    transform: "translateY(-50%)",
    top: "50%",
    width: "11px",
    height: "11px",
  },
  "&:hover": {
    background: theme.palette.secondary.main,
  },
  "input:checked  ~ .checked": {
    background: `url('data:image/svg+xml,<svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.7234 0.28125C21.3574 -0.09375 20.7573 -0.09375 20.3913 0.28125L9.31098 11.5903L3.60464 5.72493C3.23866 5.34856 2.6426 5.34856 2.27393 5.72493L0.274486 7.76477C-0.0914952 8.13702 -0.0914952 8.74966 0.274486 9.12466L8.63957 17.7208C9.00555 18.0931 9.60161 18.0931 9.97163 17.7208L23.7215 3.68235C24.0928 3.30735 24.0928 2.69334 23.7215 2.31696L21.7234 0.28125Z" fill="%235d87ff"/></svg>')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "11px 11px",
  },
}));

export const TablePaginationStyle = styled(Pagination)(() => ({
  button: {
    fontWeight: "500",
  },
}));

export const TableUsersStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ".pic": {
    marginRight: "15px",
    img: {
      width: "35px",
      height: "35px",
      borderRadius: "50%",
      display: "block",
    },
  },
  ".details": {
    textAlign: "left",
    h1: {
      marginBlock: '3px',
      fontWeight: 600,
      fontSize: "0.9rem"
    },
    h5: {
      marginTop: "0px",
      marginBottom: "0px",
      lineHeight: "normal",
      fontSize: "0.75rem",
      fontWeight: "500",
    },
    p: {
      margin: "0px",
      fontSize: "0.75rem",
      color: theme.palette.grey["600"],
    },
    ".request": {
      fontSize: "0.75rem",
      fontWeight: 400
    },
    '&.branch-request': {
      ".request": {
        color: "rgb(244 180 0)"
      }
    },
    '&.org-request': {
      ".request": {
        color: "rgba(247, 223, 255)"
      }
    },
    '&.kreon-request': {
      ".request": {
        color: "rgba(93, 135, 255)"
      }
    }
  },
}));

export const ProfileLogoStyle = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "12px",
  marginBottom: "12px",
  ".pic": {
    marginRight: "15px",
    img: {
      width: "35px",
      height: "35px",
      borderRadius: "50%",
      display: "block",
    },
  },
  ".details": {
    textAlign: "left",
    h5: {
      marginTop: "0px",
      marginBottom: "0px",
      lineHeight: "normal",
      fontSize: "0.9375rem",
      fontWeight: "500",
      fontFamily: "Lexend Deca, sans-serif",
    }
  },
}));
