import { gql } from "@apollo/client"

/*---------------------------------------------------------------------------*/
/*                                AUTHENTICATION                             */
/*---------------------------------------------------------------------------*/
export const LOGIN = gql`
  mutation Login($data: login_input) {
    login(data: $data) {
      status
      message
      token
      refreshToken
      userId
      type
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($resetPwdToken: String!, $resetPassword: String!, $type: String!) {
    resetPassword(resetPwdToken: $resetPwdToken, resetPassword: $resetPassword, type: $type) {
      status
      message
    }
  }
`

export const FORGET_PASSWORD = gql`
  mutation ForgotPassword($data: forgot_password_input) {
    forgotPassword(data: $data) {
      status
      message
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassewordWeb($input: PasswordChangeInput) {
    changePassewordWeb(input: $input) {
      status
      message
    }
  }
`

/*---------------------------------------------------------------------------*/
/*                               User Management                             */
/*---------------------------------------------------------------------------*/
export const CREATE_OWNER_USER = gql`
  mutation CreateOwnerUser($data: owner_user_create_input) {
    createOwnerUser(data: $data) {
      status
      message
    }
  }
`

export const UPDATE_OWNER_USER = gql`
  mutation UpdateOwnerUser($data: owner_user_update_input, $updateOwnerUserId: ID) {
    updateOwnerUser(data: $data, id: $updateOwnerUserId) {
      status
      message
    }
  }
`
export const DELETE_OWNER_USER = gql`
  mutation DeleteOwnerUser($deleteOwnerUserId: ID) {
    deleteOwnerUser(id: $deleteOwnerUserId) {
      status
      message
    }
  }
`

/*---------------------------------------------------------------------------*/
/*                               Role Management                             */
/*---------------------------------------------------------------------------*/
export const CREATE_ROLE_DETAILS = gql`
  mutation CreateRoleDetails($data: Role_Input) {
    createRoleDetails(data: $data) {
      message
      status
    }
  }
`
export const UPDATE_ROLE_DETAILS = gql`
  mutation UpdateRoleDetails($updateRoleDetailsId: ID!, $data: Role_Input) {
    updateRoleDetails(id: $updateRoleDetailsId, data: $data) {
      status
      message
    }
  }
`
export const DELETE_ROLE_DETAILS = gql`
  mutation DeleteRoleDetails($deleteRoleDetailsId: ID!) {
    deleteRoleDetails(id: $deleteRoleDetailsId) {
      status
      message
    }
  }
`

export const CREATE_ORGANIZATION_DETAILS = gql`
  mutation CreateOrganizationDetails($data: Organization_Input) {
    createOrganizationDetails(data: $data) {
      api_status
      message
    }
  }
`

export const UPDATE_ORGANIZATION_DETAILS = gql`
  mutation UpdateOrganizationDetails($updateOrganizationDetailsId: ID!, $data: Organization_Update_Input) {
    updateOrganizationDetails(id: $updateOrganizationDetailsId, data: $data) {
      api_status
      message
      OrgDetails {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        companyId
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
      }
    }
  }
`

export const DELETE_ORGANIZATION_DETAILS = gql`
  mutation DeleteOrganizationDetails($deleteOrganizationDetailsId: ID!) {
    deleteOrganizationDetails(id: $deleteOrganizationDetailsId) {
      status
      message
    }
  }
`

/*---------------------------------------------------------------------------*/
/*                         Loan Approval Workflow                            */
/*---------------------------------------------------------------------------*/

export const CREATE_APPROVAL_WORKFLOW = gql`
mutation CreateWorkFlow($input: WorkFlowCreate) {
  createWorkFlow(input: $input) {
    status
    message
    data {
      _id
      name
      status
      components {
        _id
        name
        status
      }
      organizations {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
      }
    }
  }
}
`

export const UPDATE_APPROVAL_WORKFLOW = gql`
mutation UpdateWorkFlow($updateWorkFlowId: ID!, $input: WorkFlowUpdate) {
  updateWorkFlow(id: $updateWorkFlowId, input: $input) {
    status
    message
    data {
      _id
      name
      components {
        _id
        name
        description
        status
      }
      organizations {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
      }
      status
    }
  }
}
`

export const CLONE_APPROVAL_WORKFLOW = gql`
mutation Mutation($cloneWorkFlowId: ID!) {
  cloneWorkFlow(id: $cloneWorkFlowId) {
    status
    message
    data {
      _id
      name
      components {
        _id
        name
        description
        status
      }
      organizations {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
      }
      status
    }
  }
}
`

export const DELETE_APPROVAL_WORKFLOW = gql`
mutation DeleteWorkFlow($deleteWorkFlowId: ID!) {
  deleteWorkFlow(id: $deleteWorkFlowId) {
    status
    message
  }
}
`

/*---------------------------------------------------------------------------*/
/*                         Organization Workflow                             */
/*---------------------------------------------------------------------------*/

export const CREATE_ORGANIZATION_WORKFLOW = gql`
mutation CreateOrganizationWorkflow($input: OrganizationWorkflowInput) {
  createOrganizationWorkflow(input: $input) {
    status
    message
    data {
      _id
      name
      branch
      organization {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        type
        role {
          _id
          Role_Name
          panel_type
          Permissions {
            permission {
              _id
              label
              name
              panel_type
            }
            access_controll {
              create
              read
              edit
              delete
            }
          }
        }
        roleId
        workflow {
          _id
          name
          components {
            _id
            name
            label
            description
            status
          }
          organizations {
            _id
            org_name
            email
            contact
            country
            address_1st_line
            address_2nd_line
            city
            post_code
            website
            founders_name
            directors_name
            sector
            legal_structure
            registration_info
            no_of_employees
            description
            account_holder_name
            account_number
            bank_name
            branch_location
            identifier_code
            tax_payerId
            tax_identification_number
            status
            type
            roleId
            workflowId
            organizationWorkflowId
          }
          status
        }
        workflowId
        organizationWorkflow {
          _id
          name
          branch
          organizationId
          status
          createdAt
        }
        organizationWorkflowId
        branches {
          _id
          branch
        }
      }
      organizationId
      approvalFlow {
        _id
        userId
        name
        email
        level
        label
      }
      status
      createdAt
    }
  }
}
`

export const UPDATE_ORGANIZATION_WORKFLOW = gql`
mutation UpdateOrganizationWorkflow($updateOrganizationWorkflowId: ID!, $input: OrganizationWorkflowInput) {
  updateOrganizationWorkflow(id: $updateOrganizationWorkflowId, input: $input) {
    status
    message
    data {
      _id
      name
      branch
      organization {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        type
        role {
          _id
          Role_Name
          panel_type
          Permissions {
            permission {
              _id
              label
              name
              panel_type
            }
            access_controll {
              create
              read
              edit
              delete
            }
          }
        }
        roleId
        workflow {
          _id
          name
          components {
            _id
            name
            label
            description
            status
          }
          organizations {
            _id
            org_name
            email
            contact
            country
            address_1st_line
            address_2nd_line
            city
            post_code
            website
            founders_name
            directors_name
            sector
            legal_structure
            registration_info
            no_of_employees
            description
            account_holder_name
            account_number
            bank_name
            branch_location
            identifier_code
            tax_payerId
            tax_identification_number
            status
            type
            roleId
            workflowId
            organizationWorkflowId
          }
          status
        }
        workflowId
        organizationWorkflow {
          _id
          name
          branch
          organizationId
          status
          createdAt
        }
        organizationWorkflowId
        branches {
          _id
          branch
        }
      }
      organizationId
      approvalFlow {
        _id
        userId
        name
        email
        level
        label
      }
      status
      createdAt
    }
  }
}
`

export const DELETE_ORGANIZATION_WORKFLOW = gql`
mutation deleteOrganizationWorkflow($deleteOrganizationWorkflowId: ID!) {
  deleteOrganizationWorkflow(id: $deleteOrganizationWorkflowId) {
    status
    message
  }
}
`

export const EMPLOYEE_BULK_UPLOAD = gql`
  mutation EmployeeBulkUpload($file: Upload!, $type: BulkUploadOption) {
    EmployeeBulkUpload(file: $file, type: $type) {
      status
      message
    }
  }
`

/*---------------------------------------------------------------------------*/
/*                               FAQ Management                             */
/*---------------------------------------------------------------------------*/

export const CREATE_FAQ = gql`
mutation CreateFAQ($input: createFAQ) {
  createFAQ(input: $input) {
    status
    message
    data {
      id
      question
      answer
      status
      isClick
    }
  }
}
`

export const UPDATE_FAQ = gql`
mutation UpdateFAQ($updateFaqId: ID, $input: updateFAQ) {
  updateFAQ(id: $updateFaqId, input: $input) {
    status
    message
    data {
      id
      question
      answer
      status
      isClick
    }
  }
}
`

export const DELETE_FAQ = gql`
mutation DeleteFAQ($deleteFaqId: ID!) {
  deleteFAQ(id: $deleteFaqId) {
    status
    message 
  }
}
`

/*---------------------------------------------------------------------------*/
/*                        Settings Configuration                             */
/*---------------------------------------------------------------------------*/
export const CREATE_SETTINGS_CONFIGURATION = gql`
mutation CreateSettingConfiguration($input: SettingConfigurationInput!) {
  createSettingConfiguration(input: $input) {
    name
    loan_details {
      loan_segment {
        personal_loan
        employee_loan
        mortgage_loan
        vehicle_loan
        education_loan
        loan_against_security
      }
      loan_amount {
        range_between {
          min
          max
          status
        }
        within_month_income
      }
    }
    employee_information {
      employee_status {
        full_time
        part_time
        contract_employee
      }
      annual_income {
        income_range
        status
      }
    }
    credit_information {
      credit_score {
        score_range {
          min
          max
          status
        }
        no_credit
      }
    }
    repayment_terms {
      repayment_schedule {
        monthly
        bi_monthly
        quarterly
        annual_payment
        user_defined {
          min
          max
          status
        }
      }
      repayment_method {
        direct_bank
        manual
        automatic
        bulk_payment
      }
      fees_charges {
        loan_processing_fee {
          status
          percentage
        }
        loan_late_payment {
          status
          percentage
          grace
        }
      }
    }
    organization_policies {
      eligibility_criteria {
        onWork_employee
        notice_period_employee
        probationary_employee
        onSite_employee
        work_from_home_employee
      }
    }
    workflow {
      _id
      name
      components {
        _id
        name
        label
      }
    }
  }
}
`

export const UPDATE_SETTINGS_CONFIGURATION = gql`
mutation UpdateSettingConfiguration($updateSettingConfigurationId: ID!, $input: SettingConfigurationInputUpdate) {
  updateSettingConfiguration(id: $updateSettingConfigurationId, input: $input) {
    name
    loan_details {
      loan_segment {
        personal_loan
        employee_loan
        mortgage_loan
        vehicle_loan
        education_loan
        loan_against_security
      }
      loan_amount {
        range_between {
          min
          max
          status
        }
        within_month_income
      }
    }
    employee_information {
      employee_status {
        full_time
        part_time
        contract_employee
      }
      annual_income {
        income_range
        status
      }
    }
    credit_information {
      credit_score {
        score_range {
          min
          max
          status
        }
        no_credit
      }
    }
    repayment_terms {
      repayment_schedule {
        monthly
        bi_monthly
        quarterly
        annual_payment
        user_defined {
          min
          max
          status
        }
      }
      repayment_method {
        direct_bank
        manual
        automatic
        bulk_payment
      }
      fees_charges {
        loan_processing_fee {
          status
          percentage
        }
        loan_late_payment {
          status
          percentage
          grace
        }
      }
    }
    organization_policies {
      eligibility_criteria {
        onWork_employee
        notice_period_employee
        probationary_employee
        onSite_employee
        work_from_home_employee
      }
    }
    workflow {
      _id
      name
      components {
        _id
        name
        label
        description
        step
        status
      }
      organizations {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        type
        role {
          _id
          Role_Name
          panel_type
          Permissions {
            permission {
              _id
              label
              name
              panel_type
            }
            access_controll {
              create
              read
              edit
              delete
            }
          }
        }
        roleId
        workflowId
        organizationWorkflow {
          _id
          name
          branch
          organization {
            _id
            org_name
            email
            contact
            country
            address_1st_line
            address_2nd_line
            city
            post_code
            website
            founders_name
            directors_name
            sector
            legal_structure
            registration_info
            no_of_employees
            description
            account_holder_name
            account_number
            bank_name
            branch_location
            identifier_code
            tax_payerId
            tax_identification_number
            status
            type
            roleId
            workflowId
            organizationWorkflowId
          }
          organizationId
          approvalFlow {
            _id
            userId
            name
            email
            level
            label
          }
          status
          createdAt
        }
        organizationWorkflowId
        branches {
          _id
          branch
        }
      }
      status
    }
  }
}
`

export const DELETE_SETTINGS_CONFIGURATION = gql`
mutation DeleteSettingConfiguration($deleteSettingConfigurationId: ID) {
  deleteSettingConfiguration(id: $deleteSettingConfigurationId) {
    status
    message
  }
}
`

export const APPROVE_LOAN_STATUS = gql`
mutation ApproveLoanStatus(
  $applicationId: ID
  $statusId: ID
  $isAccept: Boolean
) {
  approveLoanStatus(
    applicationId: $applicationId
    statusId: $statusId
    isAccept: $isAccept
  ) {
    status
    message
  }
}
`

export const EMPLOYEE_DEACTIVATION = gql`
  mutation EmployeeDeactivation($employeeDeactivationId: ID) {
    employeeDeactivation(id: $employeeDeactivationId) {
      status
      message
    }
  }
`

export const LOAN_DOCUMENT_VERIFY = gql`
mutation DocumentVerify($documentVerifyId: ID, $isAccept: Boolean) {
  documentVerify(id: $documentVerifyId, isAccept: $isAccept) {
    status
    message
    data {
      _id
      application
      name
      file
      fileType
      title
      size
      uploadedDate
      status
      verifiedBy {
        _id
      }
      createdAt
      updatedAt
    }
  }
}
`

export const CREATE_LOAN_SANCTION = gql`
mutation CreateLoanSanction($input: LoanSanctionInput) {
  createLoanSanction(input: $input) {
    status
    message
    data {
      _id
      loanId
      application {
        _id
        employee {
          _id
          employee_id
          first_name
          last_name
          email
        }
        organization {
          _id
          org_name
          email
        }
        employeeId
        organizationId
        label
        applicationNo
        status
        stage
        loanType
        loanAmount
        tenure
        documents {
          _id
          name
          file
          title
          fileType
          size
          uploadedDate
          status
          application
        }
        isMove
      }
      applicationNo
      loanAmount
      loanTenure
      monthlyInstallmentAmount
      disbursementDate
      repaymentStartDate
      repaymentEndDate
      deductionFrequency
      deductionMethod
      outstandingBalance
      bankName
      bankAccNo
      ifscCode
      bankAddress
      transactionRefNo
      purposeOfLoan
      comments
      approvalStatus
      approvalDate
      approvalBy {
        _id
        firstName
        lastName
        email
        password
        status
      }
      attachment {
        name
        fileType
        url
      }
      status
    }
  }
}
`

export const VIEW_URL = gql`
mutation GetViewedUrl($image: String) {
  getViewedUrl(image: $image)
}
`

export const GET_DB_DUMP = gql`
mutation DatabaseDumb {
  databaseDumb {
    status
    message
    data {
      _id
      user {
        _id
        firstName
        lastName
        email
        password
        status
      }
      size
      ipAddress
      name
      url
      createdAt
      updatedAt
    }
  }
}
`

export const UPDATE_EMAIL_TEMPLATE = gql`
mutation UpdateTemplate($updateTemplateId: ID, $input: TemplateInput) {
  updateTemplate(id: $updateTemplateId, input: $input) {
    status
    message
    data {
      _id
      name
      subject
      template
      updatedAt
    }
  }
}
`

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotificationList(
    $input: NotificationListInput
    $updateNotificationListId: ID
  ) {
    updateNotificationList(input: $input, id: $updateNotificationListId) {
      status
      message
      data {
        ... on OrganizationNotificationList {
          _id
          applicationReceived
          applicationApproved
          applicationRejected
          paymentDue
          paymentOverdue
          paymentConfirmation
          loanStatusUpdate
          interestRateChange
          loanRenewalReminder
          newApplication
          preliminaryReview
          documentScrutiny
          creditEvaluation
          riskAssessment
          managementApproval
          loanDocumentVerify
          loanDisbursement
        }
        ... on OwnerUsersNotificationList {
          _id
          setupEmailNotification
          setupCopyToOrganizationMail
          deactivateRequestNotification
          deleteRequestNotification
          accountUpdate
          documentRequest
          loanBalanceUpdate
          earlyRepaymentConfirmation
          renewalApplicationReceived
          renewalApplicationApproved
          applicationReceived
          applicationApproved
          applicationRejected
          paymentDue
          paymentOverdue
          paymentConfirmation
          loanStatusUpdate
          interestRateChange
          loanRenewalReminder
          newApplication
          preliminaryReview
          documentScrutiny
          creditEvaluation
          riskAssessment
          managementApproval
          loanDocumentVerify
          loanDisbursement
        }
      }
    }
  }
`

export const DELETE_ALL_NOTIFICATION = gql`
mutation DeleteAllNotification($deleteAllNotificationId: [ID]) {
  deleteAllNotification(id: $deleteAllNotificationId) {
    status
    message
  }
}
`

export const DELETE_NOTIFICATION = gql`
mutation DeleteNotification($deleteNotificationId: ID) {
  deleteNotification(id: $deleteNotificationId) {
    status
    message
  }
}
`

export const REQUEST_ACCOUNT_DEACTIVATE = gql`
  mutation RequestDeactivation {
    requestDeactivation {
      status
      message
    }
  }
`

export const ORGANIZATION_ACCOUNT_DEACTIVATION = gql`
mutation OrganizationAccountDeactivation($organizationId: String, $status: Boolean) {
  organizationAccountDeactivation(organizationId: $organizationId, status: $status) {
    status
    message
  }
}
`

export const CREATE_HRMS_SESSION = gql`
mutation CreateSession($input: CreateSessionRequest) {
  createSession(input: $input)
}
`

export const UPDATE_HRMS_SESSION = gql`
mutation UpdateIntegrationId($input: UpdateIntegrationId) {
  updateIntegrationId(input: $input) {
    status
    message
  }
}
`

export const ORGANIZATION_UPLOAD_BULK_PAYMENT = gql`
  mutation UploadBulkPayment($file: Upload) {
    uploadBulkPayment(file: $file) {
      status
      message
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshTokenGenerate($refreshToken: String) {
    refreshTokenGenerate(refreshToken: $refreshToken)
  }
`

export const VERIFY_BULK_PAYMENT = gql`
  mutation verifyBulkPayment($verifyBulkPaymentId: ID) {
    verifyBulkPayment(id: $verifyBulkPaymentId) {
      status
      message
    }
  }
`

export const DELETE_ORGANIZATION_ACCOUNT = gql`
mutation organizationAccountDelete($organizationId: String) {
  organizationAccountDelete(organizationId: $organizationId) {
    status
    message
  }
}
`

export const UPDATE_ADMIN_PROFILE = gql`
mutation UpdateProfileWeb($input: UpdateAdminProfileWebInput) {
  updateProfileWeb(input: $input) {
    status
    message
  }
}
`

export const TRANSACTION_BULK_UPLOAD = gql`
mutation TransactionRefBulkUpload($file: Upload) {
  TransactionRefBulkUpload(file: $file) {
    status
    message
  }
}
`

export const DELETE_ORG_DOMAIN = gql`
mutation DeleteOrgDomain($domainId: ID) {
  deleteOrgDomain(domainId: $domainId) {
    status
    message
  }
}
`

export const BULK_FINAL_AUTHORIZER_APPROVAL = gql`
  mutation FinalAuthorizerApprovalBulk(
    $loanRequestIds: [ID!]!
    $isAccept: Boolean!
  ) {
    FinalAuthorizerApprovalBulk(
      loanRequestIds: $loanRequestIds
      isAccept: $isAccept
    ) {
      success
      message
      failedRequests {
        loanRequestId
        message
      }
    }
  }
`

export const LOGOUT = gql`
  mutation Logout {
    logout {
    status
    message
  }
}
`
