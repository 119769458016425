import { gql } from "@apollo/client"

export const GET_USER_LOGIN_DATA = gql`
query GetUserLoginData {
  getUserLoginData {
    ... on ownerUser {
      adminId: _id
      firstName
      lastName
      email
      password
      statusOwner: status
      role {
        _id
        Role_Name
        panel_type
        Permissions {
          permission {
            _id
            label
            name
            panel_type
          }
          access_controll {
            create
            read
            edit
            delete
          }
        }
      }
      roleId
      country
      contactStatus: contact
      profile
      is_default
      reset_password_token
      type
    }
    ... on OrgDetails {
      orgId: _id
      org_name
      email
      contact
      country
      address_1st_line
      address_2nd_line
      city
      post_code
      website
      founders_name
      directors_name
      sector
      legal_structure
      registration_info
      no_of_employees
      description
      company_logo {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      account_holder_name
      account_number
      bank_name
      branch_location
      identifier_code
      tax_payerId
      tax_identification_number
      license_permits {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      privacy_policy {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      terms_conditions {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      status
      type
      role {
        _id
        Role_Name
        panel_type
        Permissions {
          permission {
            _id
            label
            name
            panel_type
          }
          access_controll {
            create
            read
            edit
            delete
          }
        }
      }
      roleId
      type
    }
    ... on Employee {
      _id
      employee_id
      first_name
      last_name
      email
      gender
      marital_status
      dob
      job_title
      profile
      organizationId
      organization {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        type
        role {
          _id
          Role_Name
          panel_type
          Permissions {
            permission {
              _id
              label
              name
              panel_type
            }
            access_controll {
              create
              read
              edit
              delete
            }
          }
        }
        roleId
        workflow {
          _id
          name
          components {
            _id
            name
            label
            description
            status
          }
          organizations {
            _id
            org_name
            email
            contact
            country
            address_1st_line
            address_2nd_line
            city
            post_code
            website
            founders_name
            directors_name
            sector
            legal_structure
            registration_info
            no_of_employees
            description
            account_holder_name
            account_number
            bank_name
            branch_location
            identifier_code
            tax_payerId
            tax_identification_number
            status
            type
            roleId
            workflowId
            organizationWorkflowId
          }
          status
        }
        workflowId
        organizationWorkflow {
          _id
          name
          organizationId
          approvalFlow {
            _id
            userId
            name
            email
            level
            label
          }
          status
          createdAt
        }
        organizationWorkflowId
      }
      org_name
      exp_years
      resign_submitted_date
      attendance_percentage
      probation_period
      contact_number
      joining_date
      loan_details {
        id
        orderId
        transactionId
        loan_type
        loan_amount
        interest_rate
        repayment_schedule
        repayment_method
      }
      repayment_details {
        orderId
        transactionId
        merchantId
        loan_type
        loan_amount
        interest_rate
        loan_process_date
      }
      employess_documents
      branch_location
      type
      isNew
      isLoanRaised
      loans {
        _id
        employee {
          _id
          employee_id
          first_name
          last_name
          email
          gender
          marital_status
          dob
          job_title
          profile
          organizationId
          org_name
          exp_years
          resign_submitted_date
          attendance_percentage
          probation_period
          contact_number
          joining_date
          employess_documents
          branch_location
          type
          isNew
          isLoanRaised
        }
        employeeId
        organizationId
        applicationNo
        status
        loanType
        loanAmount
        tenure
        documents {
          _id
          name
          file
          title
          fileType
          size
          uploadedDate
        }
        isMove
        organizationWorkflowId
        workflowId
        createdAt
        loanFlowLogs {
          _id
          application
          title
          description
          createdAt
        }
      }
      role {
        _id
        Role_Name
        panel_type
        Permissions {
          permission {
            _id
            label
            name
            panel_type
          }
          access_controll {
            create
            read
            edit
            delete
          }
        }
      }
    }
  }
}
`

export const GET_ROLE_DETAILS = gql`
  query GetRoleDetails($panelType: String!) {
    getRoleDetails(panel_type: $panelType) {
      _id
      Role_Name
      Permissions {
        permission {
          _id
          label
          name
          panel_type
        }
        access_controll {
          create
          read
          edit
          delete
        }
      }
    }
  }
`

export const GET_ALL_OWNER_USERS = gql`
  query GetOwnerUsersDetails($filter: ownerUserFilter!) {
    getOwnerUsersDetails(filter: $filter) {
      total
      page
      limit
      data {
        _id
        firstName
        lastName
        email
        password
        status
        profile
        role {
          _id
          Role_Name
          panel_type
          Permissions {
            permission {
              _id
              label
              name
              panel_type
            }
            access_controll {
              create
              read
              edit
              delete
            }
          }
        }
        country
        contact
        is_default
        reset_password_token
      }
      statusCounts {
        Active
        Pending
        Revoked
      }
    }
  }
`

export const GET_OWNER_USER_BY_ID = gql`
  query GetOwnerUserById($getOwnerUserByIdId: ID) {
    getOwnerUserById(id: $getOwnerUserByIdId) {
      _id
      firstName
      lastName
      email
      status
      profile
      role {
        _id
        Role_Name
        panel_type
        Permissions {
          permission {
            _id
            label
            name
          }
          access_controll {
            create
            read
            edit
            delete
          }
        }
      }
      country
      contact
      is_default
    }
  }
`

export const GET_ALL_ROLES_WITH_USERS = gql`
  query GetRolesWithUsers($panelType: String!) {
    getRolesWithUsers(panel_type: $panelType) {
      _id
      Role_Name
      panel_type
      Permissions {
        permission {
          _id
          label
          name
        }
        access_controll {
          create
          read
          edit
          delete
        }
      }
      users {
        _id
        firstName
        lastName
        email
        status
        profile
        country
        contact
        is_default
      }
      usersCount
    }
  }
`

export const GET_PERMISSION_DETAILS = gql`
  query GetPermissionDetails($panelType: String!) {
    getPermissionDetails(panel_type: $panelType) {
      _id
      label
      name
      panel_type
    }
  }
`

export const GET_ROLE_BY_ID = gql`
  query GetRoleById($getRoleByIdId: ID!) {
    getRoleById(id: $getRoleByIdId) {
      _id
      Role_Name
      Permissions {
        permission {
          _id
          label
          name
          panel_type
        }
        access_controll {
          create
          read
          edit
          delete
        }
      }
    }
  }
`

export const GET_ORGANIZATION_BY_ID = gql`
  query GetOrganizationById($getOrganizationByIdId: ID!) {
    getOrganizationById(id: $getOrganizationByIdId) {
      _id
      org_name
      email
      contact
      country
      address_1st_line
      address_2nd_line
      city
      post_code
      website
      companyId
      founders_name
      directors_name
      sector
      legal_structure
      registration_info
      no_of_employees
      domains {
        _id
        domain
        type
      }
      description
      company_logo {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      account_holder_name
      account_number
      bank_name
      branch_location
      identifier_code
      tax_payerId
      tax_identification_number
      license_permits {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      privacy_policy {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      terms_conditions {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      status
      type
      settingConfiguration {
        _id
        name
        loan_details {
          loan_segment {
            personal_loan
            employee_loan
            mortgage_loan
            vehicle_loan
            education_loan
            loan_against_security
          }
          loan_amount {
            range_between {
              min
              max
              status
            }
            within_month_income
          }
        }
        employee_information {
          employee_status {
            full_time
            part_time
            contract_employee
          }
          annual_income {
            income_range
            status
          }
        }
        credit_information {
          credit_score {
            score_range {
              min
              max
              status
            }
            no_credit
          }
        }
        repayment_terms {
          repayment_schedule {
            monthly
            bi_monthly
            quarterly
            annual_payment
            user_defined {
              min
              max
              status
            }
          }
          repayment_method {
            direct_bank
            manual
            automatic
            bulk_payment
          }
        }
        organization_policies {
          eligibility_criteria {
            onWork_employee
            notice_period_employee
            probationary_employee
            onSite_employee
            work_from_home_employee
          }
        }
        workflow {
          _id
          name
          components {
            _id
            name
            label
            description
            step
            status
          }
          organizations {
            _id
            org_name
            email
            contact
            country
            address_1st_line
            address_2nd_line
            city
            post_code
            website
            founders_name
            directors_name
            sector
            legal_structure
            registration_info
            no_of_employees
            description
            company_logo {
              _id
              name
              file
              fileType
              size
              uploadedDate
            }
            account_holder_name
            account_number
            bank_name
            branch_location
            identifier_code
            tax_payerId
            tax_identification_number
            license_permits {
              _id
              name
              file
              fileType
              size
              uploadedDate
            }
            privacy_policy {
              _id
              name
              file
              fileType
              size
              uploadedDate
            }
            terms_conditions {
              _id
              name
              file
              fileType
              size
              uploadedDate
            }
            status
            type
            role {
              _id
              Role_Name
              panel_type
              Permissions {
                permission {
                  _id
                  label
                  name
                  panel_type
                }
                access_controll {
                  create
                  read
                  edit
                  delete
                }
              }
            }
            roleId
            workflowId
            organizationWorkflow {
              _id
              name
              branch
              organization {
                _id
                org_name
                email
                contact
                country
                address_1st_line
                address_2nd_line
                city
                post_code
                website
                founders_name
                directors_name
                sector
                legal_structure
                registration_info
                no_of_employees
                description
                account_holder_name
                account_number
                bank_name
                branch_location
                identifier_code
                tax_payerId
                tax_identification_number
                status
                type
                roleId
                workflowId
                organizationWorkflowId
              }
              organizationId
              approvalFlow {
                _id
                userId
                name
                email
                level
                label
              }
              status
              createdAt
            }
            organizationWorkflowId
            branches {
              _id
              branch
            }
            settingConfiguration {
              _id
              name
            }
          }
          status
        }
      }
    }
  }
`

export const GET_ALL_ORGANIZATION_DETAILS = gql`
  query GetOrganizationDetails($filter: orgFilter!) {
    getOrganizationDetails(filter: $filter) {
      data {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        createdAt
        role {
          _id
          Role_Name
          panel_type
          Permissions {
            permission {
              _id
              label
              name
              panel_type
            }
            access_controll {
              create
              read
              edit
              delete
            }
          }
        }
      }
      total
      statusCount {
        Accepted
        Draft
        Pending
        Invited
        Deactivated
        Deletion
      }
      pagination {
        previous
        next
      }
    }
  }
`

export const GET_PERMISSION_WITH_ROLES = gql`
  query GetPermissionsWithRoles($panelType: String!) {
    getPermissionsWithRoles(panel_type: $panelType) {
      _id
      label
      name
      panel_type
      roleNames
      updatedAt
    }
  }
`

export const GET_ALL_EMPLOYEE_DETAILS = gql`
query GetAllEmployees($filter: EmployeeFilter) {
  getAllEmployees(filter: $filter) {
    data {
      _id
      employee_id
      first_name
      last_name
      email
      hashMail
      gender
      marital_status
      workAddress
      permanentAddress
      presentAddress
      dob
      job_title
      manager {
        id
        email
      }
      employmentType
      department
      profile
      organizationId
      organization {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        type
        roleId
        workflow {
          _id
          name
          components {
            _id
            name
            label
            description
            step
            status
          }
          organizations {
            _id
            org_name
            email
            contact
            country
            address_1st_line
            address_2nd_line
            city
            post_code
            website
            founders_name
            directors_name
            sector
            legal_structure
            registration_info
            no_of_employees
            description
            account_holder_name
            account_number
            bank_name
            branch_location
            identifier_code
            tax_payerId
            tax_identification_number
            status
            type
            roleId
            workflowId
            organizationWorkflowId
          }
          status
        }
        workflowId
        organizationWorkflow {
          _id
          name
          branch
          organizationId
          approvalFlow {
            _id
            userId
            name
            email
            level
            label
          }
          status
          createdAt
        }
        organizationWorkflowId
        branches {
          _id
          branch
        }
        settingConfiguration {
          _id
          name
          loan_details {
            loan_segment {
              personal_loan
              employee_loan
              mortgage_loan
              vehicle_loan
              education_loan
              loan_against_security
            }
            loan_amount {
              range_between {
                min
                max
                status
              }
              within_month_income
            }
          }
          employee_information {
            employee_status {
              full_time
              part_time
              contract_employee
            }
            annual_income {
              income_range
              status
            }
          }
          credit_information {
            credit_score {
              score_range {
                min
                max
                status
              }
              no_credit
            }
          }
          repayment_terms {
            repayment_schedule {
              monthly
              bi_monthly
              quarterly
              annual_payment
              user_defined {
                min
                max
                status
              }
            }
            repayment_method {
              direct_bank
              manual
              automatic
              bulk_payment
            }
          }
          organization_policies {
            eligibility_criteria {
              onWork_employee
              notice_period_employee
              probationary_employee
              onSite_employee
              work_from_home_employee
            }
          }
        }
      }
      org_name
      exp_years
      resign_submitted_date
      attendance_percentage
      probation_period
      contact_number
      joining_date
      loan_details {
        id
        orderId
        transactionId
        loan_type
        loan_amount
        interest_rate
        repayment_schedule
        repayment_method
      }
      repayment_details {
        orderId
        transactionId
        merchantId
        loan_type
        loan_amount
        interest_rate
        loan_process_date
      }
      employess_documents
      branch_location
      compensation
      bankAccounts
      employeeKYC
      type
      isNew
      isLoanRaised
    
      role {
        _id
        Role_Name
        panel_type
        Permissions {
          permission {
            _id
            label
            name
            panel_type
          }
          access_controll {
            create
            read
            edit
            delete
          }
        }
      }
      status
      activeapplication
      loans {
        _id
        applicationNo
        createdAt
        documents {
          _id
          name
          file
          title
          fileType
          size
          uploadedDate
          status
          application
        }
        employee {
          _id
        }
        isMove
        label
        loanAmount
        loanType
        loansCount
        stage
        status
        tenure
      }
    }
    total
    filteredTotalCount
    statusCounts {
      Active
      Pending
      InActive
    }
  }
}
`

export const GET_ALL_EMPLOYEE_DROPDOWN = gql`
  query GetAllEmployeeDropdown($branch: String) {
    getAllEmployeeDropdown(branch: $branch) {
      _id
      employee_id
      first_name
      last_name
      email
      job_title
      profile
      organizationId
      organization {
        _id
        org_name
        email
        branch_location
      }
      org_name
      exp_years
      resign_submitted_date
      attendance_percentage
      probation_period
      contact_number
      joining_date
      role {
        _id
        Role_Name
        panel_type
        Permissions {
          permission {
            _id
            label
            name
            panel_type
          }
          access_controll {
            create
            read
            edit
            delete
          }
        }
      }
    }
  }
`

export const GET_EMPLOYEE_BY_ID = gql`
query GetEmployeeById($getEmployeeByIdId: ID!) {
  getEmployeeById(id: $getEmployeeByIdId) {
    _id
    employee_id
    first_name
    last_name
    email
    hashMail
    gender
    marital_status
    workAddress
    permanentAddress
    presentAddress
    dob
    job_title
    manager {
      id
      email
     
    }
    employmentType
    department
    profile
    organizationId
    organization {
      _id
      org_name
      email
    }
    org_name
    exp_years
    resign_submitted_date
    attendance_percentage
    probation_period
    contact_number
    joining_date
    employess_documents
    branch_location
    compensation
    bankAccounts
    employeeKYC
    type
    isNew
    isLoanRaised
    loans {
      _id
      employee {
        _id
        employee_id
        first_name
        last_name
        email
        hashMail
        gender
        marital_status
        workAddress
        permanentAddress
        presentAddress
        dob
        job_title
        employmentType
        department
        profile
        organizationId
        org_name
        exp_years
        resign_submitted_date
        attendance_percentage
        probation_period
        contact_number
        joining_date
        employess_documents
        branch_location
        compensation
        bankAccounts
        employeeKYC
        type
        isNew
        isLoanRaised
        status
        activeapplication
      }
      employeeId
      organizationId
      label
      applicationNo
      status
      stage
      loanType
      loanAmount
      tenure
      documents {
        _id
        name
        file
        title
        fileType
        size
        uploadedDate
        status
        application
      }
      isMove
      organizationWorkflowId
      workflowId
      createdAt
      loanFlowLogs {
        _id
        application
        title
        description
        label
        createdAt
      }
      ownerApprovalStatus {
        _id
        application
        label
        verifiedBy {
          _id
          firstName
          lastName
          email
          password
          status
          roleId
          country
          contact
          profile
          is_default
          reset_password_token
          type
        }
        status
        comments
        enable
        createdAt
        applicationNo
        updatedAt
      }
      organizationApprovalStatus {
        _id
        application
        level
        verifiedBy
        name
        status
        comments
        enable
        label
        createdAt
        applicationNo
        updatedAt
      }
      OrganizationWorkflow {
        _id
        name
        branch
        organizationId
        status
        createdAt
      }
      organizationLoanFlowLogs {
        _id
        title
        description
        isCompleted
        label
        updatedAt
        createdAt
      }
      loansCount
      previousLoanDueStatus
    }
    role {
      _id
      Role_Name
      panel_type
      Permissions {
        permission {
          _id
          label
          name
          panel_type
        }
        access_controll {
          create
          read
          edit
          delete
        }
      }
    }
    status
    activeapplication
  }
}
`

export const GET_ALL_WORKFLOW_COMPONENT = gql`
  query GetAllWorkFlowComponent {
    getAllWorkFlowComponent {
      _id
      name
      description
      status
    }
  }
`

export const GET_ALL_WORKFLOW = gql`
  query GetAllWorkFlow($filter: workFlowFilter) {
    getAllWorkFlow(filter: $filter) {
      data {
        _id
        name
        components {
          _id
          name
          status
        }
        organizations {
          _id
          org_name
          email
          contact
          country
          address_1st_line
          address_2nd_line
          city
          post_code
          website
          founders_name
          directors_name
          sector
          legal_structure
          registration_info
          no_of_employees
          description
          company_logo {
            _id
            name
            file
            fileType
            size
            uploadedDate
          }
          account_holder_name
          account_number
          bank_name
          branch_location
          identifier_code
          tax_payerId
          tax_identification_number
          license_permits {
            _id
            name
            file
            fileType
            size
            uploadedDate
          }
          privacy_policy {
            _id
            name
            file
            fileType
            size
            uploadedDate
          }
          terms_conditions {
            _id
            name
            file
            fileType
            size
            uploadedDate
          }
          status
        }
        status
      }
      activeCount
      inActiveCount
    }
  }
`

export const GET_SINGLE_WORKFLOW = gql`
  query Query($getWorkFlowId: ID) {
    getWorkFlow(id: $getWorkFlowId) {
      _id
      name
      components {
        _id
        name
        description
        status
      }
      organizations {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
      }
      status
    }
  }
`

/*---------------------------------------------------------------------------*/
/*                                Loan Management                            */
/*---------------------------------------------------------------------------*/

export const GET_ALL_APPROVE_LOANS = gql`
  query GetAllLoanSanction($filter: LoanSanctionFilter) {
    getAllLoanSanction(filter: $filter) {
      data {
        _id
        loanId
        application {
          _id
        }
        applicationNo
        employee {
          _id
          first_name
          email
          profile
          org_name
          organization {
            _id
          }
        }
        loanAmount
        loanTenure
        monthlyInstallmentAmount
        disbursementDate
        repaymentStartDate
        repaymentEndDate
        deductionFrequency
        deductionMethod
        outstandingBalance
        bankName
        bankAccNo
        ifscCode
        bankAddress
        transactionRefNo
        purposeOfLoan
        comments
        approvalStatus
        approvalDate
        approvalBy {
          _id
          firstName
          lastName
          email
        }
        attachment {
          name
          fileType
          url
        }
        status
      }
      total
      statusCount {
        Paid
        Unpaid
        Closed
      }
    }
  }
`

export const GET_SINGLE_APPROVE_LOAN_DATA = gql`
query GetLoanSanction($getLoanSanctionId: ID) {
  getLoanSanction(id: $getLoanSanctionId) {
    _id
    loanId
    application {
      _id
      organization {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        type
        roleId
        workflowId
        organizationWorkflowId
        branches {
          _id
          branch
        }
        settingConfiguration {
          _id
          name
          loan_details {
            loan_segment {
              personal_loan
              employee_loan
              mortgage_loan
              vehicle_loan
              education_loan
              loan_against_security
            }
            loan_amount {
              range_between {
                min
                max
                status
              }
              within_month_income
            }
          }
          employee_information {
            employee_status {
              full_time
              part_time
              contract_employee
            }
            annual_income {
              income_range
              status
            }
          }
          credit_information {
            credit_score {
              score_range {
                min
                max
                status
              }
              no_credit
            }
          }
          repayment_terms {
            repayment_schedule {
              monthly
              bi_monthly
              quarterly
              annual_payment
              user_defined {
                min
                max
                status
              }
            }
            repayment_method {
              direct_bank
              manual
              automatic
              bulk_payment
            }
          }
          organization_policies {
            eligibility_criteria {
              onWork_employee
              notice_period_employee
              probationary_employee
              onSite_employee
              work_from_home_employee
            }
          }
        }
      }
      employeeId
      organizationId
      label
      applicationNo
      status
      stage
      loanType
      loanAmount
      tenure
      documents {
        _id
        name
        file
        title
        fileType
        size
        uploadedDate
        status
        application
      }
      isMove
      organizationWorkflow {
        _id
        name
        branch
        organizationId
        approvalFlow {
          _id
          userId
          name
          email
          level
          label
        }
        status
        createdAt
      }
      organizationWorkflowId
      workflow {
        _id
        name
        components {
          _id
          name
          label
          description
          step
          status
        }
        organizations {
          _id
          org_name
          email
          contact
          country
          address_1st_line
          address_2nd_line
          city
          post_code
          website
          founders_name
          directors_name
          sector
          legal_structure
          registration_info
          no_of_employees
          description
          account_holder_name
          account_number
          bank_name
          branch_location
          identifier_code
          tax_payerId
          tax_identification_number
          status
          type
          roleId
          workflowId
          organizationWorkflowId
        }
        status
      }
      workflowId
      createdAt
      loanFlowLogs {
        _id
        application
        title
        description
        label
        createdAt
      }
      ownerApprovalStatus {
        _id
        application
        label
        verifiedBy {
          _id
          firstName
          lastName
          email
          password
          status
          roleId
          country
          contact
          profile
          is_default
          reset_password_token
          type
        }
        status
        comments
        enable
        createdAt
        applicationNo
        updatedAt
      }
      organizationApprovalStatus {
        _id
        application
        level
        verifiedBy
        name
        status
        comments
        enable
        label
        createdAt
        applicationNo
        updatedAt
      }
      OrganizationWorkflow {
        _id
        name
        branch
        organizationId
        status
        createdAt
      }
      organizationLoanFlowLogs {
        _id
        title
        description
        isCompleted
        label
        updatedAt
        createdAt
      }
      loansCount
      previousLoanDueStatus
    }
    applicationNo
    employee {
      _id
      employee_id
      first_name
      last_name
      email
      hashMail
      gender
      marital_status
      dob
      job_title
      profile
      organizationId
      org_name
      exp_years
      resign_submitted_date
      attendance_percentage
      probation_period
      contact_number
      joining_date
      workAddress
      compensation
      loan_details {
        id
        orderId
        transactionId
        loan_type
        loan_amount
        interest_rate
        repayment_schedule
        repayment_method
      }
      repayment_details {
        orderId
        transactionId
        merchantId
        loan_type
        loan_amount
        interest_rate
        loan_process_date
      }
      employess_documents
      branch_location
      type
      isNew
      isLoanRaised
      loans {
        _id
        employeeId
        organizationId
        label
        applicationNo
        status
        stage
        loanType
        loanAmount
        tenure
        isMove
        organizationWorkflowId
        workflowId
        createdAt
        loansCount
        previousLoanDueStatus
      }
      role {
        _id
        Role_Name
        panel_type
        Permissions {
          permission {
            _id
            label
            name
            panel_type
          }
          access_controll {
            create
            read
            edit
            delete
          }
        }
      }
      status
      activeapplication
    }
    loanAmount
    loanTenure
    monthlyInstallmentAmount
    disbursementDate
    repaymentStartDate
    repaymentEndDate
    deductionFrequency
    deductionMethod
    outstandingBalance
    bankName
    bankAccNo
    ifscCode
    bankAddress
    transactionRefNo
    purposeOfLoan
    comments
    approvalStatus
    approvalDate
    approvalBy {
      _id
      firstName
      lastName
      email
      password
      status
      roleId
      country
      contact
      profile
      is_default
      reset_password_token
      type
    }
    attachment {
      name
      fileType
      url
    }
    status
  }
}
`

export const GET_ALL_LOAN_REQUEST = gql`
query GetAllLoanRequest($filter: LoanReqFilter) {
  getAllLoanRequest(filter: $filter) {
    data {
      _id
      orderId
      employee {
        _id
        employee_id
        first_name
        last_name
        email
        gender
        marital_status
        dob
        job_title
        profile
        organizationId
        org_name
        exp_years
        resign_submitted_date
        probation_period
        contact_number
        joining_date
 
        loan_details {
          id
          orderId
          transactionId
          loan_type
          loan_amount
          interest_rate
          repayment_schedule
          repayment_method
        }
        repayment_details {
          orderId
          transactionId
          merchantId
          loan_type
          loan_amount
          interest_rate
          loan_process_date
        }
        employess_documents
        type
        isNew
        loans {
          _id
          employeeId
          organizationId
          applicationNo
          status
          loanType
          loanAmount
          tenure
          isMove
          workflowId
          createdAt
        }
      }
      organization {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        type
        role {
          _id
          Role_Name
          panel_type
          Permissions {
            permission {
              _id
              label
              name
              panel_type
            }
            access_controll {
              create
              read
              edit
              delete
            }
          }
        }
        roleId
      }
      employeeId
      organizationId
      applicationNo
      status
      loanType
      loanAmount
      creditAmount
      processingFee
      tenure
      documents {
        _id
        name
        file
        title
        fileType
        size
        uploadedDate
      }
      isMove
      workflow {
        _id
        name
        components {
          _id
          name
          label
          description
          status
        }
        organizations {
          _id
          org_name
          email
          contact
          country
          address_1st_line
          address_2nd_line
          city
          post_code
          website
          founders_name
          directors_name
          sector
          legal_structure
          registration_info
          no_of_employees
          description
          account_holder_name
          account_number
          bank_name
          branch_location
          identifier_code
          tax_payerId
          tax_identification_number
          status
          type
          roleId
        }
        status
      }
      workflowId
      createdAt
    }
    total
    statusCount {
      Pending
      Cancelled
      Drafted
      Approved
      Rejected
    }
    pagination {
      previous
      next
    }
  }
}
`

export const GET_LOAN_CHART_COUNT = gql`
  query GetLoanCount {
    getLoanCount {
      Total {
        count
        percentage
        name
      }
      Approved {
        count
        percentage
        name
      }
      Pending {
        count
        percentage
        name
      }
      Rejected {
        count
        percentage
        name
      }
    }
  }
`

export const GET_SINGLE_LOAN_REQUEST = gql`
query GetLoanRequest($getLoanRequestId: ID!) {
  getLoanRequest(id: $getLoanRequestId) {
    _id
    employee {
      _id
      employee_id
      first_name
      last_name
      contact_number
      email
      gender
      marital_status
      dob
      job_title
      profile
      branch_location
      organizationId
      org_name
      exp_years
      resign_submitted_date
      attendance_percentage
      probation_period
      contact_number
      joining_date
      compensation
      workAddress
    }
    organization {
      _id
      email
      org_name
      branch_location
    }
    label
    applicationNo
    status
    stage
    loanType
    loanAmount
    tenure
    loansCount
    previousLoanDueStatus
    documents {
      application
      _id
      name
      title
      file
      fileType
      uploadedDate
    }
    isMove
    createdAt
    loanFlowLogs {
      _id
      application
      title
      description
      createdAt
    }
    ownerApprovalStatus {
      _id
      application
      label
      verifiedBy {
        _id
        firstName
      }
      status
      comments
      enable
      createdAt
      applicationNo
      updatedAt
    }
    organizationApprovalStatus {
      _id
      application
      level
      verifiedBy
      name
      label
      status
      comments
      enable
      updatedAt
    }
    OrganizationWorkflow {
      _id
      name
      organization {
        _id
        org_name
        email
      }
      organizationId
      approvalFlow {
        _id
        userId
        name
        email
        level
        label
      }
      status
      createdAt
    }
    organizationLoanFlowLogs {
      _id
      title
      description
      label
      isCompleted
      updatedAt
    }
  }
}
`

export const GET_EMPLOYEE_LOAN_REQUEST = gql`
query GetEmployeeLoanRequest(
  $getEmployeeLoanRequestId: ID!
  $filter: LoanReqFilter
) {
  getEmployeeLoanRequest(id: $getEmployeeLoanRequestId, filter: $filter) {
    data {
      _id
      employee {
        _id
        employee_id
        first_name
        last_name
        email
        gender
        marital_status
        dob
        job_title
        profile
        organizationId
        organization {
          _id
          org_name
          email
          contact
          country
          address_1st_line
          address_2nd_line
          city
          post_code
          website
          founders_name
          directors_name
          sector
          legal_structure
          registration_info
          no_of_employees
          description
          account_holder_name
          account_number
          bank_name
          branch_location
          identifier_code
          tax_payerId
          tax_identification_number
          status
          type
        }
        org_name
        exp_years
        resign_submitted_date
        probation_period
        contact_number
        joining_date
      
        loan_details {
          id
          orderId
          transactionId
          loan_type
          loan_amount
          interest_rate
          repayment_schedule
          repayment_method
        }
        repayment_details {
          orderId
          transactionId
          merchantId
          loan_type
          loan_amount
          interest_rate
          loan_process_date
        }
        employess_documents
        isNew
        loans {
          _id
          organizationId
          applicationNo
          status
          loanType
          loanAmount
          tenure
          isMove
          createdAt
        }
      }
      organizationId
      applicationNo
      status
      loanType
      loanAmount
      tenure
      documents {
        _id
        name
        file
        title
        fileType
        size
        uploadedDate
      }
      isMove
      workflow {
        _id
        name
        components {
          _id
          name
          label
          description
          status
        }
        organizations {
          _id
          org_name
          email
          contact
          country
          address_1st_line
          address_2nd_line
          city
          post_code
          website
          founders_name
          directors_name
          sector
          legal_structure
          registration_info
          no_of_employees
          description
          account_holder_name
          account_number
          bank_name
          branch_location
          identifier_code
          tax_payerId
          tax_identification_number
          status
          type
        }
        status
      }
      createdAt
      organization {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        type
        role {
          _id
          Role_Name
          panel_type
          Permissions {
            permission {
              _id
              label
              name
              panel_type
            }
            access_controll {
              create
              read
              edit
              delete
            }
          }
        }
      }
    }
    total
    statusCount {
      Cancelled
      Drafted
      Approved
      Pending
      Rejected
    }
    pagination {
      previous
      next
    }
  }
}
`

/*---------------------------------------------------------------------------*/
/*                                 FAQ Management                            */
/*---------------------------------------------------------------------------*/

export const GET_ALL_FAQ = gql`
  query GetAllFAQ($search: String) {
    getAllFAQ(search: $search) {
      id
      question
      answer
      status
      isClick
    }
  }
`

export const GET_SINGLE_FAQ = gql`
  query GetFAQ($getFaqId: ID!) {
    getFAQ(id: $getFaqId) {
      id
      question
      answer
      status
      isClick
    }
  }
`

/*---------------------------------------------------------------------------*/
/*                         Settings Configuration                            */
/*---------------------------------------------------------------------------*/

export const GET_ALL_SETTINGS_LIST = gql`
  query GetAllSettingConfiguration {
    getAllSettingConfiguration {
      _id
      name
      workflow_org_img
    }
  }
`

export const GET_SETTINGS_CONFIG = gql`
query GetSettingConfiguration($getSettingConfigurationId: ID!) {
  getSettingConfiguration(id: $getSettingConfigurationId) {
    loan_details {
      loan_segment {
        name
        value
        status
      }
      loan_amount {
        name
        value
        status
        min
        max
      }
    }
    employee_information {
      employee_status {
        name
        value
        status
      }
      annual_income {
        name
        value
        income_range
        status
      }
    }
    credit_information {
      credit_score {
        name
        value
        min
        max
        status
      }
    }
    repayment_terms {
      repayment_schedule {
        name
        value
        status
        min
        max
      }
      repayment_method {
        name
        value
        status
      }
      fees_charges {
        
          name
          value
          percentage
          status
        grace
      }
    }
    organization_policies {
      eligibility_criteria {
        name
        value
        status
      }
    }

    workflow {
      
      name
      _id
    }
    name
  }
}
`

/*---------------------------------------------------------------------------*/
/*                          Organization Workflow                            */
/*---------------------------------------------------------------------------*/

export const GET_ALL_ORGANIZATION_WORKFLOWS = gql`
  query GetAllOrganizationWorkflow {
    getAllOrganizationWorkflow {
      _id
      name
      branch
      organization {
        _id
        org_name
      }
      organizationId
      approvalFlow {
        _id
        userId
        name
        email
        level
        label
      }
      status
      createdAt
    }
  }
`

export const GET_SINGLE_ORGANIZATION_WORKFLOW = gql`
  query GetOrganizationWorkflow($getOrganizationWorkflowId: ID!) {
    getOrganizationWorkflow(id: $getOrganizationWorkflowId) {
      _id
      name
      organization {
        _id
      }
      branch
      organizationId
      approvalFlow {
        _id
        userId
        name
        email
        level
        label
      }
      salaryRequest
      status
      createdAt
    }
  }
`

export const GET_ALL_BRANCH = gql`
  query getAllBranches {
    getAllBranches
  }
`

export const GET_ALL_ORGANIZATION_LIST = gql`
  query GetAllOrganizationForList {
    getAllOrganizationForList {
      _id
      org_name
      sector
    }
  }
`

/*---------------------------------------------------------------------------*/
/*                          Loan Approval Process                            */
/*---------------------------------------------------------------------------*/

export const GET_DOCUMENTS_BY_ID = gql`
  query GetDocumentsByApplicationId($getDocumentsByApplicationIdId: ID) {
    getDocumentsByApplicationId(id: $getDocumentsByApplicationIdId) {
      _id
      application
      name
      file
      fileType
      title
      size
      uploadedDate
      status
      verifiedBy {
        _id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_DISBURSEMENT_DATA = gql`
  query GetDisbursementData($getDisbursementDataId: ID) {
    getDisbursementData(id: $getDisbursementDataId) {
      application 
      loanId
      loanAmount
      loanTenure
      monthlyInstallmentAmount
      disbursementDate
      repaymentStartDate
      repaymentEndDate
      deductionFrequency
      deductionMethod
      outStandingBalance
      bankAccounts
      approvalStatus
      employee{
        _id
      }
    }
  }
`

export const GET_NOC_DOCUMENTS = gql`
  query GetDocumentOfNocAndSancation($applicationId: String) {
    getDocumentOfNocAndSanction(applicationId: $applicationId) {
      _id
      applicationNo
      applicationId
      loanId
      category
      url
    }
  }
`

export const GET_APPROVE_LOAN_DOCS = gql`
  query Query($employeeId: String) {
    getEmployeeDocuments(employeeId: $employeeId) {
      _id
      loanId
      createdAt
      updatedAt
    }
  }
`

export const GET_SINGLE_APPROVE_LOAN_DOCS = gql`
  query GetDocumentsByLoanId($getDocumentsByLoanId: ID) {
    getDocumentsByLoanId(id: $getDocumentsByLoanId) {
      _id
      loanId
      application {
        _id
        employee {
          _id
        }
        documents {
          _id
          name
          file
          title
          fileType
          size
          uploadedDate
          status
          application
        }
      }
    }
  }
`

export const GET_ALL_EMPLOYEE_APPROVE_LOAN = gql`
  query GetEmployeeAllLoans($employeeId: String) {
    getEmployeeAllLoans(employeeId: $employeeId) {
      active {
        _id
        loanId
        application {
          _id
          organization {
            _id
            org_name
            email
          }
          employeeId
          organizationId
          label
          applicationNo
          status
          stage
          loanType
          loanAmount
          tenure
          documents {
            _id
            name
            file
            title
            fileType
            size
            uploadedDate
            status
            application
          }
          isMove
        }
        applicationNo
        employee {
          _id
          employee_id
          first_name
          last_name
          email
          hashMail
          gender
          marital_status
          dob
          job_title
          profile
          organizationId
          org_name
          exp_years
          resign_submitted_date
          attendance_percentage
          probation_period
          contact_number
          joining_date
          loan_details {
            id
            orderId
            transactionId
            loan_type
            loan_amount
            interest_rate
            repayment_schedule
            repayment_method
          }
          repayment_details {
            orderId
            transactionId
            merchantId
            loan_type
            loan_amount
            interest_rate
            loan_process_date
          }
          employess_documents
          branch_location
          type
          isNew
          isLoanRaised
          loans {
            _id
            employeeId
            organizationId
            label
            applicationNo
            status
            stage
            loanType
            loanAmount
            tenure
            isMove
            organizationWorkflowId
            workflowId
            createdAt
            loansCount
            previousLoanDueStatus
          }
          role {
            _id
            Role_Name
            panel_type
            Permissions {
              permission {
                _id
                label
                name
                panel_type
              }
              access_controll {
                create
                read
                edit
                delete
              }
            }
          }
          status
          activeapplication
        }
        loanAmount
        loanTenure
        monthlyInstallmentAmount
        disbursementDate
        repaymentStartDate
        repaymentEndDate
        deductionFrequency
        deductionMethod
        outstandingBalance
        bankName
        bankAccNo
        ifscCode
        bankAddress
        transactionRefNo
        purposeOfLoan
        comments
        approvalStatus
        approvalDate
        approvalBy {
          _id
          firstName
          lastName
          email
          password
          status
          roleId
          country
          contact
          profile
          is_default
          reset_password_token
          type
        }
        attachment {
          name
          fileType
          url
        }
        status
      }
      closed {
        _id
        loanId
        applicationNo
        loanAmount
        loanTenure
        monthlyInstallmentAmount
        disbursementDate
        repaymentStartDate
        repaymentEndDate
        deductionFrequency
        deductionMethod
        outstandingBalance
        bankName
        bankAccNo
        ifscCode
        bankAddress
        transactionRefNo
        purposeOfLoan
        comments
        approvalStatus
        approvalDate
        status
      }
    }
  }
`

export const GET_ORGANIZATION_EMPLOYEE_LIST = gql`
query GetAllEmployeesByOrganization($filter: EmployeeFilter, $organizationId: String) {
  getAllEmployeesByOrganization(filter: $filter, organizationId: $organizationId) {
    data {
      _id
      employee_id
      first_name
      last_name
      email
      hashMail
      gender
      marital_status
      dob
      job_title
      profile
      organizationId
      organization {
        _id
        org_name
        email
        contact
        country
        address_1st_line
        address_2nd_line
        city
        post_code
        website
        founders_name
        directors_name
        sector
        legal_structure
        registration_info
        no_of_employees
        description
        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        account_holder_name
        account_number
        bank_name
        branch_location
        identifier_code
        tax_payerId
        tax_identification_number
        license_permits {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        privacy_policy {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        terms_conditions {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
        status
        type
        roleId
        workflow {
          _id
          name
          components {
            _id
            name
            label
            description
            step
            status
          }
          organizations {
            _id
            org_name
            email
            contact
            country
            address_1st_line
            address_2nd_line
            city
            post_code
            website
            founders_name
            directors_name
            sector
            legal_structure
            registration_info
            no_of_employees
            description
            account_holder_name
            account_number
            bank_name
            branch_location
            identifier_code
            tax_payerId
            tax_identification_number
            status
            type
            roleId
            workflowId
            organizationWorkflowId
          }
          status
        }
        workflowId
        organizationWorkflow {
          _id
          name
          branch
          organizationId
          approvalFlow {
            _id
            userId
            name
            email
            level
            label
          }
          status
          createdAt
        }
        organizationWorkflowId
        branches {
          _id
          branch
        }
        settingConfiguration {
          _id
          name
          loan_details {
            loan_segment {
              personal_loan
              employee_loan
              mortgage_loan
              vehicle_loan
              education_loan
              loan_against_security
            }
            loan_amount {
              range_between {
                min
                max
                status
              }
              within_month_income
            }
          }
          employee_information {
            employee_status {
              full_time
              part_time
              contract_employee
            }
            annual_income {
              income_range
              status
            }
          }
          credit_information {
            credit_score {
              score_range {
                min
                max
                status
              }
              no_credit
            }
          }
          repayment_terms {
            repayment_schedule {
              monthly
              bi_monthly
              quarterly
              annual_payment
              user_defined {
                min
                max
                status
              }
            }
            repayment_method {
              direct_bank
              manual
              automatic
              bulk_payment
            }
          }
          organization_policies {
            eligibility_criteria {
              onWork_employee
              notice_period_employee
              probationary_employee
              onSite_employee
              work_from_home_employee
            }
          }
        }
      }
      org_name
      exp_years
      resign_submitted_date
      attendance_percentage
      probation_period
      contact_number
      joining_date
      loan_details {
        id
        orderId
        transactionId
        loan_type
        loan_amount
        interest_rate
        repayment_schedule
        repayment_method
      }
      repayment_details {
        orderId
        transactionId
        merchantId
        loan_type
        loan_amount
        interest_rate
        loan_process_date
      }
      employess_documents
      branch_location
      type
      isNew
      isLoanRaised
      loans {
        _id
        employee {
          _id
          employee_id
          first_name
          last_name
          email
          hashMail
          gender
          marital_status
          dob
          job_title
          profile
          organizationId
          org_name
          exp_years
          resign_submitted_date
          attendance_percentage
          probation_period
          contact_number
          joining_date
          employess_documents
          branch_location
          type
          isNew
          isLoanRaised
          status
          activeapplication
        }
        employeeId
        organizationId
        label
        applicationNo
        status
        stage
        loanType
        loanAmount
        tenure
        documents {
          _id
          name
          file
          title
          fileType
          size
          uploadedDate
          status
          application
        }
        isMove
        organizationWorkflowId
        workflowId
        createdAt
        loanFlowLogs {
          _id
          application
          title
          description
          label
          createdAt
        }
        ownerApprovalStatus {
          _id
          application
          label
          verifiedBy {
            _id
            firstName
            lastName
            email
            password
            status
            roleId
            country
            contact
            profile
            is_default
            reset_password_token
            type
          }
          status
          comments
          enable
          createdAt
          applicationNo
          updatedAt
        }
        organizationApprovalStatus {
          _id
          application
          level
          verifiedBy
          name
          status
          comments
          enable
          label
          createdAt
          applicationNo
          updatedAt
        }
        OrganizationWorkflow {
          _id
          name
          branch
          organizationId
          status
          createdAt
        }
        organizationLoanFlowLogs {
          _id
          title
          description
          isCompleted
          label
          updatedAt
          createdAt
        }
        loansCount
        previousLoanDueStatus
      }
      role {
        _id
        Role_Name
        panel_type
        Permissions {
          permission {
            _id
            label
            name
            panel_type
          }
          access_controll {
            create
            read
            edit
            delete
          }
        }
      }
      status
      activeapplication
    }
    total
  }
}
`

export const GET_ORGANIZATION_EMPLOYEE_DOCUMENT = gql`
  query GetOrganizationDocuments($getOrganizationDocumentsId: String) {
    getOrganizationDocuments(id: $getOrganizationDocumentsId) {
      _id
      name
      file
      fileType
      size
      uploadedDate
    }
  }
`

export const GET_DB_BACKUP_LIST = gql`
  query getDbBackupList($index: Int!, $limit: Int!, $search: String) {
    getDbBackupList(index: $index, limit: $limit, search: $search) {
      total
      page
      pageSize
      data {
        _id
        user {
          _id
          firstName
          lastName
          email
          status
        }
        size
        ipAddress
        name
        url
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_ALL_EMAIL_TEMPLATE = gql`
  query GetAllTemplate {
    getAllTemplate {
      _id
      name
      subject
      updatedAt
    }
  }
`

export const GET_SINGLE_EMAIL_TEMPLATE = gql`
  query GetTemplate($getTemplateId: ID) {
    getTemplate(id: $getTemplateId) {
      _id
      name
      subject
      template
      updatedAt
    }
  }
`

export const GET_NOTIFICATION_LIST = gql`
  query GetNotificationList {
    getNotificationList {
      ... on OrganizationNotificationList {
        _id
        applicationReceived
        applicationApproved
        applicationRejected
        paymentDue
        paymentOverdue
        paymentConfirmation
        loanStatusUpdate
        interestRateChange
        loanRenewalReminder
        newApplication
        preliminaryReview
        documentScrutiny
        creditEvaluation
        riskAssessment
        managementApproval
        loanDocumentVerify
        loanDisbursement
      }
      ... on OwnerUsersNotificationList {
        _id
        setupEmailNotification
        setupCopyToOrganizationMail
        deactivateRequestNotification
        deleteRequestNotification
        accountUpdate
        documentRequest
        loanBalanceUpdate
        earlyRepaymentConfirmation
        renewalApplicationReceived
        renewalApplicationApproved
        applicationReceived
        applicationApproved
        applicationRejected
        paymentDue
        paymentOverdue
        paymentConfirmation
        loanStatusUpdate
        interestRateChange
        loanRenewalReminder
        newApplication
        preliminaryReview
        documentScrutiny
        creditEvaluation
        riskAssessment
        managementApproval
        loanDocumentVerify
        loanDisbursement
      }
    }
  }
`

export const GET_NOTIFICATION_COUNT_BY_USER = gql`
query getNotificationCountByUser {
  getNotificationCountByUser
}
`

export const GET_ALL_NOTIFICATION_BY_USER = gql`
query getAllNotificationsByUser {
  getAllNotificationsByUser {
    _id
    title
    description
    mobileRedirect {
      info
      id
    }
    from {
      ... on Employee {
        sender: _id
        employee_id
        first_name
        last_name
        email
        profile
      }
      ... on OrgDetails {
        _id
        org_name
        email

        company_logo {
          _id
          name
          file

        }
      }
      ... on ownerUser {
        _id
        firstName
        lastName
        email
        profile
      }
    }
    to {
      ... on Employee {
        receiver: _id
        employee_id
        first_name
        last_name
        email
        profile
      }
      ... on OrgDetails {
        _id
        org_name
        email

        company_logo {
          _id
          name
          file
          fileType
          size
          uploadedDate
        }
      }
      ... on ownerUser {
        _id
        firstName
        lastName
        email
        profile
    
      }
    }
    createdAt
  }
}
`

export const GET_AUDIT_LOGS = gql`
  query GetLogDetails($filter: logFilter!) {
    getLogDetails(filter: $filter) {
      data {
        userId
        userName
        ip
        category
        browserName
        action
        status
        createdAt
        updatedAt
      }
      total
    }
  }
`

export const GET_ALL_OWNER_LIST = gql`
  query GetAllOwners {
    getAllOwners {
      _id
      firstName
    }
  }
`

//orgDashboad

export const GET_ORGANIZATION_DASHBOARD = gql`
query getOrganizationDashboardWeb {
  getOrganizationDashboard
}
`

// Profile
export const GET_USER_PROFILE = gql`
query Query {
  getUserProfile
}
`

export const GET_USER_PROFILE_ACTIVITY = gql`
query GetUserActivity {
  getUserActivity {
    lastLoggedIn
    lastLoan
  }
}
`

export const GET_BANNER = gql`
query getBanner {
  getBanner {
    status
    data
  }
}
`

export const EMPLOYEE_BULK_UPLOAD_URL = gql`
  query getEmployeesBulkUpload($type: BulkUploadOption) {
    getEmployeesBulkUpload(type: $type)
  }
`

export const LOAN_LOG_REPORT = gql`
  query queryReport($input: DateFilter) {
    queryReport(input: $input) {
      status
      message
    }
  }
`

export const GET_REPORTS = gql`
query GetReports {
  getReports {
    title
    label
  }
}
`

export const GET_ORGANIZATION_ACCOUNT_DEACTIVATION = gql`
query GetAllOrganizationAccountDeactivation {
  getAllOrganizationAccountDeactivation {
    _id
    user {
      _id
      org_name
      email
      contact
    }
    name
    type
    status
    activeEmployeeCount
    activeLoanRequest
    totalEmployeeCount
  }
}
`

export const GET_ALL_REPAYMENT_EMPLOYEES = gql`
query GetAllRepayementEmployee($getAllRepayementEmployeeId: ID!) {
  getAllRepayementEmployee(id: $getAllRepayementEmployeeId) {
    loanSanction {
      _id
      loanId
      application {
        _id
        employeeId
        organizationId
        label
        applicationNo
        status
        stage
        loanType
        loanAmount
        tenure
        documents {
          _id
          name
          file
          title
          fileType
          size
          uploadedDate
          status
          application
        }
        isMove
        organizationWorkflowId
        workflowId
        createdAt
      }
      applicationNo
      loanAmount
      loanProcessingAmount
      creditAmount
      loanTenure
      monthlyInstallmentAmount
      disbursementDate
      repaymentStartDate
      repaymentEndDate
      deductionFrequency
      deductionMethod
      outstandingBalance
      bankName
      bankAccNo
      ifscCode
      bankAddress
      transactionRefNo
      purposeOfLoan
      comments
      approvalStatus
      approvalDate
      approvalBy {
        _id
        firstName
        lastName
        email
        password
        status
        roleId
        country
        contact
        profile
        is_default
        reset_password_token
        type
      }
      attachment {
        name
        fileType
        url
      }
      status
    }
    loanId
    loanAmount
    tenure
    loanType
    repaymentDate
    paidStatus
    transactionRefNo
    employee {
      _id
      activeapplication
      email
    }
    organization {
      _id
      email
    }
  }
}
`

export const GET_ALL_PAID_LOAN_LIST = gql`
query getAllRepayementEmployeeData($getAllRepayementEmployeeId: ID!) {
  getAllRepayementEmployee(id: $getAllRepayementEmployeeId)
}
`

export const GET_REPAYMENT_LIST = gql`
query getAllRepayementListEmployee($getAllRepayementListEmployeeId: ID!) {
  getAllRepayementListEmployee(id: $getAllRepayementListEmployeeId)
}
`

export const GET_BULK_PAYMENT_ORGANIZATION_LIST = gql`
query GetBulkPaymentByOrganization {
  getBulkPaymentByOrganization {
    _id
    amount
    paidDate
    transactionRefNo
    verify
    userCounts
    repayments {
      employee {
        _id
      }
    }
    organization {
      _id
    }
  }
}
`

export const GET_BULK_PAYMENT_ADMIN_LIST = gql`
  query GetAllBulkPayment {
    getAllBulkPayment {
      bulkPayment {
        _id
        paidDate
        repayments {
          loanId
          loanAmount
          tenure
          loanType
          repaymentDate
          paidStatus
          transactionRefNo
          employee {
            _id
            activeapplication
            advanceSalaryLimit
          }
          loanSanction {
            _id
            application {
              _id
            }
            applicationNo
          }
        }
        userCounts
        verify
        transactionRefNo
        amount
        organization {
          _id
          account_holder_name
          account_number
        }
      }
      totalReceivedAmount
      totalPendingAmount
    }
  }
`

export const GET_SINGLE_BULK_PAYMENT_DETAIL = gql`
query GetBulkPayment($getBulkPaymentId: ID) {
  getBulkPayment(id: $getBulkPaymentId) {
    _id
    organization {
      _id
      org_name
      email
      contact
      country
      address_1st_line
      address_2nd_line
      city
      post_code
      website
      founders_name
      directors_name
      sector
      legal_structure
      registration_info
      no_of_employees
      description
      company_logo {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      account_holder_name
      account_number
      bank_name
      branch_location
      identifier_code
      tax_payerId
      tax_identification_number
      license_permits {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      privacy_policy {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      terms_conditions {
        _id
        name
        file
        fileType
        size
        uploadedDate
      }
      status
      type
      role {
        _id
        Role_Name
        panel_type
        Permissions {
          permission {
            _id
            label
            name
            panel_type
          }
          access_controll {
            create
            read
            edit
            delete
          }
        }
      }
      roleId
      workflow {
        _id
        name
        components {
          _id
          name
          label
          description
          step
          status
        }
        organizations {
          _id
          org_name
          email
          contact
          country
          address_1st_line
          address_2nd_line
          city
          post_code
          website
          founders_name
          directors_name
          sector
          legal_structure
          registration_info
          no_of_employees
          description
          account_holder_name
          account_number
          bank_name
          branch_location
          identifier_code
          tax_payerId
          tax_identification_number
          status
          type
          roleId
          workflowId
          organizationWorkflowId
          companyId
        }
        status
      }
      workflowId
      organizationWorkflow {
        _id
        name
        branch
        organizationId
        approvalFlow {
          _id
          userId
          name
          email
          level
          label
        }
        status
        createdAt
      }
      organizationWorkflowId
      branches {
        _id
        branch
      }
      settingConfiguration {
        _id
        name
        loan_details {
          loan_segment {
            personal_loan
            employee_loan
            mortgage_loan
            vehicle_loan
            education_loan
            loan_against_security
          }
          loan_amount {
            range_between {
              min
              max
              status
            }
            within_month_income
          }
        }
        employee_information {
          employee_status {
            full_time
            part_time
            contract_employee
          }
          annual_income {
            income_range
            status
          }
        }
        credit_information {
          credit_score {
            score_range {
              min
              max
              status
            }
            no_credit
          }
        }
        repayment_terms {
          repayment_schedule {
            monthly
            bi_monthly
            quarterly
            annual_payment
            user_defined {
              min
              max
              status
            }
          }
          repayment_method {
            direct_bank
            manual
            automatic
            bulk_payment
          }
          fees_charges {
            loan_processing_fee {
              status
              percentage
            }
            loan_late_payment {
              status
              percentage
              grace
            }
          }
        }
        organization_policies {
          eligibility_criteria {
            onWork_employee
            notice_period_employee
            probationary_employee
            onSite_employee
            work_from_home_employee
          }
        }
      }
      companyId
    }
    paidDate
    repayments {
      loanSanction {
        _id
        loanId
        application {
          _id
          employeeId
          organizationId
          label
          applicationNo
          status
          stage
          loanType
          loanAmount
          tenure
          documents {
            _id
            name
            file
            title
            fileType
            size
            uploadedDate
            status
            application
          }
          isMove
          organizationWorkflowId
          workflowId
          createdAt
          loanFlowLogs {
            _id
            application
            title
            description
            label
            createdAt
          }
          ownerApprovalStatus {
            _id
            application
            label
            verifiedBy {
              _id
              firstName
              lastName
              email
              password
              status
              roleId
              country
              contact
              profile
              is_default
              reset_password_token
              type
            }
            status
            comments
            enable
            createdAt
            applicationNo
            updatedAt
          }
          organizationApprovalStatus {
            _id
            application
            level
            verifiedBy
            name
            status
            comments
            enable
            label
            createdAt
            applicationNo
            updatedAt
          }
          OrganizationWorkflow {
            _id
            name
            branch
            organizationId
            status
            createdAt
          }
          organizationLoanFlowLogs {
            _id
            title
            description
            isCompleted
            label
            updatedAt
            createdAt
          }
          loansCount
          previousLoanDueStatus
        }
        applicationNo
        loanAmount
        loanProcessingAmount
        creditAmount
        loanTenure
        monthlyInstallmentAmount
        disbursementDate
        repaymentStartDate
        repaymentEndDate
        deductionFrequency
        deductionMethod
        outstandingBalance
        bankName
        bankAccNo
        ifscCode
        bankAddress
        transactionRefNo
        purposeOfLoan
        comments
        approvalStatus
        approvalDate
        approvalBy {
          _id
          firstName
          lastName
          email
          password
          status
          roleId
          country
          contact
          profile
          is_default
          reset_password_token
          type
        }
        attachment {
          name
          fileType
          url
        }
        status
      }
      loanId
      employee {
        _id
        employee_id
        first_name
        last_name
        email
        hashMail
        gender
        marital_status
        workAddress
        permanentAddress
        presentAddress
        dob
        job_title
        manager {
          id
          email
        }
        employmentType
        department
        profile
        organizationId
        org_name
        exp_years
        resign_submitted_date
        attendance_percentage
        probation_period
        contact_number
        joining_date
        loan_details {
          id
          orderId
          transactionId
          loan_type
          loan_amount
          interest_rate
          repayment_schedule
          repayment_method
        }
        repayment_details {
          orderId
          transactionId
          merchantId
          loan_type
          loan_amount
          interest_rate
          loan_process_date
        }
        employess_documents
        branch_location
        compensation
        bankAccounts
        employeeKYC
        type
        isNew
        isLoanRaised
        loans {
          _id
          employeeId
          organizationId
          label
          applicationNo
          status
          stage
          loanType
          loanAmount
          tenure
          isMove
          organizationWorkflowId
          workflowId
          createdAt
          loansCount
          previousLoanDueStatus
        }
        status
        employmentStatus
        activeapplication
        advanceSalaryLimit
      }
      loanAmount
      tenure
      loanType
      repaymentDate
      paidStatus
      transactionRefNo
    }
    userCounts
    verify
    transactionRefNo
    amount
  }
}
`

export const GET_ORGANIZATION_REPORT = gql`
  query getAllRepaymentByOrganization($input: DateRepaymentFilter) {
    getAllRepaymentByOrganization(input: $input)
  }
`

export const ADMIN_DASHBOARD = gql`
query getAdminDefaultDashboard {
  getAdminDefaultDashboard
}
`

export const ADMIN_ANALYTICS_DASHBOARD = gql`
query getAdminAnalyticDashboard {
  getAdminAnalyticDashboard
}
`

export const ORGANIZATION_DASHBOARD = gql`
query getOrgDefaultDashboard {
  getOrgDefaultDashboard
}
`

export const EMPLOYEE_DASHBOARD = gql`
query Query {
  getEmployeeDefaultDashboard
}
`

export const GLOBAL_SEARCH = gql`
query globalSearch($query: String!) {
  globalSearch(query: $query)
}
`

export const GET_EMPLOYEE_VERIFIED_DOCUMENT = gql`
query getEmployeeVerifiedDocuments($employeeId: ID!) {
  getEmployeeVerifiedDocuments(employeeId: $employeeId)
}
`

export const GET_DISBURSED_LOAN_DATA = gql`
query GetLoanSanctionWithApplication($getLoanSanctionWithApplicationId: ID) {
  getLoanSanctionWithApplication(id: $getLoanSanctionWithApplicationId) {
    _id
    loanId
    applicationNo
    loanAmount
    loanProcessingAmount
    creditAmount
    loanTenure
    monthlyInstallmentAmount
    disbursementDate
    repaymentStartDate
    repaymentEndDate
    deductionFrequency
    deductionMethod
    outstandingBalance
    bankName
    bankAccNo
    ifscCode
    bankAddress
    accountType
    transactionRefNo
    purposeOfLoan
    comments
    approvalStatus
    approvalDate
    approvalBy {
      _id
      firstName
      lastName
      email
      password
      status
      roleId
      country
      contact
      profile
      is_default
      reset_password_token
      type
    }
    attachment {
      name
      fileType
      url
    }
    status
    previousLoanStatus
    application {
      _id
    }
    employee {
      _id
      email
    }
  }
}
`

export const GET_LOAN_DATA_DASHBOARD = gql`
query getEmployeeLoanSanctionCounts($employeeId: ID!) {
  getEmployeeLoanSanctionCounts(employeeId: $employeeId)
}
`
