import { IPremissions, PERMISSION_MODULE } from "src/interface"
import { useSelector } from "src/hooks"

export default function usePermissionHook(permission?: PERMISSION_MODULE) {
  const userDetails = useSelector(state => state.userData.userDetails)

  const getAccess = userDetails.role.Permissions.find(
    (f: IPremissions) => f.permission.label === permission
  ) as IPremissions

  const moduleAccess = getAccess ? Object.values(getAccess.access_controll).some((s: boolean) => s) : false

  const allModuleGeneralAccess = userDetails.role.Permissions.reduce(
    (a, b) => Object.assign(a, { [b.permission.label]: b.access_controll }),
    {}
  )

  const getModulePermission = (permission: PERMISSION_MODULE) => {
    return userDetails.role.Permissions.find((f: IPremissions) => f.permission.label === permission)
  }

  const getModuleGeneralAccess = (permission: PERMISSION_MODULE) => {
    const access = userDetails.role.Permissions.find(
      (f: IPremissions) => f.permission.label === permission
    )?.access_controll
    if (access) {
      return Object.values(access).some(s => s)
    }
  }
  
  

  return {
    moduleAccess,
    permissions: getAccess?.permission,
    accessControl: getAccess?.access_controll,
    allModuleGeneralAccess,
    getModulePermission: getModulePermission,
    getModuleGeneralAccess: getModuleGeneralAccess,
    panelType: userDetails.role.panel_type,
  }
}
