import { Box, BoxProps, styled } from "@mui/material"

const FilterSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
}))

const LeftSection = styled("div")(() => ({
  flex: "1 1 0",
}))

const RightSection = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  button: {
    marginRight: "10px",
    "&:last-child": {
      marginRight: "0px",
    },
  },
}))

export default function FilterContainer({
  leftElement,
  rightElement,
  styleProps,
}: {
  leftElement: JSX.Element
  rightElement: JSX.Element
  styleProps?: BoxProps
}) {
  return (
    <FilterSection {...styleProps}>
      <LeftSection>{leftElement}</LeftSection>
      <RightSection>{rightElement}</RightSection>
    </FilterSection>
  )
}
