import { BoxProps } from "@mui/material"
import { TableUsersStyle } from "src/assets/styles/table"
import { ProfileAvatar } from "../common/ProfilePic"

interface ITableDetailsCard {
  name: any
  description?: any
  first_name?: string
  last_name?: string
  img?: any
  styleProps?: BoxProps
}

export default function TableDetailsCard(props: ITableDetailsCard) {
  const { name, description, img, first_name, last_name, styleProps } = props

  return (
    <TableUsersStyle {...styleProps}>
      <div className="pic">
        <ProfileAvatar src={img} firstName={first_name} lastName={last_name} size={true}/>
      </div>
      <div className="details">
        <h5>{name}</h5>
        {description && <p>{description}</p>}
      </div>
    </TableUsersStyle>
  )
}
