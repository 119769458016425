import { ButtonRow, CMButton, CommonModal } from "src/assets/styles/custom";
import { useEffect, useSelector } from "src/hooks";

interface IModalComponent {
  header?: string;
  title?: string;
  description?: any;
  icon?: any;
  buttonText?: string;
  closeButtonShow?: boolean;
  children?: JSX.Element | JSX.Element[]
  submitHandler?: () => void;
  closeHandler: () => void;
  resetHandler?: () => void;
}

export default function ModalComponent(props: IModalComponent) {
  const disabledLoader = useSelector(
    (state) => state.commonData.disabledLoader
  );
  const {
    header,
    title,
    description,
    icon,
    buttonText,
    closeButtonShow,
    children,
    submitHandler,
    closeHandler,
    resetHandler,
  } = props;

  useEffect(() => {
    return () => {
      if (resetHandler) {
        setTimeout(() => resetHandler(), 500);
      }
    };
  }, [resetHandler]);

  return (
    <>
      <CommonModal>
        {header && <h3>{header}</h3>}
        {icon && <div className="icon">{icon}</div>}
        {title && <h3>{title}</h3>}
        {description && <p>{description}</p>}
        {children && children}
        {closeButtonShow ?
          <CMButton variant="contained" onClick={closeHandler}>
            Ok
          </CMButton>
          :
          <ButtonRow>
            <CMButton variant="contained" color="inherit" onClick={closeHandler}>
              Close
            </CMButton>
            <CMButton
              disabled={disabledLoader}
              variant="contained"
              onClick={submitHandler}
            >
              {buttonText ? buttonText : 'Proceed!'}
            </CMButton>
          </ButtonRow>
        }
      </CommonModal>
    </>
  );
}
