import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import MenuList from "@mui/material/MenuList"
import {
  Dropdown,
  DropdownButton,
  DropdownContainer,
  DropdownInner,
  DropdownItem,
  DropdownSearch,
  FormError,
} from "src/assets/styles/form"
import { useEffect, useRef, useState } from "src/hooks"
import { IChangeEvent, IDropdownOptions, ISelectDropdown } from "src/interface"

interface IState {
  visible: boolean
  filterData: IDropdownOptions[]
  searchValue: string
}

type ISelectDropdownProps = ISelectDropdown & {
  selected: any
}

export default function SelectDropdown(props: ISelectDropdownProps) {
  const { name, selected, onChange, options, placeholder, enableSearch, error, disabled, view } = props
  const [state, setState] = useState<IState>({
    visible: false,
    filterData: [],
    searchValue: "",
  })
  const { visible, searchValue, filterData } = state

  const anchorRef = useRef<HTMLButtonElement & HTMLInputElement>(null)

  const handleSearch = (e: IChangeEvent) => {
    const { name, value } = e.target
    setState(state => ({ ...state, [name]: value }))
  }

  const handleToggle = () => {
    setState(state => ({ ...state, visible: !state.visible }))
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setState(state => ({ ...state, visible: false }))
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      setState(state => ({ ...state, visible: false }))
    } else if (event.key === "Escape") {
      setState(state => ({ ...state, visible: false }))
    }
  }

  useEffect(() => {
    if (visible) {
      anchorRef.current!.focus()
    } else {
      anchorRef.current!.blur()
    }
  }, [visible])

  useEffect(() => {
    const timer = setTimeout(() => {
      const filterData = options.filter((f: IDropdownOptions) =>
        f?.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      )
      setState(state => ({ ...state, filterData }))
    }, 250)

    return () => clearInterval(timer)
  }, [searchValue, options])

  useEffect(() => {
    if (enableSearch) {
      const findLabel = filterData.find((f: IDropdownOptions) => f?.value === selected)?.label
      if (findLabel) {
        setState(state => ({ ...state, searchValue: findLabel }))
      }
    }
  }, [enableSearch, selected, filterData])

  return (
    <>
      <DropdownContainer>
        {enableSearch ? (
          <DropdownSearch
            view={view}
            error={(error as string)?.length > 0}
            aria-expanded={visible ? "true" : undefined}
          >
            <input
              ref={anchorRef}
              type="text"
              placeholder={placeholder ? placeholder : "Select"}
              name="searchValue"
              value={searchValue}
              onChange={handleSearch}
              onClick={() => setState(state => ({ ...state, visible: true, filterData: options }))}
              autoComplete="off"
              disabled={disabled || view}
            />
            <span className="higlight_shadow"></span>
          </DropdownSearch>
        ) : (
          <DropdownButton
            ref={anchorRef}
            aria-controls={visible ? "composition-menu" : undefined}
            aria-expanded={visible ? "true" : undefined}
            aria-haspopup="true"
            onClick={disabled || view ? () => null : handleToggle}
            error={(error as string)?.length > 0}
            view={view}
            className={disabled ? `disabled` : ``}
          >
            {selected ? (
              <span
                className="value selected"
                title={filterData.find((f: IDropdownOptions) => f?.value === selected)?.label}
              >
                {filterData.find((f: IDropdownOptions) => f?.value === selected)?.label}
              </span>
            ) : (
              <span className="value placeholder">{placeholder ? placeholder : "Select"}</span>
            )}
            <span className="higlight_shadow"></span>
          </DropdownButton>
        )}
        <Dropdown open={visible} anchorEl={anchorRef.current} placement="bottom-start" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-start" ? "center top" : "center bottom",
              }}
            >
              <DropdownInner>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={visible} onKeyDown={handleListKeyDown}>
                    {filterData.length > 0 ? (
                      filterData.map((item: IDropdownOptions, i: number) => {
                        return (
                          <DropdownItem
                            selected={selected === item?.value}
                            key={i}
                            onClick={(e: Event | React.SyntheticEvent) => {
                              if (enableSearch) {
                                setState(state => ({ ...state, searchValue: item?.label as string }))
                              }
                              onChange({ name, value: item?.value })
                              handleClose(e)
                            }}
                          >
                            {item?.label}
                          </DropdownItem>
                        )
                      })
                    ) : (
                      <span className="no_data_found">No options found</span>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </DropdownInner>
            </Grow>
          )}
        </Dropdown>
      </DropdownContainer>
      {error && <FormError>{error}</FormError>}
    </>
  )
}
