import { GlobalStyles } from "@mui/material"

export default function GlobalStyle() {
  return (
    <GlobalStyles
      styles={theme => ({
        html: {
           fontSize: `15px !important`,
          [theme.breakpoints.down(1600)]: {
            fontSize: `13px !important`,
          },
          [theme.breakpoints.down("md")]: {
            fontSize: `11px !important`,
          },
        },
        body: {
          fontFamily: theme.typography.fontFamily,
          background: `${theme.palette.grey["100"]} !important`,
          overflow: "hidden",
        },
        button: {
          fontFamily: theme.typography.fontFamily,
          cursor: "pointer",
          "&:focus": { outline: "none" },
          "&:active": { outline: "none" },
        },
        span: { fontFamily: theme.typography.fontFamily },
        input: {
          fontFamily: theme.typography.fontFamily,
          fontSize: `0.8125rem !important`,
          "&:focus": {
            outline: "none",
          },
          "&::placeholder": {
            color: theme.palette.grey["400"],
          },
          "&:-moz-placeholder": {
            color: theme.palette.grey["400"],
          },
        },
        "*": { boxSizing: "border-box" },
        ".MuiBackdrop-root": {
          backgroundColor: "rgb(0 0 0/0.7) !important",
        },
        ".mt-0": {
          marginTop: "0px !important",
        },
        ".mb-0": {
          marginBottom: "0px !important",
        },
        ".m-0": {
          margin: "0px !important",
        },
      })}
    />
  )
}
