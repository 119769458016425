import { ListSubheader, styled } from "@mui/material"
import { IconDots } from "@tabler/icons"

type NavGroup = {
  navlabel?: boolean
  subheader?: string
}

interface ItemType {
  item: NavGroup
  hideMenu: string | boolean
}

const NavGroup = ({ item, hideMenu }: ItemType) => {
  const ListSubheaderStyle = styled((props: any) => <ListSubheader disableSticky {...props} />)(({ theme }) => ({
    ...theme.typography.overline,
    fontWeight: "600",
    color: theme.palette.grey["600"],
    lineHeight: "26px",
    padding: "15px 5px",
    fontSize: "0.8125rem",
  }))

  return <ListSubheaderStyle>{hideMenu ? <IconDots size="14" /> : item?.subheader}</ListSubheaderStyle>
}

export default NavGroup
