import { CheckOutlined, RestartAltOutlined, ScheduleOutlined, DoDisturbAlt } from "@mui/icons-material"
import { StatusStyle } from "src/assets/styles/custom"
import { STATUS } from "src/interface"

export default function StatusTag({ status }: { status: STATUS }) {
  const getIcon = () => {
    switch (status) {
      case STATUS.Active:
        return <CheckOutlined />
      case STATUS.Verified:
        return <CheckOutlined />
      case STATUS.Pending:
        return <ScheduleOutlined />
      case STATUS.Revoked:
        return <RestartAltOutlined />
      case STATUS.InActive:
        return <DoDisturbAlt />
      case STATUS.Success:
      case STATUS.Approved:
        return <CheckOutlined />
      case STATUS.Failure:
      case STATUS.Rejected:
        return <RestartAltOutlined />
      default:
        break
    }
  }

  return (
    <StatusStyle status={status}>
      {getIcon()}
      {status}
    </StatusStyle>
  )
}
