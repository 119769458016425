import { useState } from "react"
import { IFileResponse } from "../interface"

interface IUseFileDownloadHook {
  downloader: boolean
  error: string
}

export default function useFilesDownloadHook() {
  const [state, setState] = useState<IUseFileDownloadHook>({
    downloader: false,
    error: "",
  })
  const { downloader, error } = state
  const downloadHandler = (file: IFileResponse) => {
    const { name, url } = file
    if (url) {
      setState(state => ({ ...state, loader: true }))
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          setTimeout(() => {
            setState(state => ({ ...state, loader: false }))
          }, 500)
          const blobURL = URL.createObjectURL(blob)
          const a = document.createElement("a") as any
          a.href = blobURL
          a.style = "display: none"

          if (name && name.length) a.download = name
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
        .catch(error => setState(state => ({ ...state, error })))
    } else {
      setState(state => ({ ...state, error: "Resource URL not provided! You need to provide one" }))
    }
  }

  return { downloader, error, downloadHandler }
}
