const statusIndicatorColor = {
  all: "123 123 123",
  Active: "85 208 136",
  Pending: "231 175 33",
  Revoked: "233 80 80",
  Accepted: "85 208 136",
  Draft: "93 135 255",
  Invited: "248 205 95",
  Paid: "85 208 136",
  Unpaid: "231 175 33",
  Overdue: "233 80 80",
  Open: "85 208 136",
  Inprogress: "231 175 33",
  Closed: "233 80 80",
  Approved: "85 208 136",
  Rejected: "233 80 80",
  InActive:"233 80 80",
  Verified: "85 208 136",
  Success: "85 208 136",
  Failure: "233 80 80",
  Deactivate: "255 174 31",
  Deactivated: "233 80 80",
  Deletion: "233 80 80",
  Bulk_Payment: "255 255 255"
}

export { statusIndicatorColor }
