import { Box, MenuItem, MenuItemProps, Popper, PopperProps, Switch, SwitchProps, styled } from "@mui/material"
import { borderRadius, formFieldHeight, CenterFlex } from "./custom"
import { IFormInputStyles } from "./interface"

export const DropdownContainer = styled("div")(() => ({
  position: "relative",
}))

export const DropdownSearch = styled("div")<{ error?: boolean; view?: boolean }>(({ theme, error, view }) => ({
  position: "relative",
  width: "100%",
  ...(view && {
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    paddingBottom: "5px",
  }),
  input: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${error ? theme.palette.error.main : view ? "transparent" : theme.palette.grey["500"]}`,
    borderRadius: borderRadius,
    height: view ? "auto" : formFieldHeight,
    padding: view ? "0px" : "10px 40px 10px 15px",
    background: "unset",
    width: "100%",
    position: "relative",
    zIndex: "1",
    color: view ? theme.palette.grey["600"] : theme.palette.text.primary,
    transitionDuration: "0.3s",
    "&:focus + .higlight_shadow": {
      boxShadow: `0px 0px 0px 4px ${error ? theme.palette.error.light : theme.palette.grey["200"]}`,
    },
    ...(!view && {
      "&:disabled": {
        background: `url('data:image/svg+xml,<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12 14V18" stroke="${theme.palette.grey[
          "600"
        ].replace(
          "#",
          "%23"
        )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M6 10V8C6 7.65929 6.0284 7.32521 6.08296 7M18 10V8C18 4.68629 15.3137 2 12 2C10.208 2 8.59942 2.78563 7.5 4.03126" stroke="${theme.palette.grey[
          "600"
        ].replace(
          "#",
          "%23"
        )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M11 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15" stroke="${theme.palette.grey[
          "600"
        ].replace("#", "%23")}" stroke-width="1.5" stroke-linecap="round"></path></g></svg>')`,
        backgroundColor: theme.palette.grey["200"],
        backgroundRepeat: "no-repeat",
        backgroundSize: "18px 18px",
        backgroundPositionX: "calc(100% - 15px)",
        backgroundPositionY: "center",
        transitionDuration: "unset",
        "&:after": {
          content: "unset",
        },
      },
    }),
  },
  ...(!view && {
    "&:after": {
      content: '""',
      background: `url('data:image/svg+xml,<svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 0.5L6 5.5L1 0.5" stroke="%237B7B7B" stroke-linecap="round" stroke-linejoin="round"/></svg>')`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "11px 11px",
      transform: "translateY(-50%) rotate(0deg)",
      display: "inline-block",
      position: "absolute",
      width: "11px",
      height: "11px",
      right: "15px",
      top: "50%",
      transitionDuration: "0.3s",
    },
  }),
  '&[aria-expanded="true"]': {
    "&:after": {
      transform: "translateY(-50%) rotate(180deg)",
    },
  },
  ".higlight_shadow": {
    content: '""',
    display: "inline-block",
    borderRadius: borderRadius,
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    zIndex: "0",
    transitionDuration: "0.3s",
  },
}))

export const DropdownButton = styled("button")<{ multiple?: boolean; error?: boolean; view?: boolean }>(
  ({ theme, multiple, error, view }) => ({
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${error ? theme.palette.error.main : view ? "transparent" : theme.palette.grey["500"]}`,
    borderRadius: borderRadius,
    transitionDuration: "0.3s",
    color: view ? theme.palette.grey["600"] : theme.palette.text.primary,
    ...(view && {
      borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    }),
    ...(multiple
      ? {
        padding: view ? "0px" : "0px 40px 0px 15px",
        minHeight: formFieldHeight,
        maxHeight: "max-content",
        flexWrap: "wrap",
      }
      : {
        height: view ? "auto" : formFieldHeight,
        padding: view ? "0px 0px 5px 0px" : "10px 40px 10px 15px",
      }),
    width: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontSize: "0.8125rem",
    ...(!view && {
      "&:after": {
        content: '""',
        background: `url('data:image/svg+xml,<svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 0.5L6 5.5L1 0.5" stroke="%237B7B7B" stroke-linecap="round" stroke-linejoin="round"/></svg>')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "11px 11px",
        transform: "translateY(-50%) rotate(0deg)",
        display: "inline-block",
        position: "absolute",
        width: "11px",
        height: "11px",
        right: "15px",
        top: "50%",
        transitionDuration: "0.3s",
      },
    }),
    '&[aria-expanded="true"]': {
      boxShadow: `0px 0px 0px 4px ${error ? theme.palette.error.light : theme.palette.grey["200"]}`,
      "&:after": {
        transform: "translateY(-50%) rotate(180deg)",
      },
    },
    ".value": {
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      "&.placeholder": {
        color: theme.palette.grey["400"],
      },
    },
    ...(!view && {
      "&.disabled": {
        background: `url('data:image/svg+xml,<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12 14V18" stroke="${theme.palette.grey[
          "600"
        ].replace(
          "#",
          "%23"
        )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M6 10V8C6 7.65929 6.0284 7.32521 6.08296 7M18 10V8C18 4.68629 15.3137 2 12 2C10.208 2 8.59942 2.78563 7.5 4.03126" stroke="${theme.palette.grey[
          "600"
        ].replace(
          "#",
          "%23"
        )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M11 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15" stroke="${theme.palette.grey[
          "600"
        ].replace("#", "%23")}" stroke-width="1.5" stroke-linecap="round"></path></g></svg>')`,
        backgroundColor: theme.palette.grey["200"],
        backgroundRepeat: "no-repeat",
        backgroundSize: "18px 18px",
        backgroundPositionX: "calc(100% - 15px)",
        backgroundPositionY: "center",
        transitionDuration: "unset",
        color: theme.palette.grey["600"],
        cursor: "unset",
        "&:after": {
          content: "unset",
        },
      },
    }),
  })
)

export const Dropdown = styled((props: PopperProps) => (
  <Popper nonce={undefined} onResize={undefined} onResizeCapture={undefined} {...props} />
))(() => ({
  zIndex: 5,
  width: "100%",
}))

export const DropdownInner = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  borderRadius: borderRadius,
  width: "100%",
  
  // minWidth: "225px",
  maxHeight: "225px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.grey["500"],
    borderRadius: "10px",
  },
  ".no_data_found": {
    display: "flex",
    justifyContent: "center",
    padding: "5px 15px",
    color: theme.palette.grey["600"],
  },
}))

export const DropdownItem = styled((props: MenuItemProps) => <MenuItem {...props} />)(() => ({
  fontSize: "0.8125rem",
  whiteSpace: "pre-wrap",
}))

export const MultiDropdownItem = styled("div")(() => ({
  fontSize: "0.8125rem",
  padding: "6px 16px",
  display: "flex",
  alignItems: "center",
}))

export const SelectedChips = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "0.75rem",
  padding: "5px 12px",
  borderRadius: "25px",
  fontWeight: "500",
  color: theme.palette.primary.main,
  background: theme.palette.secondary.main,
  marginRight: "5px",
  marginBottom: "5px",
  marginTop: "5px",
}))

export const FormGroup = styled("div")(() => ({
  marginBottom: "20px",
}))

export const FormLabel = styled("label")(() => ({
  display: "block",
  fontSize: "0.8125rem",
  marginBottom: "7px",
  lineHeight: "normal",
  fontWeight: "500",
}))

export const FormInputStyle = styled("div")<IFormInputStyles>(({ theme, error, view, prefixEl, suffixEl }) => ({
  width: "100%",
  position: "relative",
  height: view ? "auto" : formFieldHeight,
  ...(view && {
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    paddingBottom: "5px",
  }),
  input: {
    position: "relative",
    width: "100%",
    height: "100%",
    border: `1px solid ${error ? theme.palette.error.main : view ? "transparent" : theme.palette.grey["500"]}`,
    borderRadius: borderRadius,
    padding: view ? "0px" : "10px 15px",
    ...(prefixEl && {
      paddingLeft: view ? "25px" : "40px",
    }),
    ...(suffixEl && {
      paddingRight: "45px",
    }),
    transitionDuration: "0.3s",
    color: view ? theme.palette.grey["600"] : theme.palette.text.primary,
    zIndex: "1",
    "&:focus": {
      outline: "none",
    },
    "&:focus + .higlight_shadow": {
      boxShadow: `0px 0px 0px 4px ${error ? theme.palette.error.light : theme.palette.grey["200"]}`,
    },
    ...(!view && {
      "&:disabled": {
        background: `url('data:image/svg+xml,<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12 14V18" stroke="${theme.palette.grey[
          "600"
        ].replace(
          "#",
          "%23"
        )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M6 10V8C6 7.65929 6.0284 7.32521 6.08296 7M18 10V8C18 4.68629 15.3137 2 12 2C10.208 2 8.59942 2.78563 7.5 4.03126" stroke="${theme.palette.grey[
          "600"
        ].replace(
          "#",
          "%23"
        )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M11 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15" stroke="${theme.palette.grey[
          "600"
        ].replace("#", "%23")}" stroke-width="1.5" stroke-linecap="round"></path></g></svg>')`,
        backgroundColor: theme.palette.grey["200"],
        backgroundRepeat: "no-repeat",
        backgroundSize: "18px 18px",
        backgroundPositionX: "calc(100% - 15px)",
        backgroundPositionY: "center",
        transitionDuration: "unset",
      },
    }),
    ...(view && {
      background: theme.palette.background.paper,
    }),
  },
  ".higlight_shadow": {
    content: '""',
    display: "inline-block",
    borderRadius: borderRadius,
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    zIndex: "0",
    transitionDuration: "0.3s",
  },
  ...((prefixEl || suffixEl) && {
    ".prefix, .suffix": {
      position: "absolute",
      zIndex: "2",
      top: view ? "calc(50% - 3px)" : "50%",
      transform: "translateY(-50%)",
      color: theme.palette.grey["600"],
      svg: {
        path: {
          fill: theme.palette.grey["600"],
        },
      },
    },
  }),
  ...(prefixEl && {
    ".prefix": {
      left: view ? "0px" : "10px",
    },
  }),
  ...(suffixEl && {
    ".suffix": {
      right: "10px",
    },
  }),
}))

export const FormError = styled("div")(({ theme }) => ({
  fontSize: "0.6875rem",
  color: theme.palette.error.main,
  marginTop: "5px",
  lineHeight: "normal",
}))

export const FormHint = styled("p")(({ theme }) => ({
  fontSize: "0.625rem",
  marginTop: "4px",
  marginBottom: "0",
  color: theme.palette.grey["600"],
}))

export const MultiSelectDropdownStyle = styled("div")(({ theme }) => ({
  ".MuiOutlinedInput-root": {
    "&.Mui-focused": {
      ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
      },
    },
    borderRadius: borderRadius,
    width: "100%",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey["500"],
    },
  },
}))

export const CheckboxInput = styled(Box)(({ theme }) => ({
  display: "inline-block",
  label: {
    display: "flex !important",
    input: {
      visibility: "hidden",
      position: "absolute",
    },
    ".checked": {
      position: "relative",
      display: "flex",
      width: "15px",
      height: "15px",
      borderRadius: "4px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.grey["500"],
      transitionDuration: "0.2s",
      "&:after": {
        content: '""',
        display: "inline-block",
        position: "absolute",
        left: "50%",
        top: "46%",
        width: "5px",
        height: "8px",
        border: "solid white",
        borderWidth: "0 2px 2px 0",
        transform: "translate(-50%, -50%) rotate(40deg)",
        transitionDuration: "0.2s",
        opacity: "0",
      },
    },
    "input:checked ~ .checked": {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      "&:after": {
        opacity: "1",
      },
    },
  },
}))

export const CheckboxGroup = styled("span")(() => ({
  display: "flex",
  alignItems: "center",
  ".label": {
    marginLeft: "10px",
    cursor: "pointer"
  },
}))

export const PermissionSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    background: theme.palette.grey["400"],
    opacity: "0.5",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}))

export const ToggleSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props}/>
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "&.cancel-toggle": {
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: "rgb(219 68 55)",
        },
      },
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled": {
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));


export const FileUploadContainer = styled("div")(() => ({}))

export const UploadInputField = styled("label")<{ error?: boolean }>(({ theme, error }) => ({
  ...CenterFlex(),
  width: "100%",
  borderWidth: "2px",
  borderStyle: "dashed",
  position: "relative",
  borderColor: error ? theme.palette.error.main : theme.palette.grey["500"],
  borderRadius: borderRadius,
  padding: "25px",
  input: {
    visibility: "hidden",
    position: "absolute",
    left: "-200%",
  },
  ".uploadContent": {
    textAlign: "center",
    color: theme.palette.grey["600"],
    ".label": {
      fontWeight: "500",
    },
    ".icon, .label": {
      color: error ? theme.palette.error.main : theme.palette.primary.main,
    },
    ".hint": {
      fontSize: "0.6875rem",
    },
  },
}))

export const UploadFilesView = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: theme.palette.grey["200"],
  borderRadius: borderRadius,
  padding: "10px 15px",
  marginBottom: "10px",
  ".doc": {
    display: "flex",
    alignItems: "center",
    svg: {
      marginRight: "15px",
      color: theme.palette.grey["600"],
    },
  },
  ".delete_btn": {
    ...CenterFlex(),
    border: "1px solid",
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    transitionDuration: "0.3S",
    svg: {
      width: "13px",
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
}))

export const UploadImgView = styled("div")(({ theme }) => ({
  borderWidth: "1px",
  borderColor: theme.palette.grey["500"],
  borderStyle: "solid",
  borderRadius: borderRadius,
  height: "200px",
  position: "relative",
  padding: "5px",
  marginBottom: "10px",
  ".img": {
    width: "100%",
    height: "100%",
    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      objectPosition: "center",
      display: "block",
      borderRadius: borderRadius,
    },
  },
  ".overlay": {
    background: "linear-gradient(to bottom,rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 50%,rgba(0,0,0,0.65) 100%)",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: borderRadius,
    width: "calc(100% - 10px)",
    height: "calc(100% - 10px)",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    zIndex: "1",
    color: "#ffffff",
    padding: "10px 20px",
    ".delete_btn": {
      ...CenterFlex(),
      border: "none",
      background: theme.palette.error.main,
      color: "#ffffff",
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      transitionDuration: "0.3S",
      boxShadow: "0px 5px 15px rgb(0 0 0/0.5)",
      svg: {
        width: "15px",
      },
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  },
}))

export const ToggleList = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
  gap: "12px",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".cancel-label": {
    color: "#d02318",
  },
}))

export const FormFieldStyle = styled("div")<any>(
  ({ theme, errors, view, prefixEl, suffixEl, password }) => `
  width: 100%;
  position: relative;
  height: auto;
  textarea{
    resize: vertical;
  }
  .react-datepicker-wrapper{
    width:100%;
    input{

      border-radius:8px;
    }
  }
  input, textarea {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid ${errors ? theme.palette.error.main : view ? "transparent" : theme.palette.grey["500"]};
    border-radius: ${borderRadius}px;
    padding: ${password ? (view ? "0px" : "10px 70px 10px 15px") : view ? "0px" : "10px 15px"};
    color: ${view ? theme.palette.grey["600"] : theme.palette.text.primary};
    ${!view &&
    `
        &:disabled  {
          background: url('data:image/svg+xml,<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12 14V18" stroke="${theme.palette.grey[
      "300"
    ].replace(
      "#",
      "%23"
    )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M6 10V8C6 7.65929 6.0284 7.32521 6.08296 7M18 10V8C18 4.68629 15.3137 2 12 2C10.208 2 8.59942 2.78563 7.5 4.03126" stroke="${theme.palette.grey[
      "300"
    ].replace(
      "#",
      "%23"
    )}" stroke-width="1.5" stroke-linecap="round"></path><path d="M11 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H15" stroke="${theme.palette.grey[
      "300"
    ].replace("#", "%23")}" stroke-width="1.5" stroke-linecap="round"></path></g></svg>');
          background-color: ${theme.palette.grey["100"]};
          background-repeat: no-repeat;
          background-size: 18px 18px;
          background-position-x: calc(100% - 15px);
          background-position-y: center;
          transition-duration: unset;
        },
      `
    },
    
      ${view &&
    `
        border-bottom: 1px solid ${theme.palette.grey["300"]};
        padding-bottom: 5px;
        background: ${theme.palette.background.paper};
        `
    },
    }

  ${(prefixEl || suffixEl) &&
    `
    .prefix, .suffix {
      position: absolute;
      zIndex: 2;
      top: ${view ? "calc(50% - 3px)" : "50%"};
      transform: translateY(-50%);
      color: ${theme.palette.grey["300"]};
      svg: {
        path: {
          fill: ${theme.palette.grey["300"]};
        },
      },
    },
  `
    }

  ${prefixEl &&
    `
    padding-left: ${view ? "25px" : "40px"};
    .prefix {
      left: view ? 0px : 10px;
    }
    `
    },
  ${suffixEl &&
    `
    padding-right: 45px;
    .suffix {
        right: 10px;
    }
  `
    };

`
)
