import {
  LazyQueryHookOptions,
  OperationVariables,
  MutationHookOptions,
  DefaultContext,
  ApolloCache,
} from "@apollo/client"
import {
  corporationTypes,
  countryList,
  customer_details_tab,
  customerStatusFilter,
  employeeIncome,
  employeeStatus,
  feesChargesOptions,
  interestRateOptions,
  loanTypes,
  numberOfEmployees,
  organization_details_tab,
  organizationList,
  orgManagmentFilter,
  paymentFrequencies,
  paymentMethodsOptions,
  rowsPerPageList,
  userManagmentFilters,
  workFlowFilter,
  approval_workflow_tab,
  customerRequestFilter,
  chipColors,
  approval_loan_tab,
  profile_sidebar,
  customerRequestCountFilter,

  //Temp data
  loanTransaction,
  loanData,
  loanStatusFilter,
  paymentHistory,

  userNameFilter,
  activityFilter,
  auditActionFilter,
  auditBrowserFilter
} from "./options"
import {
  UsersBreadCrumb,
  RolesBreadCrumb,
  OrgBreadcrumb,
  permissionBreadcrumb,
  customerBreadcrumb,
  customerDetailBreadcrumb,
  employeeBreadcrumb,
  employeeDetailBreadcrumb,
  loanBreadcrumb,
  loanTransactionBreadcrumb,
  loanRequestBreadcrumb,
  loanApprovalBreadcrumb,
  faqBreadcrumb,
  settingBreadcrumb,
  auditBreadcrumb,
  reportBreadcrumb,
  backupBreadcrumb,
  notificationBreadcrumb,
  emailTemplateBreadcrumb,
  profileBreadcrumb,
  viewLoanRequestBreadcrumb,
  ViewOrgBreadcrumb,
  orgLoanApprovalBreadcrumb,
  loanApprovalListBreadcrumb,
  orgLoanApprovalListBreadcrumb,
  createOrgLoanApprovalBreadcrumb,
  createLoanApprovalBreadcrumb,
  updateLoanApprovalBreadcrumb,
  viewSettingBreadcrumb,
  createSettingBreadcrumb,
  updateSettingBreadcrumb
} from "./pagesBreadCrumb"
import {
  customersColumns,
  loanRequestColumn,
  organizationColumns,
  organizationEmployessColumns,
  organizationRolesColumns,
  permissionColumns,
  usersColumns,
  loanRequestColumns,
  loanTransactionColumn,
  loanColumn,
  paymentHistoryColumn,
  repaymentColumn,
  approveLoanListColumn,
  approveLoanDocsColumn,
  BackupColumn,
  loanTransactionBulkPaymentColumn,
  loanTransactionAdminBulkPaymentColumn,
  repaymentAdminLoanColumn,
  organizationReportsColumn,
  profitRatioColumn
} from "./tableHeaders"

const queryOptions: LazyQueryHookOptions<any, OperationVariables> = {
  fetchPolicy: "network-only",
  errorPolicy: "all",
}
const mutationOptions: MutationHookOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> = {
  fetchPolicy: "network-only",
  errorPolicy: "all",
}

const fileType = [
  "application/pdf",
  "doc",
  "docx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "pdf",
]
const inValidFileType = ["application/x-zip-compressed"]
const imageType = ["image/png", "image/jpg", "image/webp", "image/jpeg", "image/jfif"]

const fadeAnimation = "animate__animated animate__faster animate__fadeIn"

const pagination = {
  page: 1,
  limit: 10,
}

export {
  mutationOptions,
  queryOptions,
  organizationList,
  rowsPerPageList,
  userManagmentFilters,
  countryList,

  UsersBreadCrumb,
  RolesBreadCrumb,
  OrgBreadcrumb,
  auditBreadcrumb,
  reportBreadcrumb,
  backupBreadcrumb,
  customerBreadcrumb,
  customerDetailBreadcrumb,
  employeeBreadcrumb,
  employeeDetailBreadcrumb,
  loanBreadcrumb,
  loanApprovalBreadcrumb,
  loanTransactionBreadcrumb,
  loanRequestBreadcrumb,
  faqBreadcrumb,
  settingBreadcrumb,
  permissionBreadcrumb,
  notificationBreadcrumb,
  emailTemplateBreadcrumb,
  profileBreadcrumb,
  viewLoanRequestBreadcrumb,
  ViewOrgBreadcrumb,
  orgLoanApprovalBreadcrumb,
  loanApprovalListBreadcrumb,
  orgLoanApprovalListBreadcrumb,
  createOrgLoanApprovalBreadcrumb,
  createLoanApprovalBreadcrumb,
  updateLoanApprovalBreadcrumb,
  viewSettingBreadcrumb,
  createSettingBreadcrumb,
  updateSettingBreadcrumb,

  numberOfEmployees,
  imageType,
  inValidFileType,
  fileType,
  orgManagmentFilter,
  customerRequestFilter,
  workFlowFilter,
  organization_details_tab,
  approval_workflow_tab,
  corporationTypes,
  loanTypes,
  interestRateOptions,
  employeeStatus,
  employeeIncome,
  paymentFrequencies,
  paymentMethodsOptions,
  feesChargesOptions,
  customer_details_tab,
  customerStatusFilter,
  fadeAnimation,
  pagination,
  chipColors,
  approval_loan_tab,
  profile_sidebar,
  customerRequestCountFilter,

  //Temp data
  loanTransaction,
  loanData,
  loanStatusFilter,
  paymentHistory,


  userNameFilter,
  activityFilter,
  auditActionFilter,
  auditBrowserFilter
}

export {
  usersColumns,
  organizationColumns,
  permissionColumns,
  organizationEmployessColumns,
  organizationRolesColumns,
  customersColumns,
  loanRequestColumn,
  loanRequestColumns,
  loanTransactionColumn,
  loanColumn,
  paymentHistoryColumn,
  repaymentColumn,
  approveLoanListColumn,
  approveLoanDocsColumn,
  BackupColumn,
  loanTransactionBulkPaymentColumn,
  loanTransactionAdminBulkPaymentColumn,
  repaymentAdminLoanColumn,
  organizationReportsColumn,
  profitRatioColumn
}
