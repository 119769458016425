import { createSlice } from "@reduxjs/toolkit"
import { IUserManagementPayload, IUserDetails } from "../../interface"
import { userDetailsState } from "src/utils"

interface StateType {
  userManagement: IUserManagementPayload
  is_authenticated: undefined | boolean
  userDetails: IUserDetails
}

const initialState = {
  is_authenticated: undefined,
  userDetails: userDetailsState,
} as StateType

export const UserDataSlice = createSlice({
  name: "user_data",
  initialState,
  reducers: {
    setIsAuthentication: (state: StateType, action) => {
      state.is_authenticated = action.payload
    },
    setUserDetails: (state: StateType, action) => {
      state.userDetails = action.payload
    },
  },
})

export const { setIsAuthentication, setUserDetails } = UserDataSlice.actions

export default UserDataSlice.reducer
