export const onlyNumber = /^[0-9\b]+$/
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/i
export const noAlph = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/
export const noAlphWitComma = /^([0-9]+\,?[0-9]*|\,[0-9]+)$/
export const onlyAlphabets = /^[a-zA-Z\s,]+$/
export const validPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/
export const phoneNumberRegex = /^[0-9]{10,15}$/
export const websiteRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w .-]*)*\/?$/
export const taxIdRegex = /^[0-9]{9}$/
export const tenDigitNumberRegex = /^[0-9]{10}$/
export const alphNumeric = /^[a-zA-Z0-9_]*$/

const postalCodePatterns = {
  IN: /^[1-9][0-9]{5}$/, // India: 6 digits
  US: /^[0-9]{5}(-[0-9]{4})?$/, // USA: 5 digits, optionally followed by a dash and 4 digits
  UK: /^[A-Za-z0-9]{2,4} [A-Za-z0-9]{3}$/, // UK: Formats like SW1A 1AA
  DE: /^[0-9]{5}$/, // Germany: 5 digits
  FR: /^[0-9]{5}$/, // France: 5 digits
  CA: /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/, // Canada: A1A 1A1
  AU: /^[0-9]{4}$/, // Australia: 4 digits
  JP: /^[0-9]{3}-[0-9]{4}$/, // Japan: 3 digits - 4 digits
  KR: /^[0-9]{5}$/, // South Korea: 5 digits
  CN: /^[0-9]{6}$/, // China: 6 digits
  BR: /^[0-9]{5}-[0-9]{3}$/, // Brazil: 5 digits - 3 digits
  NL: /^[0-9]{4} ?[A-Za-z]{2}$/, // Netherlands: 4 digits followed by 2 letters
  ES: /^[0-9]{5}$/, // Spain: 5 digits
  IT: /^[0-9]{5}$/, // Italy: 5 digits
  RU: /^[0-9]{6}$/, // Russia: 6 digits
  ZA: /^[0-9]{4}$/, // South Africa: 4 digits
  SG: /^[0-9]{6}$/, // Singapore: 6 digits
  MY: /^[0-9]{5}$/, // Malaysia: 5 digits
  PH: /^[0-9]{4}$/, // Philippines: 4 digits
  NP: /^[0-9]{5}$/, // Nepal: 5 digits
  BD: /^[0-9]{4}$/, // Bangladesh: 4 digits
  // Add more country patterns as needed
};

const validateAccountNumber = (accountNumber: any) => {
  const MIN_LENGTH = 8;
  const MAX_LENGTH = 12;

  if (accountNumber.length === 0) {
    return "Account number can't be empty";
  }

  if (!/^[0-9]+$/.test(accountNumber)) {
    return "Account number must be numeric";
  }

  if (accountNumber.length < MIN_LENGTH || accountNumber.length > MAX_LENGTH) {
    return `Account number must be between ${MIN_LENGTH} and ${MAX_LENGTH} digits`;
  }

  return "";
};

export const maxCharacterAllowed = (value: string, characters: number) => {
  return value.length <= characters ? "" : `Max characters reached. Max ${characters} Characters allowed`
}

export const onlyAlphabetsAllowed = (value: string, name: string) => {
  return onlyAlphabets.test(value) ? "" : `${name} should contain only alphabets`
}

export const validateEmailId = (value: string) => {
  return emailRegex.test(value) ? "" : "Enter valid email"
}

export const validatePhoneNumber = (value: string) => {
  return phoneNumberRegex.test(value) ? "" : "Enter a valid contact number"
}

export const validateWebsite = (value: string) => {
  return websiteRegex.test(value) ? "" : "Enter a valid website URL"
}

export const validateTaxId = (value: string) => {
  return taxIdRegex.test(value) ? "" : "Enter a valid tax ID"
}

export const passwordValidator = (password: string) => {
  let passwordError = ""
  if (!validPassword.test(password)) {
    passwordError =
      "Must contains atleast one uppercase,lowercase,numbers and password must contains  4 or more characters"
  } else {
    passwordError = ""
  }

  return passwordError
}

export const validator = (type: string, data: any) => {
  const error: any = {}

  switch (type) {
    case "login":
      error.email = data.email.length > 0 ? validateEmailId(data.email) : "Enter the email to proceed"
      error.password = data.password.length > 0 ? "" : "Enter the password"

      return error
    case "forget_password":
      error.email = data.email.length > 0 ? validateEmailId(data.email) : "Enter the email to proceed"

      return error

    case "change_password":
      error.oldPassword =
        data.oldPassword.length > 0 ? passwordValidator(data.oldPassword) : "Current password required"
      error.newPassword =
        data.newPassword.length > 0 ? passwordValidator(data.newPassword) : "New password required"

      error.confirmPassword =
        data.confirmPassword.length > 0
          ? data.newPassword === data.confirmPassword
            ? passwordValidator(data.confirmPassword)
            : "Password's did not match"
          : "Confirm password required"

      return error
    case "user_management":
      error.firstName =
        data.firstName.length > 0 ? maxCharacterAllowed(data.firstName, 20) : "First name is required"
      error.lastName = data.lastName ? maxCharacterAllowed(data.lastName, 20) : ""
      error.email = data.email.length > 0 ? validateEmailId(data.email) : "Email is required"
      error.role = data.role.length > 0 ? "" : "Select the role"
      error.contact = data.contact.length > 0
        ? (data.contact.includes(",") ? data.contact.split(",") : [data.contact]).some(
          (value: string) => {
            const isInvalidLength = value.length < 10;
            const hasConsecutiveDigits = /(0000000000|1111111111|2222222222|3333333333|4444444444|5555555555|6666666666|7777777777|8888888888|9999999999)/.test(value);

            return isInvalidLength || hasConsecutiveDigits;
          }
        )
          ? "Enter a valid 10-digit mobile number without consecutive digits"
          : ""
        : "Contact can't be empty";
      error.country = data.country.length > 0 ? "" : "You must choose the country"
      error.profile = data.profile.length > 0 ? "" : "Profile can't be empty"


      return error
    case "role_management":
      error.Role_Name = data.Role_Name.length > 0 ? "" : "Role name is required"

      return error
    case "faq_management":
      error.question = data.question.length > 0 ? "" : "Question is required"
      error.answer = data.answer.length > 0 ? "" : "Answer is required"

      return error
    case "email_template":
      error.name = data.name.length > 0 ? "" : "Template name can't be empty"
      error.subject = data.subject.length > 0 ? "" : "Subject is required"
      error.template = data.template.length > 0 ? "" : "Template can't be empty"

      return error
    case "create_org":
      error.org_name =
        data.org_name.length > 0 ? maxCharacterAllowed(data.org_name, 30) : "Organization name can't be empty"
      error.email = data.email.length > 0 ? validateEmailId(data.email) : "Email can't be empty"
      error.companyId = data.companyId.length > 0 ? "" : "Organization Id can't be empty"

      return error
    case "create_organization":
      // Company Info
      error.companyId = data.companyId.length > 0 ? "" : "Organization Id can't be empty"
      error.org_name =
        data.org_name.length > 0 ? maxCharacterAllowed(data.org_name, 32) : "Organization name can't be empty"
      error.email = data.email.length > 0 ? validateEmailId(data.email) : "Email can't be empty"
      error.contact = data.contact.length > 0
        ? (data.contact.includes(",") ? data.contact.split(",") : [data.contact]).some(
          (value: string) => {
            const isInvalidLength = value.length < 10;
            const hasConsecutiveDigits = /(0000000000|1111111111|2222222222|3333333333|4444444444|5555555555|6666666666|7777777777|8888888888|9999999999)/.test(value);

            return isInvalidLength || hasConsecutiveDigits;
          }
        )
          ? "Enter a valid 10-digit mobile number without consecutive digits"
          : ""
        : "Contact can't be empty";

      error.country = data.country.length > 0 ? "" : "Country can't be empty"

      if (data.post_code.length > 0) {
        const countryPattern = postalCodePatterns[data.country as keyof typeof postalCodePatterns];
        if (countryPattern) {
          error.post_code = countryPattern.test(data.post_code)
            ? ""
            : `Invalid postal code format for the selected country`;
        } else {
          error.post_code = maxCharacterAllowed(data.post_code, 8);
        }
      } else {
        error.post_code = "Post code can't be empty";
      }

      // error.post_code =
      //   data.post_code.length > 0 ? maxCharacterAllowed(data.post_code, 8) : "Post code can't be empty"

      error.city = data.city.length > 0 ? "" : "City can't be empty"
      error.address_1st_line =
        data.address_1st_line.length > 0
          ? maxCharacterAllowed(data.address_1st_line, 100)
          : "Address (1st line) can't be empty"
      error.address_2nd_line =
        data.address_2nd_line.length > 0
          ? maxCharacterAllowed(data.address_2nd_line, 100)
          : "Address (2nd line) can't be empty"
      error.website = data.website.length > 0 ? validateWebsite(data.website) : "Website can't be empty"

      // Company Details
      error.founders_name =
        data.founders_name.length > 0
          ? onlyAlphabetsAllowed(data.founders_name, "Founder Name")
          : "Founder's name can't be empty"
      error.directors_name =
        data.directors_name.length > 0
          ? onlyAlphabetsAllowed(data.directors_name, "Director Name")
          : "Director's name can't be empty"
      error.sector = data.sector.length > 0 ? "" : "Industry or sector can't be empty"
      error.legal_structure = data.legal_structure.length > 0 ? "" : "Legal structure can't be empty"
      error.registration_info = data.registration_info?.length > 0 ? "" : "Registration info can't be empty"
      error.no_of_employees = data.no_of_employees.length > 0 ? "" : "Number of employees can't be empty"
      error.description =
        data.description.length > 0 ? maxCharacterAllowed(data.description, 500) : "Description can't be empty"
      error.company_logo = data.company_logo.length > 0 ? "" : "Company logo can't be empty"

      // Bank Information
      error.account_holder_name =
        data.account_holder_name.length > 0
          ? onlyAlphabetsAllowed(data.account_holder_name, "Account Holder Name")
          : "Account holder name can't be empty"
      error.account_number = validateAccountNumber(data.account_number);
      error.bank_name =
        data.bank_name.length > 0 ? onlyAlphabetsAllowed(data.bank_name, "Bank Name") : "Bank name can't be empty"
      error.branch_location =
        data.branch_location.length > 0
          ? onlyAlphabetsAllowed(data.branch_location, "Branch Location")
          : "Branch location can't be empty"
      error.identifier_code = data.identifier_code.length > 0 ? "" : "Identifier code can't be empty"
      error.tax_payerId =
        data.tax_payerId.length > 0 ? "" : "Tax payer ID can't be empty"
      error.tax_identification_number =
        data.tax_identification_number.length > 0
          ? ""
          : "Tax identification number can't be empty"

      //Document
      error.terms_conditions = data.terms_conditions.length > 0 ? "" : "Terms and conditions can't be empty"
      error.privacy_policy = data.privacy_policy.length > 0 ? "" : "Privacy policy can't be empty"
      error.license_permits = data.license_permits.length > 0 ? "" : "License and permits can't be empty"

      return error
    case "reset_password":
      error.resetPassword = data.resetPassword.length > 0 ? "" : "Password field can't be empty"
      error.confirmPassword =
        data.confirmPassword.length > 0
          ? data.resetPassword === data.confirmPassword
            ? ""
            : "Password doesn't match."
          : "Confirm password can't be empty"

      return error
    default:
      break
  }
}
