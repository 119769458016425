import { CRUD } from "src/interface"

export const userManagementState = {
  type: CRUD.create,
  visible: false,
  data: {
    id: "",
    username: "",
    role: "",
  },
}

export const rolesManagementState = {
  type: CRUD.create,
  visible: false,
  data: {
    id: "",
    role: "",
  },
}

export const organizationReduxState = {
  type: CRUD.create,
  visible: false,
  data: {
    id: "",
    organization: "",
  },
}

export const loanReduxState = {
  type: CRUD.create,
  visible: false,
  data: {
    id: "",
    username: "",
    role: "",
  },
}

export const approvalWorkflowReduxState = {
  type: CRUD.create,
  visible: false,
  data: {
    id: "",
    approvalWorkflow: "",
  },
}

export const settingsReduxState = {
  type: CRUD.create,
  visible: false,
  data: {
    id: "",
    settingConfig: "",
  },
}