import { Navigate, Outlet } from "react-router-dom"
import usePermissionHook from "src/hooks/usePermissionHook"
import { PAGE_URL } from "src/routes"
import { PERMISSION_MODULE } from "src/interface"
import Spinner from "src/views/spinner/Spinner"

export default function AccessRestrictRouting({
  routeAccess,
  module,
}: {
  routeAccess: boolean
  module: PERMISSION_MODULE
}) {
  const { moduleAccess } = usePermissionHook(module)
  
  return routeAccess ? moduleAccess ? <Outlet /> : <Navigate to={PAGE_URL.dashboard} /> : <Spinner />
}
