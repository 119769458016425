import { getAuthToken } from "src/utils";
import { queryOptions } from "src/resources";
import { useEffect, useLazyQuery, useState } from "src/hooks";

//Redux
import {
  dispatch,
  setIsAuthentication,
  setUserDetails,
  setAllRoles,
} from "src/redux";

//API's
import { GET_USER_LOGIN_DATA, GET_ROLE_DETAILS } from "src/api";
import Spinner from "./views/spinner/Spinner";

export default function Main({ children }: { children: JSX.Element }) {
  const authToken = getAuthToken();

  const [state, setState] = useState<any>(true)

  //API's
  const [getUserLoginData] = useLazyQuery(GET_USER_LOGIN_DATA, queryOptions);
  const [getRoleDetails] = useLazyQuery(GET_ROLE_DETAILS, queryOptions);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (authToken) {
        try {
          const response = await getUserLoginData({
            fetchPolicy: "no-cache",
          });
          const { data } = response;
          if (data?.getUserLoginData) {
            setState(false)
            dispatch(setUserDetails(data.getUserLoginData));
            const rolesResponse = await getRoleDetails({
              variables: { panelType: data.getUserLoginData.role.panel_type },
            });
            const { data: roles } = rolesResponse;
            if (roles.getRoleDetails) {
              dispatch(setAllRoles(roles.getRoleDetails));
            }
            dispatch(setIsAuthentication(true));
          }
        } catch (err: any) {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("refresh_token");
          dispatch(setIsAuthentication(false));
        }
      }
    };

    fetchUserDetails();
  }, [authToken, getUserLoginData, getRoleDetails]);

  if (state) {

    return <Spinner />
  }

  return children;
}
