import { useMediaQuery, Box, Drawer, useTheme, styled, Divider } from "@mui/material";
import SidebarItems from "./SidebarItems";
import { dispatch, hoverSidebar, toggleMobileSidebar } from "src/redux";
import { ImgTag, Scrollbar, TableDetailsCard } from "src/components";
import { useLocation, useNavigate, useSelector } from "src/hooks";
import { PAGE_URL } from "src/routes";
import placeholder from "../../assets/images/profile/user_placeholder.jpg"
import logo from "../../assets/images/logos/logo2.svg"
import { CMButton } from "src/assets/styles/custom";
import { ProfileLogoStyle } from "src/assets/styles/table";


interface ISideBarContainer {
  $toggleWidth: number;
  $isCollapse: boolean;
}

const SideBarContainer = styled("div") <ISideBarContainer>`
  width: ${(props) => props.$toggleWidth}px;
  flex-shrink: 0;
  ${(props) => props.$isCollapse && `position: absolute;`}
`;

const LogoSection = styled("div")`
  padding-inline: 15px;
  text-align: center;
  cursor: pointer;
  a {
    margin-bottom: 15px;
  }
`;

const SidebarContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: "wrap",
  alignContent: "space-between",
  height: '100vh',
  width: 270,
  padding: "20px",
}));

const Sidebar: React.FC<{ getBannerStatus: any }> = ({ getBannerStatus }) => {
  const userDetails = useSelector((state) => state.userData.userDetails);

  const splitName = userDetails?.org_name?.split(" ")


  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const customizer = useSelector((state) => state.customizer);
  const theme = useTheme();
  const location = useLocation()
  const navigate = useNavigate()

  const toggleWidth =
    customizer.isCollapse && !customizer.isSidebarHover
      ? customizer.MiniSidebarWidth
      : customizer.SidebarWidth;

  const onHoverEnter = () => {
    if (customizer.isCollapse) {
      dispatch(hoverSidebar(true));
    }
  };

  const onHoverLeave = () => {
    dispatch(hoverSidebar(false));
  };

  if (lgUp) {
    return (
      <SideBarContainer
        $isCollapse={customizer.isCollapse}
        $toggleWidth={toggleWidth}
      >
        <Drawer
          open
          anchor="left"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          variant="permanent"
          PaperProps={{
            sx: {
              transition: theme.transitions.create("width", {
                duration: theme.transitions.duration.shortest,
              }),
              width: toggleWidth,
              borderRight: `1px dashed ${theme.palette.grey["500"]}`,
              borderLeft: "none",
              display: "flex",
              flexDirection: "column",
              marginTop: getBannerStatus ? "50px" : 0,
              zIndex: 1,
              overflowY: "visible"
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <LogoSection onClick={() => navigate('/')}>
              {userDetails.type === "Owner" || userDetails.type === "owner_Users" ? (
                <img src={logo} alt="logo" width={170} />
              ) : (
                <>
                  <ProfileLogoStyle>
                    <div className="pic">
                      <ImgTag src={userDetails?.company_logo ? userDetails?.company_logo[0]?.file : userDetails?.profile ? userDetails?.profile : placeholder} />
                    </div>
                    <div className="details">
                      <h5>{userDetails?.org_name?.toUpperCase()}</h5>
                    </div>
                  </ProfileLogoStyle>
                </>
              )}

              <Divider variant="middle" sx={{ borderColor: "#bec8d0" }} />

            </LogoSection>


            {location.pathname === PAGE_URL.profile ?
              <>
                <SidebarContainer>
                  <TableDetailsCard
                    {...{
                      img: userDetails.profile,
                      name: userDetails.firstName,
                      description: userDetails.type,
                      first_name: splitName?.[0],
                      last_name: splitName?.[1],
                    }}
                  />

                  <Box sx={{ flexGrow: 1 }}></Box>

                  <CMButton variant="contained" color="primary" fullWidth onClick={() => navigate('/')}>
                    Back to Portal
                  </CMButton>
                </SidebarContainer>
              </>
              :
              <Scrollbar
                sx={{
                  flexGrow: 1,
                  height: "calc(100% - 125px)",
                }}
              >
                <SidebarItems />
              </Scrollbar>
            }
          </Box>
        </Drawer>
      </SideBarContainer>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={customizer.isMobileSidebar}
      onClose={() => dispatch(toggleMobileSidebar())}
      variant="temporary"
      PaperProps={{
        sx: {
          width: customizer.SidebarWidth,
          border: "0 !important",
          boxShadow: (theme) => theme.shadows[8],
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box px={2} py={2} onClick={() => navigate('/')}>
        {userDetails.type === "Owner" || userDetails.type === "owner_Users" ? (
          <img src={logo} alt="logo" width={170} />
        ) : (
          <>
            <ProfileLogoStyle>
              <div className="pic">
                <ImgTag src={userDetails?.company_logo ? userDetails?.company_logo[0]?.file : userDetails?.profile ? userDetails?.profile : placeholder} />
              </div>
              <div className="details">
                <h5>{userDetails?.org_name?.toUpperCase()}</h5>
              </div>
            </ProfileLogoStyle>
          </>
        )}
      </Box>
      <Scrollbar sx={{ flexGrow: 1, overflowY: "auto" }}>
        {location.pathname === PAGE_URL.profile ?
          <>
            <SidebarContainer>
              <TableDetailsCard
                {...{
                  img: userDetails.profile,
                  name: userDetails.firstName,
                  description: userDetails.type,
                  first_name: splitName?.[0],
                  last_name: splitName?.[1],
                }}
              />

              <Box sx={{ flexGrow: 1 }}></Box>

              <CMButton variant="contained" color="primary" fullWidth onClick={() => navigate('/')}>
                Back to Portal
              </CMButton>
            </SidebarContainer>
          </>
          :
          <SidebarItems />
        }
      </Scrollbar>
    </Drawer>
  );
};

export default Sidebar;
