import { useState } from "react"
import { Box, styled } from "@mui/material"

import { CMDialog } from "src/templates"
import { ProfilePic } from "src/components"
import { setIsAuthentication, setUserDetails, dispatch } from "src/redux"
import { MyProfileModal } from "src/assets/styles/custom"
import { LogoutOutlined, PersonOutline } from "@mui/icons-material"
import { userDetailsState } from "src/utils"
import { useMutation } from "@apollo/client"
import { mutationOptions } from "src/resources"
import { LOGOUT } from "src/api"
import { useNavigate, useToastController } from "src/hooks"
import { PAGE_URL } from "src/routes"

const NavProfile = styled("div")(({ theme }) => ({
  cursor: "pointer",
  borderRadius: "50%",
  transitionDuration: "0.3s",
  "&:hover": {
    boxShadow: `0px 0px 0px 6px ${theme.palette.secondary.main}`,
  },
}))

const Profile = () => {
  const [profile, setprofile] = useState(false)
  const navigate = useNavigate()
  const [logout] = useMutation(LOGOUT, mutationOptions)
  const { triggerAfter, triggerBefore } = useToastController()

  const closeHandler = () => setprofile(false)

  const logoutHandler = async () => {
    triggerBefore()
    const response = await logout()
    const { data } = response
    if (data.logout) {
      triggerAfter()
      localStorage.removeItem("auth_token")
      localStorage.removeItem("refresh_token");
      dispatch(setIsAuthentication(false))
      dispatch(setUserDetails(userDetailsState))
      closeHandler()
    }
  }

  return (
    <Box width={36}>
      <NavProfile onClick={() => setprofile(true)}>
        <ProfilePic size="small" />
      </NavProfile>

      <CMDialog open={profile} onClick={closeHandler} maxWidth="xs" enableclosebtn="true" blurbackdrop="true">
        <MyProfileModal>
          <button onClick={() => navigate(PAGE_URL.profile)}>
            <span className="icon">
              <PersonOutline />
            </span>
            <span className="title">Profile</span>
          </button>
          <button onClick={logoutHandler}>
            <span className="icon">
              <LogoutOutlined />
            </span>
            <span className="title">Logout</span>
          </button>
        </MyProfileModal>
      </CMDialog>
    </Box>
  )
}

export default Profile
