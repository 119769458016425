import {
  AccountBalanceOutlined,
  ArticleOutlined,
  Assignment,
  AssignmentIndOutlined,
  BusinessOutlined,
  Check,
  Close,
  CreditScoreOutlined,
  Description,
  ListAlt,
  Payment,
  PaymentsOutlined,
  Person,
  PortraitRounded,
  RestartAltOutlined,
  TextSnippetOutlined,
  ExitToAppOutlined,
  GavelOutlined,
  HelpOutline,
  HomeOutlined,
  SecurityOutlined,
  SettingsAccessibilityOutlined
} from "@mui/icons-material"

import { } from '@mui/icons-material';

import {
  CUS_DETAILS_TAB,
  ORG_DETAILS_TAB,
  ORG_STATUS,
  PAYMENT_STATUS,
  STATUS,
  APPROVE_WORKFLOW_TAB,
  LOAN_REQUEST,
  LOAN_STATUS,
  LOAN_TAB,
  AUDIT_TAB,
  AUDIT_ACTION_STATUS,
  AUDIT_BROWSER_STATUS,
  PROFILE_SIDEBAR,
} from "src/interface"

export const organizationList = [
  {
    label: "Umbrella Corporation",
    value: "umbrellaCorporation",
  },
  {
    label: "Acme Corporation (Looney Tunes)",
    value: "acmeCorporation",
  },
]

/* ----------------------------------------------*/
/* -------------- Status Filter -----------------*/
/* ----------------------------------------------*/
export const userManagmentFilters = [
  {
    label: "All",
    value: STATUS.All,
  },
  {
    label: "Active",
    value: STATUS.Active,
  },
  {
    label: "Pending",
    value: STATUS.Pending,
  },
  {
    label: "Revoked",
    value: STATUS.Revoked,
  },
]
export const customerManagmentFilters = [
  {
    label: "All",
    value: STATUS.All,
  },
  {
    label: "Active",
    value: STATUS.Active,
  },
  {
    label: "Pending",
    value: STATUS.Pending,
  },
  {
    label: "InActive",
    value: STATUS.InActive,
  },
]

export const loanManageFilter = [
  {
    label: "All",
    value: STATUS.All,
  },
  {
    label: "Paid",
    value: STATUS.Paid,
  },
  {
    label: "Open",
    value: STATUS.Open,
  },
  {
    label: "Closed",
    value: STATUS.Closed,
  },
]

export const orgManagmentFilter = [
  {
    label: "All",
    value: ORG_STATUS.All,
  },
  {
    label: "Accepted",
    value: ORG_STATUS.Accepted,
  },
  {
    label: "Draft",
    value: ORG_STATUS.Draft,
  },
  {
    label: "Invited",
    value: ORG_STATUS.Invited,
  },
  {
    label: "Deactivated",
    value: ORG_STATUS.Deactivated,
  }
]

export const workFlowFilter = [
  {
    label: "Active",
    value: APPROVE_WORKFLOW_TAB.Active,
  },
  {
    label: "Inactive",
    value: APPROVE_WORKFLOW_TAB.Inactive,
  },
]

export const countryList = [
  { label: "India", value: "IN" },
  { label: "United States", value: "US" },
  { label: "United Kingdom", value: "UK" },
  { label: "Germany", value: "DE" },
  { label: "France", value: "FR" },
  { label: "Canada", value: "CA" },
  { label: "Australia", value: "AU" },
  { label: "Japan", value: "JP" },
  { label: "South Korea", value: "KR" },
  { label: "China", value: "CN" },
  { label: "Brazil", value: "BR" },
  { label: "Netherlands", value: "NL" },
  { label: "Spain", value: "ES" },
  { label: "Italy", value: "IT" },
  { label: "Russia", value: "RU" },
  { label: "South Africa", value: "ZA" },
  { label: "Singapore", value: "SG" },
  { label: "Malaysia", value: "MY" },
  { label: "Philippines", value: "PH" },
  { label: "Nepal", value: "NP" },
  { label: "Bangladesh", value: "BD" },
]

export const numberOfEmployees = [
  { label: "1-300", value: "1-300" },
  { label: "301-500", value: "301-500" },
  { label: "501-700", value: "501-700" },
  { label: "700-1000+", value: "700-1000+" },
]

export const chipColors = [
  { background: "#FFEBEE", text: "#D32F2F" },
  { background: "#E3F2FD", text: "#1976D2" },
  { background: "#E8F5E9", text: "#388E3C" },
  { background: "#FFF3E0", text: "#F57C00" },
  { background: "#F3E5F5", text: "#7B1FA2" },
  { background: "#E0F7FA", text: "#00796B" },
  { background: "#FFCDD2", text: "#C2185B" },
]

export const userNameFilter = [
  {
    label: "All",
    value: 'all',
  },
  {
    label: "Muthu pandi",
    value: "muthu",
  },
  {
    label: "Ruban",
    value: "ruban",
  },
  {
    label: "Nirmal",
    value: "nirmal",
  },
  {
    label: "Gowthaman",
    value: "gowthaman",
  },
]

export const activityFilter = [
  {
    label: "All",
    value: 'all',
  },
  {
    label: "User Management",
    value: AUDIT_TAB.User_Management,
  },
  {
    label: "Organization Management",
    value: AUDIT_TAB.Organization_Management,
  },
  {
    label: "Customer Management",
    value: AUDIT_TAB.Customer_Management,
  },
  {
    label: "Advances",
    value: AUDIT_TAB.Loan_Management,
  },
  {
    label: "Workflow Management",
    value: AUDIT_TAB.Workflow_Management,
  },
  {
    label: "Settings & Configurations",
    value: AUDIT_TAB.Settings_Configuration_Management,
  },
  {
    label: "Reports",
    value: AUDIT_TAB.Reports_Management,
  },
  {
    label: "Backups",
    value: AUDIT_TAB.Backup_Management,
  },
  {
    label: "Notifications",
    value: AUDIT_TAB.Notification_Management,
  },
  {
    label: "Login",
    value: AUDIT_TAB.Login_Management,
  },
  {
    label: "Roles",
    value: AUDIT_TAB.Role_Management,
  },
  {
    label: "FAQ",
    value: AUDIT_TAB.FAQ_Management,
  },
]

export const auditActionFilter = [
  {
    label: "All",
    value: AUDIT_ACTION_STATUS.All,
  },
  {
    label: "Login",
    value: AUDIT_ACTION_STATUS.Login,
  },
  {
    label: "Create",
    value: AUDIT_ACTION_STATUS.Create,
  },
  {
    label: "Update",
    value: AUDIT_ACTION_STATUS.Update,
  },
  {
    label: "Delete",
    value: AUDIT_ACTION_STATUS.Delete,
  },
  {
    label: "Logout",
    value: AUDIT_ACTION_STATUS.Logout,
  },
]

export const auditBrowserFilter = [
  {
    label: "All",
    value: AUDIT_BROWSER_STATUS.All,
  },
  {
    label: "Chrome",
    value: AUDIT_BROWSER_STATUS.Chrome,
  },
  {
    label: "Safari",
    value: AUDIT_BROWSER_STATUS.Safari,
  },
  {
    label: "Mozilla",
    value: AUDIT_BROWSER_STATUS.Mozilla,
  },
  {
    label: "Microsoft edge",
    value: AUDIT_BROWSER_STATUS.Microsoft_edge,
  },
  {
    label: "Opera",
    value: AUDIT_BROWSER_STATUS.Opera,
  },
]

/* ----------------------------------------------*/
/* -------------------- Tabs --------------------*/
/* ----------------------------------------------*/
export const organization_details_tab = [
  {
    id: 0,
    label: "Company Details",
    value: ORG_DETAILS_TAB.company_details,
    icon: BusinessOutlined,
  },
  {
    id: 1,
    label: "Banking Information",
    value: ORG_DETAILS_TAB.banking_information,
    icon: AccountBalanceOutlined,
  },
  {
    id: 2,
    label: "Documents",
    value: ORG_DETAILS_TAB.documents,
    icon: TextSnippetOutlined,
  },
  {
    id: 3,
    label: "Employees",
    value: ORG_DETAILS_TAB.employees,
    icon: AssignmentIndOutlined,
  },

  // {
  //   id: 5,
  //   label: "Notification",
  //   value: ORG_DETAILS_TAB.notification,
  //   icon: NotificationsNoneOutlined,
  // },

  {
    id: 5,
    label: "Advance Configuration",
    value: ORG_DETAILS_TAB.loan_configuration,
    icon: CreditScoreOutlined,
  },
]

export const customer_details_tab = [
  {
    id: 0,
    label: "Employee Profile",
    value: CUS_DETAILS_TAB.employee_profile,
    icon: PortraitRounded,
  },
  {
    id: 1,
    label: "Salary Info",
    value: CUS_DETAILS_TAB.salary_info,
    icon: PaymentsOutlined,
  },
  {
    id: 2,
    label: "Banking Details",
    value: CUS_DETAILS_TAB.banking_details,
    icon: AccountBalanceOutlined,
  },
  {
    id: 3,
    label: "Advance Request",
    value: CUS_DETAILS_TAB.loan_request,
    icon: CreditScoreOutlined,
  },
  {
    id: 4,
    label: "Repayments",
    value: CUS_DETAILS_TAB.repayments,
    icon: RestartAltOutlined,
  },
  {
    id: 5,
    label: "Documents",
    value: CUS_DETAILS_TAB.documents,
    icon: ArticleOutlined,
  },
]

export const approval_loan_tab = [
  {
    id: 0,
    label: "Advance Id",
    value: LOAN_TAB.loan_id,
    icon: Assignment,
  },
  {
    id: 1,
    label: "All Advances",
    value: LOAN_TAB.all_loans,
    icon: ListAlt,
  },
  {
    id: 2,
    label: "Payments",
    value: LOAN_TAB.payments,
    icon: Payment,
  },
  {
    id: 3,
    label: "Documents",
    value: LOAN_TAB.documents,
    icon: Description,
  },
  {
    id: 4,
    label: "View Employee Info",
    value: LOAN_TAB.view_employee_info,
    icon: Person,
  },
]

export const approval_workflow_tab = [
  {
    id: 0,
    label: "Active",
    value: APPROVE_WORKFLOW_TAB.Active,
    icon: Check,
  },
  {
    id: 1,
    label: "Inactive",
    value: APPROVE_WORKFLOW_TAB.Inactive,
    icon: Close,
  },
]

export const profile_sidebar = [
  { text: 'Personal Information', icon: HomeOutlined, value: PROFILE_SIDEBAR.personal_information },
  { text: 'Account Info & Activity', icon: SettingsAccessibilityOutlined, value: PROFILE_SIDEBAR.account_detail },
  { text: 'Security Settings', icon: SecurityOutlined, value: PROFILE_SIDEBAR.security_settings },

  // { text: 'Preferences', icon: BuildOutlined, value: PROFILE_SIDEBAR.preference },
  // { text: 'Activity and History', icon: HistoryOutlined, value: PROFILE_SIDEBAR.activity },

  { text: 'Support', icon: HelpOutline, value: PROFILE_SIDEBAR.support },
  { text: 'Legal', icon: GavelOutlined, value: PROFILE_SIDEBAR.legal },
  { text: 'Deactivate', icon: ExitToAppOutlined, value: PROFILE_SIDEBAR.deactivate },
]

/* ----------------------------------------------*/
/* --------------- Dropdown List ----------------*/
/* ----------------------------------------------*/
export const rowsPerPageList = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
]

export const corporationTypes = [
  { label: "Corporation", value: "corporation" },
  { label: "Limited Liability Company", value: "limitedLiabilityCompany" },
  { label: "Non profit", value: "nonprofit" },
  { label: "C Corporation", value: "cCorporation" },
  { label: "Private Limited Company", value: "privateLimitedCompany" },
  { label: "Cooperative", value: "cooperative" },
  { label: "NGO", value: "ngo" },
  { label: "One Person Company", value: "onePersonCompany" },
  { label: "Limited Company", value: "limitedCompany" },
  { label: "Partnership", value: "partnership" },
  { label: "Sole Proprietorship", value: "soleProprietorship" },
]

export const loanTypes = [
  { label: "Personal Advance", value: "personalLoan" },
  { label: "Employee Advance", value: "employeeLoan" },
]

export const interestRateOptions = [
  { label: "Fixed interest rate", value: "fixedInterestRate" },
  { label: "Variable interest rate", value: "variableInterestRate" },
  { label: "Annual Percentage Rate (APR)", value: "annualPercentageRate" },
]

export const employeeStatus = [
  {
    label: "Full Time",
    value: "fullTime",
  },
  {
    label: "Part-time",
    value: "partTime",
  },
  {
    label: "Self-employed",
    value: "selfEmployed",
  },
]

export const employeeIncome = [
  {
    label: "50000 - 1,00,000",
    value: "50000-100000",
  },
  {
    label: "1,00,001 - 2,50,000",
    value: "100001-250000",
  },
  {
    label: "2,50,001 - 5,00,000",
    value: "250001-500000",
  },
]

export const paymentFrequencies = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Bi-monthly",
    value: "biMonthly",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Annual payments",
    value: "annualPayments",
  },
  {
    label: "User Defined",
    value: "userDefined",
  },
]

export const paymentMethodsOptions = [
  {
    label: "Direct debit",
    value: "directDebit",
  },
  {
    label: "Bank transfer",
    value: "bankTransfer",
  },
  {
    label: "E- Mandate / UPI",
    value: "eMandateUPI",
  },
]

export const feesChargesOptions = [
  {
    label: "Processing fee ( 10% )",
    value: "processingFee10",
  },
  {
    label: "Late payment fee ( 20% )",
    value: "latePaymentFee20",
  },
  {
    label: "Prepayment penalty ( 30% )",
    value: "prepaymentPenalty30",
  },
]

export const FilterDayOptions = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last 7 Days",
    value: 'last7days'

  },
  {
    label: "Last 30 Days",
    value: 'last30days'

  },
  {
    label: "Last 6 Months",
    value: 'last6months'

  }
]

export const customerStatusFilter = [
  {
    label: "All",
    value: PAYMENT_STATUS.All,
  },
  {
    label: "Paid",
    value: PAYMENT_STATUS.Paid,
  },
  {
    label: "Unpaid",
    value: PAYMENT_STATUS.Unpaid,
  },
  {
    label: "Overdue",
    value: PAYMENT_STATUS.Overdue,
  },
  {
    label: "Bulk payment",
    value: PAYMENT_STATUS.Bulk_Payment,
  },
]

export const customerRequestFilter = [
  {
    label: "All",
    value: LOAN_REQUEST.All,
  },
  {
    label: "Approved",
    value: LOAN_REQUEST.Approved,
  },
  {
    label: "Disbursed",
    value: LOAN_REQUEST.Disbursed,
  },
  {
    label: "Pending",
    value: LOAN_REQUEST.Pending,
  },
  {
    label: "Rejected",
    value: LOAN_REQUEST.Rejected,
  },
]

export const customerRequestCountFilter = [
  {
    label: "All",
    value: LOAN_REQUEST.All,
  },
  {
    label: "Approved",
    value: LOAN_REQUEST.Approved,
  },
  {
    label: "Pending",
    value: LOAN_REQUEST.Pending,
  },
  {
    label: "Rejected",
    value: LOAN_REQUEST.Rejected,
  },
]

export const loanStatusFilter = [
  {
    label: "All",
    value: LOAN_STATUS.All,
  },
  {
    label: "Paid",
    value: LOAN_STATUS.Paid,
  },
  {
    label: "Open",
    value: LOAN_STATUS.Open,
  },
  {
    label: "Closed",
    value: LOAN_STATUS.Closed,
  },
]

export const loanData = [
  {
    loanId: "LN123456",
    orderId: "ORD001",
    transactionId: "TXN001",
    merchantId: "MERCH001",
    username: "john_doe",
    userEmail: "john_doe@example.com",
    loanAmount: 50000,
    status: "Unpaid",
    loanType: "Personal",
    loanProcessDate: "2023-01-15",
    loanDueDate: "2028-01-15",
    monthlyEMI: 1000,
    emiPaid: 15000,
    emiBalance: 35000,
    paymentTerm: "60 months",
    modeOfPayment: "Bank Transfer",
  },
  {
    loanId: "LN123457",
    orderId: "ORD002",
    transactionId: "TXN002",
    merchantId: "MERCH002",
    username: "jane_smith",
    userEmail: "jane_smith@example.com",
    loanAmount: 75000,
    status: "Overdue",
    loanType: "Home",
    loanProcessDate: "2022-06-10",
    loanDueDate: "2032-06-10",
    monthlyEMI: 1250,
    emiPaid: 20000,
    emiBalance: 55000,
    paymentTerm: "120 months",
    modeOfPayment: "Credit Card",
  },
  {
    loanId: "LN123458",
    orderId: "ORD003",
    transactionId: "TXN003",
    merchantId: "MERCH003",
    username: "alice_wonder",
    userEmail: "alice_wonder@example.com",
    loanAmount: 30000,
    status: "Paid",
    loanType: "Auto",
    loanProcessDate: "2018-03-05",
    loanDueDate: "2023-03-05",
    monthlyEMI: 500,
    emiPaid: 30000,
    emiBalance: 0,
    paymentTerm: "60 months",
    modeOfPayment: "Direct Debit",
  },
  {
    loanId: "LN123459",
    orderId: "ORD004",
    transactionId: "TXN004",
    merchantId: "MERCH004",
    username: "bob_builder",
    userEmail: "bob_builder@example.com",
    loanAmount: 100000,
    status: "Unpaid",
    loanType: "Business",
    loanProcessDate: "2021-09-01",
    loanDueDate: "2026-09-01",
    monthlyEMI: 2000,
    emiPaid: 24000,
    emiBalance: 76000,
    paymentTerm: "60 months",
    modeOfPayment: "UPI",
  },
  {
    loanId: "LN123460",
    orderId: "ORD005",
    transactionId: "TXN005",
    merchantId: "MERCH005",
    username: "charlie_brown",
    userEmail: "charlie_brown@example.com",
    loanAmount: 40000,
    status: "Overdue",
    loanType: "Education",
    loanProcessDate: "2022-11-20",
    loanDueDate: "2027-11-20",
    monthlyEMI: 800,
    emiPaid: 9600,
    emiBalance: 30400,
    paymentTerm: "60 months",
    modeOfPayment: "Net Banking",
  },
  {
    loanId: "LN123461",
    orderId: "ORD006",
    transactionId: "TXN006",
    merchantId: "MERCH006",
    username: "dave_grohl",
    userEmail: "dave_grohl@example.com",
    loanAmount: 25000,
    status: "Unpaid",
    loanType: "Personal",
    loanProcessDate: "2023-04-18",
    loanDueDate: "2028-04-18",
    monthlyEMI: 500,
    emiPaid: 3000,
    emiBalance: 22000,
    paymentTerm: "60 months",
    modeOfPayment: "Cash",
  },
  {
    loanId: "LN123462",
    orderId: "ORD007",
    transactionId: "TXN007",
    merchantId: "MERCH007",
    username: "eve_green",
    userEmail: "eve_green@example.com",
    loanAmount: 60000,
    status: "Paid",
    loanType: "Auto",
    loanProcessDate: "2017-07-22",
    loanDueDate: "2022-07-22",
    monthlyEMI: 1000,
    emiPaid: 60000,
    emiBalance: 0,
    paymentTerm: "60 months",
    modeOfPayment: "Cheque",
  },
  {
    loanId: "LN123463",
    orderId: "ORD008",
    transactionId: "TXN008",
    merchantId: "MERCH008",
    username: "frank_underwood",
    userEmail: "frank_underwood@example.com",
    loanAmount: 85000,
    status: "Unpaid",
    loanType: "Home",
    loanProcessDate: "2023-02-12",
    loanDueDate: "2033-02-12",
    monthlyEMI: 1420,
    emiPaid: 17040,
    emiBalance: 67960,
    paymentTerm: "120 months",
    modeOfPayment: "Debit Card",
  },
  {
    loanId: "LN123464",
    orderId: "ORD009",
    transactionId: "TXN009",
    merchantId: "MERCH009",
    username: "grace_hopper",
    userEmail: "grace_hopper@example.com",
    loanAmount: 70000,
    status: "Overdue",
    loanType: "Business",
    loanProcessDate: "2021-11-25",
    loanDueDate: "2026-11-25",
    monthlyEMI: 1400,
    emiPaid: 16800,
    emiBalance: 53200,
    paymentTerm: "60 months",
    modeOfPayment: "Bank Transfer",
  },
  {
    loanId: "LN123465",
    orderId: "ORD010",
    transactionId: "TXN010",
    merchantId: "MERCH010",
    username: "henry_ford",
    userEmail: "henry_ford@example.com",
    loanAmount: 45000,
    status: "Paid",
    loanType: "Personal",
    loanProcessDate: "2019-02-20",
    loanDueDate: "2024-02-20",
    monthlyEMI: 750,
    emiPaid: 45000,
    emiBalance: 0,
    paymentTerm: "60 months",
    modeOfPayment: "Credit Card",
  },
  {
    loanId: "LN123466",
    orderId: "ORD011",
    transactionId: "TXN011",
    merchantId: "MERCH011",
    username: "ida_lovelace",
    userEmail: "ida_lovelace@example.com",
    loanAmount: 55000,
    status: "Unpaid",
    loanType: "Education",
    loanProcessDate: "2023-05-14",
    loanDueDate: "2028-05-14",
    monthlyEMI: 1100,
    emiPaid: 6600,
    emiBalance: 48400,
    paymentTerm: "60 months",
    modeOfPayment: "UPI",
  },
  {
    loanId: "LN123467",
    orderId: "ORD012",
    transactionId: "TXN012",
    merchantId: "MERCH012",
    username: "james_turing",
    userEmail: "james_turing@example.com",
    loanAmount: 120000,
    status: "Overdue",
    loanType: "Home",
    loanProcessDate: "2020-08-08",
    loanDueDate: "2030-08-08",
    monthlyEMI: 2000,
    emiPaid: 48000,
    emiBalance: 72000,
    paymentTerm: "120 months",
    modeOfPayment: "Direct Debit",
  },
  {
    loanId: "LN123468",
    orderId: "ORD013",
    transactionId: "TXN013",
    merchantId: "MERCH013",
    username: "karen_jones",
    userEmail: "karen_jones@example.com",
    loanAmount: 65000,
    status: "Paid",
    loanType: "Auto",
    loanProcessDate: "2016-12-30",
    loanDueDate: "2021-12-30",
    monthlyEMI: 1083,
    emiPaid: 65000,
    emiBalance: 0,
    paymentTerm: "60 months",
    modeOfPayment: "Net Banking",
  },
  {
    loanId: "LN123469",
    orderId: "ORD014",
    transactionId: "TXN014",
    merchantId: "MERCH014",
    username: "lily_evans",
    userEmail: "lily_evans@example.com",
    loanAmount: 85000,
    status: "Unpaid",
    loanType: "Business",
    loanProcessDate: "2022-03-15",
    loanDueDate: "2027-03-15",
    monthlyEMI: 1700,
    emiPaid: 20400,
    emiBalance: 64600,
    paymentTerm: "60 months",
    modeOfPayment: "Cash",
  },
  {
    loanId: "LN123470",
    orderId: "ORD015",
    transactionId: "TXN015",
    merchantId: "MERCH015",
    username: "mike_tyson",
    userEmail: "mike_tyson@example.com",
    loanAmount: 95000,
    status: "Overdue",
    loanType: "Personal",
    loanProcessDate: "2021-01-10",
    loanDueDate: "2026-01-10",
    monthlyEMI: 1583,
    emiPaid: 31660,
    emiBalance: 63340,
    paymentTerm: "60 months",
    modeOfPayment: "Debit Card",
  },
]

export const loanTransaction = []

export const paymentHistory = [
  {
    amount: 150.75,
    transactionId: "TXN001",
    paymentMethod: "Credit Card",
    receivedDate: "2024-06-15",
  },
  {
    amount: 200.0,
    transactionId: "TXN002",
    paymentMethod: "PayPal",
    receivedDate: "2024-06-16",
  },
  {
    amount: 320.5,
    transactionId: "TXN003",
    paymentMethod: "Bank Transfer",
    receivedDate: "2024-06-17",
  },
  {
    amount: 89.99,
    transactionId: "TXN004",
    paymentMethod: "Credit Card",
    receivedDate: "2024-06-18",
  },
  {
    amount: 450.0,
    transactionId: "TXN005",
    paymentMethod: "Debit Card",
    receivedDate: "2024-06-19",
  },
  {
    amount: 299.99,
    transactionId: "TXN006",
    paymentMethod: "PayPal",
    receivedDate: "2024-06-20",
  },
  {
    amount: 500.0,
    transactionId: "TXN007",
    paymentMethod: "Bank Transfer",
    receivedDate: "2024-06-21",
  },
  {
    amount: 120.75,
    transactionId: "TXN008",
    paymentMethod: "Credit Card",
    receivedDate: "2024-06-22",
  },
  {
    amount: 75.5,
    transactionId: "TXN009",
    paymentMethod: "Debit Card",
    receivedDate: "2024-06-23",
  },
  {
    amount: 620.0,
    transactionId: "TXN010",
    paymentMethod: "PayPal",
    receivedDate: "2024-06-24",
  },
]

export const loanRequestData = [
  {
    username: "john_doe",
    organizationName: "TechCorp",
    organizationApproval: true,
    loanType: "Personal",
    loanAmount: 15000,
    attendance: 95,
    status: "Approved",
  },
  {
    username: "jane_smith",
    organizationName: "HealthInc",
    organizationApproval: false,
    loanType: "Home",
    loanAmount: 200000,
    attendance: 90,
    status: "Pending",
  },
  {
    username: "alice_jones",
    organizationName: "EduWorld",
    organizationApproval: true,
    loanType: "Education",
    loanAmount: 50000,
    attendance: 98,
    status: "Approved",
  },
  {
    username: "bob_martin",
    organizationName: "AutoWorks",
    organizationApproval: true,
    loanType: "Car",
    loanAmount: 25000,
    attendance: 85,
    status: "Approved",
  },
  {
    username: "charlie_brown",
    organizationName: "RetailCo",
    organizationApproval: false,
    loanType: "Business",
    loanAmount: 100000,
    attendance: 92,
    status: "Pending",
  },
  {
    username: "david_clark",
    organizationName: "FinancePlus",
    organizationApproval: true,
    loanType: "Personal",
    loanAmount: 12000,
    attendance: 89,
    status: "Approved",
  },
  {
    username: "emma_wilson",
    organizationName: "TechCorp",
    organizationApproval: true,
    loanType: "Home",
    loanAmount: 180000,
    attendance: 93,
    status: "Approved",
  },
  {
    username: "frank_white",
    organizationName: "HealthInc",
    organizationApproval: false,
    loanType: "Education",
    loanAmount: 60000,
    attendance: 87,
    status: "Pending",
  },
  {
    username: "grace_kim",
    organizationName: "EduWorld",
    organizationApproval: true,
    loanType: "Car",
    loanAmount: 22000,
    attendance: 94,
    status: "Approved",
  },
  {
    username: "henry_lee",
    organizationName: "AutoWorks",
    organizationApproval: true,
    loanType: "Business",
    loanAmount: 150000,
    attendance: 91,
    status: "Approved",
  },
]

export const sectorsandindustryList = [
  { label: "Agriculture and Forestry", value: "agriculture_forestry" },
  { label: "Crop Production", value: "crop_production" },
  { label: "Animal Husbandry", value: "animal_husbandry" },
  { label: "Dairy Farming", value: "dairy_farming" },
  { label: "Forestry", value: "forestry" },
  { label: "Fishing and Aquaculture", value: "fishing_aquaculture" },
  { label: "Organic Farming", value: "organic_farming" },
  { label: "Mining and Natural Resources", value: "mining_natural_resources" },
  { label: "Oil and Gas Extraction", value: "oil_gas_extraction" },
  { label: "Coal Mining", value: "coal_mining" },
  { label: "Metal Ore Mining", value: "metal_ore_mining" },
  { label: "Nonmetallic Mineral Mining", value: "nonmetallic_mineral_mining" },
  { label: "Quarrying", value: "quarrying" },
  { label: "Renewable Resource Extraction", value: "renewable_resource_extraction" },
  { label: "Construction and Real Estate", value: "construction_real_estate" },
  { label: "Residential Construction", value: "residential_construction" },
  { label: "Commercial Construction", value: "commercial_construction" },
  { label: "Industrial Construction", value: "industrial_construction" },
  { label: "Real Estate Development", value: "real_estate_development" },
  { label: "Property Management", value: "property_management" },
  { label: "Urban Planning", value: "urban_planning" },
  { label: "Manufacturing", value: "manufacturing" },
  { label: "Food and Beverage Manufacturing", value: "food_beverage_manufacturing" },
  { label: "Textiles and Apparel", value: "textiles_apparel" },
  { label: "Automotive Manufacturing", value: "automotive_manufacturing" },
  { label: "Electronics and Electrical Equipment", value: "electronics_electrical_equipment" },
  { label: "Chemicals and Pharmaceuticals", value: "chemicals_pharmaceuticals" },
  { label: "Machinery and Equipment", value: "machinery_equipment" },
  { label: "Furniture Manufacturing", value: "furniture_manufacturing" },
  { label: "Energy and Utilities", value: "energy_utilities" },
  { label: "Electric Power Generation", value: "electric_power_generation" },
  { label: "Renewable Energy", value: "renewable_energy" },
  { label: "Natural Gas Distribution", value: "natural_gas_distribution" },
  { label: "Water Supply and Waste Management", value: "water_supply_waste_management" },
  { label: "Nuclear Power", value: "nuclear_power" },
  { label: "Energy Storage", value: "energy_storage" },
  { label: "Transportation and Logistics", value: "transportation_logistics" },
  { label: "Shipping and Freight", value: "shipping_freight" },
  { label: "Rail Transport", value: "rail_transport" },
  { label: "Air Transport", value: "air_transport" },
  { label: "Warehousing and Distribution", value: "warehousing_distribution" },
  { label: "Logistics and Supply Chain Management", value: "logistics_supply_chain_management" },
  { label: "Public Transit", value: "public_transit" },
  { label: "Retail and Wholesale Trade", value: "retail_wholesale_trade" },
  { label: "Retail Stores", value: "retail_stores" },
  { label: "Wholesale Distribution", value: "wholesale_distribution" },
  { label: "E-commerce", value: "e_commerce" },
  { label: "Specialty Stores", value: "specialty_stores" },
  { label: "Direct Selling", value: "direct_selling" },
  { label: "Financial Services", value: "financial_services" },
  { label: "Banking", value: "banking" },
  { label: "Insurance", value: "insurance" },
  { label: "Investment Services", value: "investment_services" },
  { label: "Real Estate Investment Trusts (REITs)", value: "reits" },
  { label: "Wealth Management", value: "wealth_management" },
  { label: "Fintech", value: "fintech" },
  { label: "Professional Services", value: "professional_services" },
  { label: "Legal Services", value: "legal_services" },
  { label: "Accounting and Auditing", value: "accounting_auditing" },
  { label: "Management Consulting", value: "management_consulting" },
  { label: "Marketing and Advertising", value: "marketing_advertising" },
  { label: "Human Resources Consulting", value: "hr_consulting" },
  { label: "IT Consulting", value: "it_consulting" },
  { label: "Healthcare and Social Assistance", value: "healthcare_social_assistance" },
  { label: "Hospitals and Clinics", value: "hospitals_clinics" },
  { label: "Nursing Homes", value: "nursing_homes" },
  { label: "Outpatient Care", value: "outpatient_care" },
  { label: "Mental Health Services", value: "mental_health_services" },
  { label: "Social Services", value: "social_services" },
  { label: "Medical Research", value: "medical_research" },
  { label: "Education and Training", value: "education_training" },
  { label: "Primary and Secondary Education", value: "primary_secondary_education" },
  { label: "Higher Education", value: "higher_education" },
  { label: "Vocational Training", value: "vocational_training" },
  { label: "Educational Technology", value: "educational_technology" },
  { label: "Continuing Education", value: "continuing_education" },
  { label: "Information Technology (IT)", value: "information_technology" },
  { label: "Software Development", value: "software_development" },
  { label: "IT Services and Support", value: "it_services_support" },
  { label: "Hardware Manufacturing", value: "hardware_manufacturing" },
  { label: "Telecommunications", value: "telecommunications" },
  { label: "Cybersecurity", value: "cybersecurity" },
  { label: "Cloud Computing", value: "cloud_computing" },
  { label: "Media and Entertainment", value: "media_entertainment" },
  { label: "Television and Film Production", value: "television_film_production" },
  { label: "Music and Performing Arts", value: "music_performing_arts" },
  { label: "Publishing", value: "publishing" },
  { label: "Gaming and Interactive Media", value: "gaming_interactive_media" },
  { label: "Digital Content Creation", value: "digital_content_creation" },
  { label: "Broadcasting", value: "broadcasting" },
  { label: "Hospitality and Tourism", value: "hospitality_tourism" },
  { label: "Hotels and Resorts", value: "hotels_resorts" },
  { label: "Restaurants and Food Services", value: "restaurants_food_services" },
  { label: "Travel Agencies", value: "travel_agencies" },
  { label: "Event Planning", value: "event_planning" },
  { label: "Leisure and Recreational Activities", value: "leisure_recreational_activities" },
  { label: "Tour Operators", value: "tour_operators" },
  { label: "Consumer Goods and Services", value: "consumer_goods_services" },
  { label: "Household Goods", value: "household_goods" },
  { label: "Personal Care Products", value: "personal_care_products" },
  { label: "Leisure and Recreational Products", value: "leisure_recreational_products" },
  { label: "Beauty and Wellness Products", value: "beauty_wellness_products" },
]
