import { styled } from "@mui/material"

interface ISideFooter {
  children: JSX.Element
}

export const SideFooter = styled("div")(() => ({
  padding: "15px 25px",
  ".side_footer_section":{
      display:"flex",
      justifyContent:"flex-end",
  }
}))

export default function SideDialogSideFooter(props: ISideFooter) {
  const { children } = props

  return (
    <SideFooter id="sideDialogFooter">
      <div className="side_footer_section">{children}</div>
    </SideFooter>
  )
}
