import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { IQueryUpdate } from "src/interface"

export default function useQueryParamsHook() {
  const { search } = useLocation()
  const navigate = useNavigate()

  const queryParamHandler = (data: IQueryUpdate[], deleteParams?: string[]) => {
    const url = window.location.search
    const params = new URLSearchParams(url)
    data.forEach((query: IQueryUpdate) => {
      params.set(query.name, query.value)
    })
    if (deleteParams) {
      deleteParams.forEach((d: string) => {
        params.delete(d)
      })
    }

    return params
  }

  const updateQueryParam = (data: IQueryUpdate[], deleteParams?: string[]) => {
    const params = queryParamHandler(data, deleteParams)
    navigate(`${window.location.pathname}?${params}`)
  }

  return { queryValue: React.useMemo(() => new URLSearchParams(search), [search]), updateQueryParam }
}
