import { CloseOutlined, CloudUploadOutlined, DescriptionOutlined } from "@mui/icons-material"
import { Grid } from "@mui/material"
import {
  FileUploadContainer,
  FormError,
  FormHint,
  UploadFilesView,
  UploadImgView,
  UploadInputField,
} from "src/assets/styles/form"
import { IChangeEvent, IFile, IFormUpload } from "src/interface"
import { fadeAnimation, fileType } from "src/resources"
import { bytesToSize, getReadableFileType } from "src/utils"

export default function FormInputUpload(props: IFormUpload) {
  const {
    name,
    multiple,
    files,
    accept,
    onChange,
    maxSizeInBytes,
    error,
    placeholderTxt,
    placeholderContent,
    columnSize,
    hint,
  } = props

  const inputProps = { name, multiple }

  const fileUploadHandler = async (e: IChangeEvent) => {
    const fileList = e.target.files;
    let error: string | null = null;
    const newArray: IFile[] = [];

    if (fileList) {
      Array.from(fileList).forEach((item: File) => {
        // Validate file size
        const sizeValidator = item.size <= (maxSizeInBytes as number)
          ? ""
          : `Max file size ${maxSizeInBytes && bytesToSize(maxSizeInBytes)} reached`;

        // Validate file format
        const formatValidator = accept?.includes(item.type)
          ? ""
          : `Only allow the files in the following formats: ${accept
            ?.map(e => (e.split("/")[1] ? getReadableFileType(e) : e.split("/")[0]))
            .join(", ")}`;

        // Combine error messages if both size and format validations fail
        error = sizeValidator || formatValidator;

        // Add file to the array if there are no errors
        if (!error) {
          newArray.push({
            name: item.name,
            fileType: item.type,
            size: bytesToSize(item.size),
            url: URL.createObjectURL(item),
            file: item,
          });
        }
      });

      // Ensure that `props?.files` is defined and iterable
      const currentFiles = Array.isArray(props.files) ? props.files : [];
      const result = multiple ? [...currentFiles, ...newArray] : newArray;

      // Pass back the results and errors through the onChange handler
      onChange({ name, value: result, error: error || undefined });
    }
  };


  const deleteUploadedFile = (index: number) => {
    if (files) {
      onChange({ name, value: files.filter((_, i) => i !== index) })
    }
  }

  const placeholderText = Array.from(
    new Set(
      accept?.map((e) => {
        const ext = e.includes("/") ? e.split("/")[1] : e;

        return ["doc", "docx", "pdf", "png", "jpg", "webp", "jpeg", "jfif"].includes(ext) ? ext : null;
      }).filter(Boolean) // Filter out null values
    )
  ).join(", ");


  return (
    <>
      <FileUploadContainer>
        <Grid container>
          {(multiple || (!multiple && files?.length === 0)) && (
            <Grid item lg={columnSize?.fieldSize ? columnSize.fieldSize : 12} className="mb-3">
              <UploadInputField
                className={fadeAnimation}
                error={(error as string)?.length > 0}
              >
                <input
                  {...inputProps}
                  type="file"
                  accept={accept?.join(",")}
                  onChange={fileUploadHandler}
                  onClick={e => {
                    e.currentTarget.value = ""
                  }}
                />
                <div className="uploadContent">
                  {placeholderContent ? (
                    placeholderContent
                  ) : (
                    <>
                      <div className="icon">
                        <CloudUploadOutlined />
                      </div>
                      <div className="label">{placeholderTxt ? placeholderTxt : "Choose a file to upload"}</div>
                      <div className="hint">
                        {maxSizeInBytes && <>Max files size: {bytesToSize(maxSizeInBytes)}.</>}{" "}
                        {accept && (
                          <>
                            Only allow the files in the following{" "}
                            {placeholderText}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </UploadInputField>
              {error && <FormError>{error}</FormError>}
              <FormHint>{hint}</FormHint>
            </Grid>
          )}

          {files &&
            files.length > 0 &&
            files.map((item: any, i: number) => {

              return (
                <Grid item lg={columnSize?.previewSize ? columnSize?.previewSize : 12} key={i} className="mb-3">
                  {item && fileType.includes(item.fileType) ? (
                    <UploadFilesView>
                      <div className="doc">
                        <DescriptionOutlined />
                        <span className="fileName">{item.name}</span>
                      </div>
                      <button className="delete_btn" onClick={() => deleteUploadedFile(i)}>
                        <CloseOutlined />
                      </button>
                    </UploadFilesView>
                  ) : (
                    <UploadImgView>
                      <div className="img">
                        <img src={item.url ? item.url : item.file} alt={item.name} />
                      </div>
                      <div className="overlay">
                        <span className="fileName">{item.name}</span>
                        <button className="delete_btn" onClick={() => deleteUploadedFile(i)}>
                          <CloseOutlined />
                        </button>
                      </div>
                    </UploadImgView>
                  )}
                </Grid>
              )
            })}
        </Grid>
      </FileUploadContainer>
    </>
  )
}
